/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import {
    List,
    TextField,
    Datagrid,
    EditButton,
    Filter,
    TextInput,
    SelectInput,
    useListContext
} from 'react-admin';
import { Image, Movie } from '@material-ui/icons';
import { mediaStatuses } from '../../constants';
import BasicActionsToolbar from '../BasicActionsToolbar';

const MediaListFilters = (props) => {
    return (
        <Filter {...props}>
            <TextInput label="Name" source="name" />
            <SelectInput
                label="Workflow Status"
                source="workflowStatus"
                choices={mediaStatuses.map((status) => ({
                    id: status.id,
                    name: status.status
                }))}
            />
        </Filter>
    );
};

const WorkflowCustomField = ({ record, source }) => {
    const backgroundColor =
        mediaStatuses.find((el) => el.id === record[source])?.backgroundColor ||
        'gray';
    const label =
        mediaStatuses.find((el) => el.id === record[source])?.status ||
        record[source];
    return (
        <span className="workflow-custom-field" style={{ backgroundColor }}>
            {label}
        </span>
    );
};

const ThumbnailField = ({ record, source }) => {
    const style = { fontSize: 40, color: 'lightblue' };
    const [thumb, setThumb] = useState(<Image style={style} />);
    React.useEffect(() => {
        if (record) {
            switch (record.type.name) {
                case 'VIDEO':
                    setThumb(<Movie style={style} />);
                    break;
                case 'IMAGE':
                    if (record[source]) {
                        const testImage = document.createElement('img');
                        testImage.src = record[source];
                        testImage.onerror = () => {
                            setThumb(<Image style={style} />);
                        };

                        testImage.onload = () => {
                            setThumb(
                                <img
                                    className="list-thumbnail"
                                    src={record[source]}
                                    alt={record[source]}
                                />
                            );
                        };
                    } else {
                        setThumb(<Image style={style} />);
                    }
                    break;
                default:
                    setThumb(<Image style={style} />);
                    break;
            }
        }
    }, [record]);

    return <div className="align-left">{thumb}</div>;
};

function MediaList(props) {
    return (
        <List
            {...props}
            actions={
                <BasicActionsToolbar
                    listContext={useListContext}
                    showFilters
                    showCreate
                    {...props}
                />
            }
            filters={<MediaListFilters />}
        >
            <Datagrid>
                <WorkflowCustomField source="workflowStatus" label="Workflow" />
                <TextField source="type.name" label="Type" />
                <ThumbnailField
                    className="align-center"
                    source="previewFullSource"
                    label="Preview"
                />
                <TextField source="name" label="Name" />
                <EditButton />
            </Datagrid>
        </List>
    );
}

export default MediaList;
