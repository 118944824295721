import { Introspecter } from '@api-platform/admin';
import List from './LocksList';
import Show from './LocksShow';
import Edit from './LocksEdit';
import Create from './LocksCreate';

export default {
    list: List,
    show: Show,
    edit: Edit,
    create: (props) => <Introspecter component={Create} {...props} />,
    resource: 'locks',
    name: 'locks'
};
