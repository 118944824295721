(function(){var g={}; var _ = _ || {};
var f=function(window){/*

 Copyright 2016 Google Inc.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
var Dt,Et,Ft,Gt,Ht,Jt,Kt,Mt,Nt,Ot,Pt,Qt,Rt,St,Tt,Vt,Wt,Xt,Yt,Zt,$t,au,bu,cu,du,eu,fu,gu,hu,ju,ku,lu,mu,nu,ou,pu,qu,ru,su,tu,uu,vu,wu,yu,xu,zu,Bu,Cu,Du,Eu,Fu,Gu,Hu,Iu,Ku,Lu,Mu,Ou,Nu,Qu,Ru,Pu,Uu,Su,Vu,Wu,Tu,Yu,$u,bv,Zu,cv,av,dv,gv,hv,iv,ev,fv,jv,kv,lv,mv,nv,ov,qv,sv,rv,uv,Tv,Uv,Vv,Wv,Yv,Xv,$v,bw,aw,Zv,cw,ew,dw,fw,gw,jw,iw,kw,lw,mw,nw,pw,hw,qw,rw,sw,tw,uw,vw,ww,yw,zw,xw,Iw,Hw,Fw,Jw,Kw,Lw,Nw,Ow,Qw,Pw,Rw,Uw,Sw,Tw,Ww,Vw,Xw,Yw,ax,cx,bx,dx,ex,fx,gx,ix,jx,kx,hx,mx,lx,qx,rx,tx,px,ox,ux,nx,sx,yx,Ax,xx,Bx,wx,
zx,vx,Ex,Fx,Kx,Mx,Ux,Ox,Wx,Ix,Xx,Gx,Vx,ay,Yx,by,Px,Rx,ey,dy,cy,my,ky,gy,fy,iy,ny,jy,$x,Hx,Nx,hy,Tx,Jx,Sx,oy,py,Zx,Qx,ly,sy,ty,uy,Ay,yy,xy,wy,zy,Cy,Gy,Oy,$y,Fy,Xy,Ty,Uy,Vy,Py,Wy,My,Dw,Bw,Ew,Cw,Gw,Aw,fz,dz,Ry,Ny,Zy,gz,ez,Yy,az,By,Qy,iz,Ly,Jy,lz,Sy,Ky,kz,jz,Dy,hz,cz,nz,Hy,bz,mz,oz,Ey,pz,iu;Dt=" buffer";Et=" is being or has been set up";Ft="Cannot find matching variant";Gt="Jumping forward";Ht="MediaSource threw QuotaExceededError:";_.It="No stream with id";Jt="Not a persistent session.";Kt="PatchedMediaKeysWebkit.MediaKeys.findSession_";
_.Lt="Player is in ERROR state and can not switch to ";Mt="PrestoPlay TextTrack";Nt="Removing audio from variant - audio not compatible with platform";Ot="Symbol(Symbol.iterator)";Pt="bufferBehind\x3d";Qt="bufferedBehind\x3d";Rt="calling onCanSwitch()...";St="clpp.mse.Player";Tt="currentPeriod.startTime\x3d";_.Ut="onChooseStreams_";Vt="output-restricted";Wt="presentationTime\x3d";Xt="reference.endTime\x3d";Yt="reference.startTime\x3d";Zt="switch: Stream ";$t="webkit-org.w3.clearkey";
au=function(a,b,c,d){return Promise.resolve().then(function(){if(a.o&&a.g)if(null==c||null==d)a.o.parseInit(_.I(b));else{var e={periodStart:a.w,segmentStart:c,segmentEnd:d};e=a.o.parseMedia(_.I(b),e);var f=e.cues.filter(function(g){return g.startTime>=a.h&&g.startTime<a.j});a.g.append(f,e.styles);null==a.a&&(a.a=Math.max(c,a.h));a.f=Math.min(d,a.j)}})};
bu=function(a,b,c,d,e){var f=c+" "+d,g=new Map;b=_.t(b);for(var k=b.next();!k.done;k=b.next()){var l=k.value;k=l.stream;g.has(k)||g.set(k,new Map);g.get(k).has(f)||g.get(k).set(f,[]);l.startTime+=e;l.endTime+=e;l.startTime>=a.h&&l.startTime<a.j&&(l=new _.Lg(l.startTime,l.endTime,l.text),g.get(k).get(f).push(l),k==a.A&&a.g.append([l],[]))}e=_.t(g.keys());for(f=e.next();!f.done;f=e.next())for(f=f.value,a.m.has(f)||a.m.set(f,new Map),b=_.t(g.get(f).keys()),k=b.next();!k.done;k=b.next())k=k.value,l=g.get(f).get(k),
a.m.get(f).set(k,l);a.a=null==a.a?Math.max(c,a.h):Math.min(a.a,Math.max(c,a.h));a.f=Math.max(a.f,Math.min(d,a.j))};cu=function(a){for(var b=a.a;_.Th(a)&&0!==a.f.getUint8(a.a);)a.a+=1;b=a.f.buffer.slice(b,a.a);a.a+=1;return _.Uf(b)};du=function(a,b){for(var c in a.a)b(c,a.a[c])};eu=function(a,b){return _.Kj("Edge/")||_.Kj("Edg/")?!0:a.L.has(b)};
fu=function(a,b){var c=b.audio,d=b.video;if(c&&c.encrypted&&!eu(a,_.Pg(c.mimeType,c.codecs))||d&&d.encrypted&&!eu(a,_.Pg(d.mimeType,d.codecs)))return!1;var e=a.keySystem();return 0==b.drmInfos.length||b.drmInfos.some(function(f){return f.keySystem==e})};gu=function(a){var b=a.bc()||!a.isLive()&&(Infinity==a.h||a.f)?0:a.D;return Math.max(0,_.pp(a)-b)};hu=function(a,b){var c=Math.max(a.j,0);if(Infinity==a.ic)return c;var d=_.pp(a)-a.ic;d=Math.min(d+b,gu(a));return Math.max(c,d)};
ju=function(a,b){if(!a&&!b)return!0;if(!a||!b||a.byteLength!=b.byteLength)return!1;if(_.Sf(a)==_.Sf(b)&&(a.byteOffset||0)==(b.byteOffset||0))return!0;for(var c=_.I(a),d=_.I(b),e=_.t(iu(a.byteLength)),f=e.next();!f.done;f=e.next())if(f=f.value,c[f]!=d[f])return!1;return!0};ku=function(a,b){for(var c in b)if(b.hasOwnProperty(c)&&b[c]===a)return c;return""};lu=function(a,b){for(var c=0,d=_.t(a),e=d.next();!e.done;e=d.next())c+=b(e.value)?1:0;return c};
mu=function(a){var b=[a.mimeType];_.Lp.forEach(function(c,d){var e=a[d];e&&b.push(c+'\x3d"'+e+'"')});return b.join(";")};nu=function(a,b){for(var c=new _.oj(void 0,void 0===b?"total":b),d=_.t(a),e=d.next();!e.done;e=d.next())c.a.push(e.value);c.length=a.length;return c};ou=function(a,b){for(var c=null,d=_.t(a),e=d.next();!e.done;e=d.next())e=e.value,b>=e.startTime&&(c=e);return c};
pu=function(a,b){if(a===b)return!0;if(!a||!b||a.length!==b.length)return!1;for(var c=0;c<a.length;++c)if(a[c]!==b[c])return!1;return!0};qu=function(a,b){a=_.no(a);b=_.no(b);var c=a.split("-"),d=b.split("-");return c[0]==d[0]&&1==c.length&&2==d.length};ru=function(a){return a.language?_.no(a.language):a.audio&&a.audio.language?_.no(a.audio.language):a.video&&a.video.language?_.no(a.video.language):_.qe};
su=function(a,b){for(var c=_.no(a),d=new Set,e=_.t(b),f=e.next();!f.done;f=e.next())d.add(_.no(f.value));e=_.t(d);for(f=e.next();!f.done;f=e.next())if(f=f.value,f==c)return f;e=_.t(d);for(f=e.next();!f.done;f=e.next())if(f=f.value,qu(f,c))return f;e=_.t(d);for(f=e.next();!f.done;f=e.next()){var g=f=f.value,k=c;g=_.no(g);k=_.no(k);g=g.split("-");k=k.split("-");if(2==g.length&&2==k.length&&g[0]==k[0])return f}d=_.t(d);for(f=d.next();!f.done;f=d.next())if(e=f.value,qu(c,e))return e;return null};
tu=function(){this.f=function(){};this.a=new Set};uu=function(a,b){a.f=b};
vu=function(a){var b=this;this.j=a;this.h=new Map;this.a=function(){};this.f=function(){};this.g=function(){};this.o=[{nc:null,mc:2,Xb:function(c,d){return b.a(c,d)}},{nc:1,mc:2,Xb:function(c,d){return b.a(c,d)}},{nc:3,mc:2,Xb:function(c,d){return b.a(c,d)}},{nc:2,mc:1,Xb:function(c,d){return b.f(c,d)}},{nc:2,mc:3,Xb:function(c,d){return b.f(c,d)}},{nc:1,mc:3,Xb:function(c,d){return b.g(c,d)}},{nc:3,mc:1,Xb:function(c,d){return b.g(c,d)}}]};wu=function(a,b,c,d){a.a=b;a.f=c;a.g=d};
yu=function(a){var b=this;this.f=a;this.a=new Set;this.g=(new _.Xi(function(){xu(b,!1)})).Ua(.25)};xu=function(a,b){for(var c=_.t(a.a),d=c.next();!d.done;d=c.next())d.value.m(a.f.currentTime,b)};zu=function(a,b){return a.start-b.start};_.Au=function(a){this.m=a;this.log=new _.D("clpp.renditions");this.f=[];this.j=this.a=null;this.h=!1};Bu=function(a){return a.f.map(function(b){return{start:b.start,end:b.end,width:b.width,height:b.height,bandwidth:b.bandwidth,rendition:b.rendition}})};
Cu=function(a){a.a&&a.a.trigger(new _.O(_.ac))};Du=function(a){if(a.a){for(var b=!1,c={},d=0;d<a.f.length;d++){var e=a.f[d],f=e.width+"x"+e.height,g=c[f];if(g&&g!==e.bandwidth){b=!0;break}c[f]=e.bandwidth}b&&!a.h?(a.log.debug("Tracking timeupdates"),a.a.on(_.pe,a.g,a),a.h=!0):!b&&a.h&&(a.log.debug("Stop tracking timeupdates"),a.a.off(_.pe,a.g),a.h=!1)}};
Eu=function(a,b,c){a.f=a.f.filter(function(d){if(d.end<=b||d.start>=c)return!1;d.start=Math.max(d.start,b);d.end=Math.min(d.end,c);return!0});a.a&&a.a.trigger(new _.O(_.Ee))};Fu=function(a){_.sg.call(this,a);a=a.getSurface().getMedia();this.a=null;for(var b=0;b<a.textTracks.length;++b){var c=a.textTracks[b];c.mode=_.Fc;c.label===Mt&&(this.a=c)}this.a||(this.a=a.addTextTrack(_.je,Mt));this.a.mode=_.Xc};
Gu=function(a,b){var c=a.mode;a.mode=c==_.ae?_.ae:_.Xc;for(var d=a.cues,e=d.length-1;0<=e;e--){var f=d[e];f&&b(f)&&a.removeCue(f)}a.mode=c};Hu=function(){};Iu=function(a,b,c){function d(f,g){return null==f?null==g:g.id==f}for(var e=0;e<c.length;e++)if(d(a,c[e].audio)&&d(b,c[e].video))return c[e];return null};Ku=function(a,b){var c=a&&b[Ju]();return function(d){return a?c.next():b[d]}};Lu=function(a){return typeof a===_.Vc||"[object Function]"===Object.prototype.toString.call(a)};
Mu=function(a,b,c){var d=Object(a),e=Lu(d[Ju]);if(null==a&&!e)throw new TypeError("Array.from requires an array-like object or iterator - not null or undefined");var f;if("undefined"!==typeof b){if(!Lu(b))throw new TypeError("Array.from: when provided, the second argument must be a function");"undefined"!==typeof c&&(f=c)}a=Number(d.length);a=Math.min(Math.max(isNaN(a)?0:0!==a&&isFinite(a)?(0<a?1:-1)*Math.floor(Math.abs(a)):a,0),Math.pow(2,53)-1);c=Lu(this)?Object(new this(a)):Array(a);a:{d=Ku(e,
d);for(var g=0;g<a||e;){var k=d(g),l=e?k.value:k;if(e&&k.done){b=c;break a}c[g]=b?"undefined"===typeof f?b(l,g):b.call(f,l,g):l;g+=1}if(e)throw new TypeError("Array.from: provided arrayLike or iterator has length more then 2 ** 52 - 1");c.length=a;b=c}return b};Ou=function(a,b){_.H("PatchedMediaKeysMs.requestMediaKeySystemAccess");try{var c=new Nu(a,b);return Promise.resolve(c)}catch(d){return Promise.reject(d)}};
Nu=function(a,b){_.H("PatchedMediaKeysMs.MediaKeySystemAccess");this.keySystem=a;for(var c=!1,d=0;d<b.length;++d){var e=b[d],f={audioCapabilities:[],videoCapabilities:[],persistentState:_.Dd,distinctiveIdentifier:_.Dd,initDataTypes:e.initDataTypes,sessionTypes:[_.ke],label:e.label},g=!1;if(e.audioCapabilities)for(var k=0;k<e.audioCapabilities.length;++k){var l=e.audioCapabilities[k];l.contentType&&(g=!0,MSMediaKeys.isTypeSupported(this.keySystem,l.contentType.split(";")[0])&&(f.audioCapabilities.push(l),
c=!0))}if(e.videoCapabilities)for(k=0;k<e.videoCapabilities.length;++k)l=e.videoCapabilities[k],l.contentType&&(g=!0,MSMediaKeys.isTypeSupported(this.keySystem,l.contentType.split(";")[0])&&(f.videoCapabilities.push(l),c=!0));g||(c=MSMediaKeys.isTypeSupported(this.keySystem,_.De));e.persistentState==_.Vd&&(c=!1);if(c){this.a=f;return}}c=Error(_.hb);c.name=_.Oa;c.code=DOMException.NOT_SUPPORTED_ERR;throw c;};
Qu=function(a){_.H("PatchedMediaKeysMs.setMediaKeys");var b=this.mediaKeys;b&&b!=a&&Pu(b,null);delete this.mediaKeys;return(this.mediaKeys=a)?Pu(a,this):Promise.resolve()};Ru=function(a){_.H("PatchedMediaKeysMs.MediaKeys");this.a=new MSMediaKeys(a);this.f=new _.zi};Pu=function(a,b){function c(){b.msSetMediaKeys(d.a);b.removeEventListener(_.kd,c)}_.Ci(a.f);if(!b)return Promise.resolve();a.f.on(b,"msneedkey",Su);var d=a;try{return 1<=b.readyState?b.msSetMediaKeys(a.a):b.addEventListener(_.kd,c),Promise.resolve()}catch(e){return Promise.reject(e)}};
Uu=function(a){_.H("PatchedMediaKeysMs.MediaKeySession");_.pj.call(this);this.g=null;this.j=a;this.f=this.a=null;this.h=new _.zi;this.sessionId="";this.expiration=NaN;this.closed=new _.lk;this.keyStatuses=new Tu};Su=function(a){_.H("PatchedMediaKeysMs.onMsNeedKey_",a);if(a.initData){var b=document.createEvent("CustomEvent");b.initCustomEvent(_.Oc,!1,!1,null);b.initDataType=_.nc;b.initData=Vu(a.initData);this.dispatchEvent(b)}};
Vu=function(a){if(!a)return a;var b=new _.hl(a);if(1>=b.a.length)return a;for(var c=[],d=0;d<b.a.length;d++)c.push(a.subarray(b.a[d].start,b.a[d].end+1));a=[];b={};d=_.t(c);for(c=d.next();!c.done;b={gd:b.gd},c=d.next())b.gd=c.value,a.some(function(e){return function(f){return _.bl(f,e.gd)}}(b))||a.push(b.gd);b=0;d=_.t(a);for(c=d.next();!c.done;c=d.next())b+=c.value.length;b=new Uint8Array(+b);d=0;a=_.t(a);for(c=a.next();!c.done;c=a.next())c=c.value,b.set(c,d),d+=c.length;return b};
Wu=function(a,b){var c=a.keyStatuses;c.size=void 0==b?0:1;c.a=b;a.dispatchEvent(new _.O(_.cd))};Tu=function(){this.size=0;this.a=void 0};Yu=function(a){var b=Xu;return b?b+a.charAt(0).toUpperCase()+a.slice(1):a};$u=function(a,b){_.H("PatchedMediaKeysWebkit.requestMediaKeySystemAccess");try{var c=new Zu(a,b);return Promise.resolve(c)}catch(d){return Promise.reject(d)}};
bv=function(a){_.H("PatchedMediaKeysWebkit.setMediaKeys");var b=this.mediaKeys;b&&b!=a&&av(b,null);delete this.mediaKeys;(this.mediaKeys=a)&&av(a,this);return Promise.resolve()};
Zu=function(a,b){_.H("PatchedMediaKeysWebkit.MediaKeySystemAccess");this.a=this.keySystem=a;var c=!1;a==_.Ed&&(this.a=$t,c=!1);var d=!1;var e=document.getElementsByTagName(_.Be);e=e.length?e[0]:document.createElement(_.Be);for(var f=_.t(b),g=f.next();!g.done;g=f.next()){g=g.value;var k={audioCapabilities:[],videoCapabilities:[],persistentState:_.Dd,distinctiveIdentifier:_.Dd,initDataTypes:g.initDataTypes,sessionTypes:[_.ke],label:g.label},l=!1;if(g.audioCapabilities)for(var m=_.t(g.audioCapabilities),
n=m.next();!n.done;n=m.next())n=n.value,n.contentType&&(l=!0,e.canPlayType(n.contentType.split(";")[0],this.a)&&(k.audioCapabilities.push(n),d=!0));if(g.videoCapabilities)for(m=_.t(g.videoCapabilities),n=m.next();!n.done;n=m.next())n=n.value,n.contentType&&(l=!0,e.canPlayType(n.contentType,this.a)&&(k.videoCapabilities.push(n),d=!0));l||(d=e.canPlayType(_.De,this.a)||e.canPlayType("video/webm",this.a));g.persistentState==_.Vd&&(c?(k.persistentState=_.Vd,k.sessionTypes=[_.Jd]):d=!1);if(d){this.f=k;
return}}c=_.hb;if(a==_.Ed||a==_.Bc)c="None of the requested configurations were supported.";c=Error(c);c.name=_.Oa;c.code=DOMException.NOT_SUPPORTED_ERR;throw c;};cv=function(a){_.H("PatchedMediaKeysWebkit.MediaKeys");this.j=a;this.f=null;this.a=new _.zi;this.g=[];this.h=new Map};
av=function(a,b){a.f=b;_.Ci(a.a);var c=Xu;b&&(a.a.on(b,c+"needkey",function(d){_.H("PatchedMediaKeysWebkit.onWebkitNeedKey_",d);var e=document.createEvent("CustomEvent");e.initCustomEvent(_.Oc,!1,!1,null);e.initDataType=_.nc;e.initData=_.Rf(d.initData);a.f.dispatchEvent(e)}),a.a.on(b,c+"keymessage",function(d){_.H("PatchedMediaKeysWebkit.onWebkitKeyMessage_",d);var e=dv(a,d.sessionId);e?(d=new _.O(_.nd,{messageType:void 0==e.keyStatuses.a?"licenserequest":"licenserenewal",message:d.message}),_.H("PatchedMediaKeysWebkit.MediaKeySession.generated"),
e.f&&(e.f.resolve(),e.f=null),e.dispatchEvent(d)):_.Of("Session not found",d.sessionId)}),a.a.on(b,c+"keyadded",function(d){_.H("PatchedMediaKeysWebkit.onWebkitKeyAdded_",d);if(d=dv(a,d.sessionId))_.H("PatchedMediaKeysWebkit.MediaKeySession.ready"),ev(d,_.ve),d.a&&d.a.resolve(),d.a=null}),a.a.on(b,c+"keyerror",function(d){_.H("PatchedMediaKeysWebkit.onWebkitKeyError_",d);var e=dv(a,d.sessionId);if(e){_.H("PatchedMediaKeysWebkit.MediaKeySession.handleError",d);var f=Error("EME v0.1b key error"),g=
d.errorCode;g.systemCode=d.systemCode;f.errorCode=g;!d.sessionId&&e.f?(45==d.systemCode&&(f.message="Unsupported session type."),e.f.reject(f),e.f=null):d.sessionId&&e.a?(e.a.reject(f),e.a=null):(f=d.systemCode,d.errorCode.code==MediaKeyError.MEDIA_KEYERR_OUTPUT?ev(e,Vt):1==f?ev(e,"expired"):ev(e,_.bd))}}))};dv=function(a,b){var c=a.h.get(b);return c?(_.H(Kt,c),c):(c=a.g.shift())?(c.sessionId=b,a.h.set(b,c),_.H(Kt,c),c):null};
gv=function(a,b,c){_.H("PatchedMediaKeysWebkit.MediaKeySession");_.pj.call(this);this.h=a;this.m=!1;this.a=this.f=null;this.g=b;this.j=c;this.sessionId="";this.expiration=NaN;this.closed=new _.lk;this.keyStatuses=new fv};
hv=function(a,b,c){if(a.m)return Promise.reject(Error("The session is already initialized."));a.m=!0;try{if(a.j==_.Jd)if(c)var d=_.I(_.bg("LOAD_SESSION|"+c));else{var e=_.bg("PERSISTENT|"),f=new Uint8Array(e.byteLength+b.byteLength);f.set(e,0);f.set(_.I(b),e.byteLength);d=f}else d=_.I(b)}catch(k){return Promise.reject(k)}a.f=new _.lk;var g=Yu("generateKeyRequest");try{a.h[g](a.g,d)}catch(k){if("InvalidStateError"!=k.name)return a.f=null,Promise.reject(k);(new _.Xi(function(){try{a.h[g](a.g,d)}catch(l){a.f.reject(l),
a.f=null}})).$(.01)}return a.f};iv=function(a,b,c){if(a.a)a.a.then(function(){return iv(a,b,c)})["catch"](function(){return iv(a,b,c)});else{a.a=b;if(a.g==$t){var d=_.Uf(c);var e=JSON.parse(d);"oct"!=e.keys[0].kty&&(a.a.reject(Error("Response is not a valid JSON Web Key Set.")),a.a=null);d=_.Zk(e.keys[0].k);e=_.Zk(e.keys[0].kid)}else d=_.I(c),e=null;var f=Yu("addKey");try{a.h[f](a.g,d,e,a.sessionId)}catch(g){a.a.reject(g),a.a=null}}};
ev=function(a,b){var c=a.keyStatuses;c.size=void 0==b?0:1;c.a=b;a.dispatchEvent(new _.O(_.cd))};fv=function(){this.size=0;this.a=void 0};jv=function(){var a=MediaSource.prototype.addSourceBuffer;MediaSource.prototype.addSourceBuffer=function(b){for(var c=[],d=0;d<arguments.length;++d)c[d]=arguments[d];c=a.apply(this,c);c.abort=function(){};return c}};kv=function(){var a=SourceBuffer.prototype.remove;SourceBuffer.prototype.remove=function(b,c){return a.call(this,b,c-.001)}};
lv=function(){var a=MediaSource.isTypeSupported;MediaSource.isTypeSupported=function(b){return"mp2t"==b.split(/ *; */)[0].split("/")[1].toLowerCase()?!1:a(b)}};mv=function(){var a=MediaSource.isTypeSupported;MediaSource.isTypeSupported=function(b){return"opus"!=_.Qg(b)[0]&&a(b)}};
nv=function(){var a=MediaSource.isTypeSupported,b=/^dv(?:h[e1]|a[v1])\./;MediaSource.isTypeSupported=function(c){var d=c.split(/ *; */),e=d.shift(),f={};d=_.t(d);for(var g=d.next();!g.done;g=d.next())g=g.value.split("\x3d"),f[g[0]]=g[1].replace(/"(.*)"/,"$1");d=f.codecs;if(!d)return a(c);var k=!1,l=!1;c=d.split(",").filter(function(n){b.test(n)&&(l=!0);/^(hev|hvc)1\.2/.test(n)&&(k=!0);return!0});l&&(k=!1);f.codecs=c.join(",");k&&(f.eotf="smpte2084");for(var m in f)e+="; "+m+'\x3d"'+f[m]+'"';return cast.__platform__.canDisplayType(e)}};
ov=function(){this.a=new muxjs.mp4.CaptionParser;this.j=[];this.h={}};_.pv=function(){};qv=function(){this.a=new muxjs.mp4.Transmuxer({keepOriginalTimestamps:!0,remux:!1});this.f=null;this.m=[];this.j=[];this.g=[];this.h=!1;this.a.on("data",this.w.bind(this));this.a.on("done",this.o.bind(this))};
sv=function(a,b){return!window.muxjs||"mp2t"!=a.split(";")[0].split("/")[1]&&"aac"!=a.split(";")[0].split("/")[1]?!1:b?MediaSource.isTypeSupported(rv(b,a))||MediaSource.isTypeSupported(a.replace("aac",_.rd)):MediaSource.isTypeSupported(rv(_.Wb,a))||MediaSource.isTypeSupported(a.replace("aac",_.rd))||MediaSource.isTypeSupported(rv(_.Be,a))};
rv=function(a,b){var c=b.replace("mp2t",_.rd);a==_.Wb&&(c=c.replace(_.Be,_.Wb));var d=/avc1\.(66|77|100)\.(\d+)/.exec(c);if(d){var e="avc1.",f=d[1],g=Number(d[2]);e=("66"==f?e+"4200":"77"==f?e+"4d00":e+"6400")+(g>>4).toString(16);e+=(g&15).toString(16);c=c.replace(d[0],e)}return c};uv=function(a,b){a.h=!0;a.f=new _.lk;a.m=[];a.j=[];a.g=[];var c=_.I(b);a.a.push(c);a.a.flush();a.h&&a.f.reject(new _.J(_.K,3,3018));return a.f};
_.xv=function(a,b,c,d,e){this.j=a;this.A=c;this.g={};this.J=!1;this.f=null;this.h={};this.o=new _.zi;this.I=!1;this.w={};this.D=b;this.B=d;this.K=void 0===e?1:e;this.G=new _.lk;this.C={};a=this.G;b=new MediaSource;this.o.one(b,"sourceopen",a.resolve);this.j.src=wv(b);this.m=b;this.a=new _.D("clpp.mse.MediaSourceEngine")};Tv=function(a){var b=_.Pg(a.mimeType,a.codecs),c=mu(a);return _.Tg(b)||MediaSource.isTypeSupported(c)||sv(b,a.type)};
Uv=function(a,b){b.forEach(function(c,d){var e=a.m.addSourceBuffer(c);a.o.on(e,_.Sc,a.Yh.bind(a,d));a.o.on(e,"updateend",a.Ic.bind(a,d));a.g[d]=e;a.h[d]=[];a.C[d]=!1});a.J=b.has(_.Wb)&&b.has(_.Be)};Vv=function(a,b){a.f||(a.f=new _.Rg(a.A));b!=_.Kb&&(a.f.o=new _.Sg[b])};Wv=function(a){return a.m?a.m.readyState==_.Qc:!0};Yv=function(a){var b={};b.total=new _.oj(a.j.buffered,"total");b.audio=Xv(a,_.Wb);b.video=Xv(a,_.Be);var c=[];a.f&&(c=[{start:a.f.mb(),end:a.f.na()}]);b.text=nu(c,_.le);return b};
Xv=function(a,b){try{return new _.oj(a.g[b].buffered,b)}catch(c){return b in a.g&&a.a.error("failed to get buffered range for "+b,c),null}};
$v=function(a,b,c,d,e,f,g,k){k=void 0===k?!1:k;var l=a.g[b],m=-1,n=0;l&&l.buffered&&(n=l.buffered.length);b===_.Be&&null!==d&&null!==e&&k&&(m=0<n?l.buffered.end(n-1):-1);b==_.le?c=au(a.f,c,d,e):a.w[b]?c=uv(a.w[b],c).then(function(p){a.f||Vv(a,_.me);p.captions&&p.captions.length&&bu(a.f,p.captions,d,e,a.g.video.timestampOffset);var q=[];0<p.audio.byteLength&&a.g.audio&&q.push(Zv(a,_.Wb,a.he.bind(a,_.Wb,p.audio.buffer)));0<p.video.byteLength&&a.g.video&&q.push(Zv(a,_.Be,a.he.bind(a,_.Be,p.video.buffer)));
return Promise.all(q)}):(f&&window.muxjs&&(a.f||Vv(a,_.me),null==d&&null==e?a.D.init(c):a.D.g(c,function(p){p.length&&bu(a.f,p,d,e,a.g.video.timestampOffset)})),c=Zv(a,b,a.he.bind(a,b,c)));return c.then(function(){if(b===_.Be&&null!==d&&null!==e&&!k)a.B.append(d,e,g);else if(b===_.Wb&&null!==d&&null!==e)Cu(a.B);else if(b===_.Be&&null!==d&&null!==e&&k){var p=l.buffered.length,q=0<p?l.buffered.end(p-1):-1;0>m&&0<p&&(m=l.buffered.start(0));0<=m&&0<q&&a.B.append(m,q,g)}if(!a.C[b]&&l&&1===l.buffered.length){p=
l.buffered.start(0);var r=d-p;if(Math.abs(r)>a.K&&0===l.timestampOffset)return a.a.warn("Detected a delta of "+r+" between media times and segment times for "+(b+". The segment start time is ")+(d+" and the media start time is ")+(p+". Adjusting timestampOffset.")),p=Zv(a,b,function(){a.Gg(b,r)}),q=Zv(a,b,a.Rf.bind(a,b,!1)),Promise.all([p,q])}null!==d&&null!==e&&(a.C[b]=!0)})["catch"](function(p){throw p;})};
bw=function(a,b,c,d,e){if(b==_.le)return a.f.w=c,a=a.f,a.h=d,a.j=e,Promise.resolve();b=aw(a,b,c,d,e);a.J&&(b=b.concat(aw(a,_.Wb,c,d,e)));return Promise.all(b)};aw=function(a,b,c,d,e){return[Zv(a,b,a.Qg.bind(a,b)),Zv(a,b,a.Gg.bind(a,b,c)),Zv(a,b,a.sk.bind(a,b,d,e))]};Zv=function(a,b,c){if(a.I)return Promise.reject();c={start:c,p:new _.lk};a.h[b].push(c);1===a.h[b].length&&cw(a,b);return c.p};
cw=function(a,b){var c=a.h[b][0];if(c)try{a.a.debug("Starting next operation for "+b),c.start()}catch(d){"QuotaExceededError"==d.name?c.p.reject(new _.J(_.K,3,3017,{type:b})):c.p.reject(new _.J(_.K,3,3015,{mediaError:d})),dw(a,b)}else a.a.debug("No more scheduled operations for "+b)};
ew=function(a,b){if(a.I)return Promise.reject();var c=[],d;for(d in a.g){var e=new _.lk,f={start:function(g){g.resolve()}.bind(null,e),p:e};a.h[d].push(f);c.push(e);1==a.h[d].length&&f.start()}return Promise.all(c).then(function(){try{b()}catch(l){var g=Promise.reject(new _.J(_.K,3,3015,{mediaError:l}))}for(var k in a.g)dw(a,k);return g},function(){return Promise.reject()})};dw=function(a,b){a.h[b].shift();cw(a,b)};
fw=function(a,b,c){function d(f,g,k){return f>=g&&f<=k}var e=a.video;return e&&e.width&&e.height&&!(d(e.width,b.minWidth,Math.min(b.maxWidth,c.width))&&d(e.height,b.minHeight,Math.min(b.maxHeight,c.height))&&d(e.width*e.height,b.minPixels,b.maxPixels))||!d(a.bandwidth,b.minBandwidth,b.maxBandwidth)?!1:!0};gw=function(a,b,c){var d=!1;a=_.t(a);for(var e=a.next();!e.done;e=a.next()){e=e.value;var f=e.allowedByApplication;e.allowedByApplication=fw(e,b,c);f!=e.allowedByApplication&&(d=!0)}return d};
jw=function(a,b,c,d){var e=!1;d.variants=d.variants.filter(function(m){if(a&&a.X&&!fu(a,m))return _.H("Dropping variant - not compatible with key system",m),!1;var n=m.audio,p=m.video;if(n&&!Tv(n)){var q=n.codecs;0<=n.codecs.indexOf("ec-3")?(_.H("Trying to use AC-3 for EC-3"),n.codecs=n.codecs.replace("ec-3","ac-3"),Tv(n)?_.Nf("Using AC-3 instead of EC-3 for",hw(n)):(n.codecs=q,_.Nf(Nt,hw(n)),m.audio=null,e=!0,n=null)):(_.Nf(Nt,hw(n)),m.audio=null,e=!0,n=null)}return p&&!Tv(p)?(_.Nf("Dropping variant - video not compatible with platform",
hw(p)),!1):n&&b&&!iw(n,b)?(_.Nf("Dropping variant - not compatible with active audio","active audio",hw(b),"variant.audio",hw(n)),!1):p&&c&&!iw(p,c)?(_.Nf("Dropping variant - not compatible with active video","active video",hw(c),"variant.video",hw(p)),!1):!0});if(e){for(var f=!1,g=!1,k=_.t(d.variants),l=k.next();!l.done;l=k.next())l=l.value,!f&&l.video&&l.audio&&(f=!0),g||l.audio||!l.video||(g=!0);f&&g&&(d.variants=d.variants.filter(function(m){return m.video&&!m.audio?(_.Nf("Dropping variant - not compatible with video + audio",
"variant.video",hw(m.video)),!1):!0}))}d.textStreams=d.textStreams.filter(function(m){var n=_.Tg(_.Pg(m.mimeType,m.codecs));n||_.H("Dropping text stream. Is not supported by the platform.",m);return n})};iw=function(a,b){return a.mimeType!=b.mimeType||a.codecs.split(".")[0]!=b.codecs.split(".")[0]?!1:!0};
kw=function(a){return{id:a.id,active:!1,type:_.le,bandwidth:0,language:a.language,label:a.label,kind:a.kind||null,width:null,height:null,frameRate:null,mimeType:a.mimeType,audioMimeType:null,codecs:a.codecs||null,audioCodec:null,videoCodec:null,primary:a.primary,roles:a.roles,audioRoles:null,videoId:null,audioId:null,channelsCount:null,audioBandwidth:null,videoBandwidth:null,originalVideoId:null,originalAudioId:null,originalTextId:a.originalId}};lw=function(a){return a.allowedByApplication&&a.allowedByKeySystem};
mw=function(a,b){var c=a.filter(function(g){return g.audio&&g.audio.channelsCount}),d=new Map;c=_.t(c);for(var e=c.next();!e.done;e=c.next()){e=e.value;var f=e.audio.channelsCount;d.has(f)||d.set(f,[]);d.get(f).push(e)}c=Array.from(d.keys());if(0==c.length)return a;e=c.filter(function(g){return g<=b});return e.length?d.get(Math.max.apply(null,e)):d.get(Math.min.apply(null,c))};
_.ow=function(a,b,c){var d=a,e=a.filter(function(k){return k.primary});e.length&&(d=e);var f=d.length?d[0].language:"";d=d.filter(function(k){return k.language==f});if(b){var g=su(_.no(b),a.map(function(k){return k.language}));g&&(d=a.filter(function(k){return _.no(k.language)==g}))}if(c){a=nw(d,c);if(a.length)return a;_.Kf("No exact match for the text role could be found.")}else if(a=d.filter(function(k){return 0==k.roles.length}),a.length)return a;a=d.map(function(k){return k.roles}).reduce(_.Ol.je,
[]);return a.length?nw(d,a[0]):d};nw=function(a,b){return a.filter(function(c){return c.roles.includes(b)})};pw=function(a,b,c){for(var d=0;d<c.length;d++)if(c[d].audio==a&&c[d].video==b)return c[d];return null};
hw=function(a){var b=mu(a);return a.type==_.Wb?"type\x3daudio mimeType\x3d"+a.mimeType+" codecs\x3d"+a.codecs+" codecInfo\x3d"+b+" bandwidth\x3d"+a.bandwidth+" channelsCount\x3d"+a.channelsCount:a.type==_.Be?"type\x3dvideo mimeType\x3d"+a.mimeType+" codecs\x3d"+a.codecs+" codecInfo\x3d"+b+" bandwidth\x3d"+a.bandwidth+" frameRate\x3d"+a.frameRate+" width\x3d"+a.width+" height\x3d"+a.height:"unexpected stream type"};
qw=function(a,b){var c=String(_.Ti++);c=new _.Ni(c,a);c.mimeType=_.Pi(b.mimeType);if(a===_.P||a===_.Q)c.label=_.Pi(b.label),c.language=_.Pi(b.language);a===_.Qi?(c.frameRate=b.frameRate,c.roles=b.roles):a===_.Q?(c.channelsCount=b.channelsCount,c.roles=b.audioRoles||[],c.mimeType=_.Pi(b.audioMimeType)):(c.kind=_.Pi(b.kind),c.roles=b.roles);return c};
rw=function(a,b,c){var d=new _.Oi(a===_.Qi?""+b.videoId:a===_.Q?""+b.audioId:""+b.id,c);switch(a){case _.Qi:d.codec=_.Pi(b.videoCodec);d.bandwidth=b.videoBandwidth||b.bandwidth;d.width=b.width;d.height=b.height;d.originalId=b.originalVideoId;break;case _.Q:d.codec=_.Pi(b.audioCodec);d.bandwidth=b.audioBandwidth||b.bandwidth;d.originalId=b.originalAudioId;break;default:d.codec=_.Pi(b.codecs),d.originalId=b.originalTextId}(a=c.renditions.find(function(e){return _.B.La(e,d,null,["id","track"])}))||c.renditions.push(d);
return a||d};sw=function(a,b){var c=a.reduce(function(d,e){b===_.Qi?null===e.videoId||d[e.videoId]&&!e.active||(d[e.videoId]=e):b===_.Q&&(null===e.audioId||d[e.audioId]&&!e.active||(d[e.audioId]=e));return d},{});return Object.values(c)};tw=function(a,b){if(void 0!==b&&null!==b){var c=a.filter(function(d){return d.audio&&d.audio.id===b});if(0<c.length)return c}return a};uw=function(){this.g=new _.D("clpp.mse.TrackManager");this.l=null;this.h=[];this.a=new Map;this.f=new Map};
vw=function(a,b){return a.h.filter(function(c){return c.type===b})};ww=function(a,b){var c=a.a.get(b)||null;return c&&c.track};yw=function(a,b,c){c&&ww(a,c.type)===c||xw(a,b,c&&c.renditions[0])};zw=function(a,b){a.a.set(b.track.type,b);b===(a.f.get(b.track.type)||null)&&a.f.set(b.track.type,null)};
xw=function(a,b,c,d){d=void 0===d?!1:d;if(a.l&&(a.a.get(b)||null)!==c){var e=a.l;if(c){if(0>a.h.indexOf(c.track))throw Error("Unknown rendition with ID "+c.id);if(b!==c.track.type)throw Error("Types mismatch");a.g.debug("Activate rendition type\n          "+b+" with ID: "+c.id);if(b===_.P){var f=e.ea().find(function(n){return n.id==c.id});f&&(a.f.set(b,c),zw(a,c),e.trigger(new _.O(_.ij)),Aw(e,!0).then(function(){Bw(e,f)})["catch"](function(n){a.g.warn("Error while activating track",n)}))}else if(b===
_.Q)a.f.set(b,c),Cw(e,parseInt(c.id,10));else if(b===_.Qi){var g=a.a.get(_.Q)||null;if(g){var k=Dw(e).filter(function(n){return n.videoId==c.id});var l=k.find(function(n){return n.audioId==g.id});if(!l){a.g.debug("Unable to find exact variant. Searching for equivalent");var m=g.track.renditions.map(function(n){return n.id}).filter(function(n){return n!==g.id});l=k.find(function(n){return m.find(function(p){return p==n.audioId})})}}else l=Dw(e).find(function(n){return n.videoId==c.id});l?(a.f.set(b,
c),Ew(e,l,d)):a.g.warn(Ft)}}else a.g.debug(_.ua+b),ww(a,b)&&(b===_.P?Aw(e,!1).then(function(){a.a.set(b,null);e.trigger(new _.O(_.ij))})["catch"](function(n){a.g.warn("Error while de-activating text tracks",n)}):b===_.Q?a.g.info("Enabling ABR for audio is not supported"):Ew(e,null,d))}};
Iw=function(a){if(a.l){a.g.debug("Track list change detected, updating track list");for(var b=[],c=_.t(Object.values(_.Mp)),d=c.next();!d.done;d=c.next())d=d.value,a.a.set(d,null),a.f.set(d,null);c={};d=_.t(a.l.ea());for(var e=d.next();!e.done;c={Qb:c.Qb},e=d.next()){e=e.value;c.Qb=qw(_.P,e);var f=b.find(function(k){return function(l){return Fw(l,k.Qb)}}(c));c.Qb=f||c.Qb;var g=rw(_.P,e,c.Qb);e.active&&a.a.set(_.P,g);f||b.push(c.Qb)}Gw(a.l)||a.a.set(_.P,null);c=Dw(a.l);Hw(a,b,c,_.Qi);Hw(a,b,c,_.Q);
a.h=b}};Hw=function(a,b,c,d){var e=sw(c,d);c={};e=_.t(e);for(var f=e.next();!f.done;c={Rb:c.Rb},f=e.next()){f=f.value;c.Rb=qw(d,f);var g=b.find(function(l){return function(m){return Fw(m,l.Rb)}}(c));c.Rb=g||c.Rb;var k=rw(d,f,c.Rb);f.active&&a.a.set(d,k);g||b.push(c.Rb)}};Fw=function(a,b){return a.type===b.type&&a.channelsCount===b.channelsCount&&a.frameRate===b.frameRate&&a.kind===b.kind&&a.label===b.label&&a.language===b.language&&a.mimeType===b.mimeType&&pu(a.roles,b.roles)};
Jw=function(a,b,c){if(b){a.h.length||Iw(a);for(var d=b.type,e=a.a.get(d)||null,f,g=_.t(vw(a,d)),k=g.next();!k.done&&!(f=k.value.renditions.find(function(l){return l.id==b.id}));k=g.next());f&&e!==f&&(zw(a,f),d===_.Q?a.l.trigger(new _.O(_.hj)):d===_.Qi&&a.l.trigger(new _.O(_.Ge,{details:{width:f.width||0,height:f.height||0,bandwidth:f.bandwidth,track:f.track,abrSelection:c}})))}};Kw=function(){this.f=new _.ro(2);this.g=new _.ro(5);this.a=0};Lw=function(a,b){return 128E3>a.a?b:Math.min(_.to(a.f),_.to(a.g))};
_.Mw=function(){this.f=new _.D("clpp.abr");this.w=null;this.j=!1;this.g=new Kw;this.o=[];this.m=1;this.A=!1;this.a=this.h=null};Nw=function(a,b){a&&(b=b.filter(function(c){return fw(c,a,{width:Infinity,height:Infinity})}));return b.sort(function(c,d){return c.bandwidth-d.bandwidth})};Ow=function(){this.a=new Map};Qw=function(a,b){a.a.has(b)||a.a.set(b,new Pw);return a.a.get(b)};Pw=function(){this.text=this.variant=null};
Rw=function(a,b){this.f=a;this.a=new Set([a]);b=b||[];for(var c=_.t(b),d=c.next();!d.done;d=c.next())this.add(d.value)};Uw=function(a,b){var c;if(!(c=!!a.audio!=!!b.audio||!!a.video!=!!b.video||a.language!=b.language)&&(c=a.audio&&b.audio)){c=a.audio;var d=b.audio;c=!(c.channelsCount==d.channelsCount&&Sw(c,d)&&Tw(c.roles,d.roles))}!c&&(c=a.video&&b.video)&&(c=a.video,d=b.video,c=!(Sw(c,d)&&Tw(c.roles,d.roles)));return c?!1:!0};
Sw=function(a,b){if(a.mimeType!=b.mimeType)return!1;var c=a.codecs.split(",").map(function(f){return _.Qg(f)[0]}),d=b.codecs.split(",").map(function(f){return _.Qg(f)[0]});if(c.length!=d.length)return!1;c.sort();d.sort();for(var e=0;e<c.length;e++)if(c[e]!=d[e])return!1;return!0};Tw=function(a,b){var c=new Set(a),d=new Set(b);c["delete"]("main");d["delete"]("main");if(c.size!=d.size)return!1;c=_.t(c);for(var e=c.next();!e.done;e=c.next())if(!d.has(e.value))return!1;return!0};
Ww=function(a){this.a=a;this.f=new Vw(a.language,"",a.audio&&a.audio.channelsCount?a.audio.channelsCount:0)};Vw=function(a,b,c){this.g=a;this.f=b;this.a=c};Xw=function(a,b){var c=_.no(b),d=su(c,a.map(function(e){return ru(e)}));return d?a.filter(function(e){return d==ru(e)}):[]};Yw=function(a,b){return a.filter(function(c){var d=c.audio;c=c.video;return d&&0<=d.roles.indexOf(b)||c&&0<=c.roles.indexOf(b)})};ax=function(a,b){this.a=Zw;this.f=(new Map).set(Zw,b).set($w,a)};
cx=function(a){var b=this;this.f=a;this.a=a.getConfiguration().streaming;this.h=new _.zi;this.g=new _.D("clpp.mse.media.LiveEdgeObserver");bx(this)&&(a=this.f.getSurface().getMedia(),this.h.on(a,_.pe,function(){var c=b.f.getPosition(),d=b.f.getSeekRange().end;c=d-c;var e=b.f.getPlaybackRate(),f=b.a.stopChasingAt;1<=e&&c>=b.a.chaseJumpDistance?(b.g.debug("Too far from edge, jump to beginning."),b.f.setPlaybackRate(1),b.f.seek(d)):1===e&&c>=b.a.startChasingAt?(b.g.debug("Not on live edge, increase playback rate."),
b.f.setPlaybackRate(b.a.chasingRate)):1<e&&c<=f&&(b.g.debug("On live edge, reduce playback rate."),b.f.setPlaybackRate(1))}))};
bx=function(a){return 1>=a.a.chasingRate?(a.g.warn("chasingRate must be greater than 1."),!1):a.a.chaseJumpDistance<=a.a.startChasingAt?(a.g.warn("chaseJumpDistance must be greater than startChasingAt."),!1):a.a.startChasingAt<=a.a.stopChasingAt?(a.g.warn("startChasingAt must be greater than stopChasingAt."),!1):0>a.a.stopChasingAt?(a.g.warn("stopChasingAt must be greater than or equal to 0."),!1):!0};dx=function(a){this.g=a;this.a=null;this.f=function(){}};ex=function(a,b){a.f=b};
fx=function(a){var b=this;this.a=a;this.h=!1;this.g=this.a.qe();this.f=new _.Xi(function(){b.a.jj(.25*b.g)})};gx=function(a){a.f.stop();var b=a.h?0:a.g;0<=b?a.a.qe()!=b&&a.a.Fg(b):(a.f.Ua(.25),0!=a.a.qe()&&a.a.Fg(0))};ix=function(a,b){this.g=a;this.j=hx(a);this.a=a.a.currentTime;this.h=Date.now()/1E3;this.m=b;this.f=function(){}};jx=function(a,b){a.f=b};kx=function(a){this.a=a};
hx=function(a){if(a.a.paused||0==a.a.playbackRate||0===a.a.buffered.length)var b=!1;else a:{b=a.a.buffered;a=a.a.currentTime;for(var c=0;c<b.length;c++){var d=b.start(c),e=b.end(c);if(!(a<d-.1||a>e-.5)){b=!0;break a}}b=!1}return b};mx=function(a,b,c,d,e){var f=this;this.a=a;this.C=b;this.A=c;this.B=e;this.g=new _.zi;this.w=!1;this.G=a.readyState;this.h=!1;this.f=d;this.j=this.D=this.o=!1;this.g.on(a,_.Je,function(){return lx(f)});this.m=(new _.Xi(function(){lx(f)})).Ua(.25)};
lx=function(a){if(0!=a.a.readyState){if(a.a.seeking){if(!a.w)return}else a.w=!1;if(!a.a.paused&&!a.j){a.a.readyState!=a.G&&(a.h=!1,a.G=a.a.readyState);var b=a.A.smallGapLimit,c=a.a.currentTime,d=a.a.buffered;a:{var e=new _.oj(d);if(e.length&&!(1===e.length&&1E-6>e.end(0)-e.start(0)))for(var f=_.Kj("Edge/")||_.Kj("Edg/")||_.Lj()||_.Mj()||_.Oj()?.5:.1,g=0;g<e.length;g++)if(e.start(g)>c&&(0===g||e.end(g-1)-c<=f)){e=g;break a}e=null}if(null==e){if(a.f){a=a.f;d=a.g;c=hx(d);d=d.a.currentTime;b=Date.now()/
1E3;if(a.a!=d||a.j!=c)a.h=b,a.a=d,a.j=c;d=b-a.h;d>=a.m&&c&&a.f(a.a,d)}}else if((0!=e||a.o)&&a.D&&(f=d.start(e),g=gu(a.C),!(f>=g))){g=f-c;b=g<=b;var k=!1;if(!(.001>g)){if(!b&&!a.h){a.h=!0;var l=new _.O("largegap",{detail:{currentTime:c,gapSize:g}});l.cancelable=!0;a.B(l);a.A.jumpLargeGaps&&!l.defaultPrevented?k=!0:_.Nf("Ignoring large gap at",c,"size",g)}if(b||k)0==e?_.Nf(Gt,g,"seconds because of gap before start time of",f):_.Nf(Gt,g,"seconds because of gap starting at",d.end(e-1),"and ending at",
f),a.a.currentTime=f}}}}};qx=function(a,b,c,d){this.a=a;this.m=b;this.h=d;this.w=c;this.o=!1;this.f=new _.zi;this.g=new nx(a);this.j=!1;0<a.readyState?ox(this,c):px(this,c)};rx=function(a){return a.o?a.a.currentTime:a.w};tx=function(a,b){0<a.a.readyState?sx(a.g,b):px(a,b)};px=function(a,b){a.w=b;a.f.off(a.a,_.kd);a.f.one(a.a,_.kd,function(){ox(a,b)})};ox=function(a,b){a.j||(a.j=!0,a.h&&a.h());.001>Math.abs(a.a.currentTime-b)?ux(a):(a.f.one(a.a,_.$d,function(){ux(a)}),sx(a.g,0==a.a.currentTime?b:a.a.currentTime))};
ux=function(a){a.o=!0;a.f.on(a.a,_.$d,function(){return a.m()})};nx=function(a){var b=this;this.f=a;this.m=10;this.j=this.g=this.h=0;this.a=new _.Xi(function(){0>=b.h?(_.Kf(["Failed to move playhead from",b.g,"to",b.j].join(" ")),b.a.stop()):b.f.currentTime!=b.g?b.a.stop():(b.f.currentTime=b.j,b.h--)})};sx=function(a,b){a.g=a.f.currentTime;a.j=b;a.h=a.m;a.f.currentTime=b;a.a.Ua(.1)};
yx=function(a,b,c,d,e,f){var g=this;this.h=a;this.a=b.presentationTimeline;this.B=b.minBufferTime||0;this.m=c;this.A=e;this.w=null;this.j=new mx(a,b.presentationTimeline,c,vx(this,a,c),f);this.g=new qx(a,function(){a:{var k=g.j;k.w=!0;k.o=!1;k.h=!1;k=rx(g.g);var l=wx(g,k);if(.001<Math.abs(l-k)){var m=(new Date).getTime()/1E3;if(!g.w||g.w<m-1){g.w=m;tx(g.g,l);k=void 0;break a}}g.f.debug("Seek to "+k);g.A();k=void 0}return k},xx(this,d),function(){g.j.D=!0});this.o=(new _.Xi(function(){if(0!=g.h.readyState&&
!g.h.paused){var k=rx(g.g),l=hu(g.a,0),m=gu(g.a);3>m-l&&(l=m-3);k<l&&(l=wx(g,k),g.f.info("Jumping forward "+(l-k)+" seconds to catch up with the seek range."),g.h.currentTime=l)}})).Ua(.25);this.f=new _.D("clpp.mse.Playhead")};Ax=function(a){var b=rx(a.g);return 0<a.h.readyState&&!a.h.paused?zx(a,b):b};xx=function(a,b){null==b?b=Infinity>a.a.getDuration()?hu(a.a,0):gu(a.a):0>b&&(b=gu(a.a)+b);return Bx(a,zx(a,b))};Bx=function(a,b){var c=a.a.getDuration();return b>=c?c-a.m.durationBackoff:b};
wx=function(a,b){var c=new _.oj(a.h.buffered);c=c.Zb.bind(c);var d=Math.max(a.B,a.m.rebufferingGoal),e=a.m.safeSeekOffset,f=hu(a.a,0),g=gu(a.a),k=a.a.getDuration();3>g-f&&(f=g-3);var l=hu(a.a,d),m=hu(a.a,e);d=hu(a.a,d+e);if(b>=k)return a.f.debug("Playhead past duration."),Bx(a,b);if(b>g)return a.f.debug("Playhead past end."),g;if(b<f){if(c(m))return a.f.debug("Playhead before start \x26 start is buffered"),m;a.f.debug("Playhead before start \x26 start is unbuffered");return d}if(b>=l||c(b))return a.f.debug("Playhead in safe region or in buffered region."),
b;a.f.debug("Playhead outside safe region \x26 in unbuffered region.");return d};zx=function(a,b){var c=hu(a.a,0);if(b<c)return c;c=gu(a.a);return b>c?c:b};vx=function(a,b,c){if(!c.stallEnabled)return null;var d=c.stallSkip;c=new ix(new kx(b),c.stallThreshold);jx(c,function(e,f){a.f.warn("Stall detected at "+e+" for "+f+" seconds.");d?(a.f.warn("Seeking forward "+d+" seconds to break stall."),b.currentTime+=d):(a.f.warn("Pausing and unpausing to break stall."),b.pause(),b.play())});return c};
_.Cx=function(a,b){this.f=b;this.g=a;this.m=null;this.B=1;this.G=Promise.resolve();this.o=[];this.w=new Map;this.h=new Map;this.J=this.j=this.A=this.C=!1;this.I=0;this.a=new _.D("clpp.mse.Streaming");this.D=!1};Ex=function(a){return _.Dx(a,_.Wb)};Fx=function(a){return _.Dx(a,_.Be)};_.Dx=function(a,b){var c=a.h.get(b);return c?c.Kb||c.stream:null};
Kx=function(a,b){var c,d,e,f,g,k,l,m,n,p;return _.z(function(q){switch(q.a){case 1:return c=_.dm,_.v(q,a.f.U.clear(c.TEXT),2);case 2:return a.I++,a.J=!1,d=a.I,e=a.f.U,f=new Map,g=new Set,f.set(c.TEXT,b),g.add(b),_.v(q,e.init(f,!1),3);case 3:return a.j?q["return"]():_.v(q,Gx(a,g),4);case 4:if(a.j)return q["return"]();l=(k=e.getTextDisplayer().isTextVisible())||a.m.alwaysStreamText;a.I!=d||a.h.has(c.TEXT)||a.J||!l||(m=a.f.rb(),n=Hx(a,m),p=Ix(b,n,0),a.h.set(c.TEXT,p),Jx(a,p,0));_.x(q)}})};
Mx=function(a,b,c,d){b.video&&_.Lx(a,b.video,c,d);b.audio&&_.Lx(a,b.audio,c,d)};
_.Lx=function(a,b,c,d){var e=a.h.get(b.type);if(!e&&b.type==_.le&&a.m.ignoreTextStreamFailures)Kx(a,b);else if(e){var f=Nx(a,b);if(c&&f!=e.dc)for(a.a.debug("switch: switching to stream in another Period; clearing buffer and changing Periods"),c=_.t(a.h.values()),d=c.next();!d.done;d=c.next())Ox(a,d.value);else{e.Kb&&(a.a.debug("switch during trick play mode",b),b.trickModeVideo?(e.Kb=b,b=b.trickModeVideo,a.a.debug("switch found trick play stream",b)):(e.Kb=null,a.a.debug("switch found no special trick play stream")));
var g=a.o[f];if(g&&g.Kc&&(g=a.w.get(b.id))&&g.Kc)if(e.stream!=b){g=a.g.presentationTimeline.getDuration();var k=a.f.rb();k=Px(a,e,k);!c&&(e.endOfStream||k>=g)?a.a.debug(Zt+Qx(e)+" already buffered to the end. Not switching."):(b.type==_.le&&Vv(a.f.U,_.Pg(b.mimeType,b.codecs)),e.stream=b,e.Jd=!0,a.a.debug("switch: switching to Stream "+Qx(e)),e.ec?(b=a.f.rb(),g=a.f.U.na(e.type),f=(f=Rx(a,e,b,g,f))?f.sa?f.sa-f.Ia:null:null,null==f?f=!1:((k=e.stream.initSegmentReference)&&(f+=(k.sa?k.sa-k.Ia:null)||
0),k=a.f.ne().getBandwidthEstimate(),8*f/k<(null===g?0:g-b)-Math.max(a.g.minBufferTime||0,a.m.rebufferingGoal)?f=!0:(b=e.ec,b=b.h?b.h.bytesRemaining:-1,f=0>b||b>f?!0:!1))):f=!1,f&&(a.a.info("Aborting current segment request to switch."),e.ec.abort()),c&&(e.Sb?e.Wd=!0:e.eb?(e.zb=!0,e.pd=d,e.Wd=!0):(Sx(e),Tx(a,e,!0,d))))}else a.a.debug(Zt+Qx(e)+" already active")}}};
Ux=function(a){function b(k){return a.f.U.Zb(k,c,0)}var c=a.f.rb(),d=!1,e=Hx(a,c);if(_.Ef(a.h.values(),function(k){return k.dc==e}))for(var f=_.t(a.h.keys()),g=f.next();!g.done;g=f.next())g=g.value,b(g)||(Ox(a,a.h.get(g)),d=!0);else if(!_.Ef(a.h.keys(),b)){a.a.debug("(all): seeked: unbuffered seek: clearing all buffers");d=_.t(a.h.values());for(f=d.next();!f.done;f=d.next())Ox(a,f.value);d=!0}d||a.a.debug("(all): seeked: buffered seek: presentationTime\x3d"+c)};
Ox=function(a,b){var c=Qx(b);b.Sb?a.a.debug(c,"clear: already clearing the buffer"):b.zb?a.a.debug(c,"clear: already waiting"):b.eb?(a.a.debug(c,"clear: currently updating"),b.zb=!0,b.pd=0):null==a.f.U.mb(b.type)?(a.a.debug(c,"clear: nothing buffered"),null==b.xb&&Jx(a,b,0)):(a.a.debug(c,"clear: handling right now"),Sx(b),Tx(a,b,!1,0))};
Wx=function(a,b,c,d,e){var f,g,k,l,m,n,p;return _.z(function(q){if(1==q.a)return f=a.f.rb(),g=Hx(a,f),k=_.dm,l=new Map,m=new Set,b&&(l.set(k.AUDIO,b),m.add(b)),c&&(l.set(k.VIDEO,c),m.add(c)),d&&(l.set(k.TEXT,d),m.add(d)),n=a.f.U,p=a.m.forceNativeTS,_.v(q,n.init(l,p),2);if(3!=q.a){if(a.j)return q["return"]();Vx(a);return _.v(q,Gx(a,m),3)}if(a.j)return q["return"]();l.forEach(function(r,w){if(!a.h.has(w)){var y=Ix(r,g,e);a.h.set(w,y);Jx(a,y,0)}});_.x(q)})};
Ix=function(a,b,c){return{stream:a,type:a.type,Hb:null,xa:null,Kb:null,Jd:!0,dc:b,endOfStream:!1,eb:!1,xb:null,zb:!1,pd:0,Wd:!1,Sb:!1,Qe:!1,Gc:!1,Dg:c||0,ec:null,vd:!1}};
Xx=function(a,b){var c=a.o[b];if(c)return a.a.debug("(all) Period "+b+Et),c.promise;a.a.debug("(all) setting up Period "+b);c={promise:new _.lk,Kc:!1};a.o[b]=c;for(var d=new Set,e=_.t(a.g.periods[b].variants),f=e.next();!f.done;f=e.next())f=f.value,f.video&&d.add(f.video),f.video&&f.video.trickModeVideo&&d.add(f.video.trickModeVideo),f.audio&&d.add(f.audio);e=_.t(a.g.periods[b].textStreams);for(f=e.next();!f.done;f=e.next())d.add(f.value);a.G=a.G.then(function(){return a.j?null:Gx(a,d)}).then(function(){a.j||
(a.o[b].promise.resolve(),a.o[b].Kc=!0,a.a.debug("(all) setup Period "+b))})["catch"](function(g){a.j||(a.o[b].promise["catch"](function(){}),a.o[b].promise.reject(),delete a.o[b],a.a.warn("(all) failed to setup Period "+b),a.f.onError(g))});return c.promise};
Gx=function(a,b){var c,d,e,f,g,k,l,m,n,p,q;return _.z(function(r){switch(r.a){case 1:c=[];d=_.t(b);for(e=d.next();!e.done;e=d.next())f=e.value,(g=a.w.get(f.id))?(a.a.debug("(all) Stream "+f.id+Et),c.push(g.promise)):(a.a.debug("(all) setting up Stream "+f.id),a.w.set(f.id,{promise:new _.lk,Kc:!1}),c.push(f.createSegmentIndex()));_.hf(r,2);return _.v(r,Promise.all(c),4);case 4:if(a.j)return r["return"]();_.jf(r,3);break;case 2:k=_.kf(r);if(a.j)return r["return"]();l=_.t(b);for(e=l.next();!e.done;e=
l.next())m=e.value,a.w.get(m.id).promise["catch"](function(){}),a.w.get(m.id).promise.reject(),a.w["delete"](m.id);throw k;case 3:n=_.t(b);for(e=n.next();!e.done;e=n.next())p=e.value,q=a.w.get(p.id),q.Kc||(q.promise.resolve(),q.Kc=!0,a.a.debug("(all) setup Stream "+p.id));_.x(r)}})};Vx=function(a){var b=a.g.presentationTimeline.getDuration();Infinity>b?a.f.U.Nb(b):a.f.U.Nb(Math.pow(2,32))};
ay=function(a,b){if(!a.j){var c=Qx(b);if(!b.eb&&null!=b.xb&&!b.Sb)if(b.xb=null,b.zb)a.a.debug(c,"skipping update and clearing the buffer"),Tx(a,b,b.Wd,b.pd);else{try{var d=Yx(a,b);null!=d&&(Jx(a,b,d),b.Gc=!1)}catch(e){Zx(a,e);return}d=Array.from(a.h.values());$x(a,b);a.C&&d.every(function(e){return e.endOfStream})&&(a.a.debug(c,"calling endOfStream()..."),a.f.U.endOfStream().then(function(){if(!a.j&&!a.g.presentationTimeline.bc()){var e=a.f.U.getDuration();e<a.g.presentationTimeline.getDuration()&&
a.g.presentationTimeline.Nb(e)}}).then(function(){a.f.hg()}))}}};
Yx=function(a,b){function c(p){return p.type==_.le&&p.stream.mimeType==_.Kb}if(c(b))return a.f.U.Td(b.stream.originalId||""),null;var d=Qx(b),e=a.f.rb(),f=Px(a,b,e);a.a.debug(d,"timeNeeded\x3d"+f);var g=Nx(a,b.stream),k=Hx(a,f),l=a.f.U.Ca(b.type,e);a.a.debug(d,"update_:",Wt+e,"bufferedAhead\x3d"+l);var m=Math.max(a.g.minBufferTime||0,a.m.rebufferingGoal,a.m.bufferingGoal)*a.B,n=a.g.presentationTimeline.bc()?_.pp(a.g.presentationTimeline):a.g.presentationTimeline.getDuration();if(f>=n&&!b.vd)return by(a,
b),null;b.endOfStream=!1;b.dc=k;if(k!=g)return a.a.debug(d,"need Period "+k,Wt+e,"timeNeeded\x3d"+f,"currentPeriodIndex\x3d"+g),null;if(l>=m)return a.a.debug(d,"buffering goal met"),a.m.bufferLimitUpdateInterval||.5;k=a.f.U.na(b.type);k=Rx(a,b,e,k,g);if(!k)return a.g.presentationTimeline.isLive()||b.type===_.le?a.m.bufferLimitUpdateInterval||1:(a.a.debug("Media state "+d+": no next segment found. Assuming EOS."),by(a,b),null);d=Infinity;l=_.t(a.h.values());for(m=l.next();!m.done;m=l.next())m=m.value,
c(m)||(m=Px(a,m,e),d=Math.min(d,m));if(f>=d+a.g.presentationTimeline.a)return a.m.bufferLimitUpdateInterval||1;b.Dg=0;cy(a,b,e,g,k);return null};by=function(a,b){a.a.debug(Qx(b),"buffered to end of presentation");b.endOfStream=!0;if(b.type==_.Be){var c=a.h.get(_.le);c&&c.stream.mimeType==_.Kb&&(c.endOfStream=!0)}};Px=function(a,b,c){if(!b.Hb||!b.xa)return Math.max(c,b.Dg);c=Nx(a,b.Hb);return a.g.periods[c].startTime+b.xa.endTime};
Rx=function(a,b,c,d,e){var f=Qx(b);if(b.xa&&b.stream==b.Hb)return d=b.xa.position+1,a.a.debug(f,"next position known:","position\x3d"+d),dy(a,b,e,d);b.xa?(a.a.debug(f,"next position unknown: another Stream buffered"),f=Nx(a,b.Hb),f=ey(a,b,a.g.periods[f].startTime+b.xa.endTime,e)):(a.a.debug(f,"next position unknown: nothing buffered"),f=ey(a,b,d||c,e));if(null==f)return null;c=null;null==d&&(c=dy(a,b,e,Math.max(0,f-1)));return c||dy(a,b,e,f)};
ey=function(a,b,c,d){var e=Qx(b);d=a.g.periods[d];a.a.debug(e,"looking up segment:","lastSegment.endTime\x3d"+c,Tt+d.startTime);c=Math.max(0,c-d.startTime);b=b.stream.findSegmentPosition(c);null==b&&a.a.warn(e,"cannot find segment:",Tt+d.startTime,"lookupTime\x3d"+c);return b};
dy=function(a,b,c,d){var e=Qx(b);c=a.g.periods[c];var f=b.stream.getSegmentReference(d);if(!f)return a.a.debug(e,"segment does not exist:",Tt+c.startTime,"position\x3d"+d),null;var g=a.g.presentationTimeline;d=_.qp(g);g=_.pp(g);return!b.vd&&(c.startTime+f.endTime<d||c.startTime+f.startTime>g)?(a.a.debug(e,"segment is not available:",Tt+c.startTime,Yt+f.startTime,Xt+f.endTime,"availabilityStart\x3d"+d,"availabilityEnd\x3d"+g),null):f};
cy=function(a,b,c,d,e){function f(r,w){if(a.j||a.A)return null;p=!0;return fy(a,b,c,k,l,e,r,w)}var g=Qx(b),k=a.g.periods[d];a.a.debug(g,"fetchAndAppend_:",Wt+c,Tt+k.startTime,"reference.position\x3d"+e.position,Yt+e.startTime,Xt+e.endTime);var l=b.stream,m=a.g.presentationTimeline.getDuration(),n=a.g.periods[d+1];d=gy(a,b,d,Math.max(0,k.startTime-.1),n?n.startTime+.01:m);b.eb=!0;b.Jd=!1;a.a.debug(g,"fetching segment");var p=!1,q=a.f.U.na(b.type)||0;d.then(function(){return a.j||a.A?null:hy(a,b,e,
!1,function(r){return f(r.data,!0)})}).then(function(r){return p||a.j||a.A||null===r?(a.j||a.A||iy(a,b,q,e,l),null):f(r,!1)}).then(function(){a.j||a.A||(iy(a,b,q,e,l),b.eb=!1,b.Qe=!1,b.zb||a.f.Ld(),Jx(a,b,0),jy(a,b,l),a.a.debug(g,"finished fetch and append"))})["catch"](function(r){if(!a.j&&!a.A)if(r,b.eb=!1,b.type==_.le&&a.m.ignoreTextStreamFailures)1001==r.code?a.a.warn(g,"Text stream failed to download. Proceeding without it."):a.a.warn(g,"Text stream failed to parse. Proceeding without it."),
a.h["delete"](_.le);else if(7001==r.code)b.eb=!1,b.xb=null,Jx(a,b,0);else if(3017==r.code)ky(a,b,r);else if(b.type===_.le)r instanceof _.J&&r.f(1),Sx(b),a.f.onError(new _.J(1,2,2010,r));else if(1001<=r.code&&1003>=r.code){var w=!0;1001===r.code&&500<=r.data.status||(w=typeof a.f.kf===_.Vc&&!a.f.kf(b.stream));w&&(b.Gc=!0,r.f(_.K),Zx(a,r))}else a.a.error(g,"failed fetch and append: code\x3d"+r.code),b.Gc=!0,r instanceof _.J&&r.f(_.K),Zx(a,r)})};
my=function(a,b){b=void 0===b?!1:b;if(a.j)a.a.error("Unable to retry after StreamingEngine is destroyed!");else if(a.A)a.a.error("Unable to retry after StreamingEngine encountered a fatal error!");else for(var c=_.t(a.h.values()),d=c.next();!d.done;d=c.next()){d=d.value;var e=Qx(d);if(d.Gc||b&&ly(d))a.a.info(e,"Retrying after failure..."),d.Gc=!1,Jx(a,d,.1)}};
ky=function(a,b,c){var d=Qx(b);if(Array.from(a.h.values()).some(function(f){return f!=b&&f.Qe}))a.a.debug(d,Ht,"waiting for another stream to recover...");else{var e=Math.round(100*a.B);if(20<e)a.B-=.2;else if(4<e)a.B-=.04;else{a.a.error(d,"MediaSource threw QuotaExceededError too many times");b.Gc=!0;a.A=!0;a.f.onError(c);return}a.a.warn(d,Ht,"reducing buffering goals by "+(100-Math.round(100*a.B))+"%");b.Qe=!0}Jx(a,b,4)};
gy=function(a,b,c,d,e){if(!b.Jd)return Promise.resolve();var f=Qx(b);c=a.g.periods[c].startTime-(b.stream.presentationTimeOffset||0);a.a.debug(f,"setting timestamp offset to "+c);a.a.debug(f,"setting append window start to "+d);a.a.debug(f,"setting append window end to "+e);d=bw(a.f.U,b.type,c,d,e);if(!b.stream.initSegmentReference)return d;a.a.debug(f,"fetching init segment");e=hy(a,b,b.stream.initSegmentReference,!0).then(function(g){if(a.j)return null;a.a.debug(f,"appending init segment");return $v(a.f.U,
b.type,g,null,null,b.stream.closedCaptions&&0<b.stream.closedCaptions.size)})["catch"](function(g){b.Jd=!0;return Promise.reject(g)});return Promise.all([d,e])};
fy=function(a,b,c,d,e,f,g,k){k=void 0===k?!1:k;var l=Qx(b),m=e.closedCaptions&&0<e.closedCaptions.size;null!=e.emsgSchemeIdUris&&0<e.emsgSchemeIdUris.length&&(new _.bi).da("emsg",a.K.bind(a,d,f,e.emsgSchemeIdUris)).parse(g);return ny(a,b,c).then(function(){if(a.j)return null;a.a.debug(l,"appending media segment");return $v(a.f.U,b.type,g,f.startTime+d.startTime,f.endTime+d.startTime,m,e.id,k)}).then(function(){a.j||(a.a.debug(l,"appended media segment"),b.Hb=e,b.xa=f)})};
iy=function(a,b,c,d,e){if(!a.g.presentationTimeline.isLive()&&b.type!==_.le){var f=Qx(b);c=(a.f.U.na(b.type)||0)-c;var g=d.endTime-d.startTime,k=c-g;Math.abs(k)>=Math.min(.01,g)&&Math.abs(k)<=Math.min(1,g)&&(a.a.debug(f,"Adjusted segment",d.position,"with real duration",c,"and accounted for",k,"delta between segment duration and real duration"),b.vd=!0,e.adjustSegmentDurations(d.position,k))}};
ny=function(a,b,c){if(a.j)return Promise.resolve();var d=Qx(b);a.a.debug(d,"checking buffer length");var e=Math.max(a.m.bufferBehind,a.g.presentationTimeline.a),f=a.f.U.mb(b.type);if(null==f)return a.a.debug(d,"buffer behind okay because nothing buffered:",Wt+c,Pt+e),Promise.resolve();var g=c-f,k=g-e;if(.01>=k)return a.a.debug(d,"buffer behind okay:",Wt+c,Qt+g,Pt+e,"underflow\x3d"+Math.abs(k)),Promise.resolve();a.a.debug(d,"buffer behind too large:",Wt+c,Qt+g,Pt+e,"overflow\x3d"+k);return a.f.U.remove(b.type,
f,f+k).then(function(){a.j||a.a.debug(d,"evicted "+k+" seconds")})};jy=function(a,b,c){if(!a.C){var d=Qx(b);b=Array.from(a.h.values());1!=b.length||b[0].type!=_.le?a.C=b.every(function(e){return e.type==_.le||a.D?!0:!e.zb&&!e.Sb&&e.xa}):a.D&&(a.C=!0);if(a.C){a.a.debug(d,"startup complete");c=Nx(a,c);a.o[c]||Xx(a,c).then(function(){a.j||(a.a.debug(d,Rt),a.f.Ie())})["catch"](_.Ol.Yc);for(c=0;c<a.g.periods.length;++c)Xx(a,c)["catch"](_.Ol.Yc);a.f.$j&&(a.a.debug(d,"calling onStartupComplete()..."),a.f.$j())}}};
$x=function(a,b){var c=Qx(b),d=Nx(a,b.stream);if(b.dc!=d){var e=b.dc,f=Array.from(a.h.values());f.every(function(g){return g.dc==e})?f.every(ly)?(a.a.debug(c,"all need Period "+e),Xx(a,e).then(function(){if(!a.j)if(f.every(function(n){var p=ly(n),q=Nx(a,n.stream);return p&&n.dc==e&&q!=e})){var g=a.g.periods[e];a.a.debug(c,"calling onChooseStreams()...");var k=a.f.Je(g);a.D=!k.variant&&!!k.text;var l=new Map;k.variant&&k.variant.video&&l.set(_.Be,k.variant.video);k.variant&&k.variant.audio&&l.set(_.Wb,
k.variant.audio);k.text&&l.set(_.le,k.text);var m=_.t(a.h.keys());for(k=m.next();!k.done;k=m.next())if(k=k.value,!l.has(k)&&k!=_.le){a.a.error(c,"invalid Streams chosen: missing "+k+" Stream");a.f.onError(new _.J(_.K,5,5005));return}m=_.t(Array.from(l.keys()));for(k=m.next();!k.done;k=m.next())if(k=k.value,!a.h.has(k))if(k==_.le)Wx(a,null,null,l.get(_.le),g.startTime),l["delete"](k);else{a.a.error(c,"invalid Streams chosen: unusable "+k+" Stream");a.f.onError(new _.J(_.K,5,5005));return}g=_.t(Array.from(a.h.keys()));
for(k=g.next();!k.done;k=g.next())k=k.value,(m=l.get(k))?(_.Lx(a,m,!1,0),Jx(a,a.h.get(k),0)):a.h["delete"](k);a.a.debug(c,Rt);a.f.Ie()}else a.a.debug(c,"ignoring transition to Period",e,"since another is happening")})["catch"](_.Ol.Yc)):a.a.debug(c,"all MediaStates need Period "+e+", but not all MediaStates are idle"):a.a.debug(c,"not all MediaStates need Period "+e)}};Hx=function(a,b){var c=ou(a.g.periods,b+_.Vp);return c?a.g.periods.indexOf(c):0};
Nx=function(a,b){for(var c=a.g.periods,d=0;d<c.length;d++){for(var e=c[d],f=new Set,g=_.t(e.variants),k=g.next();!k.done;k=g.next())k=k.value,k.audio&&f.add(k.audio),k.video&&f.add(k.video),k.video&&k.video.trickModeVideo&&f.add(k.video.trickModeVideo);e=_.t(e.textStreams);for(g=e.next();!g.done;g=e.next())f.add(g.value);if(f.has(b))return d}return-1};
hy=function(a,b,c,d,e){d=void 0===d?!1:d;e=void 0===e?null:e;var f=_.oo(c.Oa(),c.Ia,c.sa,a.m.attemptParameters,c.startTime,c.endTime,b.type,c.timescale);f.type=_.Op;f.onFragment=c.Ac?null:e;if(e=c.a())f.keyInfo=e,f.onFragment=null;d&&b.type===_.Be&&a.m.overridePasp&&f.responseModifiers.push(a.L.bind(a));a.a.debug("fetching: reference\x3d",c);c=a.f.ub.fetch(f);b.ec=c;return c.promise.then(function(g){var k=a.f.ne(),l=g.timeMs,m=g.data.byteLength;l&&m&&!d&&k.segmentDownloaded(l,m);b.ec=null;return g.data})};
Tx=function(a,b,c,d){var e,f,g,k;return _.z(function(l){if(1==l.a)return e=Qx(b),b.zb=!1,b.Wd=!1,b.pd=0,b.Sb=!0,a.a.debug(e,"clearing buffer"),d?(g=a.f.rb(),k=a.f.U.getDuration(),f=a.f.U.remove(b.type,g+d,k)):f=a.f.U.clear(b.type).then(function(){return!a.j&&c?a.f.U.flush(b.type):null}),_.v(l,f,2);if(a.j)return l["return"]();a.a.debug(e,"cleared buffer");b.Hb=null;b.xa=null;b.Sb=!1;b.endOfStream=!1;b.vd=!1;Jx(a,b,0);_.x(l)})};
Jx=function(a,b,c){a.a.debug(Qx(b),"updating in "+c+" seconds");b.xb=(new _.Vi(function(){ay(a,b)})).$(c)};Sx=function(a){null!=a.xb&&(a.xb.stop(),a.xb=null)};oy=function(a){var b,c,d,e;return _.z(function(f){b=[];c=_.t(a.h.values());for(d=c.next();!d.done;d=c.next())e=d.value,Sx(e),e.eb&&e.ec&&(e.eb=!1,b.push(e.ec.abort())),e.zb&&b.push(Tx(a,e,!0,0));return _.v(f,Promise.all(b),0)})};
py=function(a,b,c){(a=a.h.get(b))&&c&&(Sx(a),a.stream=c,a.Hb=c,a.xa&&(a.xa=c.getSegmentReference(a.xa.position)),a.xa||(a.Hb=null))};Zx=function(a,b){var c;_.z(function(d){switch(d.a){case 1:if(a.j)return d["return"]();if(!a.f.Hg){d.H(2);break}_.hf(d,3);return _.v(d,a.f.Hg(b),5);case 5:_.jf(d,2);break;case 3:c=_.kf(d),c.g=b,b=c;case 2:if(!b.j)a.f.onError(b);_.x(d)}})};Qx=function(a){return"("+a.type+":"+a.stream.id+")"};ly=function(a){return!a.eb&&null==a.xb&&!a.zb&&!a.Sb};
sy=function(a){_.Qk.call(this,St);var b=this;this.C=qy;this.h=null;this.Ce=0;this.bg=2;this.Nc=null;this.Ae=!1;this.m=new _.zi;this.Aa=this.yd=this.vc=this.wc=this.o=this.G=this.j=null;this.Mc=!1;this.g=this.a=this.B=this.f=this.sc=this.L=null;this.Bg=1E9;this.Dd=new Set;this.O=!0;this.aa=null;this.vg=!1;this.cg=0;this.X=null;this.T=new Ow;this.Ag={width:Infinity,height:Infinity};this.D=null;this.Z=_.Wo;this.tc=new Vw(this.configuration.preferredAudioLanguage,this.configuration.preferredAudioRole,
this.configuration.preferredAudioChannelCount);this.ra=this.configuration.preferredTextLanguage;this.J=this.uc=null;this.oc=new Map;this.ga=null;this.Ja=this.configuration.preferredTextRole;a&&a(this);this.Be=null;this.m.on(window,"online",function(){b.C==ry&&my(b.f)})};
ty=function(a){return new _.Au(function(b){if(void 0===b||null===b)return null;var c=a.getTrackManager(),d=[];if(c){c=_.t(c.getVideoTracks());for(var e=c.next();!e.done;e=c.next())d.push.apply(d,_.bf(e.value.renditions))}return d.find(function(f){return f.id==b})||null})};
uy=function(a,b){if(a.Z!==b)if(a.Z===_.gm&&b!==_.Wo){var c=ku(b,_.dp);a.log.info(_.Lt+c)}else{c=a.Ce;c=0===c?0:Date.now()-c;var d=a.Z;a.Z=b;a.Ce=Date.now();a.D&&_.Sl(a.D.a,a.Z);b===_.$o&&(a.bg=a.ue?1:2);var e=a.bg,f={currentState:a.Z,previousState:d,timeSinceLastStateChangeMS:c,reason:b===_.$o||d===_.$o?e:void 0},g=ku(d,_.dp),k=ku(b,_.dp),l=b!==_.$o?"":"["+ku(e,_.cp)+"]";a.log.info(_.Ra+g+" -\x3e "+(k+l));a.trigger(new _.O(_.ee,{detail:f}));(b===_.bp||b===_.gm)&&a.Aa&&a.Aa.stop();b===_.$o?a.trigger(new _.O(_.gc,
{detail:{bufferedTimeMS:-1,reason:e}})):d===_.$o&&a.trigger(new _.O(_.fc,{detail:{bufferedTimeMS:c,reason:e}}))}};Ay=function(a){var b=!1;b=void 0===b?!0:b;var c;return _.z(function(d){switch(d.a){case 1:return a.C==vy?d["return"](Promise.reject(new _.J(_.K,7,7E3))):_.v(d,wy(a),2);case 2:return _.v(d,xy(a),3);case 3:if(!b){d.H(0);break}c=a.getSurface().getMedia();return _.v(d,yy(a,c),5);case 5:return _.v(d,zy(a,c),0)}})};
yy=function(a,b){a.h||(a.m.on(b,_.Sc,function(){if(a.h.error){var c=a.h.error.code;if(1==c)c=null;else{var d=a.h.error.msExtendedCode;d&&(0>d&&(d+=Math.pow(2,32)),d=d.toString(16));c=new _.J(_.K,3,3016,{code:c,extendedError:d,message:a.h.error.message})}}else c=null;if(c)a.onError(c)}),a.h=b);return Promise.resolve()};xy=function(a){a.h&&(a.m.off(a.h,_.Sc),a.h=null);return Promise.resolve()};
wy=function(a){var b;return _.z(function(c){switch(c.a){case 1:a.C!=vy&&(a.C=qy);if(b=a.h)a.m.off(b,_.jd),a.m.off(b,_.Md),a.m.off(b,_.Id),a.m.off(b,_.Qc),a.m.off(b,_.Rd),a.m.off(b,_.Pd);a.wc&&(a.wc.release(),a.wc=null);a.Aa&&(a.Aa.stop(),a.Aa=null);if(!a.B){c.H(2);break}return _.v(c,a.B.stop(),3);case 3:a.B=null;case 2:if(!a.g){c.H(4);break}return _.v(c,a.g.stop(),4);case 4:if(!a.f){c.H(6);break}return _.v(c,a.f.destroy(),7);case 7:a.f=null;case 6:a.o&&(a.o.release(),a.o=null);if(!a.G){c.H(8);break}return _.v(c,
a.G.destroy(),9);case 9:a.G=null;case 8:a.yd&&(a.yd.release(),a.yd=null);b&&b.src&&(b.removeAttribute("src"),b.load());if(!a.j){c.H(10);break}return _.v(c,a.j.destroy(),11);case 11:a.j=null;case 10:a.T.clear(),a.L=null,a.Dd.clear(),a.a=null,a.D=null,a.O=!0,a.oc.clear(),a.Mc=!1,By(a),_.x(c)}})};
zy=function(a,b){var c,d,e;return _.z(function(f){if(1==f.a)return c=window.muxjs?new ov:new _.pv,d=_.xg(a.configuration,["html",_.be]),a.I=d.create(a),a.Nc=ty(a),a.Nc.track(a),e=new _.xv(b,c,a.I,a.Nc,a.configuration.streaming.maxSegmentToMediaOffset),_.v(f,e.G,2);a.G=e;_.x(f)})};
Cy=function(a,b){var c,d;return _.z(function(e){if(1==e.a)return c=a.getNetworkEngine(),d=a,_.v(e,_.Wp.create(b.url,c,a.configuration.manifest.attemptParameters,b.type),2);d.B=e.f;a.B.configure(a.configuration.manifest);_.x(e)})};
Gy=function(a,b){var c;return _.z(function(d){a.sc=new tu;uu(a.sc,function(e){Dy(a,_.Ki,e)});c=new _.im(Promise.resolve().then(function(){var e,f,g;return _.z(function(k){switch(k.a){case 1:if(a.a){k.H(3);break}_.hf(k,4);e=a;return _.v(k,a.B.start(b.url,Ey(a)),6);case 6:e.a=k.f;_.jf(k,1);break;case 4:f=_.kf(k);if(!a.Ba)throw f;return _.v(k,a.Ba(b.url,f),8);case 8:if(g=k.f)b.url=g;else throw f;k.H(1);break;case 3:!a.a.presentationTimeline||_.B.N(a.configuration.suggestedPresentationDelay)||isNaN(a.configuration.suggestedPresentationDelay)||
(a.log.info("Apply suggested presentation delay of: "+a.configuration.suggestedPresentationDelay),a.a.presentationTimeline.D=a.configuration.suggestedPresentationDelay);a.a.presentationTimeline&&(a.a.presentationTimeline.C=!!a.configuration.lowLatencyMode);if(0==a.a.periods.length)throw new _.J(_.K,4,4014);Fy(a.a.periods);_.x(k)}})}),function(){a.log.info("Aborting parser step...");return a.B.stop()});return _.v(d,c.promise,0)})};
Oy=function(a){var b,c,d,e,f;return _.z(function(g){switch(g.a){case 1:a.j=new _.ll({ub:a.getNetworkEngine(),onError:function(k){return a.onError(k)},Ke:function(k){if(a.f){var l=Hy(a),m=!1,n=Object.keys(k);0==n.length&&a.log.warn("Got a key status event without any key statuses, so we don't know the real key statuses. If we don't have all the keys, you'll need to set restrictions so we don't select those tracks.");var p=1==n.length&&"00"==n[0];p&&a.log.warn("Got a synthetic key status event, so we don't know the real key statuses. If we don't have all the keys, you'll need to set restrictions so we don't select those tracks.");
if(n.length){n=_.t(l.variants);for(var q=n.next();!q.done;q=n.next()){q=q.value;var r=[];q.audio&&r.push(q.audio);q.video&&r.push(q.video);r=_.t(r);for(var w=r.next();!w.done;w=r.next()){var y=w.value;w=q.allowedByKeySystem;y.keyId&&(y=k[p?"00":y.keyId],q.allowedByKeySystem=!!y&&!Iy.includes(y));w!=q.allowedByKeySystem&&(m=!0)}}}k=Ex(a.f);p=Fx(a.f);(k=pw(k,p,l.variants))&&!k.allowedByKeySystem&&(a.log.debug("Choosing new streams after key status changed"),Jy(a,l));m&&(Ky(a),Ly(a,l.variants))}},onExpirationUpdated:function(k,
l){if(a.B&&a.B.onExpirationUpdated)a.B.onExpirationUpdated(k,l);a.trigger(new _.O(_.Hc,{expirationTime:l}))},onEvent:function(k){return a.ge(k)},getConfiguration:function(){return a.getConfiguration()},Fc:function(){return a.Fc()}});My(a);c=(b=a.configuration.drm)?b.offlineId||"":"";d=[];e=_.Tn(a.a.periods);if(!c){g.H(2);break}f=_.mm(_.rc);if(!f){g.H(2);break}return _.v(g,f.ek(b,e,a.getNetworkEngine()),4);case 4:return _.v(g,f.Vi(c),5);case 5:d=g.f;case 2:return _.v(g,_.ql(a.j,e,d),6);case 6:Ny(a,
a.a.periods),_.x(g)}})};
$y=function(a){var b,c,d,e,f,g,k,l,m,n,p;return _.z(function(q){if(1==q.a)return b=a.h,c=function(r){a.h&&a.L&&(r.type===_.$d&&a.Gd?(a.L.a=$w,By(a)):Py(a));Qy(a)},d=function(){if(a.h){var r=a.h.playbackRate;0!=r&&a.vc.set(r)}},a.m.on(b,_.Md,c),a.m.on(b,_.Id,c),a.m.on(b,_.Qc,c),a.m.on(b,_.$d,c),a.m.on(b,_.Zd,c),a.m.on(b,_.Pd,c),a.m.on(b,_.Rd,d),a.g||(a.g=new _.Mw,a.g.configure(a.configuration.abr)),Ry(a,a.a.periods),a.tc=new Vw(a.configuration.preferredAudioLanguage,a.configuration.preferredAudioRole,a.configuration.preferredAudioChannelCount),
a.ra=a.configuration.preferredTextLanguage,a.Ja=a.configuration.preferredTextRole,_.v(q,_.Bl(a.j,b),2);if(3!=q.a)return a.g.init(function(r,w,y){!a.O&&Sy(a,r,w,y)}),a.o=Ty(a,a.configuration.startTime),a.wc=Uy(a),a.vc=new fx({qe:function(){return b.playbackRate},Fg:function(r){b.playbackRate=r},jj:function(r){b.currentTime+=r}}),a.isLive()&&a.configuration.streaming.enableLiveEdgeChasing&&(a.yd=new cx(a)),e=Math.max(1E-4,a.configuration.streaming.rebufferingGoal),Vy(a,e),a.f=Wy(a),a.f.m=a.configuration.streaming,
Xy(a),a.C=ry,_.v(q,a.f.start(),3);a.configuration.streaming.startAtSegmentBoundary&&(f=Ax(a.o),g=Yy(a,f),tx(a.o.g,g));k=_.t(a.a.periods);for(l=k.next();!l.done;l=k.next())m=l.value,Zy(a,m);Ky(a);n=Hy(a)||a.a.periods[0];p=n.variants.some(function(r){return r.primary});a.configuration.preferredAudioLanguage||p||a.log.warn("No preferred audio language set.  We will choose an arbitrary language initially");Ly(a,n.variants);a.m.one(b,_.jd,function(){a.D.o=Date.now()/1E3-(null===a.Be?0:a.Be)});_.x(q)})};
Fy=function(a){function b(d){return d.video&&d.audio||d.video&&d.video.codecs.includes(",")}if(a.some(function(d){return d.variants.some(b)})){_.H("Found variant with audio and video content, so filtering out audio-only content in all periods.");a=_.t(a);for(var c=a.next();!c.done;c=a.next())c=c.value,c.variants=c.variants.filter(b)}};
Xy=function(a){function b(q){var r="";q.video&&(r=_.Qg(q.video.codecs)[0]);var w="";q.audio&&(w=_.Qg(q.audio.codecs)[0]);return r+"-"+w}var c=a.a.periods.flatMap(function(q){return q.variants});c=mw(c,a.configuration.preferredAudioChannelCount);if(1<a.a.periods.length){for(var d=function(q){q=q.split(".");var r=q[0];1<q.length&&(r+="."+q[1]);return r.toLowerCase()},e=[],f=_.t(a.a.periods),g=f.next();!g.done;g=f.next()){var k=new Set;g=_.t(g.value.variants);for(var l=g.next();!l.done;l=g.next())k.add(d(l.value.audio.codecs));
e.push(k)}var m=e[0];f={};for(k=1;k<e.length;f={Xd:f.Xd},k++)f.Xd=e[k],m=new Set(_.bf(m).concat().filter(function(q){return function(r){return q.Xd.has(r)}}(f)));c=c.filter(function(q){return m.has(d(q.audio.codecs))})}e=new _.wi;c=_.t(c);for(f=c.next();!f.done;f=c.next())f=f.value,k=b(f),e.push(k,f);var n=null,p=Infinity;du(e,function(q,r){for(var w=0,y=0,F=_.t(r),C=F.next();!C.done;C=F.next())w+=C.value.bandwidth||0,++y;w/=y;a.log.debug(_.xc,q,"avg bandwidth",w);w<p&&(n=q,p=w)});c=_.t(a.a.periods);
for(e=c.next();!e.done;e=c.next())e=e.value,e.variants=e.variants.filter(function(q){if(b(q)==n)return!0;a.log.debug("Dropping Variant (better codec available)",q);return!1})};Ty=function(a,b){return new yx(a.h,a.a,a.configuration.streaming,b,function(){a.wc&&xu(a.wc,!0);a.f&&Ux(a.f)},function(c){return a.dispatchEvent(c)})};
Uy=function(a){var b=new dx(a.a);ex(b,function(){Ky(a)});var c=new vu(a.sc);wu(c,function(e){e.kg&&e.kg();Dy(a,_.Li,e)},function(e){e.Ij&&e.Ij();Dy(a,_.Mi,e)},function(e,f){f||(Dy(a,_.Li,e),Dy(a,_.Mi,e))});var d=new yu(a.h);d.a.add(b);d.a.add(c);return d};Vy=function(a,b){a.L=new ax(b,Math.min(.5,b/2));a.L.a=$w;By(a);a.Aa=(new _.Xi(function(){Py(a)})).Ua(.25)};
Py=function(a){switch(a.C){case ry:a:if(a.h.ended||Wv(a.G))var b=!0;else{if(a.a.presentationTimeline.isLive()){b=_.pp(a.a.presentationTimeline);var c=a.getBufferInfo();if(null!==c&&(c=c.na(),null!==c&&c>=b)){b=!0;break a}}b=!1}break;default:b=!1}var d=0;if(a.G){var e=Yv(a.G);c=e.video;e=e.audio;var f=a.h.currentTime;if(c||e)d=c&&e?Math.min(c.Ca(f),e.Ca(f)):c?c.Ca(f):e.Ca(f)}c=a.L;e=d;f=b;var g=c.f.get(c.a);d=c.a;e=f||e>=g?Zw:$w;c.a=e;c=d!=e;d=az(a);a.log.debug("Buffering state change",c,"is buffering",
d,"buffered to end",b,"state",a.getState());c||a.getState()===_.$o&&!d?By(a):a.Mc&&Qy(a)};Wy=function(a){var b={rb:function(){return Ax(a.o)},U:a.G,ub:a.getNetworkEngine(),Je:a.Wh.bind(a),Ie:a.Vh.bind(a),onError:a.onError.bind(a),hg:a.Gj.bind(a),onEvent:a.ge.bind(a),pg:a.Xh.bind(a),Ld:a.Uj.bind(a),ne:function(){return a.g},kf:a.fh.bind(a),Hg:a.wk.bind(a)};return new _.Cx(a.a,b)};My=function(a){var b=_.em(a.configuration.drm);a.j.h=b};
Dw=function(a){if(a.a&&a.o){var b=bz(a),c=[];a=_.t(cz(a));for(var d=a.next();!d.done;d=a.next()){d=d.value;var e=d.audio,f=d.video,g=e?e.codecs:null,k=f?f.codecs:null,l=[];k&&l.push(k);g&&l.push(g);var m=f&&f.mimeType||null,n=e&&e.mimeType||null,p=[];e&&p.push(e.kind);f&&p.push(f.kind);p=p[0]||null;var q=new Set,r=new Set;if(e)for(var w=_.t(e.roles),y=w.next();!y.done;y=w.next())r.add(y.value);if(f)for(w=_.t(f.roles),y=w.next();!y.done;y=w.next())q.add(y.value);g={id:d.id,active:!1,type:"variant",
bandwidth:d.bandwidth,language:d.language,label:null,kind:p,width:null,height:null,frameRate:null,mimeType:m,audioMimeType:n,codecs:l.join(", "),audioCodec:g,videoCodec:k,primary:d.primary,roles:Array.from(q),audioRoles:Array.from(r),videoId:null,audioId:null,channelsCount:null,audioBandwidth:null,videoBandwidth:null,originalVideoId:null,originalAudioId:null,originalTextId:null};f&&(g.videoId=f.id,g.originalVideoId=f.originalId,g.width=f.width||null,g.height=f.height||null,g.frameRate=f.frameRate||
null,g.videoBandwidth=f.bandwidth||null);e&&(g.audioId=e.id,g.originalAudioId=e.originalId,g.channelsCount=e.channelsCount,g.audioBandwidth=e.bandwidth||null,g.label=e.label,g.audioRoles=e.roles);e=g;e.active=d==b;c.push(e)}return c}return[]};Bw=function(a,b){if(a.a&&a.f){var c=Hy(a),d=c.textStreams.find(function(e){return e.id==b.id});d?(dz(a,c,d,!1),ez(a,d),a.ra=d.language):a.log.error(_.It,b.id)}};
Ew=function(a,b,c){var d=void 0===d?0:d;if(a.a&&a.f){var e=Hy(a);if(a.configuration.abr.enabled&&b)a.log.info("Disable ABR and enable manual track selection to select "+(b.width+"x"+b.height+"@"+b.bandwidth)),a.configuration.abr.enabled=!1,a.g.disable();else if(!a.configuration.abr.enabled&&!b){a.log.info("Enable ABR Selection");a.configuration.abr.enabled=!0;a.g.enable();a.J=null;c=Ly(a,e.variants);null!=a.ga&&(a.tc=a.ga,a.ga=null);null==c||a.O||Sy(a,c);return}if(b){a.J=b.id;var f=e.variants.find(function(g){return g.id==
b.id});f?(a.J=f.video.id,lw(f)?(fz(a,e,f,!1),gz(a,f,c,d),null==a.ga&&(a.ga=a.tc),a.tc=new Ww(f),Ly(a,e.variants)):a.log.error("Unable to switch to restricted track",b.id)):a.log.error("No variant with id",b.id)}else a.J=null,null!=a.ga&&(a.tc=a.ga,a.ga=null),c=Ly(a,e.variants),null==c||a.O||Sy(a,c)}};
Cw=function(a,b){if(a.f){var c=Fx(a.f),d=Ex(a.f),e=ou(a.a.periods,Ax(a.o)),f=a.a.periods[e?a.a.periods.indexOf(e):0].variants.filter(function(l){return l.audio.id==b});e=f.find(function(l){return l.video.id==c.id});if(!e){a.log.debug("Cannot find exact variant, looking for anything else that matches with given audioId");var g=a.getTrackManager().wa();if(g){var k=g.renditions.map(function(l){return l.id});e=f.find(function(l){return k.find(function(m){return m==l.video.id})})}}if(e){if(d.codecs.split(".")[0]===
e.audio.codecs.split(".")[0]){a.uc=b;(d=a.getLoadedSource())&&d.type!==_.wm&&(a.J=e.video.id);d=Hy(a);Jy(a,d);Promise.resolve();return}a.log.warn(Ft)}}Promise.reject()};Gw=function(a){var b=a.Ae;return a.a?a.G.getTextDisplayer().isTextVisible():b};
Aw=function(a,b){var c,d,e,f;return _.z(function(g){c=a.Ae;d=b;if(c==d)return g["return"]();a.Ae=d;if(a.C!=ry)return g.H(0);a.G.getTextDisplayer().setTextVisibility(b);if(a.configuration.streaming.alwaysStreamText)return g.H(0);if(!b){var k=a.f;k.J=!0;var l=k.h.get(_.le);l&&(Sx(l),k.h["delete"](_.le));return g.H(0)}e=Hy(a);f=_.ow(e.textStreams,a.ra,a.Ja);return 0<f.length?_.v(g,Kx(a.f,f[0]),0):g.H(0)})};
fz=function(a,b,c,d){var e=a.getTrackManager();if(e){var f=a.D.w;if(c.video){Jw(e,c.video,d);var g=e.fa();f.h!==g&&(f.h=g,f.a.push({timestamp:Date.now()/1E3,id:g.id,trackId:g.track.id,type:g.track.type,fromAdaptation:d,width:g.width,height:g.height,bandwidth:g.bandwidth}))}c.audio&&(Jw(e,c.audio,d),e=e.ta(),f.f!==e&&(f.f=e,f.a.push({timestamp:Date.now()/1E3,id:e.id,trackId:e.track.id,type:e.track.type,fromAdaptation:d,width:null,height:null,bandwidth:e.bandwidth})))}Qw(a.T,b).variant=c};
dz=function(a,b,c,d){Qw(a.T,b).text=c;if(b=a.getTrackManager())Jw(b,c,d),c=b.va(),a=a.D.w,a.g!==c&&(a.g=c,a.a.push({timestamp:Date.now()/1E3,id:c.id,trackId:c.track.id,type:c.track.type,fromAdaptation:d,width:null,height:null,bandwidth:null}))};
Ry=function(a,b){for(var c=0;c<b.length;c++){for(var d=b[c],e=new Map,f=_.t(d.variants),g=f.next();!g.done;g=f.next())if(g=g.value,g.video&&g.video.closedCaptions){g=g.video;for(var k=_.t(g.closedCaptions.keys()),l=k.next();!l.done;l=k.next())if(l=l.value,!e.has(l)){var m={id:a.Bg++,originalId:l,createSegmentIndex:Promise.resolve.bind(Promise),findSegmentPosition:function(){return null},getSegmentReference:function(){return null},initSegmentReference:null,presentationTimeOffset:0,mimeType:_.Kb,codecs:"",
kind:"caption",encrypted:!1,keyId:null,language:g.closedCaptions.get(l),label:null,type:_.le,primary:!1,trickModeVideo:null,emsgSchemeIdUris:null,roles:g.roles,channelsCount:null,closedCaptions:null};e.set(l,m)}}e=_.t(e.values());for(f=e.next();!f.done;f=e.next())d.textStreams.push(f.value)}};
Ny=function(a,b){for(var c=a.f?Ex(a.f):null,d=a.f?Fx(a.f):null,e=_.t(b),f=e.next();!f.done;f=e.next())jw(a.j,c,d,f.value);c=lu(b,function(g){return g.variants.some(lw)});if(0==c)throw new _.J(_.K,4,4032);if(c<b.length)throw new _.J(_.K,4,4011);c=_.t(b);for(d=c.next();!d.done;d=c.next())d=d.value,gw(d.variants,a.configuration.restrictions,a.Ag)&&a.f&&Hy(a)==d&&Ky(a),hz(a,d.variants)};
Zy=function(a,b){var c=a.f?Ex(a.f):null,d=a.f?Fx(a.f):null;jw(a.j,c,d,b);d=b.variants;if(!d.some(lw))throw new _.J(_.K,4,4011);hz(a,b.variants);gw(d,a.configuration.restrictions,a.Ag)&&a.f&&Hy(a)==b&&Ky(a);if(!_.Mj(2)&&(c=a.j?a.j.getDrmInfo():null)){d=_.t(d);for(var e=d.next();!e.done;e=d.next()){e=_.t(e.value.drmInfos);for(var f=e.next();!f.done;f=e.next())if(f=f.value,f.keySystem==c.keySystem){f=_.t(f.initData||[]);for(var g=f.next();!g.done;g=f.next())g=g.value,_.Al(a.j,g.initDataType,g.initData)}}}};
gz=function(a,b,c,d){c=void 0===c?!1:c;d=void 0===d?0:d;a.O?(a.aa=b,a.vg=c,a.cg=d):Mx(a.f,b,c,d)};ez=function(a,b){a.O?a.X=b:_.Lx(a.f,b,!0,0)};Yy=function(a,b){function c(g,k){if(!g)return null;var l=g.findSegmentPosition(k-f.startTime);return null==l?null:(l=g.getSegmentReference(l))?l.startTime+f.startTime:null}var d=Ex(a.f),e=Fx(a.f),f=Hy(a);d=c(d,b);e=c(e,b);return null!=e&&null!=d?Math.max(e,d):null!=e?e:null!=d?d:b};az=function(a){return a.j&&a.j.C?!0:a.L?a.L.getState()===$w:!1};
By=function(a){if(a.D&&a.L&&a.o){var b=iz(a)===_.$o,c=a.vc;c.h=b;gx(c);a.o.j.j=b;Qy(a)}};Qy=function(a){uy(a,iz(a))};iz=function(a){var b=a.getDuration(),c=a.getPosition();return a.h&&a.h.ended||(a.Mc||a.Z===_.bp)&&1>=Math.abs(b-c)?_.bp:az(a)||a.Gd?_.$o:a.h&&a.h.paused?_.hp:_.uo};
Ly=function(a,b){try{hz(a,b)}catch(e){return a.onError(e),null}var c=b.filter(function(e){return lw(e)});c=tw(c,a.uc);var d=a.tc.create(c);d=Array.from(d.values());a.g.setVariants(d);if(null!==a.J&&null!==a.uc&&(d=d.find(function(e){return e.video.id==a.J&&e.audio.id==a.uc}),a.J=null,d))return d;if(a.configuration.abr.enabled||null===a.J)return a.g.chooseVariant();c=c.find(function(e){return null!==e.video&&e.video.id===a.J});return c?c:(a.log.warn("Selected video track not found. Falling back to ABR selection"),
a.g.chooseVariant())};Jy=function(a,b){var c=Ly(a,b.variants);a.uc=c&&c.audio&&c.audio.id;c&&(fz(a,b,c,!0),gz(a,c,!0));(c=_.ow(b.textStreams,a.ra,a.Ja)[0]||null)&&(a.configuration.streaming.alwaysStreamText||Gw(a))&&(dz(a,b,c,!0),ez(a,c))};
lz=function(a,b){a.O=!0;a.g.disable();a.log.debug("Choosing new streams after period changed");var c=Ly(a,b.variants),d=_.ow(b.textStreams,a.ra,a.Ja)[0]||null;a.aa&&(b.variants.includes(a.aa)&&(c=a.aa),a.aa=null);a.X&&(b.textStreams.includes(a.X)&&(d=a.X),a.X=null);c&&jz(a,b,function(){return fz(a,b,c,!0)});d&&(a.configuration.streaming.alwaysStreamText||Gw(a))&&jz(a,b,function(){return dz(a,b,d,!0)});b===Hy(a)&&kz(a);return a.configuration.streaming.alwaysStreamText||Gw(a)?{variant:c,text:d}:{variant:c,
text:null}};Sy=function(a,b,c,d){c=void 0===c?!1:c;d=void 0===d?0:d;if(a.configuration.abr.enabled){a.log.debug("switch_");var e=mz(a,b);jz(a,e,function(){return fz(a,e,b,!0)});e===Hy(a)&&kz(a);a.f&&Mx(a.f,b,c,d)}};Ky=function(a){var b=a.getTrackManager();b&&a.C!=vy&&(b.l&&(Iw(b),b.l.trigger(new _.O(_.Np))),kz(a))};kz=function(a){var b=Hy(a);if(b){var c=a.oc.get(b)||[];for(a.oc["delete"](b);c.length;)c.shift()()}};jz=function(a,b,c){a.oc.has(b)||a.oc.set(b,[]);a.oc.get(b).push(c)};
Dy=function(a,b,c){a.trigger(new _.O(b,{detail:{schemeIdUri:c.schemeIdUri,value:c.value,startTime:c.startTime,endTime:c.endTime,id:c.id,eventElement:c.eventElement,type:c.type}}))};
hz=function(a,b){var c=a.j?_.B.gf(a.j.ga):{},d=Object.keys(c);d=d.length&&"00"==d[0];for(var e=!1,f=!1,g=[],k=[],l=_.t(b),m=l.next();!m.done;m=l.next()){m=m.value;var n=[];m.audio&&n.push(m.audio);m.video&&n.push(m.video);n=_.t(n);for(var p=n.next();!p.done;p=n.next())if(p=p.value,p.keyId){var q=c[d?"00":p.keyId];q?Iy.includes(q)&&(k.includes(q)||k.push(q)):g.includes(p.keyId)||g.push(p.keyId)}m.allowedByApplication?m.allowedByKeySystem&&(e=!0):f=!0}if(!e)throw new _.J(_.K,4,4012,{hasAppRestrictions:f,
missingKeys:g,restrictedKeyStatuses:k});};cz=function(a){a=Hy(a);return null==a?[]:a.variants.filter(function(b){return lw(b)})};nz=function(a){var b=Hy(a);return null==b?[]:b.textStreams.filter(function(c){return!a.Dd.has(c)})};Hy=function(a){var b=Ax(a.o),c=null;a=_.t(a.a.periods);for(var d=a.next();!d.done;d=a.next())d=d.value,d.startTime<=b&&(c=d);return c};bz=function(a){var b=Hy(a);return b&&Qw(a.T,b).variant};
mz=function(a,b){for(var c=_.t(a.a.periods),d=c.next();!d.done;d=c.next())if(d=d.value,d.variants.includes(b))return d;return null};
oz=function(a,b,c){var d,e,f,g,k,l,m,n,p,q,r,w,y,F,C,E,G,M,T;return _.z(function(S){switch(S.a){case 1:return d=_.dm,_.hf(S,2),_.v(S,_.Wp.create(b.url,a.getNetworkEngine(),a.configuration.manifest.attemptParameters,b.type),4);case 4:return e=S.f,e.configure(a.configuration.manifest),_.v(S,e.start(b.url,Ey(a)),5);case 5:f=S.f;Fy(f.periods);if(!f.periods.length)throw new _.J(_.K,4,4014);return _.v(S,a.B.stop(),6);case 6:a.B=e;g=c.audio&&c.audio.id;k=c.video&&c.video.id;a.a.periods=f.periods;l=ou(f.periods,
Ax(a.o))||f.periods[0];Xy(a);m=Iu(g,k,l.variants);n=_.ow(l.textStreams,a.ra,a.Ja)[0]||null;Ly(a,l.variants);p=m&&m.video;q=m&&m.audio;r=[];w=new Set;y=_.t(l.variants);for(F=y.next();!F.done;F=y.next())C=F.value,C.video&&w.add(C.video),C.audio&&w.add(C.audio);E=_.t(_.bf(w).concat(_.bf(l.textStreams)));for(G=E.next();!G.done;G=E.next())M=G.value,r.push(M.createSegmentIndex());return _.v(S,Promise.all(r),7);case 7:py(a.f,d.AUDIO,q);py(a.f,d.VIDEO,p);py(a.f,d.TEXT,n);m&&fz(a,l,m,!1);a.log.info("Switch CDN successful.");
_.jf(S,0);break;case 2:T=_.kf(S);if(!e){S.H(8);break}return _.v(S,e.stop(),8);case 8:throw T;}})};
Ey=function(a){return{networkingEngine:a.getNetworkEngine(),filterNewPeriod:function(b){return Zy(a,b)},filterAllPeriods:function(b){return Ny(a,b)},onTimelineCueAdded:function(b){var c=a.sc;a:{var d=_.t(c.a);for(var e=d.next();!e.done;e=d.next())if(e=e.value,e.schemeIdUri===b.schemeIdUri&&e.startTime===b.startTime&&e.endTime===b.endTime){d=e;break a}d=null}null==d&&(c.a.add(b),c.f(b))},onEvent:function(b){return a.ge(b)},onError:function(b){return a.onError(b)}}};pz=function(){this.name=St};
iu=function qz(a){var c;return _.sf(qz,function(d){1==d.a&&(c=0);if(3!=d.a)return c<a?_.v(d,c,3):d.H(0);c++;return d.H(2)})};tu.prototype.release=function(){this.f=function(){};this.a.clear()};tu.prototype.cues=function(){return this.a};vu.prototype.release=function(){this.j=null;this.h.clear();this.a=function(){};this.f=function(){};this.g=function(){}};
vu.prototype.m=function(a,b){for(var c={},d=_.t(this.j.cues()),e=d.next();!e.done;c={$d:c.$d,fd:c.fd},e=d.next()){e=e.value;c.$d=this.h.get(e)||null;c.fd=a<e.startTime?1:a>e.endTime?3:2;this.h.set(e,c.fd);var f=this.o.find(function(g){return function(k){return k.nc===g.$d&&k.mc===g.fd}}(c));f&&f.Xb(e,b)}};yu.prototype.release=function(){this.g.stop();for(var a=_.t(this.a),b=a.next();!b.done;b=a.next())b.value.release();this.a.clear()};
_.Au.prototype.track=function(a){null!=this.a&&(this.a&&(this.a.off(_.Xd,this.g),this.h&&this.a.off(_.pe,this.g)),this.a=null);a&&(this.a=a,this.a.on(_.Xd,this.g,this))};
_.Au.prototype.append=function(a,b,c){var d=this.m?this.m(c):null;d||this.log.debug("Unable to resolve rendition for id",c);c={start:a,end:b,rendition:d,width:d?d.width:0,height:d?d.height:0,bandwidth:d?d.bandwidth:0};var e=this.f.find(function(f){return f.start>=a&&f.end<=b});if(e)e.rendition=d,e.width=c.width,e.height=c.height,e.bandwidth=c.bandwidth,e.start=c.start,e.end=c.end;else{d=_.t(this.f);for(e=d.next();!e.done;e=d.next())e=e.value,a>=e.start&&a<e.end?e.end=a:b>=e.start&&b<e.end&&(e.start=
b);this.f.push(c);this.f.sort(zu)}Du(this);this.g();this.a&&this.a.trigger(new _.O(_.Ee))};_.Au.prototype.clear=function(){this.f=[];Du(this);this.a&&(this.a.trigger(new _.O(_.Ee)),this.a.trigger(new _.O(_.ac)))};
_.Au.prototype.g=function(){if(this.a){var a=this.a.getPosition();if(0<=a){a:{for(var b=this.f.length,c=Number.MAX_SAFE_INTEGER,d=0;d<b;d++){var e=this.f[d];c=Math.min(e.start,c);if(e.start<=a&&e.end>a){a=.1>e.end-a&&d<b-1?this.f[d+1].rendition:e.rendition;break a}if(e.start>a)break}a=0<b&&c>a&&.25>c-a?this.f[0].rendition:null}if(a&&this.j!==a&&(this.j=a,this.a)){c=b=-1;if(a)b=a.width||-1,c=a.height||-1;else if(d=this.a.getSurface().getMedia())b=d.videoWidth,c=d.videoHeight;this.a.trigger(new _.O(_.dc,
{detail:{width:b,height:c,bandwidth:a?a.bandwidth:null,rendition:a}}))}}}};_.u(Fu,_.sg);_.h=Fu.prototype;
_.h.append=function(a){function b(f){if(f.startTime>=f.endTime)_.Kf("Invalid cue times: "+f.startTime+" - "+f.endTime),f=null;else{var g=new VTTCue(f.startTime,f.endTime,f.payload);g.lineAlign=f.lineAlign;f.positionAlign&&(g.positionAlign=f.positionAlign);g.size=f.size;try{g.align=f.textAlign}catch(k){}f.textAlign===_.oc&&g.align!==_.oc&&(g.align="middle");f.writingMode===_.ze?g.vertical="lr":f.writingMode===_.Ae&&(g.vertical="rl");1===f.lineInterpretation&&(g.snapToLines=!1);null!=f.line&&(g.line=
f.line);null!=f.position&&(g.position=f.position);f=g}f&&c.push(f)}var c=[];a=_.t(a);for(var d=a.next();!d.done;d=a.next()){d=d.value;var e=d.a;if(0<e.length)for(d=_.t(e),e=d.next();!e.done;e=d.next())b(e.value);else b(d)}a=c.slice().sort(function(f,g){return f.startTime!==g.startTime?f.startTime-g.startTime:f.endTime!==g.endTime?f.endTime-g.startTime:c.indexOf(g)-c.indexOf(f)});a=_.t(a);for(d=a.next();!d.done;d=a.next())this.a.addCue(d.value)};
_.h.remove=function(a,b){if(!this.a)return!1;Gu(this.a,function(c){return c.startTime<b&&c.endTime>a});return!0};_.h.isTextVisible=function(){return this.a.mode==_.ae};_.h.setTextVisibility=function(a){null!==this.a&&(this.a.mode=a?_.ae:_.Xc)};_.h.destroy=function(){this.a&&Gu(this.a,function(){return!0});this.a=null;return Promise.resolve()};Hu.prototype.V=function(){return!0};Hu.prototype.create=function(a){return new Fu(a)};Hu.prototype.create=Hu.prototype.create;Hu.prototype.isSupported=Hu.prototype.V;_.wg(_.be,new Hu);var Ju=Ot;_.Em(function(){if(_.Mj(3)){_.H("Array.install");try{_.Ve(),_.af(),_.Ve(),_.af(),Ju=Symbol.iterator?Symbol.iterator:Ot}catch(a){Ju=Ot}Array.from=Mu}});Nu.prototype.createMediaKeys=function(){_.H("PatchedMediaKeysMs.MediaKeySystemAccess.createMediaKeys");var a=new Ru(this.keySystem);return Promise.resolve(a)};Nu.prototype.getConfiguration=function(){_.H("PatchedMediaKeysMs.MediaKeySystemAccess.getConfiguration");return this.a};Ru.prototype.createSession=function(a){_.H("PatchedMediaKeysMs.MediaKeys.createSession");a=a||_.ke;if(a!=_.ke)throw new TypeError(_.$a+a+_.ca);return new Uu(this.a,a)};
Ru.prototype.setServerCertificate=function(){_.H("PatchedMediaKeysMs.MediaKeys.setServerCertificate");return Promise.resolve(!1)};_.wf(Uu,_.pj);_.h=Uu.prototype;_.h.generateRequest=function(a,b){_.H("PatchedMediaKeysMs.MediaKeySession.generateRequest");this.a=new _.lk;try{this.g=this.j.createSession(_.De,_.I(b),null),this.h.on(this.g,"mskeymessage",this.Qj.bind(this)),this.h.on(this.g,"mskeyadded",this.Oj.bind(this)),this.h.on(this.g,"mskeyerror",this.Pj.bind(this)),Wu(this,_.fe)}catch(c){this.a.reject(c)}return this.a};
_.h.load=function(){_.H("PatchedMediaKeysMs.MediaKeySession.load");return Promise.reject(Error(_.Ia))};_.h.update=function(a){_.H("PatchedMediaKeysMs.MediaKeySession.update");this.f=new _.lk;try{this.g.update(_.I(a))}catch(b){this.f.reject(b)}return this.f};_.h.close=function(){_.H("PatchedMediaKeysMs.MediaKeySession.close");try{this.g.close(),this.closed.resolve(),_.Ci(this.h)}catch(a){this.closed.reject(a)}return this.closed};
_.h.remove=function(){_.H("PatchedMediaKeysMs.MediaKeySession.remove");return Promise.reject(Error(_.Ja))};_.h.Qj=function(a){_.H("PatchedMediaKeysMs.onMsKeyMessage_",a);this.a&&(this.a.resolve(),this.a=null);this.dispatchEvent(new _.O(_.nd,{messageType:void 0==this.keyStatuses.a?_.fd:_.ed,message:a.message.buffer}))};
_.h.Oj=function(a){_.H("PatchedMediaKeysMs.onMsKeyAdded_",a);this.a?(_.H("Simulating completion for a PR persistent license."),Wu(this,_.ve),this.a.resolve(),this.a=null):this.f&&(Wu(this,_.ve),this.f.resolve(),this.f=null)};
_.h.Pj=function(a){_.H("PatchedMediaKeysMs.onMsKeyError_",a);a=Error("EME PatchedMediaKeysMs key error");a.errorCode=this.g.error;if(null!=this.a)this.a.reject(a),this.a=null;else if(null!=this.f)this.f.reject(a),this.f=null;else switch(this.g.error.code){case MSMediaKeyError.MS_MEDIA_KEYERR_OUTPUT:case MSMediaKeyError.MS_MEDIA_KEYERR_HARDWARECHANGE:Wu(this,_.Fd);break;default:Wu(this,_.bd)}};var rz;_.h=Tu.prototype;_.h.forEach=function(a){this.a&&a(this.a,rz)};_.h.get=function(a){if(this.has(a))return this.a};
_.h.has=function(a){var b=rz;return this.a&&_.bl(_.I(a),_.I(b))?!0:!1};_.h.entries=function(){};_.h.keys=function(){};_.h.values=function(){};
_.Em(function(){!window.HTMLVideoElement||!window.MSMediaKeys||navigator.requestMediaKeySystemAccess&&MediaKeySystemAccess.prototype.getConfiguration||(_.Nf("Using ms-prefixed EME v20140218"),rz=(new Uint8Array([0])).buffer,delete HTMLMediaElement.prototype.mediaKeys,HTMLMediaElement.prototype.mediaKeys=null,HTMLMediaElement.prototype.setMediaKeys=Qu,window.MediaKeys=Ru,window.MediaKeySystemAccess=Nu,navigator.requestMediaKeySystemAccess=Ou)});Zu.prototype.createMediaKeys=function(){_.H("PatchedMediaKeysWebkit.MediaKeySystemAccess.createMediaKeys");var a=new cv(this.a);return Promise.resolve(a)};Zu.prototype.getConfiguration=function(){_.H("PatchedMediaKeysWebkit.MediaKeySystemAccess.getConfiguration");return this.f};
cv.prototype.createSession=function(a){_.H("PatchedMediaKeysWebkit.MediaKeys.createSession");a=a||_.ke;if(a!=_.ke&&a!=_.Jd)throw new TypeError(_.$a+a+_.ca);var b=this.f||document.createElement(_.Be);b.src||(b.src="about:blank");a=new gv(b,this.j,a);this.g.push(a);return a};cv.prototype.setServerCertificate=function(){_.H("PatchedMediaKeysWebkit.MediaKeys.setServerCertificate");return Promise.resolve(!1)};_.u(gv,_.pj);_.h=gv.prototype;
_.h.generateRequest=function(a,b){_.H("PatchedMediaKeysWebkit.MediaKeySession.generateRequest");return hv(this,b,null)};_.h.load=function(a){_.H("PatchedMediaKeysWebkit.MediaKeySession.load");return this.j==_.Jd?hv(this,null,a):Promise.reject(Error(Jt))};_.h.update=function(a){_.H("PatchedMediaKeysWebkit.MediaKeySession.update",a);var b=new _.lk;iv(this,b,a);return b};
_.h.close=function(){_.H("PatchedMediaKeysWebkit.MediaKeySession.close");if(this.j!=_.Jd){if(!this.sessionId)return this.closed.reject(Error("The session is not callable.")),this.closed;var a=Yu("cancelKeyRequest");try{this.h[a](this.g,this.sessionId)}catch(b){}}this.closed.resolve();return this.closed};_.h.remove=function(){_.H("PatchedMediaKeysWebkit.MediaKeySession.remove");return this.j!=_.Jd?Promise.reject(Error(Jt)):this.close()};_.h=fv.prototype;_.h.forEach=function(a){this.a&&a(this.a,_.Up.value())};
_.h.get=function(a){if(this.has(a))return this.a};_.h.has=function(a){var b=_.Up.value();return this.a&&ju(a,b)?!0:!1};_.h.entries=function(){};_.h.keys=function(){};_.h.values=function(){};var Xu="";
_.Em(function(){if(!(!window.HTMLVideoElement||navigator.requestMediaKeySystemAccess&&MediaKeySystemAccess.prototype.getConfiguration)){if(HTMLMediaElement.prototype.webkitGenerateKeyRequest)_.Nf("Using webkit-prefixed EME v0.1b"),Xu="webkit";else if(HTMLMediaElement.prototype.generateKeyRequest)_.Nf("Using nonprefixed EME v0.1b");else return;navigator.requestMediaKeySystemAccess=$u;delete HTMLMediaElement.prototype.mediaKeys;HTMLMediaElement.prototype.mediaKeys=null;HTMLMediaElement.prototype.setMediaKeys=
bv;window.MediaKeys=cv;window.MediaKeySystemAccess=Zu}});_.Em(function(){_.H("MediaSource.install");if(window.MediaSource)if(window.cast&&cast.__platform__&&cast.__platform__.canDisplayType)_.Nf("Patching Chromecast MSE bugs."),nv();else if(_.Pj()){var a=navigator.appVersion;a=0<=a.indexOf("Version")?parseInt(a.match(/Version\/(\d+)/)[1],10):13;lv();10>=a?(_.Nf("Blacklisting MSE on Safari \x3c\x3d 10."),window.MediaSource=null):12>=a?(_.Nf("Patching Safari 11 \x26 12 MSE bugs."),jv(),kv()):(_.Nf("Patching Safari 13 MSE bugs."),jv())}else _.Mj(2)||_.Mj(3)||
_.Mj(4)?mv():_.Nf("Using native MSE as-is.");else _.Nf("No MSE implementation available.")});ov.prototype.init=function(a){var b=muxjs.mp4.probe;a=_.I(a);this.j=b.videoTrackIds(a);this.h=b.timescale(a);this.a.init()};ov.prototype.g=function(a,b){var c=_.I(a);(c=this.a.parse(c,this.j,this.h))&&c.captions&&b(c.captions);this.a.clearParsedCaptions()};ov.prototype.f=function(){this.a.resetCaptionStream()};_.pv.prototype.init=function(){};_.pv.prototype.g=function(){};_.pv.prototype.f=function(){};qv.prototype.destroy=function(){this.a&&(this.a.dispose(),this.a=null);return Promise.resolve()};qv.prototype.w=function(a){this.g=a.captions;var b=new Uint8Array(a.data.byteLength+a.initSegment.byteLength);b.set(a.initSegment,0);b.set(a.data,a.initSegment.byteLength);a.type===_.Be?this.m.push(b):a.type===_.Wb&&this.j.push(b)};qv.prototype.o=function(){var a={video:_.cl.apply(null,this.m),captions:this.g,audio:_.cl.apply(null,this.j)};this.f.resolve(a);this.h=!1};_.h=_.xv.prototype;
_.h.destroy=function(){var a=this;this.I=!0;var b=[],c;for(c in this.h){var d=this.h[c],e=d[0];this.h[c]=d.slice(0,1);e&&b.push(e.p["catch"](_.Ol.Yc));for(e=1;e<d.length;++e)d[e].p["catch"](_.Ol.Yc),d[e].p.reject()}this.f&&b.push(this.f.destroy());this.A&&b.push(this.A.destroy());for(var f in this.w)b.push(this.w[f].destroy());return Promise.all(b).then(function(){a.o&&(a.o.release(),a.o=null);a.j&&(a.j.removeAttribute("src"),a.j.load(),a.j=null);a.m=null;a.f=null;a.A=null;a.g={};a.w={};a.C={};a.D=
null;a.J=!1;a.h={}})};
_.h.init=function(a,b){var c=this,d;return _.z(function(e){if(1==e.a)return d=_.dm,_.v(e,c.G,2);a.forEach(function(f,g){var k=_.Pg(f.mimeType,f.codecs);if(g==d.TEXT)Vv(c,k);else{var l=new Map;l.set(g,k);if((!MediaSource.isTypeSupported(k)||!b||f.keyInfo)&&sv(k,g)){c.w[g]=new qv;k="aac"==k.split(";")[0].split("/")[1]?k.replace("aac",_.rd):rv(g,k);var m=new Map;m.set(g,k);try{var n=k.match(/"(.*?)"/)[0].replace(/"/g,"").split(","),p=new Map;if(1<n.length)for(var q=_.t(n),r=q.next();!r.done;r=q.next()){var w=
r.value;w.startsWith("avc")?p.set(_.Be,'video/mp4;codecs\x3d"'+w+'"'):w.startsWith("mp4a")&&p.set(_.Wb,'audio/mp4;codecs\x3d"'+w+'"')}l=0<p.size?p:m}catch(y){l=m}}Uv(c,l)}});_.x(e)})};_.h.mb=function(a){return a===_.le?this.f.mb():(a=Xv(this,a))?a.mb():null};_.h.na=function(a){return a===_.le?this.f.na():(a=Xv(this,a))?a.na():null};_.h.Zb=function(a,b,c){return a===_.le?this.f.Zb(b):(a=Xv(this,a))?a.Zb(b,!1,c):!1};
_.h.Ca=function(a,b){if(a===_.le)return this.f.Ca(b);var c=Xv(this,a);return c?c.Ca(b):0};_.h.Td=function(a){var b=this.na(_.Be)||0;this.f.Td(a,b)};_.h.remove=function(a,b,c){var d=this;return a===_.le?this.f.remove(b,c):Zv(this,a,this.Cg.bind(this,a,b,c)).then(function(){if(a===_.Be){var e=d.mb(a),f=d.na(a);null!==e&&null!==f&&Eu(d.B,e,f)}else a===_.Wb&&(e=d.B,e.a&&e.a.trigger(new _.O(_.ac)))})};
_.h.clear=function(a){if(a==_.le){if(!this.f)return Promise.resolve();this.D.f();return this.f.remove(0,Infinity)}return Zv(this,a,this.Cg.bind(this,a,0,this.m.duration))};_.h.flush=function(a){return a==_.le?Promise.resolve():Zv(this,a,this.Rf.bind(this,a))};_.h.endOfStream=function(a){var b=this;return ew(this,function(){Wv(b)||(a?b.m.endOfStream(a):b.m.endOfStream())})};_.h.Nb=function(a){var b=this;ew(this,function(){b.m.duration=a})};_.h.getDuration=function(){return this.m.duration};
_.h.he=function(a,b){this.a.debug("Appending data to "+a+Dt);try{this.g[a].appendBuffer(b)}catch(c){throw this.a.warn("Failed to append data for "+a,c),c;}this.a.debug("Done Appending data to "+a+Dt)};_.h.Cg=function(a,b,c){if(c<=b)this.Ic(a);else{this.a.debug("Removing data from "+a+" range:",b,c);try{this.g[a].remove(b,c)}catch(d){throw this.a.warn("Failed to remove data from "+a+" buffer. Range:",b,c,d),d;}this.a.debug("Done Removing data from "+a+" range:",b,c)}};
_.h.Qg=function(a){try{this.a.debug("Aborting operations for "+a+Dt);var b=this.g[a].appendWindowStart,c=this.g[a].appendWindowEnd;this.g[a].abort();this.g[a].appendWindowStart=b;this.g[a].appendWindowEnd=c}catch(d){throw this.a.warn("Failed to abort operations for "+a+Dt,d),d;}this.a.debug("Done Aborting operations for "+a+Dt);this.Ic(a)};
_.h.Rf=function(a){try{this.j.readyState>HTMLMediaElement.HAVE_NOTHING?(this.a.debug("Flushing buffer for "+a),this.j.currentTime-=.001):this.a.warn("Skip buffer flush for "+a+" with element state: "+this.j.readyState)}catch(b){throw this.a.warn("Failed to flush buffer for "+a,b),b;}this.a.debug("Done Flushing buffer for "+a);this.Ic(a)};
_.h.Gg=function(a,b){0>b&&(b+=.001);try{this.a.debug("Setting timestamp offset for "+a,b),this.g[a].timestampOffset=b}catch(c){throw this.a.warn("Failed to set timestamp offset for "+a,b,c),c;}this.a.debug("Done Setting timestamp offset for "+a,b);this.Ic(a)};
_.h.sk=function(a,b,c){try{this.a.debug("Setting append window for "+a,b,c),this.g[a].appendWindowStart=0,this.g[a].appendWindowEnd=c,this.g[a].appendWindowStart=b}catch(d){throw this.a.warn("Failed to set append window for "+a,b,c,d),d;}this.a.debug("Done Setting append window for "+a,b,c);this.Ic(a)};_.h.Yh=function(a){this.h[a][0].p.reject(new _.J(_.K,3,3014,{mediaError:this.j.error?this.j.error.code:0}))};
_.h.Ic=function(a){var b=this.h[a][0];b&&(b.p.resolve(),this.a.debug("Update-End received for "+a),dw(this,a))};_.h.getTextDisplayer=function(){return this.A};var wv=window.URL.createObjectURL;_.u(uw,_.R);_.h=uw.prototype;_.h.Cb=function(a){this.l=a};_.h.release=function(){this.h=[];this.a.clear();this.f.clear()};_.h.load=function(){};_.h.destroy=function(){this.release();this.l=null};_.h.getVideoTracks=function(){return vw(this,_.Qi)};_.h.getAudioTracks=function(){return vw(this,_.Q)};_.h.ea=function(){return vw(this,_.P)};_.h.fa=function(){return this.a.get(_.Qi)||null};_.h.ta=function(){return this.a.get(_.Q)||null};_.h.va=function(){return this.a.get(_.P)||null};
_.h.Ma=function(){return this.f.get(_.Qi)||null};_.h.Xa=function(){return this.f.get(_.Q)||null};_.h.Ya=function(){return this.f.get(_.P)||null};_.h.wa=function(){return ww(this,_.Qi)};_.h.ha=function(){return ww(this,_.Q)};_.h.ia=function(){return ww(this,_.P)};_.h.Ta=function(a){yw(this,_.Qi,a)};_.h.ma=function(a){yw(this,_.Q,a)};_.h.qa=function(a){yw(this,_.P,a)};_.h.Sa=function(a,b){xw(this,_.Qi,a,void 0===b?!1:b)};_.h.gb=function(a,b){xw(this,_.Q,a,void 0===b?!1:b)};
_.h.ib=function(a,b){xw(this,_.P,a,void 0===b?!1:b)};_.h.addTextTrack=function(a){var b=this;return _.z(function(c){return b.l?_.v(c,b.l.addTextTrack(a.url,a.language,a.kind,a.mimeType,a.codec,a.label),0):c.H(0)})};_.h.oa=function(){return!!this.l&&this.l.oa()};uw.prototype.isAbrEnabled=uw.prototype.oa;uw.prototype.addTextTrack=uw.prototype.addTextTrack;uw.prototype.setTextRendition=uw.prototype.ib;uw.prototype.setAudioRendition=uw.prototype.gb;uw.prototype.setVideoRendition=uw.prototype.Sa;
uw.prototype.setTextTrack=uw.prototype.qa;uw.prototype.setAudioTrack=uw.prototype.ma;uw.prototype.setVideoTrack=uw.prototype.Ta;uw.prototype.getTextTrack=uw.prototype.ia;uw.prototype.getAudioTrack=uw.prototype.ha;uw.prototype.getVideoTrack=uw.prototype.wa;uw.prototype.getLoadingTextRendition=uw.prototype.Ya;uw.prototype.getLoadingAudioRendition=uw.prototype.Xa;uw.prototype.getLoadingVideoRendition=uw.prototype.Ma;uw.prototype.getTextRendition=uw.prototype.va;uw.prototype.getAudioRendition=uw.prototype.ta;
uw.prototype.getVideoRendition=uw.prototype.fa;uw.prototype.getTextTracks=uw.prototype.ea;uw.prototype.getAudioTracks=uw.prototype.getAudioTracks;uw.prototype.getVideoTracks=uw.prototype.getVideoTracks;_.h=_.Mw.prototype;_.h.stop=function(){this.w=null;this.j=!1;this.o=[];this.m=1;this.h=null};_.h.init=function(a){this.w=a};
_.h.chooseVariant=function(){var a=Nw(this.a.restrictions,this.o),b=Lw(this.g,this.a.defaultBandwidthEstimate);this.o.length&&!a.length&&(this.f.warn("No variants met the ABR restrictions. Choosing a variant by lowest bandwidth."),a=Nw(null,this.o),a=[a[0]]);var c=a[0]||null;a=_.t(_.ng(a));for(var d=a.next();!d.done;d=a.next()){var e=d.value;d=e.item;var f=isNaN(this.m)||0===this.m?1:Math.abs(this.m),g=f*d.bandwidth,k=g/this.a.bandwidthDowngradeTarget;e=f*(e.next||{bandwidth:Infinity}).bandwidth/
this.a.bandwidthUpgradeTarget;this.f.debug("Bandwidth ranges:",(g/1E6).toFixed(3),(k/1E6).toFixed(3),(e/1E6).toFixed(3));b>=k&&b<=e&&(c=d)}this.h=Date.now();return c};_.h.enable=function(){this.j=!0};_.h.disable=function(){this.j=!1};
_.h.segmentDownloaded=function(a,b){this.f.debug("Segment downloaded:","deltaTimeMs\x3d"+a,"numBytes\x3d"+b,"lastTimeChosenMs\x3d"+this.h,"enabled\x3d"+this.j);var c=this.g;if(!(16E3>b)){var d=8E3*b/a,e=a/1E3;c.a+=b;_.so(c.f,e,d);_.so(c.g,e,d)}if(null!=this.h&&this.j)a:{this.f.debug("Suggesting Streams...");if(!this.A){if(!(128E3<=this.g.a)){this.f.debug("Still waiting for a good estimate...");break a}this.A=!0;if(this.a.useSwitchIntervalForInitialSwitch&&Date.now()-this.h<1E3*this.a.switchInterval){this.f.debug("Skipping initial estimation switch due to switch interval...");
break a}}else if(Date.now()-this.h<1E3*this.a.switchInterval){this.f.debug("Still within switch interval...");break a}c=this.chooseVariant();this.f.debug("Calling switch_(), bandwidth\x3d"+Math.round(Lw(this.g,this.a.defaultBandwidthEstimate)/1E3)+" kbps");this.w(c)}};_.h.playbackRateChanged=function(a){this.m=a};_.h.getBandwidthEstimate=function(){return Lw(this.g,this.a.defaultBandwidthEstimate)};_.h.setVariants=function(a){this.o=a};_.h.configure=function(a){this.a=a};
_.h.reset=function(){this.A=!1;this.g=new Kw;var a=this.chooseVariant();a&&this.w(a)};Ow.prototype.clear=function(){this.a.clear()};Rw.prototype.add=function(a){if(Uw(this.f,a))return this.a.add(a),!0;_.Kf("Rejecting variant - not compatible with root.");return!1};Rw.prototype.values=function(){return this.a.values()};Ww.prototype.create=function(a){var b=this,c=a.filter(function(d){return Uw(b.a,d)});return c.length?new Rw(c[0],c):this.f.create(a)};
Vw.prototype.create=function(a){var b=[];b=Xw(a,this.g);var c=a.filter(function(d){return d.primary});b=b.length?b:c.length?c:a;this.f&&(a=Yw(b,this.f),a.length?b=a:_.Kf("No exact match for variant role could be found."));this.a&&(a=mw(b,this.a),a.length?b=a:_.Kf("No exact match for the channel count could be found."));a=new Rw(b[0]);b=_.t(b);for(c=b.next();!c.done;c=b.next())c=c.value,Uw(a.f,c)&&a.add(c);return a};ax.prototype.getState=function(){return this.a};var $w=0,Zw=1;cx.prototype.release=function(){this.h.release()};dx.prototype.release=function(){this.a=this.g=null;this.f=function(){}};dx.prototype.m=function(a){var b=this.a,c=this.g.periods;a=ou(c,a)||c[0];b!=a&&this.f(a);this.a=a};fx.prototype.release=function(){this.f&&(this.f.stop(),this.f=null);this.a=null};fx.prototype.set=function(a){this.g=a;gx(this)};ix.prototype.release=function(){this.g=null;this.f=function(){}};mx.prototype.release=function(){this.g&&(this.g.release(),this.g=null);null!=this.m&&(this.m.stop(),this.m=null);this.f&&(this.f.release(),this.f=null);this.a=this.C=this.B=null;this.j=!1};mx.prototype.Ld=function(){this.o=!0;lx(this)};qx.prototype.release=function(){this.f&&(this.f.release(),this.f=null);null!=this.g&&(this.g.release(),this.g=null);this.m=function(){};this.a=null;this.h=function(){};this.j=!1};nx.prototype.release=function(){this.a&&(this.a.stop(),this.a=null);this.f=null};yx.prototype.release=function(){this.g&&(this.g.release(),this.g=null);this.j&&(this.j.release(),this.j=null);this.o&&(this.o.stop(),this.o=null);this.h=this.g=this.a=this.m=null;this.A=function(){}};_.Cx.prototype.destroy=function(){for(var a=_.t(this.h.values()),b=a.next();!b.done;b=a.next())Sx(b.value);this.h.clear();this.w.clear();this.m=this.o=this.G=this.g=this.f=null;this.j=!0;return Promise.resolve()};
_.Cx.prototype.start=function(){var a=this,b,c,d;return _.z(function(e){if(1==e.a){b=a.f.rb();c=Hx(a,b);d=a.f.Je(a.g.periods[c]);if(!d.variant&&!d.text)throw a.a.error("init: no Streams chosen"),new _.J(_.K,5,5005);a.D=!d.variant&&!!d.text;return _.v(e,Wx(a,d.variant?d.variant.audio:null,d.variant?d.variant.video:null,d.text,b),2)}if(a.j)return e["return"]();a.a.debug("init: completed initial Stream setup");a.f&&a.f.Jj&&(a.a.debug("init: calling onInitialStreamsSetup()..."),a.f.Jj());_.x(e)})};
_.Cx.prototype.K=function(a,b,c,d){var e=cu(d.F),f=cu(d.F),g=_.Xh(d.F),k=_.Xh(d.F),l=_.Xh(d.F),m=_.Xh(d.F);d=_.$h(d.F,d.F.ua()-d.F.getPosition());a=a.startTime+b.startTime+k/g;if(c.includes(e))if("urn:mpeg:dash:event:2012"==e)this.f.pg();else this.f.onEvent(new _.O("emsg",{detail:{startTime:a,endTime:a+l/g,schemeIdUri:e,value:f,timescale:g,presentationTimeDelta:k,eventDuration:l,id:m,messageData:d}}))};
_.Cx.prototype.L=function(a){var b=this,c=!1,d=0;(new _.bi).P("moov",_.ci).P("trak",_.ci).P("mdia",_.ci).P("minf",_.ci).P("stbl",_.ci).da("stsd",_.di).P("encv",function(e){e.F.skip(78);_.ci(e)}).P("pasp",function(e){d=e.start+e.se;var f=_.Xh(e.F),g=_.Xh(e.F);c=f!==g;b.a.debug("hSpacing\x3d"+f+", vSpacing\x3d"+g+", updatePASP\x3d"+c);e.wb.stop()}).parse(a.data);c&&(a=new _.Mh(new DataView(a.data)),a.seek(d),_.N(a,1),_.N(a,1))};_.u(sy,_.Qk);_.h=sy.prototype;_.h.init=function(a,b){var c=a.getMedia();_.Qk.prototype.init.call(this,a,b);yy(this,c)};_.h.namespace=function(){return St};_.h.If=function(){return new uw};_.h.getState=function(){return this.Z};_.h.getDrmInfo=function(){return this.j?this.j.getDrmInfo():null};
_.h.unload=function(){var a=this;Aw(this,!1);this.Mc=!1;if(this.w){var b=this.getTrackManager();b&&b.release();return this.C==vy?Promise.resolve():Ay(this).then(function(){a.log.debug(_.Ua,a.w);a.w=null;a.Ce=0;a.uc=null;a.J=null})}return Promise.resolve()};_.h.release=function(){var a=this;return _.Qk.prototype.release.call(this).then(function(){return a.unload()}).then(function(){return uy(a,_.Wo)})};
_.h.getBufferInfo=function(a){if(this.G){var b=Yv(this.G),c=new _.oj(void 0,a);switch(a){case _.Qi:return b.video?b.video:c;case _.Q:return b.audio?b.audio:c;case _.P:return b.text?b.text:c}return b.total?b.total:c}return this.Nc?(b=Bu(this.Nc),nu(b,a)):_.Qk.prototype.getBufferInfo.call(this,a)};_.h.getPosition=function(){return this.o?Ax(this.o):_.Qk.prototype.getPosition.call(this)};_.h.getPresentationStartTime=function(){return this.a?this.a.presentationTimeline.getPresentationStartTime()||0:0};
_.h.getDuration=function(){return this.a?this.a.presentationTimeline.getDuration():Infinity};_.h.destroy=function(){var a=this;return _.z(function(b){if(1==b.a)return _.v(b,_.Qk.prototype.destroy.call(a),2);if(3!=b.a){if(a.C==vy)return b["return"]();a.log.debug("Destroying player...");a.C=vy;return _.v(b,xy(a),3)}a.m&&(a.m.release(),a.m=null);a.g=null;_.x(b)})};
_.h.load=function(a){var b=this,c,d,e,f;return _.z(function(g){switch(g.a){case 1:if(b.C==vy)return g["return"](Promise.reject(new _.J(_.K,7,7E3)));b.D=new _.Xl;b.Be=Date.now()/1E3;uy(b,_.gp);b.w=_.Am(a);_.Pk(a)&&(b.configuration=_.Ok(b.md,a));b.log.info(_.Fa,b.configuration);b.log.info(_.Qa,b.w);(c=b.getTrackManager())&&c.load();d=b.getSurface().getMedia();d.loop=!!b.configuration.loop;_.B.N(b.configuration.volume)||b.setVolume(b.configuration.volume);_.B.N(b.configuration.muted)||b.setMuted(b.configuration.muted);
b.B&&b.B.configure(b.configuration.manifest);b.j&&My(b);if(b.f){b.f.m=b.configuration.streaming;try{for(var k=_.t(b.a.periods),l=k.next();!l.done;l=k.next())Zy(b,l.value)}catch(n){b.onError(n)}l=Ex(b.f);var m=Fx(b.f);k=Hy(b)||b.a.periods[0];l=pw(l,m,k.variants);b.g&&l&&l.allowedByApplication&&l.allowedByKeySystem?Ly(b,k.variants):(b.log.debug("Choosing new streams after changing configuration"),Jy(b,k))}b.g&&(b.g.configure(b.configuration.abr),b.configuration.abr.enabled&&!b.O?b.g.enable():b.g.disable());
e=b.w[b.getSourceIndex()];_.hf(g,2);return _.v(g,yy(b,d),4);case 4:return _.v(g,zy(b,d),5);case 5:return _.v(g,Cy(b,e),6);case 6:return _.v(g,Gy(b,e),7);case 7:return _.v(g,Oy(b),8);case 8:return _.v(g,$y(b),9);case 9:_.jf(g,0);break;case 2:throw f=_.kf(g),b.onError(f),f;}})};_.h.isLive=function(){return this.a?this.a.presentationTimeline.isLive():!1};_.h.tg=function(){this.log.debug("User seeking finished");Py(this);Qy(this)};
_.h.getSeekRange=function(){if(this.a){var a=this.a.presentationTimeline;return{start:hu(a,0),end:gu(a)}}return{start:0,end:0}};_.h.getPlaybackRate=function(){if(this.vc){var a=this.vc;a=a.h?0:a.g}else a=0;return a};
_.h.setPlaybackRate=function(a){if(0==a)this.log.warn("A trick play rate of 0 is unsupported!");else if(this.C==ry){this.vc.set(a);this.g.playbackRateChanged(a);var b=this.f,c=1<Math.abs(a);if(a=b.h.get(_.Be)){var d=a.stream;if(d)if(b.a.debug("setTrickPlay",c),c)(c=d.trickModeVideo)&&!a.Kb&&(b.a.debug("Engaging trick mode stream",c),_.Lx(b,c,!1,0),a.Kb=d);else if(d=a.Kb)b.a.debug("Restoring non-trick-mode stream",d),a.Kb=null,_.Lx(b,d,!0,0)}}};
_.h.ea=function(){if(this.a&&this.o){var a=Hy(this);if(null==a)a=null;else{if(!Qw(this.T,a).text){var b=_.ow(a.textStreams,this.ra,this.Ja);b.length&&(b=b[0],Qw(this.T,a).text=b)}a=Qw(this.T,a).text}b=[];for(var c=_.t(nz(this)),d=c.next();!d.done;d=c.next()){d=d.value;var e=kw(d);e.active=d==a;b.push(e)}return b}return[]};_.h.getThumbnailStreams=function(){if(this.a&&this.o){var a=Hy(this);return a?a.thumbStreams:[]}return[]};
_.h.getStats=function(){if(this.C!=ry)return _.Yl();Qy(this);var a=this.h;if(a.getVideoPlaybackQuality){a=a.getVideoPlaybackQuality();var b=this.D,c=Number(a.totalVideoFrames);b.h=Number(a.droppedVideoFrames);b.g=c}if(this.C==ry){if(a=bz(this))this.D.A=a.bandwidth;a&&a.video&&(b=this.D,c=a.video.height||NaN,b.j=a.video.width||NaN,b.f=c);a=this.g.getBandwidthEstimate();this.D.m=a}return this.D.getStats()};
_.h.getTimelineCues=function(){return this.sc?_.bf(this.sc.cues()).concat().map(function(a){return{schemeIdUri:a.schemeIdUri,value:a.value,startTime:a.startTime,endTime:a.endTime,id:a.id,eventElement:a.eventElement,type:a.type}}):[]};_.h.resetAbr=function(){this.g&&this.g.reset()};
_.h.addTextTrack=function(a,b,c,d,e,f){var g=this,k,l,m,n,p,q,r,w,y;return _.z(function(F){if(1==F.a){if(g.C!=ry)throw g.log.error("Must call load() and wait for it to resolve before adding text tracks."),Error("State error!");k=Hy(g);l=_.dm;m=g.a.periods.indexOf(k);n=m+1;p=n>=g.a.periods.length?g.a.presentationTimeline.getDuration():g.a.periods[n].startTime;q=p-k.startTime;if(Infinity==q)throw new _.J(1,4,4040);r=new _.kp(1,0,q,function(){return[a]},0,null);w={id:g.Bg++,originalId:null,createSegmentIndex:Promise.resolve.bind(Promise),
findSegmentPosition:function(){return 1},getSegmentReference:function(C){return 1==C?r:null},adjustSegmentDurations:function(){},getDuration:function(){return q},initSegmentReference:null,presentationTimeOffset:0,mimeType:d,codecs:e||"",kind:c,encrypted:!1,keyId:null,language:b,label:f||null,type:l.TEXT,primary:!1,trickModeVideo:null,emsgSchemeIdUris:null,roles:[],channelsCount:null,closedCaptions:null};g.Dd.add(w);k.textStreams.push(w);return _.v(F,Kx(g.f,w),2)}if(y=_.Dx(g.f,_.le))Qw(g.T,k).text=
y;g.Dd["delete"](w);g.log.debug("Choosing new streams after adding a text stream");Jy(g,k);Ky(g);return F["return"](kw(w))})};_.h.Wh=function(a){this.log.debug(_.Ut,a);try{this.log.debug("onChooseStreams_, choosing variant from ",a.variants);this.log.debug("onChooseStreams_, choosing text from ",a.textStreams);var b=lz(this,a);this.log.debug("onChooseStreams_, chose variant ",b.variant);this.log.debug("onChooseStreams_, chose text ",b.text);return b}catch(c){return this.onError(c),{variant:null,text:null}}};
_.h.Vh=function(){this.log.debug("canSwitch_");this.O=!1;this.configuration.abr.enabled&&this.g.enable();this.aa&&(Mx(this.f,this.aa,this.vg,this.cg),this.aa=null);this.X&&(_.Lx(this.f,this.X,!0,0),this.X=null)};_.h.Xh=function(){this.B&&this.B.update&&this.B.update()};_.h.Uj=function(){this.o&&this.o.j.Ld()};
_.h.fh=function(a){var b=Hy(this),c=b.variants.some(function(g){var k=!1,l=g[a.type];l&&(k=g.allowedByApplication&&l.id!==a.id,a.type===_.Wb&&(k=k&&l.language===a.language));return k})&&this.oa();if(c){for(var d=_.t(b.variants),e=d.next();!e.done;e=d.next()){e=e.value;var f=e[a.type];f&&f.id===a.id&&(e.allowedByApplication=!1)}Ky(this);Jy(this,b)}return c};_.h.Gj=function(){this.log.debug("endOfStream called by streaming engine");this.Mc=!0};
_.h.onError=function(a){if(this.C!==vy){var b=new _.O(_.Sc,{detail:a});a.a===_.K&&uy(this,_.gm);this.trigger(b);b.defaultPrevented&&(b.a=!0)}};_.h.ge=function(a){a.type===_.Kc?this.dispatchEvent(new _.O(_.Kc,{keySystem:this.j.keySystem(),Hi:_.Gl(this.j)})):"largegap"!==a.type&&(a.type===_.Ke?By(this):"emsg"!==a.type&&(a instanceof _.O?this.dispatchEvent(a):this.log.debug("Unhandled/ignored event",a.type)))};_.h.oa=function(){return this.configuration.abr.enabled};
_.h.wk=function(a){var b=this,c,d,e,f,g,k,l,m;return _.z(function(n){switch(n.a){case 1:if(!b.Ba)return n["return"]();b.log.info("Attempt to switch CDN.");c=b.getLoadedSource();d=b.oa();return _.v(n,b.Ba(c.url,a),2);case 2:e=n.f;if(!e)return n["return"]();f=_.B.Qa(b.getLoadedSource(),{url:e});d&&b.g.disable();return _.v(n,oy(b.f),3);case 3:g=bz(b);case 4:return k=!1,_.hf(n,7),b.log.info("Switching CDN to "+f.url+"."),_.v(n,oz(b,f,g),9);case 9:_.jf(n,6);break;case 7:return l=_.kf(n),b.log.info("Switch failed"),
_.v(n,b.Ba(f.url,a),10);case 10:if(m=n.f)f.url=m,k=!0;else throw l;case 6:k?n.H(4):(d&&b.g.enable(),my(b.f,!0),b.w[b.getSourceIndex()]=f,a.j=!0,b.trigger(new _.O(_.lc,{newManifest:f.url})),_.x(n))}})};var vy=0,qy=1,ry=2,Iy=[Vt,_.bd];pz.prototype.canPlay=function(a){if(_.Oj())return 0;var b=a.type||"",c=typeof a.Cc!==_.ec||a.Cc;if(b===_.wm&&c&&_.Pj())return 0;c=_.Hj();a=_.Wp.V(a.url,b);return c&&a?_.Mj(2)?1:2:0};pz.prototype.create=function(){return new sy};pz.prototype.create=pz.prototype.create;
pz.prototype.canPlay=pz.prototype.canPlay;_.vi(new pz);};
if(typeof(module)!="undefined"&&module.exports){var x=require("./cl.core.min.js");_ = x._;(f.call(g,this));module.exports=g;}
else if (typeof(define)!="undefined"&&define.amd) {define(["./cl.core.min"], function(c){_=c._;(f.call(g,this));return g;});}
else{_=this.clpp._;(f.call(g,this));}
})();
