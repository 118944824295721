/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DateFnsUtils from '@date-io/date-fns';
import ClearIcon from '@material-ui/icons/Clear';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FileUploadDiaglog from './FileUploadModal';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        justifyContent: 'space-between'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85)
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark
              },
    title: {
        flex: '1 1 100%'
    }
}));

const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const [open, setOpen] = useState(false);
    const {
        numSelected, loading, selectedDate, setSelectedDate,
        handleWorkflowStatusChange = () => {}, workflowFilterVisible = false,
        handleResetAndRefreshPage, hideImportButton = false, hideFilter = false,
        availsWorkflowStatuses = []
} = props;

    return (
        <Toolbar
            className={`${classes.root} ${
                numSelected > 0 ? classes.highlight : ''
            }`}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Button
                    variant="contained"
                    color="default"
                    style={{ visibility: hideImportButton ? 'hidden' : 'visible' }} // only for avails
                    disabled={hideImportButton}
                    className={classes.button}
                    startIcon={<CloudUploadIcon />}
                    onClick={() => setOpen(true)}
                >
                    Import
                </Button>
            )}
            <FileUploadDiaglog
                open={open}
                onClose={(callback) => {
                    setOpen(false);
                    if (callback) callback();
                }}
                handleResetAndRefreshPage={handleResetAndRefreshPage}
            />
            {loading && <CircularProgress size={30} />}
            <div style={{ float: 'right', visibility: hideFilter ? 'hidden' : 'visible' }}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '500px', alignItems: 'flex-end' }}>
                        <div style={{ paddingBottom: '8px', visibility: workflowFilterVisible ? 'visible' : 'hidden' }}>
                            <FormControl sx={{ m: 1, minWidth: 120 }} style={{ width: '180px' }}>
                                <InputLabel id="workflow-status-label">Workflow status</InputLabel>
                                <Select
                                    label="Workflow status"
                                    labelId="workflow-status-label"
                                    onChange={handleWorkflowStatusChange}
                                    variant="standard"
                                >
                                    <MenuItem>NONE</MenuItem>
                                    {
                                        availsWorkflowStatuses.map((status) => <MenuItem key={status} value={status}>{status}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Filter by import date"
                                    value={selectedDate}
                                    onChange={setSelectedDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <IconButton
                                style={{ verticalAlign: 'bottom' }}
                                onClick={() => setSelectedDate(null)}
                            >
                                <ClearIcon />
                            </IconButton>
                        </div>

                    </div>
                )}
            </div>
        </Toolbar>
    );
};

export default TableToolbar;
