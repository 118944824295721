/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { IconButton } from '@material-ui/core';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { useTranslate } from 'react-admin';
import ConfirmationDialog from './ConfirmationDialog';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px'
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main
    }
}));

export default function WorkflowTimeline({
    currentStatus,
    allStatuses = [],
    lastStatus,
    nextTransitionAction,
    nextTransitionActionDialogMessage = 'Are you sure you want to go to next transition?'
}) {
    const classes = useStyles();

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const translate = useTranslate();

    const handleNextTransitionClick = () => {
        setShowConfirmationDialog(true);
    };

    let currentIdx;
    let timelineSide = 'left';
    let actionButtonAlign = {
        left: '25px'
    };
    return (
        <div>
            {showConfirmationDialog && (
                <ConfirmationDialog
                    text={nextTransitionActionDialogMessage}
                    handleOnClose={() => setShowConfirmationDialog(false)}
                    handleOnOk={nextTransitionAction}
                />
            )}
            <Timeline align="alternate">
                {allStatuses.map((status, idx) => {
                    timelineSide = timelineSide === 'right' ? 'left' : 'right';
                    const current = status.id === currentStatus;
                    if (timelineSide === 'right') {
                        actionButtonAlign = { right: '25px' };
                    } else {
                        actionButtonAlign = { left: '25px' };
                    }

                    if (current) {
                        currentIdx = idx;
                    }
                    return (
                        <TimelineItem key={status.id}>
                            <TimelineOppositeContent>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {current
                                        ? translate('current')
                                        : idx > currentIdx
                                        ? translate('nextPossible')
                                        : translate('passed')}
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot
                                    style={{
                                        backgroundColor: status.backgroundColor
                                    }}
                                >
                                    {current ? (
                                        <AccessTimeIcon />
                                    ) : idx > currentIdx ? (
                                        <DoubleArrowIcon />
                                    ) : (
                                        <DoneIcon />
                                    )}
                                </TimelineDot>
                                {idx + 1 < allStatuses.length && (
                                    <TimelineConnector />
                                )}
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper
                                    elevation={3}
                                    className={classes.paper}
                                    style={{
                                        color: current ? '#fff' : '#000',
                                        backgroundColor: current
                                            ? status.backgroundColor
                                            : '#fff'
                                    }}
                                >
                                    <Typography variant="h6" component="h1">
                                        {status.status}
                                    </Typography>
                                    <Typography>
                                        {status.description}
                                    </Typography>
                                    {nextTransitionAction &&
                                        current &&
                                        !lastStatus && (
                                            <IconButton
                                                className="mui-button-hover"
                                                onClick={
                                                    handleNextTransitionClick
                                                }
                                                style={{
                                                    position: 'absolute',
                                                    top: '15px',
                                                    backgroundColor: '#fff',
                                                    ...actionButtonAlign
                                                }}
                                            >
                                                <SkipNextIcon />
                                            </IconButton>
                                        )}
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    );
                })}
            </Timeline>
        </div>
    );
}
