/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import {
    List,
    TextField,
    Datagrid,
    EditButton,
    DateField,
    Filter,
    TextInput,
    SelectInput,
    useListContext,
    BulkDeleteButton
} from 'react-admin';
import { releaseStatuses } from '../../constants';
import BasicActionsToolbar from '../BasicActionsToolbar';
import '../entities.scss';

const ColorCode = ({ record, source }) => {
    return (
        <div
            style={{
                borderRadius: '15px',
                border: '1px solid silver',
                width: '30px',
                height: '30px',
                backgroundColor: record[source]
            }}
        />
    );
};

const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" />
        <SelectInput
            label="Workflow Status"
            source="workflowStatus"
            choices={releaseStatuses.map((status) => ({
                id: status.id,
                name: status.status
            }))}
        />
    </Filter>
);

const ReleaseList = (props) => {
    const WorkflowCustomField = ({ record, source }) => {
        const backgroundColor = releaseStatuses.find((el) => el.id === record[source])?.backgroundColor || 'gray';
        const label = releaseStatuses.find((el) => el.id === record[source])?.status || record[source];
        return (
            <span style={{ fontWeight: 'bold' }}>
                <span
                    style={{
                        display: 'inline-block',
                        borderRadius: '15px',
                        border: '1px solid silver',
                        width: '10px',
                        height: '10px',
                        backgroundColor,
                        verticalAlign: 'middle',
                        marginRight: '10px'
                    }}
                />
                {label}
            </span>
        );
    };

    const ReleaseFormattedNumberCustomField = ({ record, source }) => {
        return <div>{formatNumber(record.releaseStatistics[source])}</div>;
    };

    return (
        <List
            bulkActionButtons={<BulkDeleteButton undoable={false} />}
            {...props}
            actions={<BasicActionsToolbar listContext={useListContext} showFilters showCreate {...props} />}
            filters={<PostFilter />}
        >
            <Datagrid>
                <DateField label="Valid From" source="validFrom" />
                <DateField label="Valid To" source="validTo" />
                <TextField source="name" label="Name" />
                <TextField source="comment" label="Comment" />
                <WorkflowCustomField source="workflowStatus" label="Workflow/Status" />
                <ColorCode source="colorCode" label="Color" />
                <TextField source="releaseStatistics.assets" label="Assets" />
                <ReleaseFormattedNumberCustomField source="releaseLength" label="Length/Min" />
                <ReleaseFormattedNumberCustomField source="releaseCost" label="Cost/&euro;" />
                <EditButton label="Manage" />
            </Datagrid>
        </List>
    );
};

export default ReleaseList;
