import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const CountryMultiSelect = ({ selectLabel, contriesOptions, defaultCountries, onChange }) => {
    return (
        <Autocomplete
            id="tags-outlined"
            multiple
            disableCloseOnSelect
            options={contriesOptions}
            getOptionLabel={(option) => option.name}
            value={defaultCountries}
            onChange={(e, value) => onChange(value)}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </React.Fragment>
              )}
            renderTags={(value) => {
                    return <div>{`(${value.length})`} {value.map((option) => option.name).join(', ')}</div>;
                }}
            renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={selectLabel}
                        />
);
        }}
        />
    );
};

export default CountryMultiSelect;
