/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import MUITableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TableHead from '@material-ui/core/TableHead';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'none'
        }
    }
});

function TableRow(props) {
    const { row, isItemSelected, onClick, headCells, onEdit, hasEdit } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    return (
        <React.Fragment>
            <MUITableRow
                className={classes.root}
                key={row.name}
                hover
                onClick={(event) => onClick(event, row.name)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
            >
                <TableCell size="small" style={{ maxWidth: '30px' }}>
                    <Checkbox checked={isItemSelected} />
                </TableCell>
                {headCells.map((cell) => {
                    return (
                        <TableCell key={cell.id} style={cell.tdStyle} component="th" scope="row">
                            {cell.transform ? cell.transform(row[cell.id]) : row[cell.id]}
                        </TableCell>
                    );
                })}
                {hasEdit ? (
                    <TableCell component="th" scope="row">
                        <EditButton
                            row={row}
                            onClick={onEdit}
                        />
                    </TableCell>
) : null }
                <TableCell size="small">
                    {row.type === 'season' && (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                </TableCell>
            </MUITableRow>
            {row.type === 'season' && row.episodes && (
                <MUITableRow className={classes.root}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Episodes
                                </Typography>
                                <Table size="small" aria-label="episodes">
                                    <TableHead>
                                        <MUITableRow>
                                            <TableCell>Order</TableCell>
                                            <TableCell>Title</TableCell>
                                        </MUITableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.episodes.map((episode) => (
                                            <MUITableRow key={`${row.name}-${episode.name}`}>
                                                <TableCell>{episode.episodeOrder}</TableCell>
                                                <TableCell>{episode.name}</TableCell>
                                            </MUITableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </MUITableRow>
            )}
        </React.Fragment>
    );
}

TableRow.defaultProps = {
    hasEdit: true
};

export default TableRow;
