import { Introspecter } from '@api-platform/admin';
import Create from './ReleasesCreate';
// import Edit from './ReleasesEdit';
import List from './ReleasesList';
import ReleaseDashboard from './ReleaseDashboard';

export default {
    create: (props) => <Introspecter component={Create} {...props} />,
    edit: (props) => <Introspecter component={ReleaseDashboard} {...props} />,
    list: List,
    resource: 'releases',
    name: 'releases'
};
