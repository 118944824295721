import React, { useState } from 'react';
import {
 DialogTitle, Dialog, Button, TextField, DialogActions, IconButton, Checkbox
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const SearchAssetsModal = ({ isSearchModalOpen, availsList, handleSetTeaserAsset, onClose }) => {
    const [selectedAsset, setSelectedAsset] = useState({});

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
        <Dialog
            open={isSearchModalOpen}
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
        >
            <DialogTitle disableTypography style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>Select Asset (available within the time period)</h3>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <div
                style={{
                        width: '500px',
                        padding: '30px',
                        position: 'relative'
                    }}
            >
                <Autocomplete
                    autoHighlight
                    options={availsList.filter((avail) => avail.title)}
                    filterOptions={(options, { inputValue }) => {
                        const filteredListByKey = [
                            ...options.filter((option) => option.name.toLowerCase().includes(inputValue)),
                            ...options.filter((option) => option.genre.some((gen) => gen.toLowerCase().includes(inputValue)))
                        ];
                        // using Set in order to remove duplicates for the cases when inputValue is found in both filter keys (ex. Krimi)
                        return [...new Set(filteredListByKey)];
                    }}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => setSelectedAsset(value)}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {`${option.name} - ${option.genre}`}
                        </React.Fragment>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Search for assets" />
                    )}
                />
            </div>
            <DialogActions>
                <Button style={{ border: '1px solid #999' }} onClick={() => { setSelectedAsset({}); onClose(); }}>
                    Cancel
                </Button>
                <Button
                    style={{ border: '1px solid #999' }}
                    onClick={() => {
                        handleSetTeaserAsset({ id: selectedAsset['@id'], name: selectedAsset.name });
                        onClose();
                    }}
                >
                    Pick
                </Button>
            </DialogActions>
        </Dialog>
      );
};

export default SearchAssetsModal;
