/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import {
    List,
    TextField,
    Datagrid,
    EditButton,
    Filter,
    TextInput,
    ReferenceField,
    SelectInput,
    ReferenceInput
} from 'react-admin';
import { Check, Clear } from '@material-ui/icons';
import VideosListActions from './VideoListActions';
import { videoStatuses } from '../../constants';
import ListThumbnailField from './ListThumbnailField';
import '../entities.scss';

const MyCustomBooleanField = ({ record, source }) => {
    return record[source] ? <Check /> : <Clear />;
};

const WorkflowCustomField = ({ record, source }) => {
    const backgroundColor =
        videoStatuses.find((el) => el.id === record[source])?.backgroundColor ||
        'gray';
    const label =
        videoStatuses.find((el) => el.id === record[source])?.status ||
        record[source];
    return (
        <span className="workflow-custom-field" style={{ backgroundColor }}>
            {label}
        </span>
    );
};

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" />
        <SelectInput
            label="Workflow Status"
            source="workflowStatus"
            choices={videoStatuses.map((status) => ({
                id: status.id,
                name: status.status
            }))}
        />
        <ReferenceInput
            label="Content Provider"
            source="contentProvider"
            reference="content_providers"
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Licence" source="licence" reference="licences">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const VideosList = (props) => {
    return (
        <List
            {...props}
            actions={<VideosListActions />}
            filters={<PostFilter />}
        >
            <Datagrid>
                <MyCustomBooleanField source="enabled" />
                <WorkflowCustomField
                    source="workflowStatus"
                    label="WF Status"
                />
                <ListThumbnailField source="smallImage" label="Preview" />
                <TextField source="name" label="Name" />
                <TextField source="slug" label="Slug" />
                <TextField source="category.name" label="Category" />
                <ReferenceField
                    link={false}
                    label="Content Provider"
                    source="contentProvider"
                    reference="content_providers"
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                    link={false}
                    label="Licence"
                    source="licence"
                    reference="licences"
                >
                    <TextField source="name" />
                </ReferenceField>
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default VideosList;
