/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
 DialogTitle, Dialog, Button, Select, FormControl, InputLabel, MenuItem, TextField, DialogActions,
 Switch, IconButton
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import { createPageTypes, pageTypes } from '../../utils/editorial-process';

const CreatePageModal = (props) => {
    const { modalType, releases, pageData, onClose } = props;
    const [release, setRelease] = useState('');
    const [pageTitle, setPageTitle] = useState('');
    const [pageType, setPageType] = useState('');
    const [contentType, setContentType] = useState('');
    const [pageSlug, setPageSlug] = useState('/');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isGridView, setIsGridView] = useState(true);
    const isCopyPageModal = modalType === createPageTypes.COPY;
    const history = useHistory();
    const resetFields = () => {
        setRelease('');
        setPageTitle('');
        setPageType('');
        setContentType('');
        setPageSlug('/');
        setStartDate(null);
        setEndDate(null);
        setIsGridView(true);
    };

    const handleClose = () => {
        resetFields();
        onClose();
    };

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={!!modalType}
            >
                <DialogTitle disableTypography style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>{isCopyPageModal ? `Copy page "${pageData.name}"` : 'Create new page'}</h3>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <div
                    style={{
                        width: '500px',
                        padding: '30px',
                        position: 'relative'
                    }}
                >
                    <div style={{ display: 'flex', marginBottom: '50px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginRight: '20px' }}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label" required>Release</InputLabel>
                                <Select
                                    defaultValue={release}
                                    onChange={(e) => setRelease(e.target.value)}
                                >
                                    {releases.map((releaseItem) => <MenuItem value={releaseItem}>{releaseItem}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <TextField required defaultValue={pageTitle} label={isCopyPageModal ? 'New page title' : 'Page title'} onChange={(e) => setPageTitle(e.target.value)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    autoOk
                                    required
                                    format="dd/MM/yyyy"
                                    id="date-picker-inline"
                                    label="Start Availability"
                                    value={startDate}
                                    onChange={setStartDate}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    autoOk
                                    required
                                    format="dd/MM/yyyy"
                                    id="date-picker-inline"
                                    label="End Availability"
                                    value={endDate}
                                    onChange={setEndDate}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginRight: '20px' }}>
                            {isCopyPageModal
                                ? (<TextField required defaultValue={pageSlug} label="New Slug" onChange={(e) => setPageSlug(`${e.target.value}`)} />)
                                : (
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label" required>Page Type</InputLabel>
                                        <Select
                                            autoWidth
                                            defaultValue={pageType}
                                            onChange={(e) => setPageType(e.target.value)}
                                        >
                                            {Object.values(pageTypes).map((pageTypeValue) =>
                                                <MenuItem value={pageTypeValue.label}>{pageTypeValue.label}</MenuItem>
                                        )}
                                        </Select>
                                    </FormControl>
                                )}
                            {(pageType === pageTypes.SUBCATEGORY.label) && (
                                <>
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label" required>Content Type</InputLabel>
                                        <Select
                                            defaultValue={contentType}
                                            onChange={(e) => setContentType(e.target.value)}
                                        >
                                            {Object.values(pageTypes.SUBCATEGORY.contentTypes).map((contentTypeValue) =>
                                                <MenuItem value={contentTypeValue}>{contentTypeValue}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <div style={{ marginTop: '20px' }}>
                                        <span>Teaser-Lanes</span>
                                        <Switch
                                            checked={isGridView}
                                            onChange={() => setIsGridView(!isGridView)}
                                        />
                                        <span>Grid-View</span>
                                    </div>
                                </>
                            )}
                        </div>
                        {(pageType === pageTypes.SUBCATEGORY.label) && (
                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                <TextField required defaultValue={pageSlug} label="Slug" onChange={(e) => setPageSlug(`${e.target.value}`)} />
                            </div>
                        )}
                    </div>
                </div>
                <DialogActions>
                    <Button style={{ border: '1px solid #999' }} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button style={{ border: '1px solid #999' }} onClick={() => history.push('/edit-page')}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreatePageModal;
