/* eslint-disable max-len */
import jwtDecode from 'jwt-decode';
import { extractBaseUrl } from 'utils/helpers';

export default {
    login: ({ code }) => {
        const request = new Request(`${extractBaseUrl(global.RA_API_ENTRYPOINT)}/auth/validate`, {
            method: 'POST',
            body: JSON.stringify({ _auth_code: code }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include'
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                localStorage.setItem('token', token);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkAuth: () => {
        try {
            if (!localStorage.getItem('token') || new Date().getTime() / 1000 > jwtDecode(localStorage.getItem('token')).exp) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },
    checkError: (err) => {
        if ([401, 403].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const jwt = localStorage.getItem('token');
        if (!jwt) {
            return Promise.reject();
        }
        const decodedJwt = jwtDecode(jwt);
        const { roles } = decodedJwt;
        return Promise.resolve(roles);
    },
    getCurrentUserEmail: () => {
        const token = localStorage.getItem('token');
        const { username } = jwtDecode(token);

        return username;
    }
};
