import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';
import ReleaseListFilters from './SeriesListFilters';

const ReleaseList = (props) => (
    <List {...props} filters={<ReleaseListFilters />}>
        <Datagrid>
            <TextField source="name" />
            <TextField source="workflowStatus" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ReleaseList;
