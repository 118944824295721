/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { createPageTypes } from '../../utils/editorial-process';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        justifyContent: 'space-between'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85)
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark
              },
    title: {
        flex: '1 1 100%'
    }
}));

const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, setModalType } = props;

    return (
        <Toolbar
            className={`${classes.root} ${
                numSelected > 0 ? classes.highlight : ''
            }`}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
            <Button
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={() => setModalType(createPageTypes.NEW)}
            >
                Create
            </Button>
        </Toolbar>
    );
};

export default TableToolbar;
