import {
    Edit,
    SimpleForm, TextInput, PasswordInput, required
   } from 'react-admin';
import RoleInput from './RoleInput';

import validatePassword from './validatePassword';

export default (props) => (
    <Edit {...props} undoable={false}>
        <SimpleForm>
            <TextInput source="forename" validate={[required()]}/>
            <TextInput source="surname" validate={[required()]}/>
            <TextInput type="email" source="email" validate={[required()]}/>
            <PasswordInput source="password" validate={validatePassword}/>
            <RoleInput />
        </SimpleForm>
    </Edit>
);
