export const cleanSSDScannerPrefix = (scannerOutput) => {
    const scannerPrefix = 'SSD0';
    let sanitizedValue = scannerOutput;

    if (scannerOutput.length > 4 && scannerOutput.includes(scannerPrefix)) {
        sanitizedValue = scannerOutput.replace(scannerPrefix, '');
    }
    return sanitizedValue;
};

export const hasDuplicates = (array) => new Set(array).size !== array.length;

export const extractBaseUrl = (apiPath) => {
    if (apiPath.includes('api')) {
        const apiPathBySlash = apiPath.split('/');
        if (apiPathBySlash[apiPathBySlash.length - 1] === 'api') {
            apiPathBySlash.pop();
        }
        return apiPathBySlash.join('/');
    }
    return apiPath;
};
