import React, { useState } from 'react';
import { Button } from 'react-admin';
import { Close, ListAlt, ExpandMore, AttachFile, Error } from '@material-ui/icons';
import { DialogTitle, Dialog, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

const CustomTabs = ({ data }) => {
    const [selectedItem, setSelectedItem] = useState(Object.keys(data)[0]);

    return (
        <>
            <div style={{ display: 'flex' }}>
                {Object.keys(data).map((item) => {
                    const numberOfRows = Object.keys(data[item]).length;
                    return (
                        <button
                            type="button"
                            key={item}
                            onClick={() => {
                                setSelectedItem(item);
                            }}
                            style={{
                                flex: '1 1 0',
                                cursor: 'pointer',
                                border: '2px solid forestgreen',
                                outline: 'none',
                                background: 'transparent',
                                textTransform: 'capitalize',
                                padding: '10px',
                                marginRight: '10px',
                                color: 'forestgreen',
                                fontWeight: item === selectedItem ? 'bold' : 'initial'
                            }}
                        >
                            {`${item} (${numberOfRows})`}
                        </button>
                    );
                })}
            </div>
            <div style={{ marginBottom: '40px' }}>
                {Object.keys(data[selectedItem]).map((row) => (
                    <>
                        <h4>{row}</h4>
                        {Object.keys(data[selectedItem][row]).map((column) => (
                            <Accordion square>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <p style={{ textTransform: 'capitalize', margin: '0' }}>{column}</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>{data[selectedItem][row][column]}</div>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </>
                ))}
            </div>
        </>
    );
};

const setContent = (data) => {
    return Object.keys(data).map((key) => {
        if (typeof data[key] === 'string') {
            return (
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AttachFile />
                        <h3 style={{ textTransform: 'capitalize', marginLeft: '10px' }}>{key}</h3>
                    </div>
                    <div style={{ marginBottom: '40px' }}>{data[key]}</div>
                </>
            );
        }

        if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
            return (
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AttachFile />
                        <h3 style={{ textTransform: 'capitalize', marginLeft: '10px' }}>{key}</h3>
                    </div>
                    <CustomTabs data={data[key]} />
                </>
            );
        }

        // case available for 404 error
        if (Array.isArray(data[key]) && data[key].length) {
            return (
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Error />
                        <h3 style={{ textTransform: 'capitalize', marginLeft: '10px' }}>{key}</h3>
                    </div>
                    <div style={{ marginBottom: '40px' }}>
                        {data[key].map((item) => (
                            <div>{item}</div>
                        ))}
                    </div>
                </>
            );
        }
        return '';
    });
};

const AvailsDialog = (props) => {
    const { onClose, open, data, topic } = props;

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth="md" fullWidth>
            <DialogTitle color="primary" id="simple-dialog-title" className="import-avails-logs">
                <div>
                    <ListAlt style={{ verticalAlign: 'middle', marginRight: '10px' }} />
                    {`${topic} Import Log`}
                </div>
                <Button variant="text" className="import-avails-logs__close-button" onClick={onClose}>
                    <Close />
                </Button>
            </DialogTitle>
            <div
                style={{
                    padding: '30px',
                    position: 'relative'
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>{setContent(data)}</div>
                <div
                    style={{
                        bottom: '30px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '40px'
                    }}
                />
            </div>
        </Dialog >
    );
};

export default AvailsDialog;
