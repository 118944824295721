/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import {
    List,
    TextField,
    Datagrid,
    EditButton,
    Filter,
    TextInput,
    useListContext,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    BooleanField,
    BooleanInput, SelectInput
} from 'react-admin';
import BasicActionsToolbar from '../BasicActionsToolbar';

const TrainunitsListFilters = (props) => {
    return (
        <Filter {...props}>
            <BooleanInput label="Status" source="status" />
            <TextInput label="TZN" source="tzn" />
            <TextInput label="VZN" source="vzn" />
            <TextInput label="BR" source="br" />
            <TextInput label="UIC" source="uic" />
            <TextInput label="Chassis" source="chassisId" />
            <TextInput label="Fahn" source="fahn" />
            <SelectInput
                label="Active with customers"
                source="activeWithCustomers"
                style={{ minWidth: '200px' }}
                choices={[{ id: 1, name: 'Yes' }, { id: 0, name: 'No' }].map(({ id, name }) => ({
                    id,
                    name
                }))}
            />
        </Filter>
    );
};

const ActiveWithCustomerTextField = ({ source, record }) => {
    switch (record[source]) {
        case 0:
            return 'No';
        case 1:
            return 'Yes';
        default:
            return 'N/A';
    }
};

const SSDSFilteredField = (props) => {
    const trainUnitId = props?.record?.['@id'];
     return (
         <ReferenceManyField {...props} filter={{ train: trainUnitId }}>
             <SingleFieldList>
                 <ChipField source="serialNumber" />
             </SingleFieldList>
         </ReferenceManyField>
     );
};

function TrainunitsList(props) {
    return (
        <List
            {...props}
            actions={<BasicActionsToolbar listContext={useListContext} showFilters showCreate {...props} />}
            filters={<TrainunitsListFilters />}
        >
            <Datagrid>
                <BooleanField source="status" label="Status" />
                <TextField source="tzn" label="TZN" />
                <TextField source="br" label="BR" />
                <TextField source="uic" label="UIC" />
                <TextField source="vzn" label="VZN" />
                <TextField source="chassisId" label="Chassis" />
                <TextField source="fahn" label="Fahn" />
                <ActiveWithCustomerTextField source="activeWithCustomers" label="Active with customers" />
                <SSDSFilteredField label="SSD Serial Number" reference="s_s_ds" target="ssds" sortable={false} />
                <EditButton />
            </Datagrid>
        </List>
    );
}

export default TrainunitsList;
