/* eslint-disable no-lone-blocks */
import React, { useState } from 'react';
import {
    TextField,
    Button,
    IconButton,
    Switch,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input
} from '@material-ui/core';
import classNames from 'classnames';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { teaserlaneTypes } from '../../utils/editorial-process';
import Tabs from './Tabs';

const TeaserlaneOptions = ({
    type,
    showButton,
    buttonSlug,
    teaserlaneBgImagePath,
    channelPartener,
    hasBackgroundImage,
    lightModeBgColor,
    darkModeBgColor,
    setChannelPartener,
    setButtonSlug,
    setShowButton,
    setHasBackgroundImage,
    setLightModeBgColor,
    setDarkModeBgColor
}) => {
    return (
        <>
            <div style={{ gridArea: 'laneOptions', marginTop: '20px', display: 'flex', borderTop: type === teaserlaneTypes.SHOWCASE ? 'none' : '1px solid #999', padding: '20px 0' }}>
                <div style={{ marginRight: '20px' }}>
                    <Switch
                        checked={showButton}
                        onChange={() => setShowButton(!showButton)}
                    />
                    <div>Show button</div>
                </div>
                <TextField style={{ marginRight: '20px' }} label="Button text" disabled={!showButton}/>
                <FormControl style={{ width: '100%', maxWidth: '150px' }}>
                    <InputLabel id="demo-simple-select-label" required>Available Slugs</InputLabel>
                    <Select
                        disabled={!showButton}
                        value={buttonSlug}
                        onChange={(e) => setButtonSlug(e.target.slug)}
                    >
                        <MenuItem value="/slug1">/slug1</MenuItem>
                        <MenuItem value="/slug2">/slug2</MenuItem>
                        <MenuItem value="/slug3">/slug3</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {type === teaserlaneTypes.SHOWCASE
                ? (
                    <>
                        <div style={{ gridArea: 'channelPartener', display: 'flex', alignItems: 'flex-end' }}>
                            <FormControl style={{ width: '100%', maxWidth: '150px' }}>
                                <InputLabel id="demo-simple-select-label" required>Partener Channel</InputLabel>
                                <Select
                                    value={channelPartener}
                                    onChange={(e) => setChannelPartener(e.target.value)}
                                >
                                    <MenuItem value="ARD">ARD</MenuItem>
                                    <MenuItem value="Paramount">Paramount</MenuItem>
                                    <MenuItem value="TNT">TNT</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ gridArea: 'teaserlaneShowcaseBgOptions', display: 'flex', padding: '20px 0' }}>
                            <div style={{ display: 'flex', alignItems: 'flex-end', marginRight: '70px' }}>
                                <div>Background Color</div>
                                <Switch
                                    checked={hasBackgroundImage}
                                    onChange={() => setHasBackgroundImage(!hasBackgroundImage)}
                                />
                                <div>Background Image</div>
                            </div>
                            {hasBackgroundImage
                                ? (
                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <TextField value={teaserlaneBgImagePath} label="Background Image Path"/>
                                    </div>
                                )
                                : (
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ marginRight: '50px' }}>
                                            <label htmlFor="light-mode">Background Image Light Mode</label>
                                            <div style={{ display: 'flex' }}>
                                                <Input
                                                    disableUnderline
                                                    style={{ display: 'block', width: '25px', height: '25px' }}
                                                    inputProps={{
                                                            style: { width: '100%', height: '100%' }
                                                        }}
                                                    type="color"
                                                    id="light-mode"
                                                    name="light-mode"
                                                    value={lightModeBgColor}
                                                    onChange={(e) => setLightModeBgColor(e.target.value)}
                                                />
                                                <Button
                                                    startIcon={<EditIcon />}
                                                    onClick={() => {
                                                        const inputElement = document.querySelector('#light-mode');
                                                        inputElement.click();
                                                    }}
                                                >

                                                    Edit
                                                </Button>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="dark-mode">Background Image Dark Mode</label>
                                            <div style={{ display: 'flex' }}>
                                                <Input
                                                    disableUnderline
                                                    style={{ display: 'block', width: '25px', height: '25px' }}
                                                    inputProps={{
                                                        style: { width: '100%', height: '100%' }
                                                    }}
                                                    type="color"
                                                    id="dark-mode"
                                                    name="dark-mode"
                                                    value={darkModeBgColor}
                                                    onChange={(e) => setDarkModeBgColor(e.target.value)}
                                                />
                                                <Button
                                                    startIcon={<EditIcon />}
                                                    onClick={() => {
                                                        const inputElement = document.querySelector('#dark-mode');
                                                        inputElement.click();
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                )}
                        </div>
                    </>
)
                : null}
            {type === teaserlaneTypes.SPECIAL
                ? (
                    <div style={{ gridArea: 'teaserlaneShowcaseBgOptions', display: 'flex', padding: '20px 0' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end', marginRight: '70px' }}>
                            <div>Background Color</div>
                            <Switch
                                checked={hasBackgroundImage}
                                onChange={() => setHasBackgroundImage(!hasBackgroundImage)}
                            />
                            <div>Background Image</div>
                        </div>
                        {hasBackgroundImage
                                ? (
                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <TextField value={teaserlaneBgImagePath} label="Background Image Path"/>
                                    </div>
                                )
                                : (
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ marginRight: '50px' }}>
                                            <label htmlFor="light-mode">Background Image Light Mode</label>
                                            <div style={{ display: 'flex' }}>
                                                <Input
                                                    disableUnderline
                                                    style={{ display: 'block', width: '25px', height: '25px' }}
                                                    inputProps={{
                                                            style: { width: '100%', height: '100%' }
                                                        }}
                                                    type="color"
                                                    id="light-mode"
                                                    name="light-mode"
                                                    value={lightModeBgColor}
                                                    onChange={(e) => setLightModeBgColor(e.target.value)}
                                                />
                                                <Button
                                                    startIcon={<EditIcon />}
                                                    onClick={() => {
                                                        const inputElement = document.querySelector('#light-mode');
                                                        inputElement.click();
                                                    }}
                                                >

                                                    Edit
                                                </Button>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="dark-mode">Background Image Dark Mode</label>
                                            <div style={{ display: 'flex' }}>
                                                <Input
                                                    disableUnderline
                                                    style={{ display: 'block', width: '25px', height: '25px' }}
                                                    inputProps={{
                                                        style: { width: '100%', height: '100%' }
                                                    }}
                                                    type="color"
                                                    id="dark-mode"
                                                    name="dark-mode"
                                                    value={darkModeBgColor}
                                                    onChange={(e) => setDarkModeBgColor(e.target.value)}
                                                />
                                                <Button
                                                    startIcon={<EditIcon />}
                                                    onClick={() => {
                                                        const inputElement = document.querySelector('#dark-mode');
                                                        inputElement.click();
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                )}
                    </div>
)
                : null}
        </>
    );
};

const TeaserlaneDefault = ({ teaserlane: { id: teaserlaneId, type, tabs }, handleSetNewTeaserTab, openSearchModal }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [showButton, setShowButton] = useState(false);
    const [buttonSlug, setButtonSlug] = useState();

    return (
        <>
            <TextField style={{ gridArea: 'headline' }} defaultValue="" label="Headline" multiline variant="outlined" onChange={() => null} />
            <Tabs
                tabType="teaser"
                maxTabs={4}
                currentTab={currentTab}
                tabs={tabs}
                setCurrentTab={setCurrentTab}
                handleSetNewTeaserTab={() => handleSetNewTeaserTab(teaserlaneId)}
                openSearchModal={(tabId) => openSearchModal(teaserlaneId, tabId)}
            />
            <TeaserlaneOptions
                type={type}
                showButton={showButton}
                buttonSlug={buttonSlug}
                setShowButton={setShowButton}
                setButtonSlug={setButtonSlug}
            />
        </>
);
};

const TeaserlaneShowcase = ({ teaserlane: { id: teaserlaneId, type, tabs }, handleSetNewTeaserTab, openSearchModal }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [showButton, setShowButton] = useState(false);
    const [buttonSlug, setButtonSlug] = useState();
    const [teaserlaneBgImagePath] = useState('');
    const [channelPartener, setChannelPartener] = useState('');
    const [hasBackgroundImage, setHasBackgroundImage] = useState(false);
    const [lightModeBgColor, setLightModeBgColor] = useState('#999999');
    const [darkModeBgColor, setDarkModeBgColor] = useState('#ffffff');

    return (
        <>
            <TextField style={{ gridArea: 'headline', marginRight: '20px' }} defaultValue="" label="Headline" multiline variant="outlined" onChange={() => null} />
            <TextField style={{ gridArea: 'shortText', marginLeft: '20px' }} defaultValue="" label="Short Text" multiline variant="outlined" onChange={() => null} />
            <Tabs
                tabType="teaser"
                maxTabs={4}
                currentTab={currentTab}
                tabs={tabs}
                isSelectAssetAvailable={!!channelPartener}
                setCurrentTab={setCurrentTab}
                handleSetNewTeaserTab={() => handleSetNewTeaserTab(teaserlaneId)}
                openSearchModal={(tabId) => openSearchModal(teaserlaneId, tabId)}
            />
            <TeaserlaneOptions
                type={type}
                showButton={showButton}
                buttonSlug={buttonSlug}
                teaserlaneBgImagePath={teaserlaneBgImagePath}
                channelPartener={channelPartener}
                hasBackgroundImage={hasBackgroundImage}
                lightModeBgColor={lightModeBgColor}
                darkModeBgColor={darkModeBgColor}
                setShowButton={setShowButton}
                setButtonSlug={setButtonSlug}
                setChannelPartener={setChannelPartener}
                setHasBackgroundImage={setHasBackgroundImage}
                setLightModeBgColor={setLightModeBgColor}
                setDarkModeBgColor={setDarkModeBgColor}
            />
        </>
);
};

const TeaserlaneSpecial = ({ teaserlane: { id: teaserlaneId, type, tabs }, handleSetNewTeaserTab, openSearchModal }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [showButton, setShowButton] = useState(false);
    const [buttonSlug, setButtonSlug] = useState();
    const [hasBackgroundImage, setHasBackgroundImage] = useState(false);
    const [lightModeBgColor, setLightModeBgColor] = useState('#999999');
    const [darkModeBgColor, setDarkModeBgColor] = useState('#ffffff');
    const [teaserlaneBgImagePath] = useState('');

    return (
        <>
            <TextField style={{ gridArea: 'headline' }} defaultValue="" label="Headline" multiline variant="outlined" onChange={() => null} />
            <Tabs
                tabType="teaser"
                maxTabs={4}
                currentTab={currentTab}
                tabs={tabs}
                setCurrentTab={setCurrentTab}
                handleSetNewTeaserTab={() => handleSetNewTeaserTab(teaserlaneId)}
                openSearchModal={(tabId) => openSearchModal(teaserlaneId, tabId)}
            />
            <TeaserlaneOptions
                type={type}
                showButton={showButton}
                buttonSlug={buttonSlug}
                teaserlaneBgImagePath={teaserlaneBgImagePath}
                hasBackgroundImage={hasBackgroundImage}
                lightModeBgColor={lightModeBgColor}
                darkModeBgColor={darkModeBgColor}
                setShowButton={setShowButton}
                setButtonSlug={setButtonSlug}
                setHasBackgroundImage={setHasBackgroundImage}
                setLightModeBgColor={setLightModeBgColor}
                setDarkModeBgColor={setDarkModeBgColor}
            />
        </>
);
};

const setTeaserlaneComponent = (teaserlane, handleSetNewTeaserTab, openSearchModal) => {
    switch (teaserlane.type) {
        case teaserlaneTypes.DEFAULT:
    return <TeaserlaneDefault teaserlane={teaserlane} handleSetNewTeaserTab={handleSetNewTeaserTab} openSearchModal={openSearchModal} />;

        case teaserlaneTypes.SHOWCASE:
    return <TeaserlaneShowcase teaserlane={teaserlane} handleSetNewTeaserTab={handleSetNewTeaserTab} openSearchModal={openSearchModal} />;

        case teaserlaneTypes.SPECIAL:
    return <TeaserlaneSpecial teaserlane={teaserlane} handleSetNewTeaserTab={handleSetNewTeaserTab} openSearchModal={openSearchModal} />;
        default:
    <div>la</div>;
    }

    return null;
};


const Teaserlane = ({
    teaserlanePosition,
    numOfLanes,
    teaserlane,
    handleSetNewTeaserTab,
    handleDeleteTeaserlane,
    handleMoveTeaserlane,
    openSearchModal
}) => {
    return (
        <section style={{ width: '100%', borderTop: '3px solid #999' }}>
            <div
                className={classNames('editPage-section teaserlane', {
                teaserlane__showcase: teaserlane.type === teaserlaneTypes.SHOWCASE
            })}
                style={{ display: 'grid', width: '100%', maxWidth: '90%', margin: 'auto' }}
            >


                <div style={{ gridArea: 'title', padding: '20px 0', fontWeight: 'bold' }}>{teaserlane.type}</div>
                <div style={{ gridArea: 'deleteTeaserlane', display: 'flex', justifyContent: 'flex-end' }}>
                    <p style={{ marginRight: '20px' }}>Position {`${teaserlanePosition + 1} / ${numOfLanes}`}</p>
                    <div style={{ boxShadow: '0 0 5px 0 rgba(0,0,0,.3)', width: '48px', height: '48px' }}>
                        <IconButton onClick={() => handleDeleteTeaserlane(teaserlanePosition)} >
                            <DeleteOutlineIcon />
                        </IconButton>
                    </div>
                    <div style={{ boxShadow: '0 0 5px 0 rgba(0,0,0,.3)', width: '48px', height: '48px', margin: '0 10px' }}>
                        <IconButton
                            disabled={teaserlanePosition - 1 < 0}
                            onClick={() => handleMoveTeaserlane(teaserlanePosition, teaserlanePosition - 1)}
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                    </div>
                    <div style={{ boxShadow: '0 0 5px 0 rgba(0,0,0,.3)', width: '48px', height: '48px' }}>
                        <IconButton
                            disabled={teaserlanePosition + 1 >= numOfLanes}
                            onClick={() => handleMoveTeaserlane(teaserlanePosition, teaserlanePosition + 1)}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    </div>
                </div>

                {setTeaserlaneComponent(teaserlane, handleSetNewTeaserTab, openSearchModal)}
            </div>
        </section>
    );
};

export default Teaserlane;
