/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import MUITableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import TableHead from './TableHead';
import TablePaginationActions from './TablePaginationActions';
import TableToolbar from './TableToolbar';
import TableRow from './TableRow';
import { availsMoviesWorkflowStatuses, videoStatuses } from '../../constants';

const availsUseStyles = makeStyles({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: '10px'
    },
    table: {
        minWidth: 750
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
});

const headCells = [
    {
        id: 'type',
        numeric: false,
        disablePadding: true,
        label: 'Category',
        style: { width: '10%', textAlign: 'center' },
        tdStyle: { textAlign: 'center' },
        transform: (value) => (value === 'movie' ? 'Film' : 'Season')
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Title',
        style: { width: '25%', textAlign: 'left' },
        tdStyle: { textAlign: 'left' },
        transform: (value) => value
    },
    {
        id: 'year',
        numeric: false,
        disablePadding: false,
        label: 'Year',
        style: { width: '1%', textAlign: 'center' },
        tdStyle: { textAlign: 'center' },
        transform: (value) => value
    },
    {
        id: 'parentalRatingNumber',
        numeric: false,
        disablePadding: false,
        label: 'FSK',
        style: { width: '1%', textAlign: 'center' },
        tdStyle: { textAlign: 'center' },
        transform: (value) => value
    },
    {
        id: 'subtitles',
        numeric: false,
        disablePadding: false,
        label: 'Subtitles',
        style: { width: '2%', textAlign: 'center' },
        tdStyle: { textAlign: 'center' },
        transform: (value) => value
    },
    {
        id: 'language',
        numeric: false,
        disablePadding: false,
        label: 'Lang.',
        style: { width: '3%', textAlign: 'left' },
        tdStyle: { textAlign: 'center' },
        transform: (value) => (Array.isArray(value) ? value.join(', ') : value)
    },
    {
        id: 'originalLanguage',
        numeric: false,
        disablePadding: false,
        label: 'Orig.Lang.',
        style: { width: '3%', textAlign: 'left' },
        tdStyle: { textAlign: 'center' },
        transform: (value) => (Array.isArray(value) ? value.join(', ') : value)
    },
    {
        id: 'workflowStatus',
        numeric: false,
        disablePadding: false,
        label: 'Workflow',
        style: { width: '10%', textAlign: 'center' },
        tdStyle: { textAlign: 'left' },
        transform: (value) => {
            const workflowStatusDetails = videoStatuses.find((status) => status.id === value);
            const workflowStatusText = workflowStatusDetails?.status || value;
            const workflowStatusColor = workflowStatusDetails?.backgroundColor || '#fff';
            return (
                <div>
                    <span
                        style={{
                            display: 'inline-block',
                            marginRight: '5px',
                            width: '10px',
                            height: '10px',
                            border: '1px solid silver',
                            borderRadius: '5px',
                            backgroundColor: workflowStatusColor
                        }}
                    />
                    {workflowStatusText}
                </div>
            );
        }
    },
    {
        id: 'revenue',
        numeric: false,
        disablePadding: false,
        label: 'Revenue',
        style: { width: '5%', textAlign: 'center' },
        tdStyle: { textAlign: 'center' },
        transform: (value) => value || '-'
    },
    {
        id: 'importedAt',
        numeric: false,
        disablePadding: false,
        label: 'Import Date',
        style: { width: '10%', textAlign: 'center' },
        tdStyle: { textAlign: 'center' },
        transform: (value) => (value ? moment(value).format('YYYY-MM-DD') : '-')
    }
];

const defaultParamsString = '010ascnamenull';

export default function AvailsViewMovies() {
    const classes = availsUseStyles();
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [dataLength, setDataLength] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [selectedWorkflowStatus, setSelectedWorkflowStatus] = React.useState();
    const [resetTable, setResetTable] = React.useState(false);
    const [filtersString, setFiltersString] = React.useState('');

    const history = useHistory();
    const getAvails = (callback, defaultFilters = true) => {
        setLoading(true);
        const url = new URL(`${window.RA_API_ENTRYPOINT}/avails`);
        let requestParams;
        if (defaultFilters) {
            requestParams = {
                page: 1,
                itemsPerPage: 10,
                'order[name]': 'asc',
                type: 'movie'
            };
        } else {
            requestParams = {
                page: page + 1,
                itemsPerPage: rowsPerPage,
                [`order[${orderBy}]`]: order,
                type: 'movie'
            };
        }

        if (selectedDate) {
            requestParams['importedAt[after]'] = moment(selectedDate).format('YYYY-MM-DD');
        }

        if (selectedWorkflowStatus) {
            requestParams.workflowStatus = selectedWorkflowStatus;
        }

        url.search = new URLSearchParams(requestParams).toString();

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setLoading(false);
                const movies = response['hydra:member'];
                // .filter(({ type, workflowStatus } =
                // { type: '', workflowStatus: '' }) => type.toLowerCase() === 'movie' && workflowStatus === 'PickedForRelease');
                setData(movies);
                setDataLength(movies.length);
                if (callback) {
                    callback();
                }
            });
    };

    React.useEffect(() => {
        if (resetTable) {
            getAvails(() => {
                setPage(0);
                setRowsPerPage(10);
                setOrder('asc');
                setOrderBy('name');
                setSelectedDate(null);
                setFiltersString(defaultParamsString);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetTable]);

    React.useEffect(() => {
        if (!resetTable && filtersString !== `${page}${rowsPerPage}${order}${orderBy}${selectedDate}`) {
            getAvails(null, false);
            setFiltersString(`${page}${rowsPerPage}${order}${orderBy}${selectedDate}`);
        } else if (
            filtersString === defaultParamsString &&
            page === 0 &&
            rowsPerPage === 10 &&
            order === 'asc' &&
            orderBy === 'name' &&
            selectedDate === null
        ) {
            setResetTable(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, order, orderBy, selectedDate, resetTable, filtersString]);

    React.useEffect(() => {
        setResetTable(true);
    }, [selectedWorkflowStatus]);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleResetAndRefreshPage = () => {
        setResetTable(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleEdit = (availId) => {
        history.push(`/movies/${availId}/edit`);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableToolbar
                    numSelected={selected.length}
                    loading={loading}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    hideImportButton
                    handleResetAndRefreshPage={handleResetAndRefreshPage}
                    workflowFilterVisible
                    availsWorkflowStatuses={availsMoviesWorkflowStatuses}
                    handleWorkflowStatusChange={({ target: { value } }) => setSelectedWorkflowStatus(value)}
                />
                <TableContainer>
                    <Table size="small" className={classes.table} aria-label="custom pagination table">
                        <TableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {data.map((row) => {
                                const isItemSelected = isSelected(row.name);
                                return (
                                    <TableRow
                                        key={row['@id']}
                                        headCells={headCells}
                                        row={row}
                                        isItemSelected={isItemSelected}
                                        onClick={handleClick}
                                        onEdit={() => handleEdit(row['@id'].split('/')[3])}
                                    />
                                );
                            })}

                            {emptyRows > 0 && (
                                <MUITableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={headCells.length} />
                                </MUITableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <MUITableRow>
                                <TablePagination
                                    style={{ width: '100%' }}
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: dataLength }]}
                                    colSpan={headCells.length + 2}
                                    count={dataLength}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page'
                                        },
                                        native: true
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </MUITableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}
