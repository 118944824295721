
import { Button } from 'react-admin';
import './CheckinCheckout.scss';

const CheckinCheckout = ({ onCheckinClick, onCheckoutClick, entity, returnDialog }) => (
    <div className="checkin-checkout">
        <Button
            className="checkin-checkout__button"
            onClick={onCheckinClick}
            size="large"
            label={`${entity}.checkin`}
            component="label"
            variant="contained"
        />
        { returnDialog }
        <Button
            className="checkin-checkout__button"
            onClick={onCheckoutClick}
            size="large"
            label={`${entity}.checkout`}
            component="label"
            variant="contained"
        />
    </div>
);

export default CheckinCheckout;
