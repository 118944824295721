/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable import/order */
import React, { useCallback, useEffect, useState } from 'react';
import {
    useEditController,
    Edit,
    TextInput,
    DateInput,
    useInput,
    SimpleForm,
    useRefresh,
    useNotify,
    useMutation
} from 'react-admin';
import moment from 'moment';
import {
    ArrowDropUp, Search, CloudUpload, CloudDownload,
    Launch, AccessTime, OndemandVideo, VideoLibrary, List,
    Storage, DateRange, Web, WebAsset
} from '@material-ui/icons';
import { Paper } from '@material-ui/core';
import { releaseColors, releaseStatuses } from '../../constants';
import './ReleaseDashboard.scss';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import RDB_MovieList from './RDB_MovieList';
import RDB_SeasonList from './RDB_SeasonList';
import RDB_EpisodesList from './RDB_EpisodesList';
import RDB_DisplayDates from './RDB_DisplayDates';
import RDB_PageList from './RDB_PageList';
import RDB_PageContentMovieList from './RDB_PageContentMovieList';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RDB_PageContentList from './RDB_PageContentList';
import MetadataFileUploadDiaglog from './MetadataFileUploadModal';
import { extractBaseUrl } from '../../utils/helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DataTable from 'react-data-table-component';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import WorkflowTimeline from '../WorkflowTimeline';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function ReleaseDashboard(props) {
    const [release, setRelease] = useState();
    const [selectedMovieRow, setSelectedMovieRow] = useState(null);
    const [selectedSeasonRow, setSelectedSeasonRow] = useState(null);
    const [selectedPageRow, setSelectedPageRow] = useState(null);
    const [selectedContentRow, setSelectedContentRow] = useState(null);
    const [clearSelectedMovie, setClearSelectedMovie] = useState(false);
    const [clearSelectedSeason, setClearSelectedSeason] = useState(false);
    const [clearSelectedPage, setClearSelectedPage] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [movieSearchKeyword, setMovieSearchKeyword] = useState('');
    const [pageSearchKeyword, setPageSearchKeyword] = useState('');
    const [seasonList, setSeasonList] = useState([]);
    const [episodesList, setEspisodesList] = useState([]);
    const [displayDatesList, setDisplayDatesList] = useState([]);
    const [pageList, setPageList] = useState([]);
    const [pageContentList, setPageContentList] = useState([]);
    const [pageContentAssetsList, setPageContentAssetsList] = useState([]);
    const [importType, setImportType] = useState('excel');
    const [metadataFileUploadDiaglog, setMetadataFileUploadDialog] = useState(false);
    const [exportExcelMetadataLoading, setExportMetadataLoading] = useState(false);
    const [launchReleaseLoading, setLaunchReleaseLoading] = useState(false);
    const [episodesListLoading, setEpisodesListLoading] = useState(false);
    const [pagesListLoading, setPagesListLoading] = useState(false);
    const [backupContentCheckbox, setBackupContentCheckbox] = useState(false);
    const { record, loading } = useEditController(props);
    const [releaseLaunchStatusListDialogOpen, setReleaseLaunchStatusListDialogOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('*');
    const refresh = useRefresh();
    const notify = useNotify();
    const [mutate] = useMutation();
    const recordUUID = record ? (record.id.split('/')[record.id.split('/').length - 1]) : null;

    const updateTransition = () => {
        mutate(
            {
                type: 'update',
                resource: 'releases',
                payload: {
                    id: record.id,
                    data: {
                        ...record,
                        makeWorkflowTransition: record.nextWorkflowTransitions[0]
                    }
                }
            },
            {
                mutationMode: 'pessimistic',
                onSuccess: () => {
                    notify('Transition changed', 'info', {}, true);
                    refresh();
                },
                onFailure: (error) => notify(`Error: ${error.message}`, 'warning')
            }
        );
    };

    const getPagesByRelease = (releaseId) => {
        setPagesListLoading(true);
        const url = new URL(`${window.RA_API_ENTRYPOINT}/pages`);
        const requestParams = {
            page: 1,
            itemsPerPage: 999,
            releases: releaseId
        };
        url.search = new URLSearchParams(requestParams).toString();
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setPagesListLoading(false);
                if (response && response['hydra:member'] && response['hydra:member'].length > 0) {
                    setPageList(response['hydra:member']);
                }
            }).catch(() => {
                setPagesListLoading(false);
            });
    };

    const onSelectedMovieRowChange = useCallback((row) => {
        setSelectedMovieRow(row || null);
    }, []);

    const onSelectedSeasonRowChange = useCallback((row) => {
        setSelectedSeasonRow(row || null);
    });

    const onSelectedPageRowChange = useCallback((row) => {
        setSelectedPageRow(row || null);
    });

    const onSelectedContentRowChange = (selectedRows) => {
        setSelectedContentRow(selectedRows);
    };

    useEffect(() => {
        if (record) {
            if (selectedMovieRow && selectedMovieRow.resourceId) {
                if (selectedMovieRow.type === 'Movie') {
                    const movieOriginalData = record.assets.find((asset) => asset.type === 'movie' && asset.id === selectedMovieRow.resourceId);
                    if (movieOriginalData && movieOriginalData.licenses) {
                        setDisplayDatesList(movieOriginalData.licenses.map((license) => ({ slug: movieOriginalData.slug, start: license.start, end: license.end })));
                    }
                }
                if (selectedMovieRow.type === 'Serie') {
                    if (selectedSeasonRow && selectedSeasonRow.resourceId) {
                        const seasonOriginalData = record.assets.find((asset) => asset.type === 'season' && asset.id === selectedSeasonRow.resourceId);
                        if (seasonOriginalData && seasonOriginalData.licenses) {
                            setDisplayDatesList(seasonOriginalData.licenses.map((license) => ({ slug: seasonOriginalData.slug, start: license.start, end: license.end })));
                        }
                    } else {
                        setDisplayDatesList([]);
                    }
                }
            }
        }
    }, [selectedMovieRow, selectedSeasonRow, record]);

    useEffect(() => {
        if (record) {
            if (selectedMovieRow) {
                const currentSeasonList = record.assets.filter((asset) => asset.type === 'season' && asset.series.id === selectedMovieRow.resourceId);
                setSeasonList(currentSeasonList);
                setSelectedSeasonRow(null);
                setClearSelectedSeason(!clearSelectedSeason);
                if (currentSeasonList.length === 0) {
                    setEspisodesList([]);
                }
            } else {
                setSeasonList([]);
                setSelectedSeasonRow(null);
                setDisplayDatesList([]);
            }
        }
    }, [selectedMovieRow, record]);

    useEffect(() => {
        if (record) {
            if (selectedSeasonRow) {
                setEpisodesListLoading(true);
                fetch(new URL(`${window.RA_API_ENTRYPOINT}/avails/${selectedSeasonRow.resourceId}`), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                    .then((response) => response.json())
                    .then((response) => {
                        setEpisodesListLoading(false);
                        if (response.episodes) {
                            setEspisodesList(response.episodes);
                        }
                    }).catch(() => {
                        setEpisodesListLoading(false);
                    });
            } else {
                setEspisodesList([]);
            }
        }
    }, [selectedSeasonRow, record]);

    useEffect(() => {
        if (record && selectedPageRow) {
            fetch(new URL(`${window.RA_API_ENTRYPOINT}/pages/${selectedPageRow.resourceId}/data-schema`), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then((response) => response.json())
                .then((response) => {
                    setPageContentList(response);
                });
        } else {
            setPageContentList([]);
        }
    }, [selectedPageRow, record]);

    useEffect(() => {
        if (record && !loading) {
            getPagesByRelease(recordUUID);
            setSelectedMovieRow(null);
            setSelectedSeasonRow(null);
            setSelectedPageRow(null);
            setSelectedContentRow(null);

            const categoriesList = [];
            if (record.assets) {
                record.assets.forEach((asset) => {
                    if (asset.category && !categoriesList.includes(asset.category)) {
                        categoriesList.push(asset.category);
                    }
                });
            }
            setCategories(categoriesList);
        }
    }, [record, loading]);

    useEffect(() => {
        if (selectedContentRow && pageContentList) {
            const contentAssets = pageContentList.find((content) => content.id === selectedContentRow);
            if (contentAssets?.contents) {
                setPageContentAssetsList(contentAssets.contents);
            } else {
                setPageContentAssetsList([]);
            }
        } else {
            setPageContentAssetsList([]);
        }
    }, [selectedContentRow, pageContentList]);

    useEffect(() => {
        setSelectedMovieRow(null);
        setSelectedSeasonRow(null);
        setSelectedPageRow(null);
        setSelectedContentRow(null);
        if (selectedMovieRow) {
            setClearSelectedMovie(!clearSelectedMovie);
        }
        if (selectedSeasonRow) {
            setClearSelectedSeason(!clearSelectedSeason);
        }
        if (selectedPageRow) {
            setClearSelectedPage(!clearSelectedPage);
        }
    }, [movieSearchKeyword, pageSearchKeyword, backupContentCheckbox, selectedCategory]);

    const launchRelease = () => {
        setLaunchReleaseLoading(true);
        fetch(`${global.RA_API_ENTRYPOINT}/generate_release_workers`, {
            method: 'POST',
            body: JSON.stringify({ release: record.id }),
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/ld+json'
            }
        }).then((response) => response.json()).then((response) => {
            if (response && response.message) {
                notify(response.message);
            }
            setLaunchReleaseLoading(false);
        }).catch((error) => {
            notify('Something went wrong. Request error occured!');
            // eslint-disable-next-line no-console
            console.log(error);
            setLaunchReleaseLoading(false);
        });
    };

    const exportReleaseMetadata = () => {
        setExportMetadataLoading(true);

        fetch(`${extractBaseUrl(global.RA_API_ENTRYPOINT)}${record.id}/excel/export`, {
            method: 'POST',
            body: JSON.stringify({ assets: selectedMovieRow ? [selectedMovieRow.resourceId] : [] }),
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `metadata_export_${recordUUID}_${Date.now()}.xlsx`);
                document.body.appendChild(link);
                link.click();
                setExportMetadataLoading(false);
            })
            .catch((error) => {
                setExportMetadataLoading(false);
                return Promise.reject({ Error: 'Something Went Wrong', error });
            });
    };

    const exportReleasePages = () => {
        setExportMetadataLoading(true);
        fetch(`${extractBaseUrl(global.RA_API_ENTRYPOINT)}${record.id}/json/export`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `page_export_${recordUUID}.json`);
                document.body.appendChild(link);
                link.click();
                setExportMetadataLoading(false);
            })
            .catch((error) => {
                setExportMetadataLoading(false);
                return Promise.reject({ Error: 'Something Went Wrong', error });
            });
    };

    let moviesCount = 0;
    let seriesTitles = [];
    let seriesCount = 0;
    let seasonsCount = 0;
    let totalAssetsCount = 0;

    if (record && record.assets) {
        moviesCount = record.assets.filter((asset) => asset.type === 'movie').length;
        seasonsCount = record.assets.filter((asset) => asset.type === 'season').length;
        record.assets.filter((asset) => asset.type === 'season').forEach((asset) => {
            seriesTitles.push(asset.series.name);
        });
        seriesTitles = [...new Set(seriesTitles)];
        seriesCount = seriesTitles.length;
        totalAssetsCount = moviesCount + seriesCount;
    }

    // eslint-disable-next-line consistent-return
    const save = async (values) => {
        try {
            await mutate(
                {
                    type: 'update',
                    resource: props.resource,
                    payload: {
                        data: {
                            ...values,
                            makeWorkflowTransition: ''
                        },
                        id: values.id
                    }
                },
                { returnPromise: true }
            );
            notify('Release was edited!');
            refresh();
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(error);

            if (!submissionErrors) {
                notify(`Error: ${error.message}`);
                return {};
            }

            return submissionErrors;
        }
    };

    return (
        <Edit
            {...props}
            hasShow={false}
            mutationMode="pessimistic"
        >
            <SimpleForm save={save}>
                <div className="flex-inline-form">
                    <TextInput style={{ width: '100%' }} label="Release Name" source="name" variant="standard" />
                    <DateInput
                        style={{ width: '100%' }}
                        label="Start SSD Exchange"
                        source="ssdExchangeFrom"
                        range="from"
                        inputProps={{
                            min: moment().format('YYYY-MM-DD')
                        }}
                        onChange={(e) => setRelease({
                            ...release,
                            ssdExchangeFrom: e.target.value
                        })}
                        variant="standard"
                    />
                    <DateInput
                        style={{ width: '100%' }}
                        label="Valid From"
                        source="validFrom"
                        range="from"
                        inputProps={{
                            min: release && release.ssdExchangeFrom ? moment(release.ssdExchangeFrom).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
                        }}
                        onChange={(e) => setRelease({
                            ...release,
                            validFrom: e.target.value
                        })}
                        variant="standard"
                    />
                    <DateInput
                        style={{ width: '100%' }}
                        label="Valid To"
                        source="validTo"
                        range="to"
                        inputProps={{
                            min: release && release.validFrom ? moment(release.validFrom).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
                        }}
                        onChange={(e) => setRelease({
                            ...release,
                            validTo: e.target.value
                        })}
                        variant="standard"
                    />
                    <TextInput style={{ width: '100%' }} label="Comment" source="comment" variant="standard" />
                    <div className="flex-inline-form">
                        <CustomColorCodeField source="colorCode" defaultValue="#fff" />
                    </div>
                    <div className="">
                        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                            <Button
                                color="primary"
                                onClick={launchRelease}
                                startIcon={launchReleaseLoading ? <CircularProgress size={20} /> : <Launch />}
                            >
                                Launch
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => setReleaseLaunchStatusListDialogOpen(true)}
                                startIcon={launchReleaseLoading ? <CircularProgress size={20} /> : <AccessTime />}
                            >
                                Status
                            </Button>
                        </ButtonGroup>
                        <LaunchReleaseStatusListDialog releaseId={recordUUID} onClose={() => setReleaseLaunchStatusListDialogOpen(false)} open={releaseLaunchStatusListDialogOpen} />
                    </div>
                </div>
                <AppBar color="default" position="static" style={{ width: '100%' }}>
                    <Tabs indicatorColor="secondary" value={activeTab} onChange={(event, value) => setActiveTab(value)} aria-label="simple tabs example">
                        <Tab label={`Assets (${totalAssetsCount})`} {...a11yProps(0)} />
                        <Tab label={`Pages (${pageList.length})`} {...a11yProps(1)} />
                        <Tab label="Workflow" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <div style={{ display: activeTab === 0 ? 'inline' : 'none' }}>
                    <div className="release-dashboard">
                        <div className="release-dashboard__titles-container">
                            <Paper className="release-dashboard__titles-container__titles" style={{ fontSize: '12px !important' }}>
                                <span className="section-header"><VideoLibrary fontSize="small" /> Titles - {`${moviesCount} Movies, ${seriesCount} Series, ${seasonsCount} Seasons`}</span>
                                <div className="release-dashboard__titles-container__titles__filters-container">
                                    <TextField
                                        value={movieSearchKeyword}
                                        onChange={(e) => { setMovieSearchKeyword(e.target.value); }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <FormControl style={{ fontSize: '12px !important' }}>
                                        <Select
                                            style={{ fontSize: '12px !important' }}
                                            value={selectedCategory}
                                            onChange={(e) => setSelectedCategory(e.target.value)}
                                        >
                                            <MenuItem selected style={{ fontSize: '12px !important' }} value="*">All categories</MenuItem>
                                            {categories.map((category) => <MenuItem style={{ fontSize: '12px !important' }} value={category}>{category}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <FormControlLabel
                                        style={{ fontSize: '12px !important' }}
                                        control={(
                                            <Checkbox
                                                checked={backupContentCheckbox}
                                                onChange={(e) => setBackupContentCheckbox(e.target.checked)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        )}
                                        label="Backup content"
                                    />
                                </div>
                                <div className="">
                                    <RDB_MovieList clearSelected={clearSelectedMovie} release={record} loading={loading} data={record?.assets || []} onSelectedRowsChange={onSelectedMovieRowChange} filters={{ movieSearchKeyword, backupContentCheckbox, selectedCategory }} />
                                </div>
                            </Paper>
                            <div className="release-dashboard__titles-container__seasons-episodes-container">
                                <Paper className="release-dashboard__titles-container__seasons-episodes-container__seasons">
                                    <span className="section-header"><List fontSize="small" /> Seasons</span>
                                    <div className="">
                                        <RDB_SeasonList
                                            data={seasonList}
                                            onSelectedRowsChange={onSelectedSeasonRowChange}
                                            clearSelected={clearSelectedSeason}
                                        />
                                    </div>
                                </Paper>
                                <Paper className="release-dashboard__titles-container__seasons-episodes-container__episodes">
                                    <span className="section-header"><OndemandVideo fontSize="small" /> Episodes</span>
                                    <div className="">
                                        <RDB_EpisodesList data={episodesList} loading={episodesListLoading} />
                                    </div>
                                </Paper>
                            </div>
                            <div className="release-dashboard__titles-container__metadata-display-dates-container">
                                <Paper className="release-dashboard__titles-container__metadata-display-dates-container__metadata-container">
                                    <span className="section-header"><Storage fontSize="small" /> Metadata (excel)</span>
                                    <div className="release-dashboard__titles-container__metadata-display-dates-container__metadata-container__import-controls-container">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            startIcon={<CloudUpload />}
                                            onClick={() => { setMetadataFileUploadDialog(true); setImportType('excel'); }}
                                        >
                                            Import
                                        </Button>
                                        <Button
                                            disabled={exportExcelMetadataLoading}
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            startIcon={exportExcelMetadataLoading ? <CircularProgress size={20} /> : <CloudDownload />}
                                            onClick={exportReleaseMetadata}
                                        >
                                            Export ({selectedMovieRow ? 1 : 'all'})
                                        </Button>
                                    </div>
                                </Paper>
                                <Paper className="release-dashboard__titles-container__metadata-display-dates-container__display-dates-container">
                                    <span className="section-header"><DateRange fontSize="small" /> Display Dates</span>
                                    <div className="">
                                        <RDB_DisplayDates data={displayDatesList} />
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: activeTab === 1 ? 'inline' : 'none' }}>
                    <div className="release-dashboard">
                        <div className="release-dashboard__pages-container">
                            <Paper className="release-dashboard__pages-container__pages">
                                <span className="section-header"><WebAsset fontSize="small" /> Pages</span>
                                <div className="release-dashboard__pages-container__pages__filters-container">
                                    <TextField
                                        value={pageSearchKeyword}
                                        id="input-with-icon-textfield"
                                        onChange={(e) => { setPageSearchKeyword(e.target.value); }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                                <div className="">
                                    <RDB_PageList
                                        loading={pagesListLoading}
                                        clearSelected={clearSelectedPage}
                                        data={pageList || []}
                                        onSelectedRowsChange={onSelectedPageRowChange}
                                        filters={{ pageSearchKeyword }}
                                    />
                                </div>
                            </Paper>
                            <Paper className="release-dashboard__pages-container__pages-content">
                                <span className="section-header"><Web fontSize="small" /> Content</span>
                                <RDB_PageContentList data={pageContentList} onSelectedRowChange={onSelectedContentRowChange} selectedContent={selectedContentRow} />
                            </Paper>
                            <Paper className="release-dashboard__pages-container__pages-assets">
                                <div className="release-dashboard__pages-container__pages-assets__import-controls-container">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        startIcon={<CloudUpload />}
                                        onClick={() => { setMetadataFileUploadDialog(true); setImportType('json'); }}
                                    >
                                        JSON Import
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        startIcon={<CloudDownload />}
                                        onClick={exportReleasePages}
                                    >
                                        JSON Export
                                    </Button>
                                </div>
                                <span className="section-header"><VideoLibrary fontSize="small" /> Assets</span>
                                <RDB_PageContentMovieList
                                    data={pageContentAssetsList}
                                    selectedContentRow={pageContentList.find((content) => content.id === selectedContentRow)}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>
                <div style={{ display: activeTab === 2 ? 'inline' : 'none' }}>
                    {record && (
                        <WorkflowTimeline
                            nextTransitionAction={updateTransition}
                            resource="releases"
                            record={record}
                            currentStatus={record.workflowStatus}
                            allStatuses={releaseStatuses}
                            lastStatus={record.nextWorkflowTransitions.length === 0}
                        />
                    )}
                </div>
                <MetadataFileUploadDiaglog
                    importType={importType}
                    releaseId={record?.id}
                    open={metadataFileUploadDiaglog}
                    onClose={(callback) => {
                        setMetadataFileUploadDialog(false);
                        if (callback) callback();
                    }}
                    handleResetAndRefreshPage={() => {
                        refresh();
                    }}
                />

            </SimpleForm >
        </Edit >
    );
}

export default ReleaseDashboard;

const CustomColorCodeField = (props) => {
    const { input } = useInput(props);
    return (
        <div style={{ display: 'flex' }}>
            {releaseColors.map((color) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30px', marginRight: '10px' }}>
                        <div style={{ textAlign: 'center' }}>
                            <button
                                onClick={() => input.onChange(color.code)}
                                style={{ width: '30px', height: '30px', marginRight: '10px', border: 'none', backgroundColor: color.code }}
                                type="button"
                            />
                        </div>
                        <div style={{ textAlign: 'center' }}>{input?.value === color.code ? <ArrowDropUp /> : ''}</div>
                    </div>
                );
            })}
        </div>
    );
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const LaunchReleaseStatusListDialog = ({ open, onClose, releaseId }) => {
    const [data, setData] = useState([]);
    const [requestLoading, setRequestLoading] = useState(false);
    const notify = useNotify();

    const getLaunchReleaseStatusList = () => {
        setRequestLoading(true);
        fetch(`${global.RA_API_ENTRYPOINT}/generate_release_workers?release=${releaseId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/ld+json'
            }
        }).then((response) => response.json()).then((response) => {
            setRequestLoading(false);
            if (Array.isArray(response) && response.length > 0) {
                setData(response);
            }
        }).catch((error) => {
            setRequestLoading(false);
            notify('Something went wrong. Request error occured!');
            // eslint-disable-next-line no-console
            console.log(error);
        });
    };

    useEffect(() => {
        if (open) {
            getLaunchReleaseStatusList();
        }
    }, [open]);

    const columns = [
        {
            name: 'Release ID',
            selector: (row) => row.releaseId,
            sortable: true,
            minWidth: '300px'
        },
        {
            name: 'Status message',
            selector: (row) => row.statusMessage,
            sortable: true
        },
        {
            name: 'Launch start date',
            selector: (row) => row.startImportAt,
            sortable: true
        },
        {
            name: 'Status code',
            selector: (row) => row.statusId,
            sortable: true,
            maxWidth: '50px'
        }
    ];

    const ExpandedComponent = (props) => {
        const [expandedData, setExpandedData] = useState(null);

        const getLaunchReleaseStatusById = (id) => {
            fetch(`${global.RA_API_ENTRYPOINT}/generate_release_workers/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/ld+json'
                }
            }).then((response) => response.json()).then((response) => {
                setExpandedData(response);
            }).catch((error) => {
                notify('Something went wrong. Request error occured!');
                // eslint-disable-next-line no-console
                console.log(error);
            });
        };

        useEffect(() => {
            getLaunchReleaseStatusById(props.data.id);
        }, []);
        return <div style={{ paddingLeft: '20px' }}>{expandedData ? <pre>{JSON.stringify(expandedData, null, 2)}</pre> : 'loading...'}</div>;
    };

    return (
        <Dialog onClose={onClose} aria-labelledby="launch-release-status-list" open={open} maxWidth="md" fullWidth>
            <DialogTitle id="launch-release-status-list">
                <span>Release launch list</span>
                <IconButton aria-label="close" style={{ position: 'absolute', right: '2px', top: 0 }} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DataTable
                dense
                columns={columns}
                data={data}
                pagination
                progressPending={requestLoading}
                progressComponent={<div className="loader-container"><CircularProgress /></div>}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
            />
        </Dialog>
    );
};
