import React from 'react';
import InputGuesser from '@api-platform/admin/lib/InputGuesser';
import EditGuesser from '@api-platform/admin/lib/EditGuesser';

const TrainunitsEdit = (props) => {
    return (
        <EditGuesser {...props}>
            <InputGuesser source="status" />
            <InputGuesser source="tzn" />
            <InputGuesser source="br" />
            <InputGuesser source="uic" />
            <InputGuesser source="vzn" />
            <InputGuesser source="chassisId" />
            <InputGuesser source="comment" />
            <InputGuesser source="fahn" />
        </EditGuesser>
    );
};

export default TrainunitsEdit;
