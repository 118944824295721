import React from 'react';
import {
    Create,
    SimpleForm,
    BooleanInput,
    TextInput,
    useRedirect,
    useNotify,
    useMutation,
    required
} from 'react-admin';
import {
    submitErrorsMutators,
    SubmitErrorsSpy
} from 'final-form-submit-errors';

const LocksCreate = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const [mutate] = useMutation();
    // eslint-disable-next-line consistent-return
    const save = async (values) => {
        try {
            await mutate(
                {
                    type: 'create',
                    resource: props.resource,
                    payload: {
                        data: {
                            ...values,
                            comment: values?.comment || ''
                        },
                        id: values.id
                    }
                },
                { returnPromise: true }
            );
            notify(`${props.resource} was created !`);
            redirect(`/${props.resource}`);
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(error);

            if (!submissionErrors) {
                notify(`Error: ${error.message}`);
                return {};
            }

            return submissionErrors;
        }
    };
    return (
        <Create {...props} mutationMode="pessimistic">
            <SimpleForm
                save={save}
                mutators={{
                    ...submitErrorsMutators
                }}
            >
                <BooleanInput label="Status" source="status" />
                <TextInput source="name" validate={[required()]}/>
                <TextInput source="comment" />
                <TextInput source="code" validate={[required()]}/>
                <SubmitErrorsSpy />
            </SimpleForm>
        </Create>
    );
};

export default LocksCreate;
