import { getData } from 'country-list';

export const getCountries = async (onSuccess, onError) => {
    try {
        const countriesList = await getData();
        onSuccess(countriesList);
    } catch (e) {
        onError(e);
    }
};
