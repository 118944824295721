import React from 'react';
import CreateGuesser from '@api-platform/admin/lib/CreateGuesser';
import InputGuesser from '@api-platform/admin/lib/InputGuesser';

const TrainunitsCreate = (props) => {
    return (
        <CreateGuesser {...props}>
            <InputGuesser source="status" />
            <InputGuesser source="tzn" />
            <InputGuesser source="br" />
            <InputGuesser source="uic" />
            <InputGuesser source="vzn" />
            <InputGuesser source="chassisId" />
            <InputGuesser source="comment" />
            <InputGuesser source="fahn" />
        </CreateGuesser>
    );
};

export default TrainunitsCreate;
