import React from 'react';
import {
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    SelectInput,
    BooleanInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

function VideoInputElements() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <BooleanInput label="Enabled" source="enabled" />
            <TextInput label="Name" source="name" />
            <RichTextInput source="shortText" />
            <TextInput label="Costs" source="costs" />
            <ReferenceInput
                label="Category"
                source="category[@id]"
                reference="categories"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="Small Image"
                source="smallImage[@id]"
                reference="media"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Media" source="media[@id]" reference="media">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput
                label="Tags"
                reference="tags"
                source="tags"
                fullWidth
                allowEmpty
            >
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceInput
                label="Licences"
                source="licence"
                reference="licences"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="Content Provider"
                source="contentProvider"
                reference="content_providers"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </div>
    );
}

export default VideoInputElements;
