import React from 'react';
import {
    Edit,
    SimpleForm,
    BooleanInput,
    TextInput,
    SaveButton,
    DeleteButton,
    Toolbar,
    useEditController,
    useTranslate,
    required,
    useRefresh,
    useNotify,
    useMutation
} from 'react-admin';
import {
    submitErrorsMutators,
    SubmitErrorsSpy
} from 'final-form-submit-errors';

const CustomEditToolbar = (props) => {
    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SaveButton />
            <DeleteButton disabled={props.isLockInUse} />
        </Toolbar>
    );
};

const LocksEdit = (props) => {
    const translate = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();
    const [mutate] = useMutation();
    const { record } = useEditController(props);
    const isLockInUse = !!record?.case?.length;

    // eslint-disable-next-line consistent-return
    const save = async (values) => {
        try {
            await mutate(
                {
                    type: 'update',
                    resource: props.resource,
                    payload: {
                        data: {
                            ...values,
                            comment: values?.comment || ''
                        },
                        id: values.id
                    }
                },
                { returnPromise: true }
            );
            notify('Lock was edited!');
            refresh();
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(error);

            if (!submissionErrors) {
                notify(`Error: ${error.message}`);
                return {};
            }

            return submissionErrors;
        }
    };

    return (
        <Edit {...props}>
            <SimpleForm
                toolbar={<CustomEditToolbar isLockInUse={isLockInUse}/>}
                mutators={{
                    ...submitErrorsMutators
                }}
                save={save}
            >
                <BooleanInput label="Status" source="status" />
                <TextInput source="name" validate={[required()]}/>
                <TextInput source="comment" />
                <TextInput source="code" validate={[required()]}/>
                <SubmitErrorsSpy />
                {isLockInUse ? <p style={{ width: '100%', fontSize: '10px', color: 'red' }}>*{translate('lockDeletionValidation')}</p> : null}
            </SimpleForm>
        </Edit>
    );
};

export default LocksEdit;
