/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, useLocale, usePermissions } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { userHasAccessToMenuFirstLevel, userHasAccessToMenuSecondLevel } from './utils/user';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    link: {
        width: '100%'
    }
}));

const Menu = ({ onMenuClick, logout }) => {
    const classes = useStyles();
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    let resources = useSelector(getResources);
    const [collapsedLvls, setCollapsedLvls] = useState([]);
    const { permissions: userRoles } = usePermissions();
    const levels = [];
    const locale = useLocale();
    resources.forEach((res) => {
        if (!levels.find((lvl) => lvl.level === res.options.level.level)) {
            levels.push(res.options.level);
        }
    });

    const delivery = resources.filter((el) => el.options.level.level === 'delivery')
    .reduce((acc, curr) => {
        acc[curr.name] = curr;
        return acc;
    }, {});
    resources = resources.filter((el) => el.options.level.level !== 'delivery');
    resources.push(delivery.delivery_cases);
    resources.push(delivery.s_s_ds);
    resources.push(delivery.locks);
    resources.push(delivery.trainunits);

    const portal = resources.filter((el) => el?.options.level.level === 'portal')
    .reduce((acc, curr) => {
        acc[curr.name] = curr;
        return acc;
    }, {});
    resources = resources.filter((el) => el?.options.level.level !== 'portal');
    resources.push(portal.releases);
    resources.push(portal.portal_settings);
    resources.push(portal.avails);
    resources.push(portal.series);
    resources.push(portal.seasons);
    resources.push(portal.movies);
    resources.push(portal.pages);

    const toggleCollapseLvl = (lvl) => {
        const safeCopyArray = [...collapsedLvls];

        if (collapsedLvls.includes(lvl)) {
            safeCopyArray.splice(
                collapsedLvls.findIndex((el) => el === lvl),
                1
            );
            setCollapsedLvls([...safeCopyArray]);
        } else {
            setCollapsedLvls([...collapsedLvls, lvl]);
        }
    };

    return (
        <div>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
            >
                {levels
                    .filter((lvl) => userHasAccessToMenuFirstLevel(userRoles || [], lvl.level))
                    .map((lvl, idx) => {

                    return (
                        <React.Fragment key={lvl.level}>
                            <ListItem
                                button
                                onClick={() => toggleCollapseLvl(idx)}
                            >
                                <ListItemIcon>
                                    <lvl.icon />
                                </ListItemIcon>
                                <ListItemText primary={lvl.label[locale] || lvl.label} />
                                {collapsedLvls.includes(idx) ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItem>
                            <Collapse
                                in={open && collapsedLvls.includes(idx)}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    {resources
                                        .filter((res) => userHasAccessToMenuSecondLevel(userRoles || [], lvl.level, res.options.name))
                                        .filter(
                                            (res) =>
                                                res.options.level.level ===
                                                lvl.level
                                        )
                                        .map((res) => {
                                            return (
                                                <ListItem
                                                    key={res.name}
                                                    button
                                                    className={classes.nested}
                                                >
                                                    <MenuItemLink
                                                        className={classes.link}
                                                        to={`/${res.name}`}
                                                        primaryText={
                                                            (res.options &&
                                                                res.options
                                                                    .label) ||
                                                            res.name
                                                        }
                                                        leftIcon={
                                                            res.options.icon ? (
                                                                <res.options.icon />
                                                            ) : (
                                                                <DefaultIcon />
                                                            )
                                                        }
                                                        onClick={onMenuClick}
                                                        sidebarIsOpen={open}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    );
                })}
            </List>
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
