/* eslint-disable no-console */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import {
    Edit,
    useMutation,
    useNotify,
    useRedirect,
    TabbedForm,
    FormTab,
    useEditController,
    List,
    Datagrid,
    TextField,
    useDataProvider,
    useRefresh,
    ReferenceField,
    useTranslate,
    usePermissions,
    Toolbar,
    SaveButton,
    DeleteButton
} from 'react-admin';
import { useLocale } from 'ra-core';
import { submitErrorsMutators, SubmitErrorsSpy } from 'final-form-submit-errors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { HorizontalSplit } from '@material-ui/icons';
import moment from 'moment';
import {
    Backdrop,
    CircularProgress,
    DialogTitle,
    Dialog,
    TextField as MuiTextField,
    Button,
    Link
} from '@material-ui/core';
import CasesInputElements from './CasesInputElements';
import BasicActionsToolbar from '../BasicActionsToolbar';
import WorkflowTimeline from '../WorkflowTimeline';
import { caseStatuses } from '../../constants';
import '../entities.scss';
import {
    isDeleteCasesAccessible,
    isDetailsTabAccessibleInEditCases,
    isLogsTabAccessibleInEditCases,
    isSaveCasesAccessible,
    isNextTransitionButtonVisible
} from '../../utils/user';
import { cleanSSDScannerPrefix } from '../../utils/helpers';

const LinkField = ({ record, source }) => {
    const translate = useTranslate();
    return <Link href={`#/s_s_ds/${encodeURIComponent(record[source])}`}>{translate('link')}</Link>;
};

const CustomEditToolbar = (props) => {
    const { permissions: userRoles } = usePermissions();

    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
            {isSaveCasesAccessible(userRoles) && <SaveButton />}
            {isDeleteCasesAccessible(userRoles) && <DeleteButton />}
        </Toolbar>
    );
};

const CasesEdit = (props) => {
    const dataProvider = useDataProvider();
    const [showSSDDialog, setShowSSDDialog] = React.useState(false);
    const [inputSSDNumber, setInputSSDNumber] = React.useState('');
    const [requestInProgress, setRequestInProgress] = React.useState(false);
    const { record } = useEditController(props);
    const [mutate] = useMutation();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const translate = useTranslate();
    const locale = useLocale();
    const { permissions: userRoles } = usePermissions();

    const { onSuccess, onFailure, id, transform, basePath, actions } = props;
    let validWorkflowForSSDSManipulation = false;
    if (record) {
        validWorkflowForSSDSManipulation = ['OnEndavaLocation', 'OnDBLocation', 'CheckIn'].includes(
            record.workflowStatus
        );
    }
    const additionalProps = { mutationMode: 'pessimistic' };

    if (onSuccess) {
        additionalProps.onSuccess = onSuccess;
    }
    if (onFailure) {
        additionalProps.onFailure = onFailure;
    }
    if (id) {
        additionalProps.id = id;
    }
    if (transform) {
        additionalProps.transform = transform;
    }
    if (basePath) {
        additionalProps.basePath = basePath;
    }
    if (actions !== null) {
        additionalProps.actions = <BasicActionsToolbar showList {...props} />;
    }

    // eslint-disable-next-line consistent-return
    const save = async (values) => {
        const body = { ...values };
        delete body.logs;
        try {
            const response = await mutate(
                {
                    type: 'update',
                    resource: props.resource,
                    payload: {
                        id: values['@id'],
                        data: body
                    }
                },
                { returnPromise: true }
            );
            if (onSuccess) {
                onSuccess(response);
            } else {
                notify(`${props.resource} was updated !`);
                redirect(`/${props.resource}`);
            }
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(error);

            if (!submissionErrors) {
                notify(`Error: ${error.message}`);
                return {};
            }

            return submissionErrors;
        }
    };

    const getSSDBySSDNumber = (serialNumber) => {
        return dataProvider.getList('s_s_ds', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name' },
            filter: { serialNumber }
        });
    };

    const asociateSSDWithCase = () => {
        setRequestInProgress(true);
        getSSDBySSDNumber(inputSSDNumber).then(({ data }) => {
            if (Array.isArray(data) && data.length > 0) {
                dataProvider
                    .update('s_s_ds', {
                        id: data[0]['@id'],
                        data: { deliveryCase: record['@id'] }
                    })
                    .then(() => {
                        setShowSSDDialog(false);
                        notify('SSD succesfully associated with the current case!');
                        refresh();
                        setRequestInProgress(false);
                    });
            } else {
                notify(`No SSD found with Serial Number: ${inputSSDNumber}!`);
                setRequestInProgress(false);
            }
        });
    };

    const RemoveSSDFromCaseBtn = (p) => {
        const onClickHandler = () => {
            dataProvider
                .update('s_s_ds', {
                    id: p.record['@id'],
                    data: { deliveryCase: null }
                })
                .then(() => {
                    notify('SSD removed from the current case!');
                    refresh();
                });
        };

        return (
            <Button
                disabled={!validWorkflowForSSDSManipulation}
                variant="contained"
                color="primary"
                onClick={onClickHandler}
            >
                {translate('ra.action.remove')}
            </Button>
        );
    };

    const ResetSSDFromCaseBtn = (p) => {
        const onClickHandler = () => {
            fetch(`${window.RA_API_ENTRYPOINT}/s_s_ds/reset`, {
                method: 'POST',
                body: JSON.stringify({ serialNumber: p.record.serialNumber }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then(() => {
                    notify('SSD succefully reseted!');
                    refresh();
                })
                .catch((error) => {
                    console.log(error);
                    notify('SSD could not be reseted!');
                });
        };

        return (
            <Button variant="contained" color="primary" onClick={onClickHandler}>
                {translate('ra.action.reset')}
            </Button>
        );
    };

    const updateTransition = () => {
        dataProvider
            .update('delivery_cases', {
                id: record['@id'],
                data: {
                    makeWorkflowTransition: record.nextWorkflowTransitions[0]
                }
            })
            .then(() => {
                notify('Transition changed!');
                refresh();
            });
    };

    // <TabbedForm /> is a bit stupid :( because if the first child is null it displays the next child
    // but the content of the next child only appears after clicking it.
    // So basically the TabbedForm does not filter out null children and this causes ux issues.
    // For this reason I had to move FormTabs out of the return and handle them in the method body
    const formTabs = [
        <FormTab label="details">
            <CasesInputElements />
        </FormTab>,
        <FormTab label="SSDs">
            <div style={{ width: '100%' }}>
                {record && (
                    <>
                        {validWorkflowForSSDSManipulation && (
                            <div
                                style={{
                                    textAlign: 'right',
                                    marginBottom: '10px'
                                }}
                            >
                                <Button variant="contained" color="primary" onClick={() => setShowSSDDialog(true)}>
                                    + {translate('ssds.addSSD')}
                                </Button>
                            </div>
                        )}
                        <List
                            empty={false}
                            {...props}
                            mutationMode="pessimistic"
                            resource="s_s_ds"
                            filter={{ deliveryCase: record['@id'] }}
                            actions={false}
                            bulkActionButtons={false}
                        >
                            <Datagrid>
                                <TextField source="serialNumber" label="serialNumber" />
                                <ReferenceField
                                    link={false}
                                    label="Case"
                                    source="deliveryCase"
                                    reference="delivery_cases"
                                >
                                    <TextField source="serialNumber" />
                                </ReferenceField>
                                <RemoveSSDFromCaseBtn label="Remove" />
                                {isSaveCasesAccessible(userRoles) && <ResetSSDFromCaseBtn label="Reset" />}
                                <LinkField source="id" label="source" />
                            </Datagrid>
                        </List>
                    </>
                )}
                <Dialog
                    onClose={() => setShowSSDDialog(false)}
                    aria-labelledby="simple-dialog-title"
                    open={showSSDDialog}
                >
                    <DialogTitle color="primary" id="simple-dialog-title" className="upload-dialog-title">
                        <HorizontalSplit
                            style={{
                                verticalAlign: 'middle',
                                marginRight: '10px'
                            }}
                        />
                        {translate('cases.associateSSD')}
                    </DialogTitle>
                    <div
                        style={{
                            width: '500px',
                            padding: '30px',
                            position: 'relative'
                        }}
                    >
                        <div className="flex-inline flex-left">
                            <MuiTextField
                                label={`SSD ${translate('number')}`}
                                variant="outlined"
                                size="small"
                                value={inputSSDNumber}
                                onChange={(e) => setInputSSDNumber(cleanSSDScannerPrefix(e.target.value))}
                            />
                        </div>
                        <div className="flex-inline flex-right" style={{ paddingTop: '10px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => asociateSSDWithCase()}
                                disabled={!inputSSDNumber}
                            >
                                OK
                            </Button>
                            <Button onClick={() => setShowSSDDialog(false)} variant="contained">
                                {translate('ra.action.cancel')}
                            </Button>
                        </div>
                    </div>
                    {requestInProgress && (
                        <Backdrop className="spinning-loader-overlay" open>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )}
                </Dialog>
            </div>
        </FormTab>,
        <FormTab label="Logs">
            <TableContainer component={Paper} style={{ width: '50%' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>{translate('date')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>{translate('createdBy')}</strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>{translate('entry')}</strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>{translate('category')}</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {record && record.logs && record.logs.length > 0 ? (
                            record.logs.map((log) => (
                                <TableRow key={log.createdAt}>
                                    <TableCell>{moment(log.createdAt).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
                                    <TableCell>{log?.createdBy || 'N/A'}</TableCell>
                                    <TableCell align="right">{log?.entry || 'N/A'}</TableCell>
                                    <TableCell align="right">{log?.category || 'N/A'}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell align="center" colSpan={3}>
                                    {translate('noLogs')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </FormTab>,
        <FormTab label="Status">
            <div style={{ width: '100%' }}>
                {record && (
                    <WorkflowTimeline
                        resource="delivery_cases"
                        record={record}
                        currentStatus={record.workflowStatus}
                        allStatuses={caseStatuses.map((el) => ({
                            ...el,
                            description: el.description[locale],
                            status: el.status[locale]
                        }))}
                        lastStatus={record.nextWorkflowTransitions.length === 0}
                        nextTransitionAction={isNextTransitionButtonVisible(userRoles) ? updateTransition : null}
                    />
                )}
            </div>
        </FormTab>
    ];

    // these two conditions should always be equal according to requirements
    if (!isDetailsTabAccessibleInEditCases(userRoles) && !isLogsTabAccessibleInEditCases(userRoles)) {
        // remove tabs if they are not supported by roles
        formTabs.splice(0, 1);
        formTabs.splice(1, 1);
    }

    return (
        <Edit {...props} {...additionalProps}>
            <TabbedForm
                save={save}
                mutators={{
                    ...submitErrorsMutators
                }}
                sanitizeEmptyValues={false}
                toolbar={<CustomEditToolbar />}
            >
                {formTabs}
                <SubmitErrorsSpy />
            </TabbedForm>
        </Edit>
    );
};

export default CasesEdit;
