import { Introspecter } from '@api-platform/admin';
import Edit from './SeriesEdit';
import List from './SeriesList';

export default {
    list: List,
    edit: (props) => <Introspecter component={Edit} {...props} />,
    resource: 'series',
    name: 'series'
};
