(function(){var g={}; var _ = _ || {};
var f=function(window){/*

 Copyright 2016 Google Inc.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
var nC="BANDWIDTH",oC="CLOSED-CAPTIONS",pC="EXT-X-BYTERANGE",qC="EXT-X-ENDLIST",rC="EXT-X-MEDIA-SEQUENCE",sC="EXT-X-PLAYLIST-TYPE",tC="EXT-X-STREAM-INF",uC="EXT-X-TARGETDURATION",vC="GROUP-ID",wC="Guessing audio-only",xC="SUBTITLES",yC="URI",zC=function(a,b){b=void 0===b?0:b;return b+10<=a.length&&73===a[b]&&68===a[b+1]&&51===a[b+2]&&255>a[b+3]&&255>a[b+4]&&128>a[b+6]&&128>a[b+7]&&128>a[b+8]&&128>a[b+9]?!0:!1},AC=function(a,b,c,d){this.g=a;this.type=b;this.a=c;this.f=d||null},BC=function(a,b,c,d){this.id=
a;this.name=b;this.a=c;this.value=void 0===d?null:d},CC=function(a,b){this.name=a;this.value=b},DC=function(a,b,c){return(a=a.getAttribute(b))?a.value:c||null},EC=function(a,b){var c=a.getAttribute(b);if(!c)throw new _.J(_.K,4,4023,b);return c.value},FC=function(a,b){this.f=b;this.a=a},GC=function(){this.a=0},MC=function(a,b,c){b=_.Uf(b);b=b.replace(/\r\n|\r(?=[^\n]|$)/gm,"\n").trim();var d=b.split(/\n+/m);if(!/^#EXTM3U($|[ \t\n])/m.test(d[0]))throw new _.J(_.K,4,4015);b=0;for(var e=!0,f=_.t(d),g=
f.next();!g.done;g=f.next())if(g=g.value,g=g.trim(),!g.length||HC.xe(g)||e)e=!1;else if(g=IC(a,g),--a.a,JC.includes(g.name)){b=1;break}else g.name==tC&&(e=!0);f=[];e=!0;g=_.t(_.ng(d));for(var k=g.next();!k.done;k=g.next()){var l=k.value;k=l.te;var m=l.item;l=l.next;m=m.trim();if(!m.length||HC.xe(m)||e)e=!1;else{m=IC(a,m);if(KC.includes(m.name)){if(1!=b)throw new _.J(_.K,4,4017);d=d.splice(k,d.length-k);a=LC(a,c,d,f);return new AC(c,b,f,a)}f.push(m);m.name==tC&&(m.a.push(new CC(yC,l)),e=!0)}}return new AC(c,
b,f)},LC=function(a,b,c,d){var e=[],f=[];c.forEach(function(g){/^(#EXT)/.test(g)?(g=IC(a,g),JC.includes(g.name)?d.push(g):f.push(g)):HC.xe(g)||(g=HC.td(b,g.trim()),e.push(new FC(g,f)),f=[])});return e},IC=function(a,b){var c=a.a++,d=b.match(/^#(EXT[^:]*)(?::(.*))?$/);if(!d)throw new _.J(_.K,4,4016,{invalidTag:b});var e=d[1],f=d[2];d=[];var g;if(f){f=new _.Vg(f);var k;(k=_.Wg(f,/^([^,=]+)(?:,|$)/g))&&(g=k[1]);for(var l=/([^=]+)=(?:"([^"]*)"|([^",]*))(?:,|$)/g;k=_.Wg(f,l);)d.push(new CC(k[1],k[2]||
k[3]))}return new BC(c,e,d,g)},PC=function(){var a=this;this.h=this.j=null;this.ga=1;this.w=new Map;this.aa=new Set;this.f=new Map;this.g=null;this.B="";this.K=new GC;this.Z=0;this.A=new _.Xi(function(){NC(a)});this.m=OC;this.I=null;this.O=0;this.T=Infinity;this.C=new _.Hk;this.X=[];this.J=new Map;this.G=!1;this.o=new Map;this.L=null;this.a=new _.D("clpp.hls.HlsParser");this.D=new Map},UC=function(a,b){var c,d,e,f,g,k,l,m,n,p,q;return _.z(function(r){if(1==r.a)return c=HC,d=QC,e=b.Rg,_.v(r,RC(a,e),
2);if(3!=r.a){f=r.f;g=MC(a.K,f.data,f.uri);if(1!=g.type)throw new _.J(_.K,4,4017);l=(k=c.Vb(g.a,rC))?Number(k.value):0;m=b.stream;return _.v(r,SC(a,b.Vd,g,l,m.type,m.mimeType,m.codecs,m.keyInfo),3)}n=r.f;b.fb.replace(n);p=n[n.length-1];if(q=c.Vb(g.a,qC))TC(a,d.VOD),a.g.Nb(p.endTime);_.x(r)})},YC=function(a,b){var c,d,e,f,g,k,l,m,n,p,q,r,w,y,F,C,E,G,M,T,S;return _.z(function(va){if(1==va.a){c=MC(a.K,b,a.B);if(1===c.type){a.D.set(a.B,c);d=c.f[0].a;e=_.dm;f=new _.pk(d);g=f.pa.split(".").pop();k=VC[e.AUDIO][g];
l=VC[e.VIDEO][g];m=[];l&&m.push("avc1.42E01E");k&&m.push("mp4a.40.2");var Ea=a.K,db=a.B,Xa=m.join(","),Ma=[],Ya=[];Ya.push(new CC(nC,"0"));Ya.push(new CC(yC,db));Xa.length&&Ya.push(new CC("CODECS",Xa));Ma.push(new BC(Ea.a++,tC,Ya));c=new AC(db,0,Ma)}return _.v(va,WC(a,c.a),2)}n=va.f;if(!a.j)throw new _.J(_.K,7,7001);a.j.filterAllPeriods([n]);p=Infinity;q=0;r=Infinity;w=_.t(a.f.values());for(y=w.next();!y.done;y=w.next())F=y.value,p=Math.min(p,F.Fe),q=Math.max(q,F.Fe),F.stream.type!=_.le&&(r=Math.min(r,
F.duration));a.m!=OC?(a.g=new _.lp(0,3*a.O,!0),a.g.f=!1):(a.g=new _.lp(null,0,!0),a.g.f=!0);XC(a);if(a.m!=OC){a.Z=a.T;C=QC;a.m!==C.LIVE||isNaN(a.h.availabilityWindowOverride)||(a.g.ic=a.h.availabilityWindowOverride);for(E=0;95443.7176888889<=q;)E+=95443.7176888889,q-=95443.7176888889;if(E)for(a.a.debug("Offsetting live streams by",E,"to compensate for rollover"),G=_.t(a.f.values()),y=G.next();!y.done;y=G.next())M=y.value,95443.7176888889>M.Fe?(a.a.debug("Offset applied to",M.stream.type),M.stream.presentationTimeOffset=
-E,M.fb.offset(E)):a.a.debug("Offset NOT applied to",M.stream.type)}else for(a.g.Nb(r),a.g.offset(-p),T=_.t(a.f.values()),y=T.next();!y.done;y=T.next())S=y.value,S.stream.presentationTimeOffset=p,S.fb.offset(-p),_.tp(S.fb,r);a.I={presentationTimeline:a.g,periods:[n],offlineSessionIds:[],minBufferTime:0};_.x(va)})},WC=function(a,b){var c,d,e,f,g;return _.z(function(k){switch(k.a){case 1:c=HC;d=c.Tc(b,"EXT-X-MEDIA");e=c.Tc(b,tC);for(var l=_.t(e),m=l.next();!m.done;m=l.next()){var n=m.value,p=DC(n,"AUDIO");
m=DC(n,"VIDEO");var q=DC(n,xC);n=ZC(a,n);if(q){var r=$C(_.le,n);a.o.set(q,r);_.Bg(n,r)}p&&(q=aD(_.Wb,n),a.o.set(p,q));m&&(p=aD(_.Be,n),a.o.set(m,p))}return _.v(k,bD(a,d),2);case 2:l=HC.Of(d,oC);l=_.t(l);for(m=l.next();!m.done;m=l.next())m=m.value,p=DC(m,"LANGUAGE")||_.qe,p=_.no(p),q=EC(m,vC),m=EC(m,"INSTREAM-ID"),a.J.get(q)||a.J.set(q,new Map),a.J.get(q).set(m,p);return _.v(k,cD(a,e),3);case 3:return f=k.f,_.v(k,dD(a,d),4);case 4:return g=k.f,k["return"]({startTime:0,variants:f,textStreams:g,thumbStreams:[]})}})},
dD=function(a,b){var c,d,e,f,g,k,l,m,n,p,q,r;return _.z(function(w){if(1==w.a)return c=HC.Of(b,xC),d=c.map(function(y){var F,C;return _.z(function(E){if(1==E.a)return _.hf(E,2),_.v(E,eD(a,y),4);if(2!=E.a)return F=E.f,E["return"](F.stream);C=_.kf(E);throw C;})}),_.v(w,Promise.all(d),2);e=w.f;f=_.t(c);for(g=f.next();!g.done;g=f.next())if(k=g.value,l=EC(k,vC),m=a.o.get(l))if(n=a.w.get(l))for(p=_.t(n),q=p.next();!q.done;q=p.next())r=q.value,r.stream.codecs=m;return w["return"](e.filter(function(y){return y}))})},
bD=function(a,b){var c;return _.z(function(d){if(1==d.a)return b=b.filter(function(e){var f=DC(e,yC)||"";return DC(e,"TYPE")!==xC&&""!==f}),b.length?_.v(d,eD(a,b[0]),2):d.H(2);c=b.slice(1).map(function(e){return eD(a,e)});return _.v(d,Promise.all(c),0)})},cD=function(a,b){var c,d,e,f,g,k,l,m,n,p,q;return _.z(function(r){if(1==r.a){c=_.Ol;d=function(w){var y,F,C,E,G,M,T;return _.z(function(S){return 1==S.a?(y=DC(w,"FRAME-RATE"),F=Number(EC(w,nC)),C=DC(w,"RESOLUTION"),E=_.t(C?C.split("x"):[null,null]),
G=E.next().value,M=E.next().value,_.v(S,fD(a,w,C,y),2)):(T=S.f)?S["return"](gD(a,T.audio,T.video,F,G,M,y)):S["return"]([])})};e=0;f=Infinity;for(g=0;g<b.length;++g)k=Number(EC(b[g],nC)),k<f&&(f=k,e=g);l=b.splice(e,1).pop();return _.v(r,d(l),2)}if(3!=r.a)return m=r.f,n=b.map(d),_.v(r,Promise.all(n),3);p=r.f;p.splice(e,0,m);q=p.reduce(c.je,[]);return r["return"](q.filter(c.cb))})},fD=function(a,b,c,d){var e,f,g,k,l,m,n,p,q,r,w,y,F,C;return _.z(function(E){if(1==E.a)return e=_.dm,f=ZC(a,b),g=DC(b,"AUDIO"),
k=DC(b,"VIDEO"),m=(l=g||k)&&a.w.has(l)?a.w.get(l):[],n={audio:g?m:[],video:k?m:[]},a.a.debug("Guessing stream type for",b.toString()),q=!1,r=EC(b,yC),w=n.audio.find(function(G){return G.Vd===r}),y=$C(e.VIDEO,f),F=c||d||y,1!==f.length||F?!m.length&&1<f.length?(a.a.debug("Guessing multiplexed audio+video"),p=e.VIDEO,f=[f.join(",")]):n.audio.length&&w?(a.a.debug(wC),p=e.AUDIO,q=!0):n.video.length?(a.a.debug(wC),p=e.AUDIO):(a.a.debug("Guessing video-only"),p=e.VIDEO):(p=e.AUDIO,a.a.debug(wC)),q?E.H(2):
_.v(E,hD(a,b,f,p),3);2!=E.a&&(C=E.f);if(C)n[C.stream.type]=[C];else if(null===C)return a.a.debug("streamInfo is null"),E["return"](null);iD(n);return E["return"](n)})},iD=function(a){a=_.t(a.audio.concat(a.video));for(var b=a.next();!b.done;b=a.next()){b=b.value;var c=b.stream.codecs.split(",");c=c.filter(function(d){return"mp4a.40.34"!=d});b.stream.codecs=c.join(",")}},gD=function(a,b,c,d,e,f,g){c.forEach(function(F){if(F=F.stream)F.width=Number(e)||void 0,F.height=Number(f)||void 0,F.frameRate=
Number(g)||void 0});b.length||(b=[null]);c.length||(c=[null]);var k=[];b=_.t(b);for(var l=b.next();!l.done;l=b.next()){l=l.value;for(var m=_.t(c),n=m.next();!n.done;n=m.next()){var p=n.value;n=l?l.stream:null;var q=p?p.stream:null,r=l?l.drmInfos:null,w=p?p.drmInfos:null;p=(p?p.Vd:"")+" - "+(l?l.Vd:"");var y=void 0;if(n&&q)if(r.length&&w.length?0<_.Nl(r,w).length:1)y=_.Nl(r,w);else{a.a.warn("Incompatible DRM info in HLS variant.  Skipping.");continue}else n?y=r:q&&(y=w);a.aa.has(p)?a.a.debug("Skipping variant which only differs in text streams."):
(n=jD(a,n,q,d,y),k.push(n),a.aa.add(p))}}return k},jD=function(a,b,c,d,e){return{id:a.ga++,language:b?b.language:_.qe,primary:!!b&&b.primary||!!c&&c.primary,audio:b,video:c,bandwidth:d,drmInfos:e,allowedByApplication:!0,allowedByKeySystem:!0}},eD=function(a,b){var c,d,e,f,g,k,l,m,n,p,q,r,w;return _.z(function(y){if(1==y.a){c=EC(b,vC);d="";e=EC(b,"TYPE").toLowerCase();f=_.dm;e==_.je&&(e=f.TEXT);e!==f.TEXT&&c&&a.o.has(c)&&(d=a.o.get(c));g=EC(b,yC);if(a.f.has(g))return y["return"](a.f.get(g));k=_.no(DC(b,
"LANGUAGE",_.qe));l=DC(b,"NAME");m=b.getAttribute("DEFAULT");n=b.getAttribute("AUTOSELECT");p=DC(b,"CHANNELS");q=e==_.Wb?p?parseInt(p.split("/")[0],10):null:null;r=!!m&&!!n;return _.v(y,kD(a,g,d,e,k,r,l,q,null),2)}w=y.f;if(null==w)return y["return"](null);a.w.has(c)?a.w.get(c).push(w):a.w.set(c,[w]);if(a.f.has(g))return y["return"](a.f.get(g));a.f.set(g,w);return y["return"](w)})},ZC=function(a,b){var c=DC(b,"CODECS","avc1.42E01E,mp4a.40.2").split(/\s*,\s*/),d=new Set,e=[];c=_.t(c);for(var f=c.next();!f.done;f=
c.next()){f=f.value;var g=_.Qg(f)[0];d.has(g)?a.a.debug("Ignoring duplicate codec"):(e.push(f),d.add(g))}return e},hD=function(a,b,c,d){var e,f,g,k,l,m;return _.z(function(n){if(1==n.a){e=_.dm;f=EC(b,yC);if(a.f.has(f))return n["return"](a.f.get(f));g=DC(b,oC);k=null;d==e.VIDEO&&g&&"NONE"!=g&&(k=a.J.get(g));l=aD(d,c);return _.v(n,kD(a,f,l,d,_.qe,!1,null,null,k),2)}m=n.f;if(null==m)return n["return"](null);if(a.f.has(f))return n["return"](a.f.get(f));a.f.set(f,m);return n["return"](m)})},kD=function(a,
b,c,d,e,f,g,k,l){var m,n,p,q,r,w,y,F,C,E,G,M,T,S,va,Ea,db,Xa,Ma,Ya,pb,Lb,eb,kb,xb,uc,Pa,Mb,Vb,zg;return _.z(function(Rc){switch(Rc.a){case 1:m=HC;n=m.td(a.B,b);if(a.m==OC&&a.D.has(n)){p=a.D.get(n);a.D["delete"](n);Rc.H(2);break}return _.v(Rc,RC(a,n),3);case 3:r=Rc.f,n=r.uri,p=MC(a.K,r.data,n);case 2:if(1!=p.type)throw new _.J(_.K,4,4017);w=[];p.f.forEach(function(Ph){Ph=m.Tc(Ph.f,"EXT-X-KEY");w.push.apply(w,Ph)});y=!1;F=[];C=null;E=_.t(w);for(G=E.next();!G.done;G=E.next())M=G.value,T=EC(M,"METHOD"),
S=DC(M,"KEYFORMAT","identity"),"NONE"!=T&&("AES-128"==T?(va=DC(M,"IV",""),Ea=m.td(a.B,EC(M,yC)),q={method:"AES-128",url:Ea,iv:va?_.$k((va||"0x").slice(2)):null},a.G=!0):(y=!0,(Xa=(db=lD[S])?db(M):null)?(Xa.keyIds.length&&(C=Xa.keyIds[0]),F.push(Xa)):a.a.warn("Unsupported HLS KEYFORMAT",S)));if(y&&!F.length&&!a.G)throw new _.J(_.K,4,4026);mD(a,p);return _.v(Rc,nD(a,d,c,p),4);case 4:return Ya=Ma=Rc.f,Lb=(pb=m.Vb(p.a,rC))?Number(pb.value):0,_.v(Rc,SC(a,b,p,Lb,d,Ya,c,q),5);case 5:return eb=Rc.f,kb=eb[0].startTime,
xb=eb[eb.length-1].endTime,uc=xb-kb,Pa=new _.rp(eb),Mb=oD(p,q),Vb=void 0,d==_.le&&(Vb=_.ie),zg={id:a.ga++,originalId:g,createSegmentIndex:Promise.resolve.bind(Promise),findSegmentPosition:Pa.find.bind(Pa),getSegmentReference:Pa.get.bind(Pa),adjustSegmentDurations:Pa.xc.bind(Pa),getDuration:Pa.getDuration.bind(Pa),initSegmentReference:Mb,presentationTimeOffset:0,mimeType:Ya,codecs:c,kind:Vb,encrypted:y,keyId:C,language:e,label:g,type:d,primary:f,trickModeVideo:null,emsgSchemeIdUris:null,frameRate:void 0,
width:void 0,height:void 0,bandwidth:void 0,roles:[],channelsCount:k,closedCaptions:l,keyInfo:q},Rc["return"]({stream:zg,fb:Pa,drmInfos:F,Vd:b,Rg:n,Fe:kb,cl:xb,duration:uc})}})},mD=function(a,b){var c=HC.Vb(b.a,sC),d=HC.Vb(b.a,qC);d=c&&"VOD"==c.value||d;c=c&&"EVENT"==c.value&&!d;c=!d&&!c;d?TC(a,OC):(c?TC(a,pD):TC(a,qD),d=rD(b.a,uC),d=Number(d.value),a.O=Math.max(d,a.O),a.T=Math.min(d,a.T))},oD=function(a,b){var c=HC.Tc(a.a,"EXT-X-MAP");if(!c.length)return null;if(1<c.length)throw new _.J(_.K,4,4020);
var d=c[0];c=EC(d,yC);var e=HC.td(a.g,c);c=0;var f=null;if(d=DC(d,"BYTERANGE"))c=d.split("@"),d=Number(c[0]),c=Number(c[1]),f=c+d-1;return new _.jp(function(){return[e]},c,f,b)},sD=function(a,b,c,d,e){var f=b.f,g=b.a;b=rD(f,"EXTINF").value.split(",");b=d+Number(b[0]);var k=0,l=null;if(f=HC.Vb(f,pC))k=f.value.split("@"),f=Number(k[0]),k=k[1]?Number(k[1]):a.sa+1,l=k+f-1;e&&!e.iv&&(e.iv=_.cl(new Uint8Array(12),new Uint8Array([c>>24&255,c>>16&255,c>>8&255,c&255])));return new _.kp(c,d,b,function(){return[g]},
k,l,e)},XC=function(a){a.g&&(a.X.forEach(function(b){_.np(a.g,b,0)}),a.X=[])},SC=function(a,b,c,d,e,f,g,k){var l,m,n,p,q,r,w,y,F,C,E,G;return _.z(function(M){if(1==M.a)return l=c.f,m=[],n=l[0].a,p=sD(null,l[0],d,0,k||null),q=oD(c,k||null),_.v(M,tD(a,b,q,p,e,f,g),2);r=M.f;a.a.debug("First segment",n.split("/").pop(),"starts at",r);for(w=0;w<l.length;++w)y=l[w],F=m[m.length-1],C=0==w?r:F.endTime,E=d+w,G=sD(F,y,E,C,k||null),m.push(G);a.X.push(m);XC(a);return M["return"](m)})},tD=function(a,b,c,d,e,f,
g){var k;return _.z(function(l){if(1==l.a){if(null!==a.L)return a.a.debug("Reusing cached start time for",e),l.H(2);k=a;return _.v(l,uD(a,b,c,d,f,g),3)}2!=l.a&&(k.L=l.f,a.a.debug("Fetched start time for",e));return l["return"](a.L)})},wD=function(a,b){var c,d,e,f,g,k,l;return _.z(function(m){switch(m.a){case 1:return c=b.a(),d=_.oo(b.Oa(),b.Ia,b.Ia+2048-1,a.h.attemptParameters),d.type=_.Op,e=_.oo(b.Oa(),b.Ia,b.sa,a.h.attemptParameters),e.type=_.Op,c&&(e.keyInfo=c),_.hf(m,2),f=a.G?e:d,_.v(m,vD(a,f),
4);case 4:return g=m.f,m["return"](g);case 2:k=_.kf(m);if(7001==k.code||a.G)throw k;a.a.warn("Unable to fetch a partial HLS segment! Falling back to a full segment request, which is expensive!  Your server should support Range requests and CORS preflights.",d.uris[0]);return _.v(m,vD(a,e),5);case 5:return l=m.f,m["return"](l)}})},uD=function(a,b,c,d,e,f){var g,k,l,m,n,p,q,r,w,y,F;return _.z(function(C){switch(C.a){case 1:if(a.I){g=a.f.get(b);k=g.fb;if(l=k.get(d.position))return a.a.debug("Found segment start time in previous manifest"),
C["return"](l.startTime);a.a.debug("Unable to find segment start time in previous manifest!")}a.a.debug("Fetching segment to find start time");if("audio/mpeg"==e)return C["return"](0);if(e!=_.Xb){C.H(2);break}return _.v(C,wD(a,d),3);case 3:return m=C.f,n=_.I(m.data),zC(n)?C["return"](yD(n)):C["return"](DD(m.data));case 2:if(e!=_.De&&e!=_.$b){C.H(4);break}p=[wD(a,d)];c&&p.push(wD(a,c));return _.v(C,Promise.all(p),5);case 5:return q=C.f,r=q[0],w=q[1]||q[0],C["return"](ID(a,r.data,w.data));case 4:if(e!=
_.Ce){C.H(6);break}return _.v(C,wD(a,d),7);case 7:return y=C.f,C["return"](DD(y.data));case 6:if(e===_.Zb||e===_.Yb)return C["return"](0);F=JD;if(!F(e,f))return C["return"](0);throw new _.J(_.K,4,4030);}})},ID=function(a,b,c){var d=0;(new _.bi).P("moov",_.ci).P("trak",_.ci).P("mdia",_.ci).da("mdhd",function(g){g.F.skip(0==g.version?8:16);d=_.Xh(g.F);g.wb.stop()}).parse(c,!0);if(!d)throw a.a.error("Unable to find timescale in init segment!"),new _.J(_.K,4,4030);var e=0,f=!1;(new _.bi).P("moof",_.ci).P("traf",
_.ci).da("tfdt",function(g){e=(0==g.version?_.Xh(g.F):_.Zh(g.F))/d;f=!0;g.wb.stop()}).parse(b,!0);if(!f)throw new _.J(_.K,4,4030);return e},DD=function(a){function b(){d.seek(e+188);f=_.Vh(d);71!=f&&(d.seek(e+192),f=_.Vh(d));71!=f&&(d.seek(e+204),f=_.Vh(d));71!=f&&c();_.ai(d)}function c(){throw new _.J(_.K,4,4030);}for(var d=new _.Sh(new DataView(a),!1),e=0,f=0;;)if(e=d.getPosition(),f=_.Vh(d),71!=f&&c(),a=_.Wh(d),8191===(a&8191))b();else if(a&16384)if(a=(_.Vh(d)&48)>>4,0!=a&&2!=a||c(),3==a&&(a=_.Vh(d),
d.skip(a)),1!=_.Xh(d)>>8)b();else{d.skip(3);a=_.Vh(d)>>6;0!=a&&1!=a||c();0==_.Vh(d)&&c();a=_.Vh(d);var g=_.Wh(d),k=_.Wh(d);return(1073741824*((a&14)>>1)+((g&65534)<<14|(k&65534)>>1))/9E4}else b()},yD=function(a){function b(l,m,n){for(var p="";m<n;m++)p+="%"+("00"+l[m].toString(16)).slice(-2);return p}function c(l){return l[0]<<21|l[1]<<14|l[2]<<7|l[3]}function d(){throw new _.J(_.K,4,4030);}zC(a)||d();var e=10;a[5]&64&&(e=e+4+c(a.subarray(10,14)));do{var f=c(a.subarray(e+4,e+8));1>f&&d();var g=String.fromCharCode(a[e],
a[e+1],a[e+2],a[e+3]);if("PRIV"===g){g=a.subarray(e+10,e+f+10);for(var k=0;k<g.byteLength;k++)if(0===g[k]){if("com.apple.streaming.transportStreamTimestamp"===unescape(b(g,0,k)))return a=g.subarray(k+1),f=(a[3]&1)<<30|a[4]<<22|a[5]<<14|a[6]<<6|a[7]>>>2,f*=4,f+=a[7]&3,Math.round(f/9E4);break}}zC(a,e)||d();e+=10;e+=f}while(e<a.byteLength);return d()},$C=function(a,b){for(var c=KD[a],d=0;d<c.length;d++)for(var e=0;e<b.length;e++)if(c[d].test(b[e].trim()))return b[e].trim();return a==_.le?"":null},aD=
function(a,b){if(1==b.length)return b[0];var c=$C(a,b);if(null!=c)return c;throw new _.J(_.K,4,4025,{codecList:b});},nD=function(a,b,c,d){var e,f,g,k,l,m,n,p,q;return _.z(function(r){if(1==r.a){e=_.dm;f=d.f[0].a;g=new _.pk(f);k=g.pa.split(".").pop();l=VC[b];if(m=l[k])return r["return"](m);if(b==e.TEXT)return c&&"vtt"!=c?r["return"](_.Ob):r["return"](_.me);n=_.$i([f],a.h.attemptParameters);n.method="HEAD";n.type=_.Op;return _.v(r,vD(a,n),2)}p=r.f;q=p.headers["content-type"];if(!q)throw new _.J(_.K,
4,4021,k);return r["return"](q.split(";")[0])})},rD=function(a,b){var c=HC.Vb(a,b);if(!c)throw new _.J(_.K,4,4024,{missingTag:b});return c},RC=function(a,b){var c=_.$i([b],a.h.attemptParameters);c.type=1;return vD(a,c)},JD=function(a,b){return MediaSource.isTypeSupported((a.split("/")[0]||"")+'/mp4;codecs\x3d"'+b+'"')},NC=function(a){var b,c,d;_.z(function(e){if(1==e.a){a.a.info(_.ib);if(!a.j)return e["return"]();_.hf(e,2);return _.v(e,a.update(),4)}if(2!=e.a)return b=a.Z,a.A.$(b),_.jf(e,0);c=_.kf(e);
a.j&&(a.j.onError(c),d=c,1===d.a&&a.A.$(.1));_.x(e)})},TC=function(a,b){a.m=b;a.g&&(a.g.f=a.m==OC);a.m!=OC||a.A.stop()},vD=function(a,b){if(!a.C)throw new _.J(_.K,7,7001);var c=a.j.networkingEngine.fetch(b);_.lo(a.C,c);return c.promise},LD=function(){};BC.prototype.toString=function(){function a(d){return d.name+"\x3d"+(isNaN(Number(d.value))?'"'+d.value+'"':d.value)}var b="#"+this.name,c=this.a?this.a.map(a):[];this.value&&c.unshift(this.value);0<c.length&&(b+=":"+c.join(","));return b};
BC.prototype.getAttribute=function(a){var b=this.a.filter(function(c){return c.name==a});return b.length?b[0]:null};var HC={Tc:function(a,b){return a.filter(function(c){return c.name==b})},Of:function(a,b){return a.filter(function(c){return EC(c,"TYPE")===b})},Vb:function(a,b){var c=HC.Tc(a,b);return c.length?c[0]:null},Yk:function(a,b,c){return a.filter(function(d){var e=d.getAttribute("TYPE");d=d.getAttribute(vC);return e.value==b&&d.value==c})},td:function(a,b){return _.Pl([a],[b])[0]},xe:function(a){return/^#(?!EXT)/m.test(a)}};var JC=[uC,rC,"EXT-X-DISCONTINUITY-SEQUENCE",sC,"EXT-X-MAP","EXT-X-I-FRAMES-ONLY",qC],KC=["EXTINF",pC,"EXT-X-DISCONTINUITY","EXT-X-PROGRAM-DATE-TIME","EXT-X-KEY","EXT-X-DATERANGE"];_.h=PC.prototype;_.h.configure=function(a){this.h=a};_.h.start=function(a,b){var c=this,d,e;return _.z(function(f){if(1==f.a)return c.j=b,_.v(f,RC(c,a),2);if(3!=f.a)return d=f.f,c.B=d.uri,_.v(f,YC(c,d.data),3);e=c.Z;0<e&&c.A.$(e);return f["return"](c.I)})};_.h.stop=function(){this.A&&(this.A.stop(),this.A=null);var a=[];this.C&&(a.push(this.C.destroy()),this.C=null);this.h=this.j=null;this.aa.clear();this.f.clear();this.D.clear();this.I=null;this.w.clear();this.o.clear();return Promise.all(a)};
_.h.update=function(){var a=this,b,c,d;return _.z(function(e){if(1==e.a){if(a.m==OC)return e["return"]();b=[];a.L=null;c=Array.from(a.f.values());return c.length?_.v(e,UC(a,c[0]),2):e.H(2)}for(d=1;d<c.length;++d)b.push(UC(a,c[d]));return _.v(e,Promise.all(b),0)})};_.h.onExpirationUpdated=function(){};
var KD={audio:[/^vorbis$/,/^opus$/,/^flac$/,/^mp4a/,/^[ae]c-3$/],video:[/^avc/,/^hev/,/^hvc/,/^vp0?[89]/,/^av1$/],text:[/^vtt$/,/^wvtt/,/^stpp/]},VC={audio:{mp4:_.$b,m4s:_.$b,m4i:_.$b,m4a:_.$b,cmfa:_.$b,ts:_.Ce,aac:_.Xb,ec3:_.Zb,ac3:_.Yb},video:{mp4:_.De,m4s:_.De,m4i:_.De,m4v:_.De,cmfv:_.De,ts:_.Ce},text:{mp4:_.Ob,m4s:_.Ob,m4i:_.Ob,cmft:_.Ob,vtt:_.me,ttml:_.Pb}},lD={"urn:uuid:edef8ba9-79d6-4ace-a3c8-27dcd51d21ed":function(a){var b=EC(a,"METHOD"),c=["SAMPLE-AES","SAMPLE-AES-CTR","SAMPLE-AES-CENC"];
if(!c.includes(b))return _.Of("Widevine in HLS is only supported with [",c.join(", "),"], not",b),null;b=EC(a,yC);b=_.qj.parse(b);b=_.I(b.data);b=_.Ql(_.Bc,[{initDataType:_.nc,initData:b}]);if(a=DC(a,"KEYID"))b.keyIds=[a.substr(2).toLowerCase()];return b}},OC="VOD",qD="EVENT",pD="LIVE",QC={VOD:OC,Hk:qD,LIVE:pD};_.u(LD,_.qm);LD.prototype.ab=function(){_.Wp.Qd("m3u8",PC);_.Wp.$c(_.wm,PC);_.Wp.$c(_.Qb,PC)};LD.prototype.Va=function(){_.Wp.Se("m3u8");_.Wp.Rd(_.wm);_.Wp.Rd(_.Qb)};LD.prototype.id=function(){return"clpp.component.hls"};_.A("clpp.hls.HlsComponent",LD);};
if(typeof(module)!="undefined"&&module.exports){var x=require("./cl.core.min.js");_ = x._;(f.call(g,this));module.exports=g;}
else if (typeof(define)!="undefined"&&define.amd) {define(["./cl.core.min"], function(c){_=c._;(f.call(g,this));return g;});}
else{_=this.clpp._;(f.call(g,this));}
})();
