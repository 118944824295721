import React, { useState } from 'react';
import { useTranslate, useNotify } from 'react-admin';
import { Button, Dialog, DialogTitle, DialogContent, Paper, TextField, DialogActions } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import authProvider from '../HydraAuthProvider';

const sendButtonStyle = {
    backgroundColor: '#ec0016',
    color: '#fff'
};

const supportButtonStyle = {
    ...sendButtonStyle,
    margin: '0 10px'
};

const closeButtonStyle = {
    backgroundColor: '#e0e0e0',
    color: '#999'
};

const noSidePadding = {
    paddingLeft: '0',
    paddingRight: '0'
};

const dialogTitleStyles = {
    ...noSidePadding,
    textAlign: 'center',
    backgroundColor: '#ec0016',
    color: '#fff',
    fontFamily: 'system-ui',
    fontWeight: '700'
};


const Contact = () => {
    const translate = useTranslate();
    const [isContactFormOpen, setIsContactFormOpen] = useState(false);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const notify = useNotify();
    const userEmail = authProvider.getCurrentUserEmail();
    const isSendMessageDisabled = !subject || !message;

    const setContactFormVisibility = () => {
        if (!isContactFormOpen) {
            setIsContactFormOpen(true);
        } else {
            setIsContactFormOpen(false);
        }
    };

    const clearAndCloseContactForm = () => {
        setSubject('');
        setMessage('');
        setIsContactFormOpen(false);
    };

    const sendEmail = () => {
        fetch(`${global.RA_API_ENTRYPOINT}/mailer/send-support`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ email: userEmail, subject, body: message })
        })
        .then((response) => response.json())
        .then((data) => {
            clearAndCloseContactForm();
            notify(data.message);
        })
        .catch((error) => {
            notify(error);
        });
    };

    return (
        <>
            <Button
                size="small"
                style={supportButtonStyle}
                startIcon={<HelpOutline />}
                onClick={setContactFormVisibility}
            >
                Support
            </Button>
            {isContactFormOpen && (
            <Dialog
                open
                fullScreen
                onClose={setContactFormVisibility}
            >
                <Paper>
                    <DialogTitle
                        disableTypography
                        style={dialogTitleStyles}
                    >
                        Support
                    </DialogTitle>
                    <DialogContent>
                        <p>{translate('support.infoText')}</p>
                        <TextField
                            disabled
                            margin="normal"
                            required
                            fullWidth
                            variant="outlined"
                            label="Email"
                            defaultValue={userEmail}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="outlined"
                            label={translate('support.subject')}
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            multiline
                            rows={5}
                            variant="outlined"
                            label={translate('support.message')}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <DialogActions style={noSidePadding}>
                            <Button
                                disabled={isSendMessageDisabled}
                                style={isSendMessageDisabled ? null : sendButtonStyle}
                                variant="contained"
                                onClick={sendEmail}
                            >
                                {translate('support.send')}
                            </Button>
                            <Button
                                style={closeButtonStyle}
                                variant="contained"
                                onClick={() => {
                                    setSubject('');
                                    setMessage('');
                                    setContactFormVisibility();
                                }}
                            >
                                {translate('ra.action.close')}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Paper>
            </Dialog>
)}
        </>
    );
};

Contact.propTypes = {

};

export default Contact;
