import { Filter, SelectInput, TextInput } from 'react-admin';
import { availsSeriesWorkflowStatuses } from '../../constants';

const ReleaseListFilters = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" />
        <SelectInput
            label="Workflow Status"
            source="workflowStatus"
            choices={availsSeriesWorkflowStatuses.map((status) => ({
                id: status,
                name: status
            }))}
        />
    </Filter>
);

export default ReleaseListFilters;
