import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
 Button, FormControl, InputLabel, MenuItem, Select, TextField, Paper, Checkbox, Switch, FormControlLabel
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { EditButton, useNotify, useRedirect } from 'react-admin';
import { iso6392 as isoLanguages } from 'iso-639-2';
import { seriesServices } from './services';
import './SeriesEdit.scss';
import AvailsDialog from '../../components/AvailsDialog';

const StyledTableCell = withStyles(() => ({
    head: {
    },
    body: {
        padding: 0,
        height: '50px'
    }
}))(TableCell);

const StyledTableRow = withStyles(() => ({
    root: {
        backgroundColor: '#EEE'
    }
}))(TableRow);

const cleanId = (id) => {
    const fragmentedId = id.split('/');
    return fragmentedId[fragmentedId.length - 1];
};

const genresList = ['Action',
    'Animation',
    'Arthouse',
    'Comedy',
    'Dokumentation',
    'Drama',
    'Infotainment',
    'Kids & Family',
    'Krimi & Thriller',
    'Romance',
    'Sci-Fi & Fantasy'
];

const FSKList = ['0', '6', '12', '16', '18'];

const seriesAPIData2UIData = (apiData) => {
    return {
        seriesTitle: apiData?.name || '',
        originalTitle: apiData?.originalTitle || '',
        description: apiData?.description || '',
        shortDescription: apiData?.shortDescription || '',
        ellipsis: apiData?.titleEllipsis || '',
        land: apiData?.land || '',
        languages: apiData?.languages || [],
        subtitles: apiData?.subtitles || [],
        genres: apiData?.genres || [],
        tags: apiData?.tags || [],
        year: apiData?.year || '',
        screenplay: apiData?.screenplay || '',
        direction: apiData?.direction || '',
        producer: apiData?.producer || '',
        cast: apiData?.cast || '',
        staticPictureSourceLink: apiData?.staticImageLink || '',
        seasons: apiData?.seasons || [],
        fsk: apiData?.fsk || '',
        revenue: apiData?.revenue,
        workflowStatus: apiData?.workflowStatus || ''
    };
};

const areArraysEqual = (array1, array2) => {
    // if needed provide a more complex implementation
    return JSON.stringify(array1) === JSON.stringify(array2);
};

const areValuesEqual = (value1, value2) => {
    if (Array.isArray(value1)) {
        return areArraysEqual(value1, value2);
    }
    return value1 === value2;
};

const checkIfFormIsChanged = (stateArray) => {
    return !!stateArray.find(([initialStateValue, currentStateValue]) => !areValuesEqual(initialStateValue, currentStateValue));
};

const SeriesEdit = ({ id, dataServices = seriesServices }) => {
    const [seriesTitle, setSeriesTitle] = useState('');
    const [initialSeriesTitle, setInitialSeriesTitle] = useState('');
    const [originalTitle, setOriginalTitle] = useState('');
    const [initialOriginalTitle, setInitialOriginalTitle] = useState('');
    const [seasons, setSeasons] = useState([]);
    const [description, setDescription] = useState('');
    const [initialDescription, setInitialDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [initialShortDescription, setInitialShortDescription] = useState('');
    const [ellipsis, setEllipsis] = useState('');
    const [initialEllipsis, setInitialEllipsis] = useState('');
    const [land, setLand] = useState([]);
    const [initialLand, setInitialLand] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [initialLanguages, setInitialLanguages] = useState([]);
    const [subtitles, setSubtitles] = useState([]);
    const [initialSubtitles, setInitialSubtitles] = useState([]);
    const [genres, setGenres] = useState([]);
    const [initialGenres, setInitialGenres] = useState([]);
    const [tags, setTags] = useState([]);
    const [initialTags, setInitialTags] = useState([]);
    const [fsk, setFSK] = useState('');
    const [initialFSK, setInitialFSK] = useState('');
    const [year, setYear] = useState(0);
    const [initialYear, setInitialYear] = useState(0);
    const [screenplay, setScreenplay] = useState([]);
    const [initialScreenplay, setInitialScreenplay] = useState([]);
    const [direction, setDirection] = useState([]);
    const [initialDirection, setInitialDirection] = useState([]);
    const [producer, setProducer] = useState([]); // ? - no corresponding key on avails/id response
    const [initialProducer, setInitialProducer] = useState([]); // ? - no corresponding key on avails/id response
    const [cast, setCast] = useState([]);
    const [initialCast, setInitialCast] = useState([]);
    const [staticPictureSourceLink, setStaticPictureSourceLink] = useState('');
    const [initialStaticPictureSourceLink, setInitialStaticPictureSourceLink] = useState('');
    // const [isAvailable, setIsAvailable] = useState(true); // removed from the requirements because BE does not support it
    // const [provider, setProvider] = useState(''); // removed from the requirements because BE does not support it
    // const [category, setCategory] = useState([]); // removed from the requirements because BE does not support it
    // const [videoSource, setVideoSource] = useState(''); // removed from the requirements because BE does not support it
    const [revenue, setRevenue] = useState('-');
    const [workflowStatus, setWorkflowStatus] = useState('Draft');
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [tagOptions, setTagOptions] = useState([]);
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isErrorFeedbackDialogOpen, setIsErrorFeedbackDialogOpen] = useState(false);
    const [submitSuccessResponseData, setSubmitSuccessResponseData] = useState();
    const [isWorkflowStatusUpdating, setIsWorkflowStatusUpdating] = useState(false);
    const [workflowStatusUpdateError, setWorkflowStatusUpdateError] = useState('');

    const notify = useNotify();
    const redirect = useRedirect();
    const decoratedIsoLanguages = isoLanguages.map((isoLang) => ({ ...isoLang, code: isoLang.iso6392B }));

    const onGetSeriesByIdSuccess = (series) => {
        setSeriesTitle(series.seriesTitle);
        setInitialSeriesTitle(series.seriesTitle);

        setOriginalTitle(series.originalTitle);
        setInitialOriginalTitle(series.originalTitle);

        setDescription(series.description);
        setInitialDescription(series.description);

        setShortDescription(series.shortDescription);
        setInitialShortDescription(series.shortDescription);

        setEllipsis(series.ellipsis);
        setInitialEllipsis(series.ellipsis);

        setLand(countriesOptions.filter(({ code }) => series.land.includes(code)));
        setInitialLand(countriesOptions.filter(({ code }) => series.land.includes(code)));

        setLanguages(decoratedIsoLanguages.filter(({ code }) => series.languages.includes(code)));
        setInitialLanguages(decoratedIsoLanguages.filter(({ code }) => series.languages.includes(code)));

        setSubtitles(decoratedIsoLanguages.filter(({ code }) => series.subtitles.includes(code)));
        setInitialSubtitles(decoratedIsoLanguages.filter(({ code }) => series.subtitles.includes(code)));

        setGenres(series.genres);
        setInitialGenres(series.genres);

        setTags(series.tags);
        setInitialTags(series.tags);

        setYear(parseInt(series.year, 10));
        setInitialYear(parseInt(series.year, 10));

        setScreenplay(series.screenplay);
        setInitialScreenplay(series.screenplay);

        setDirection(series.direction);
        setInitialDirection(series.direction);

        setProducer(series.producer);
        setInitialProducer(series.producer);

        setCast(series.cast);
        setInitialCast(series.cast);

        setStaticPictureSourceLink(series.staticPictureSourceLink);
        setInitialStaticPictureSourceLink(series.staticPictureSourceLink);

        setFSK(series.fsk);
        setInitialFSK(series.fsk);

        setRevenue(series.revenue);
        setWorkflowStatus(series.workflowStatus);
        setSeasons(series.seasons);

        setLoaded(true);
    };

    const onGetSeriesByIdError = () => {
        notify('Failed to load series data', 'error');
    };

    const onGetCountriesSuccess = (countries) => {
        setCountriesOptions(countries);
    };

    const onGetCountriesError = () => {
        notify('Failed to load countries', 'error');
    };

    const onGetTagsSuccess = (apiTags) => {
        setTagOptions(apiTags?.['hydra:member']);
    };

    const onGetTagsError = () => {
        notify('Failed to load tags', 'error');
    };

    const getStateAndInitialStatePairs = () => [
            [initialSeriesTitle, seriesTitle],
            [initialOriginalTitle, originalTitle],
            [initialDescription, description],
            [initialShortDescription, shortDescription],
            [initialEllipsis, ellipsis],
            [initialLand, land],
            [initialLanguages, languages],
            [initialSubtitles, subtitles],
            [initialGenres, genres],
            [initialTags, tags],
            [initialFSK, fsk],
            [initialYear, year],
            [initialScreenplay, screenplay],
            [initialDirection, direction],
            [initialProducer, producer],
            [initialCast, cast],
            [initialStaticPictureSourceLink, staticPictureSourceLink]
        ];

    const uiData2ApiData = () => {
        return {
           title: seriesTitle,
            originalTitle,
            description,
            shortdescription: shortDescription,
            ellipsis,
            tags: tags.map(({ name }) => name),
            genres,
            languages: languages.map(({ code }) => code),
            subtitles: subtitles.map(({ code }) => code),
            cast,
            land: land?.map(({ code }) => code),
            direction,
            producer,
            year,
            screenplay,
            parentalRatingNumber: fsk
        };
    };

    const { editSeriesById, editSeriesByIdThroughAnotherEndpoint, getCountries, getTags, getSeriesById } = dataServices;

    const onSubmitSuccess = (httpResponseData) => {
        setSubmitSuccessResponseData(httpResponseData);
        if (httpResponseData?.errors?.length) {
            setIsErrorFeedbackDialogOpen(true);
        } else {
            notify('Series edit success', 'info');
        }
        setIsFormEdited(false);
    };

    const onSubmitError = () => {
        notify('Series edit error', 'error');
    };

    const handleSubmit = () => {
        editSeriesById(cleanId(id), uiData2ApiData(), onSubmitSuccess, onSubmitError);
    };

    const handleCancel = () => {
        setSeriesTitle(initialSeriesTitle);
        setOriginalTitle(initialOriginalTitle);
        setDescription(initialDescription);
        setShortDescription(initialShortDescription);
        setEllipsis(initialEllipsis);
        setLand(initialLand);
        setLanguages(initialLanguages);
        setSubtitles(initialSubtitles);
        setGenres(initialGenres);
        setTags(initialTags);
        setYear(parseInt(initialYear, 10));
        setScreenplay(initialScreenplay);
        setDirection(initialDirection);
        setProducer(initialProducer);
        setCast(initialCast);
        setStaticPictureSourceLink(initialStaticPictureSourceLink);
        setFSK(initialFSK);

        setIsFormEdited(false);
    };

    const handleUpdateWorkflowsSuccess = ({ workflowStatus: newWorkflowStatus }) => {
        setWorkflowStatus(newWorkflowStatus);
        setWorkflowStatusUpdateError('');
        setIsWorkflowStatusUpdating(false);
    };

    const handleUpdateWorkflowsError = () => {
        setWorkflowStatusUpdateError('Failed to update workflow status. Please try again later.');
        setIsWorkflowStatusUpdating(false);
    };

    const handleWorkflowStatusChange = () => {
        setIsWorkflowStatusUpdating(true);
        const updates = { makeWorkflowTransition: workflowStatus === 'Published' ? 'unpublish' : 'publish' };
        editSeriesByIdThroughAnotherEndpoint(cleanId(id), updates, handleUpdateWorkflowsSuccess, handleUpdateWorkflowsError);
    };

    useEffect(() => {
        getCountries(onGetCountriesSuccess, onGetCountriesError);
        getTags(onGetTagsSuccess, onGetTagsError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (countriesOptions.length === 0) {
            return;
        }

        getSeriesById(cleanId(id), onGetSeriesByIdSuccess, onGetSeriesByIdError, seriesAPIData2UIData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countriesOptions]);

    useEffect(() => {
        setIsFormEdited(checkIfFormIsChanged(getStateAndInitialStatePairs()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        seriesTitle, originalTitle, description, shortDescription, ellipsis, land, languages, subtitles, genres, tags,
        fsk, year, screenplay, direction, producer, cast, staticPictureSourceLink,
        initialSeriesTitle, initialOriginalTitle, initialDescription, initialShortDescription, initialEllipsis,
        initialLand, initialLanguages, initialSubtitles, initialGenres, initialTags, initialFSK, initialYear,
        initialScreenplay, initialDirection, initialProducer, initialCast, initialStaticPictureSourceLink
    ]);

    return (
        <>
            {!loaded ? (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
    ) : (
        <Paper style={{ height: '100%', position: 'relative' }}>
            <div className="series-edit">
                <h3 style={{ gridArea: 'pageTitle' }}>EDIT SERIE</h3>
                <TextField
                    onChange={({ target: { value } }) => {
                                setSeriesTitle(value);
                            }}
                    label="Series Title"
                    value={seriesTitle}
                    style={{ gridArea: 'title1' }}
                />
                <TextField
                    onChange={({ target: { value } }) => {
                                setOriginalTitle(value);
                            }}
                    label="Original Title"
                    value={originalTitle}
                    style={{ gridArea: 'title2' }}
                />
                <div style={{ gridArea: 'revenue' }}>
                    <div className="title">Revenue/&#36;</div>
                    <div>{revenue}</div>
                </div>
                <div style={{ gridArea: 'workflow' }}>
                    <FormControlLabel
                        control={<Switch size="small" disabled={isWorkflowStatusUpdating} checked={workflowStatus === 'Published'} onChange={handleWorkflowStatusChange} />}
                        label={`Workflow status (${workflowStatus})`}
                    />
                    { workflowStatusUpdateError && <FormHelperText error>{workflowStatusUpdateError}</FormHelperText> }
                    { isWorkflowStatusUpdating && <LinearProgress classes={{ root: 'series-edit__progress' }} /> }
                </div>
                <TextField
                    onChange={({ target: { value } }) => {
                                setDescription(value);
                            }}
                    style={{ gridArea: 'description1' }}
                    label="Description"
                    value={description}
                    multiline
                    variant="outlined"
                />
                <TextField
                    onChange={({ target: { value } }) => {
                                setShortDescription(value);
                            }}
                    style={{ gridArea: 'description2' }}
                    label="Short Description"
                    value={shortDescription}
                    multiline
                    variant="outlined"
                />
                <TextField
                    onChange={({ target: { value } }) => {
                                setEllipsis(value);
                            }}
                    style={{ gridArea: 'description3' }}
                    label="Ellipsis"
                    value={ellipsis}
                />
                <div style={{ gridArea: 'seasons' }}>
                    <div>Seasons</div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">No.</StyledTableCell>
                                    <StyledTableCell align="center">Title</StyledTableCell>
                                    <StyledTableCell align="center">Season title</StyledTableCell>
                                    <StyledTableCell align="center">Year</StyledTableCell>
                                    <StyledTableCell align="center">FSK</StyledTableCell>
                                    <StyledTableCell align="center">Subtitle</StyledTableCell>
                                    <StyledTableCell align="center">Orig.</StyledTableCell>
                                    <StyledTableCell align="center">Other Language</StyledTableCell>
                                    <StyledTableCell align="center">Workflow/Status</StyledTableCell>
                                    <StyledTableCell align="center">Revenue</StyledTableCell>
                                    <StyledTableCell> </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {seasons.map((season) => (
                                    <StyledTableRow key={season.id}>
                                        <StyledTableCell align="center">{season.seasonNumber}</StyledTableCell>
                                        <StyledTableCell align="center">{season.seriesTitle}</StyledTableCell>
                                        <StyledTableCell align="center">{season.name}</StyledTableCell>
                                        <StyledTableCell align="center">{season.year}</StyledTableCell>
                                        <StyledTableCell align="center">{season.fsk}</StyledTableCell>
                                        <StyledTableCell align="center">{season.subtitles.join(', ')}</StyledTableCell>
                                        <StyledTableCell align="center">{season.originalLanguage}</StyledTableCell>
                                        <StyledTableCell align="center">{season.languages.join(', ')}</StyledTableCell>
                                        <StyledTableCell align="center">{season.workflowStatus}</StyledTableCell>
                                        <StyledTableCell align="center">{season.revenue}</StyledTableCell>
                                        <StyledTableCell align="left"><EditButton onClick={() => redirect(`/seasons/${season.id}/edit`)} /></StyledTableCell>
                                    </StyledTableRow>
                                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <br />
                </div>
                <div style={{ gridArea: 'country' }}>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        disableCloseOnSelect
                        options={countriesOptions}
                        getOptionLabel={(option) => option.name}
                        value={land}
                        onChange={(e, newValue) => setLand(newValue)}
                        renderTags={(newValue) => (
                            <div>{`(${newValue.length})`} {newValue.map((option) => option.name)
                                        .join(', ')}
                            </div>
        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Country"
                            />
                                    )}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </React.Fragment>
                                      )}
                    />
                </div>
                <div style={{ gridArea: 'languages' }}>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id="tags-outlined"
                        options={decoratedIsoLanguages}
                        getOptionLabel={(option) => option.name}
                        defaultValue={decoratedIsoLanguages.filter((country) => languages.includes(country.code))}
                        value={languages}
                        onChange={(e, newValue) => setLanguages(newValue)}
                        renderTags={(newValue) => (
                            <div>{`(${newValue.length})`} {newValue.map((option) => option.name)
                                        .join(', ')}
                            </div>
        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Languages"
                            />
                                    )}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </React.Fragment>
                        )}
                    />
                </div>
                <div style={{ gridArea: 'subtitles' }}>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id="tags-outlined"
                        options={decoratedIsoLanguages}
                        getOptionLabel={(option) => option.name}
                        defaultValue={decoratedIsoLanguages.filter((country) => subtitles.includes(country.code))}
                        value={subtitles}
                        onChange={(e, newValue) => setSubtitles(newValue)}
                        renderTags={(newValue) => (
                            <div>{`(${newValue.length})`} {newValue.map((option) => option.name)
                                        .join(', ')}
                            </div>
        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Subtitles"
                            />
                                    )}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </React.Fragment>
                        )}
                    />
                </div>
                <FormControl style={{ gridArea: 'genre' }}>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id="demo-simple-select"
                        options={genresList}
                        getOptionLabel={(option) => option || ''}
                        value={genres}
                        onChange={(e, newValue) => setGenres(newValue)}
                        renderTags={(newValue) => (
                            <div>{`(${newValue.length})`} {newValue.join(', ')}</div>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Genres"
                            />
                        )}
                        renderOption={(option) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={genres.includes(option)}
                                />
                                {option}
                            </React.Fragment>
                        )}
                    />
                </FormControl>
                {/* <FormControl style={{ gridArea: 'category' }} /> */}
                <FormControl style={{ gridArea: 'tags' }}>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id="tags-outlined"
                        options={tagOptions}
                        getOptionLabel={(option) => option.name}
                        value={tags}
                        onChange={(e, newValue) => setTags(newValue)}
                        renderTags={(newValue) => (
                            <div>{`(${newValue.length})`} {newValue.map((option) => option.name)
                                        .join(', ')}
                            </div>
        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Tags"
                            />
                                    )}
                        renderOption={(option) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={tags.map((tag) => tag.name).includes(option.name)}
                                />
                                {option.name}
                            </React.Fragment>
                        )}
                    />
                </FormControl>
                <FormControl style={{
                                gridArea: 'fsk',
                                marginRight: '20px'
                            }}
                >
                    <InputLabel id="demo-simple-select-label">FSK</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={fsk}
                        onChange={({ target: { value } }) => {
                                        setFSK(value);
                                    }}
                    >
                        {FSKList.map((fskItem) => <MenuItem value={fskItem}>{fskItem}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField
                    type="number"
                    label="Year"
                    value={year}
                    style={{ gridArea: 'year' }}
                    onChange={({ target: { value } }) => {
                                setYear(value);
                            }}
                />
                <TextField
                    label="Screenplay"
                    value={screenplay}
                    style={{ gridArea: 'screenplay' }}
                    focused={screenplay.length}
                    onChange={({ target: { value } }) => {
                                        setScreenplay(value);
                                    }}
                />
                <TextField
                    label="Direction"
                    value={direction}
                    style={{ gridArea: 'direction' }}
                    focused={direction.length}
                    onChange={({ target: { value } }) => {
                                        setDirection(value);
                                    }}
                />
                <TextField
                    label="Producer"
                    value={producer}
                    style={{ gridArea: 'producer' }}
                    focused={producer.length}
                    onChange={({ target: { value } }) => {
                                        setProducer(value);
                                    }}
                />
                <TextField
                    style={{ gridArea: 'cast' }}
                    value={cast}
                    focused={cast.length}
                    label="Cast"
                    multiline
                    variant="outlined"
                    onChange={({ target: { value } }) => {
                                    setCast(value);
                                }}
                />
                <TextField disabled label="Static Picture Source Link" value={staticPictureSourceLink} style={{ gridArea: 'pictureSrc' }} />
                {/* <TextField disabled label="VideoSource Link" value={videoSource} style={{ gridArea: 'videoSrc' }} /> */}
                <div style={{ gridArea: 'buttons', display: 'flex', justifyContent: 'flex-end', paddingTop: '60px' }}>
                    <Button
                        disabled={!isFormEdited}
                        onClick={handleCancel}
                        variant="outlined"
                        color="secondary"
                        style={{ marginRight: '20px', width: '220px' }}
                    >
                        Cancel Changes
                    </Button>
                    <Button
                        disabled={!isFormEdited}
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{ gridArea: 'save', width: '220px' }}
                    >
                        Save
                    </Button>
                </div>
            </div>
            {/* eslint-disable-next-line max-len */}
            { isErrorFeedbackDialogOpen && <AvailsDialog data={submitSuccessResponseData} open onClose={() => setIsErrorFeedbackDialogOpen(false)} /> }
        </Paper>
                  )}
        </>
    );
};

SeriesEdit.propTypes = {
    id: PropTypes.string.isRequired,
    dataServices: PropTypes.object.isRequired
};

export default SeriesEdit;
