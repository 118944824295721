(function(){var g={}; var _ = _ || {};
var f=function(window){/*

 Copyright 2016 Google Inc.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
var cE="Channel parsing failure! Ignoring scheme and value",dE="ContentProtection",eE="EssentialProperty",fE="SegmentBase does not contain sufficient segment information:",gE="SegmentList does not contain sufficient segment information:",hE="SegmentTemplate does not contain sufficient segment information:",iE="SegmentTimeline",jE='The last "S" element cannot have a negative repeat',kE="duration",lE='ignoring the remaining "S" elements.',mE="presentationTimeOffset",nE="schemeIdUri",oE="static",pE=
"timescale",qE="urn:microsoft:playready",rE="urn:mpeg:cenc:2013",sE="urn:mpeg:dash:utc:http-head:2014",tE="urn:uuid:9a04f079-9840-4286-ab92-e65be0885f95",uE="urn:uuid:edef8ba9-79d6-4ace-a3c8-27dcd51d21ed",vE=function(a){return new _.im(Promise.reject(a),function(){return Promise.resolve()})},wE=function(a){return new _.im(Promise.all(a.map(function(b){return b.promise})),function(){return Promise.all(a.map(function(b){return b.abort()}))})},yE=function(a,b){return a.find(function(c){return xE.get(c.Lb)===
b})},zE=function(a){var b=_.L.Cd(a.node,qE,"pro");if(!b)return _.Kf("Missing \x3cms:pro/\x3e node in ContentProtection element",a),null;a=_.Zk(b.textContent);a=_.wn(a);a=_.sn(a.a);return{keyId:null,Lb:uE,node:document.createElement("null"),init:[{initData:_.pn(a),initDataType:_.nc,keyId:null}]}},AE=function(a){var b=_.L.Cd(a.node,rE,"pssh");if(!b)return _.Kf("Missing \x3ccenc:pssh/\x3e node in ContentProtection element",a),null;a=_.Zk(b.textContent);a=new _.Sh(new DataView(a.buffer),!1);a.skip(12);
a.skip(16);a.skip(4);b=_.Vh(a);_.ai(a);8===b&&a.skip(2);_.Vh(a);b=_.Vh(a);for(var c=new Uint8Array(b),d=0;d<b;d++)c[d]=_.Vh(a);a=_.al(c);a=_.sn(a).a;b=_.$k(a);b=_.un(b);c=_.ag(b);b=_.I(_.cg('\x3cWRMHEADER xmlns\x3d"http://schemas.microsoft.com/DRM/2007/03/PlayReadyHeader" version\x3d"4.0.0.0"\x3e\x3cDATA\x3e\x3cPROTECTINFO\x3e\x3cKEYLEN\x3e'+(b.byteLength+"\x3c/KEYLEN\x3e\x3cALGID\x3eAESCTR\x3c/ALGID\x3e\x3c/PROTECTINFO\x3e\x3cKID\x3e")+(c+"\x3c/KID\x3e\x3c/DATA\x3e\x3c/WRMHEADER\x3e"),!0));a=new _.tn(_.vn,
a,null,b);return{keyId:null,Lb:tE,node:document.createElement("null"),init:[{initData:_.pn(a),initDataType:_.nc,keyId:null}]}},DE=function(a,b){var c=BE(a),d=null,e=[],f=[],g=yE(c,_.Bc),k=yE(c,_.Ac);if(k&&!g){var l=zE(k);l&&c.push(l)}g&&!k&&(g=AE(g))&&c.push(g);g=new Set(c.map(function(m){return m.keyId}));g["delete"](null);if(1<g.size)throw new _.J(_.K,4,4010);b||(f=c.filter(function(m){return"urn:mpeg:dash:mp4protection:2011"==m.Lb?(d=m.init||d,!1):!0}),f.length&&(e=CE(d,f),0==e.length&&(e=[_.Ql("",
d)])));if(c.length&&(b||!f.length))for(e=[],c=_.t(xE.values()),f=c.next();!f.done;f=c.next())f=f.value,f!=_.Ed&&e.push(_.Ql(f,d));if(c=Array.from(g)[0]||null)for(f=_.t(e),g=f.next();!g.done;g=f.next())for(g=_.t(g.value.initData),k=g.next();!k.done;k=g.next())k.value.keyId=c;return{Jf:c,Wk:d,drmInfos:e,Qf:!0}},EE=function(a,b,c){var d=DE(a,c);if(b.Qf){a=1==b.drmInfos.length&&!b.drmInfos[0].keySystem;c=0==d.drmInfos.length;if(0==b.drmInfos.length||a&&!c)b.drmInfos=d.drmInfos;b.Qf=!1}else if(0<d.drmInfos.length&&
(b.drmInfos=b.drmInfos.filter(function(e){return d.drmInfos.some(function(f){return f.keySystem==e.keySystem})}),0==b.drmInfos.length))throw new _.J(_.K,4,4008);return d.Jf||b.Jf},CE=function(a,b){for(var c=[],d=_.t(b),e=d.next();!e.done;e=d.next()){e=e.value;var f=xE.get(e.Lb);if(f){var g=_.Ql(f,e.init||a);if(f=FE.get(f))g.licenseServerUri=f(e);c.push(g)}}return c},BE=function(a){var b=[];a=_.t(a);for(var c=a.next();!c.done;c=a.next())(c=GE(c.value))&&b.push(c);return b},GE=function(a){var b=a.getAttribute(nE),
c=_.L.getAttributeNS(a,rE,"default_KID"),d=_.L.Pf(a,rE,"pssh").map(_.L.Ub);if(!b)return _.Of("Missing required schemeIdUri attribute on","ContentProtection element",a),null;b=b.toLowerCase();if(c&&(c=c.replace(/-/g,"").toLowerCase(),c.includes(" ")))throw new _.J(_.K,4,4009);var e=[];try{e=d.map(function(f){return{initDataType:_.nc,initData:_.Zk(f),keyId:null}})}catch(f){throw new _.J(_.K,4,4007);}return{node:a,Lb:b,keyId:c,init:0<e.length?e:null}},IE=function(a,b,c,d){var e,f=(new _.bi).da("sidx",
function(g){e=HE(b,d,c,g)});a&&f.parse(a);if(e)return e;_.Of('Invalid box type, expected "sidx".');throw new _.J(_.K,3,3004);},HE=function(a,b,c,d){var e=[];d.F.skip(4);var f=_.Xh(d.F);if(0==f)throw _.Of("Invalid timescale."),new _.J(_.K,3,3005);if(0==d.version){var g=_.Xh(d.F);var k=_.Xh(d.F)}else g=_.Zh(d.F),k=_.Zh(d.F);d.F.skip(2);var l=_.Wh(d.F);a=a+d.size+k;for(k=0;k<l;k++){var m=_.Xh(d.F),n=(m&2147483648)>>>31;m&=2147483647;var p=_.Xh(d.F);d.F.skip(4);if(1==n)throw _.Of("Heirarchical SIDXs are not supported."),
new _.J(_.K,3,3006);e.push(new _.kp(e.length,g/f-b,(g+p)/f-b,function(){return c},a,a+m-1));g+=p;a+=m}d.wb.stop();return e},JE=function(a){this.f=a;this.a=new _.Sh(a,!1)},NE=function(a){var b=KE(a);if(7<b.length)throw new _.J(_.K,3,3002);for(var c=0,d=0;d<b.length;d++)c=256*c+b[d];b=c;c=KE(a);a:{d=_.t(LE);for(var e=d.next();!e.done;e=d.next())if(_.bl(c,new Uint8Array(e.value))){d=!0;break a}d=!1}if(d)c=a.f.byteLength-a.a.getPosition();else{if(8==c.length&&c[1]&224)throw new _.J(_.K,3,3001);d=c[0]&
(1<<8-c.length)-1;for(e=1;e<c.length;e++)d=256*d+c[e];c=d}c=a.a.getPosition()+c<=a.f.byteLength?c:a.f.byteLength-a.a.getPosition();d=new DataView(a.f.buffer,a.f.byteOffset+a.a.getPosition(),c);a.a.skip(c);return new ME(b,d)},KE=function(a){var b=_.Vh(a.a),c;for(c=1;8>=c&&!(b&1<<8-c);c++);if(8<c)throw new _.J(_.K,3,3002);var d=new Uint8Array(c);d[0]=b;for(b=1;b<c;b++)d[b]=_.Vh(a.a);return d},ME=function(a,b){this.id=a;this.a=b},OE=function(a){if(8<a.a.byteLength)throw new _.J(_.K,3,3002);if(8==a.a.byteLength&&
a.a.getUint8(0)&224)throw new _.J(_.K,3,3001);for(var b=0,c=0;c<a.a.byteLength;c++){var d=a.a.getUint8(c);b=256*b+d}return b},PE=function(){},RE=function(a,b,c,d,e,f){function g(){return e}var k=[];a=new JE(a.a);for(var l=null,m=null;_.Th(a.a);){var n=NE(a);if(187==n.id){var p=QE(n);p&&(n=c*p.Ak,p=b+p.ik,null!=l&&k.push(new _.kp(k.length,l-f,n-f,g,m,p-1)),l=n,m=p)}}null!=l&&k.push(new _.kp(k.length,l-f,d-f,g,m,null));return k},QE=function(a){var b=new JE(a.a);a=NE(b);if(179!=a.id)throw _.Kf("Not a CueTime element."),
new _.J(_.K,3,3013);a=OE(a);b=NE(b);if(183!=b.id)throw _.Kf("Not a CueTrackPositions element."),new _.J(_.K,3,3012);b=new JE(b.a);for(var c=0;_.Th(b.a);){var d=NE(b);if(241==d.id){c=OE(d);break}}return{Ak:a,ik:c}},TE=function(a,b){var c=SE.we(a,b,"Initialization");if(!c)return null;var d=a.M.Ka,e=c.getAttribute("sourceURL");e&&(d=_.Pl(a.M.Ka,[e]));e=0;var f=null;if(c=_.L.W(c,"range",_.L.Od))e=c.start,f=c.end;return new _.jp(function(){return d},e,f)},WE=function(a,b){var c=Number(SE.Pa(a,UE,mE))||
0,d=SE.Pa(a,UE,pE),e=1;d&&(e=_.L.Nd(d)||1);c=c/e||0;d=TE(a,UE);e=a.M.mimeType.split("/")[1];if(a.M.contentType!=_.le&&e!=_.rd&&"webm"!=e)throw _.Of("SegmentBase specifies an unsupported container type.",a.M),new _.J(_.K,4,4006);if("webm"==e&&!d)throw _.Of(fE,"the SegmentBase uses a WebM container,","but does not contain an Initialization element.",a.M),new _.J(_.K,4,4005);var f=SE.we(a,UE,"RepresentationIndex"),g=SE.Pa(a,UE,"indexRange"),k=a.M.Ka;g=_.L.Od(g||"");if(f){var l=f.getAttribute("sourceURL");
l&&(k=_.Pl(a.M.Ka,[l]));g=_.L.W(f,"range",_.L.Od,g)}if(!g)throw _.Of(fE,"the SegmentBase does not contain @indexRange","or a RepresentationIndex element.",a.M),new _.J(_.K,4,4002);e=VE(a,b,d,k,g.start,g.end,e,c);return{createSegmentIndex:e.createSegmentIndex,findSegmentPosition:e.findSegmentPosition,getSegmentReference:e.getSegmentReference,adjustSegmentDurations:e.adjustSegmentDurations,getDuration:e.getDuration,initSegmentReference:d,Ha:c}},VE=function(a,b,c,d,e,f,g,k){var l=a.presentationTimeline,
m=!a.Dc||!a.ca.ye,n=a.ca.start,p=a.ca.duration,q=b,r=null;return{createSegmentIndex:function(){var w,y,F,C,E,G;return _.z(function(M){if(1==M.a)return w=[q(d,e,f),"webm"==g?q(c.Oa(),c.Ia,c.sa):null],q=null,_.v(M,Promise.all(w),2);y=M.f;F=y[0];C=y[1]||null;E=null;g==_.rd?E=IE(F,e,d,k):(G=new PE,E=G.parse(F,C,d,k));_.np(l,E,n);r=new _.rp(E);m&&_.tp(r,p);return M["return"](y)})},findSegmentPosition:function(w){return r.find(w)},getSegmentReference:function(w){return r.get(w)},adjustSegmentDurations:function(w,
y){return r.xc(w,y)},getDuration:function(){return r.getDuration()}}},UE=function(a){return a.cd},$E=function(a,b){var c=TE(a,XE);var d=YE(a);var e=SE.wg(a,XE),f=e.kc;0==f&&(_.Kf("SegmentList@startNumber must be \x3e 0"),f=1);var g=0;e.la?g=e.la*(f-1):e.timeline&&0<e.timeline.length&&(g=e.timeline[0].start);d={la:e.la,startTime:g,kc:f,Ha:e.Ha,timeline:e.timeline,cc:d};if(!d.la&&!d.timeline&&1<d.cc.length)throw _.Kf(gE,"the SegmentList specifies multiple segments,","but does not specify a segment duration or timeline.",
a.M),new _.J(_.K,4,4002);if(!d.la&&!a.ca.duration&&!d.timeline&&1==d.cc.length)throw _.Kf(gE,"the SegmentList specifies one segment,","but does not specify a segment duration, period duration,","or timeline.",a.M),new _.J(_.K,4,4002);if(d.timeline&&0==d.timeline.length)throw _.Kf(gE,"the SegmentList has an empty timeline.",a.M),new _.J(_.K,4,4002);f=e=null;a.ya.id&&a.M.id&&(f=a.ya.id+","+a.M.id,e=b[f]);g=ZE(a.ca.duration,d.kc,a.M.Ka,d);e?(e.Qa(g),f=_.qp(a.presentationTimeline),_.sp(e,f-a.ca.start)):
(_.np(a.presentationTimeline,g,a.ca.start),e=new _.rp(g),f&&a.Dc&&(b[f]=e));a.Dc&&a.ca.ye||_.tp(e,a.ca.duration);return{createSegmentIndex:Promise.resolve.bind(Promise),findSegmentPosition:e.find.bind(e),getSegmentReference:e.get.bind(e),adjustSegmentDurations:e.xc.bind(e),getDuration:e.getDuration.bind(e),initSegmentReference:c,Ha:d.Ha}},XE=function(a){return a.Mb},ZE=function(a,b,c,d){var e=d.cc.length;d.timeline&&d.timeline.length!=d.cc.length&&(e=Math.min(d.timeline.length,d.cc.length),_.Kf("The number of items in the segment timeline and the number of segment",
"URLs do not match, truncating",d.cc.length,"to",e));for(var f=[],g=d.startTime,k={},l=0;l<e;k={Zd:k.Zd},l++){var m=d.cc[l];k.Zd=_.Pl(c,[m.ij]);var n=void 0;n=null!=d.la?g+d.la:d.timeline?d.timeline[l].end:g+a;f.push(new _.kp(l+b,g,n,function(p){return function(){return p.Zd}}(k),m.start,m.end));g=n}return f},YE=function(a){return[a.M.Mb,a.ka.Mb,a.ya.Mb].filter(_.Ol.cb).map(function(b){return _.L.S(b,"SegmentURL")}).reduce(function(b,c){return 0<b.length?b:c}).map(function(b){b.getAttribute("indexRange")&&
!a.ve&&(a.ve=!0,_.Kf("We do not support the SegmentURL@indexRange attribute on SegmentList.  We only use the SegmentList@duration attribute or SegmentTimeline, which must be accurate."));var c=b.getAttribute("media");b=_.L.W(b,"mediaRange",_.L.Od,{start:0,end:null});return{ij:c,start:b.start,end:b.end}})},eF=function(a,b,c,d){var e=aF(a);var f=SE.wg(a,bF);var g=SE.Pa(a,bF,"media"),k=SE.Pa(a,bF,"index");f={la:f.la,timescale:f.timescale,kc:f.kc,Ha:f.Ha,bf:f.bf,timeline:f.timeline,Ee:g,Vc:k,de:f.de};
g=f.Vc?1:0;g+=f.timeline?1:0;g+=f.la?1:0;if(0==g)throw _.Of("SegmentTemplate does not contain any segment information:","the SegmentTemplate must contain either an index URL template","a SegmentTimeline, or a segment duration.",a.M),new _.J(_.K,4,4002);1!=g&&(_.Kf("SegmentTemplate containes multiple segment information sources:","the SegmentTemplate should only contain an index URL template,","a SegmentTimeline or a segment duration.",a.M),f.Vc?(_.Nf("Using the index URL template by default."),f.timeline=
null):_.Nf("Using the SegmentTimeline by default."),f.la=null);if(!f.Vc&&!f.Ee)throw _.Of(hE,"the SegmentTemplate's media URL template is missing.",a.M),new _.J(_.K,4,4002);if(f.Vc){c=a.M.mimeType.split("/")[1];if(c!=_.rd&&"webm"!=c)throw _.Of("SegmentTemplate specifies an unsupported container type.",a.M),new _.J(_.K,4,4006);if("webm"==c&&!e)throw _.Of(hE,"the SegmentTemplate uses a WebM container,","but does not contain an initialization URL template.",a.M),new _.J(_.K,4,4005);d=SE.Bd(f.Vc,a.M.id,
null,a.bandwidth||null,null);d=_.Pl(a.M.Ka,[d]);a=VE(a,b,e,d,0,null,c,f.Ha)}else f.la?(d||(c=a.presentationTimeline,c.o=Math.max(c.o,f.de),c.A.debug("notifyAvailabilityTimeOffset: maxAvailabilityTimeOffset\x3d"+c.o),_.op(a.presentationTimeline,f.la),_.mp(a.presentationTimeline,a.ca.start)),a=cF(a,f)):(g=b=null,a.ya.id&&a.M.id&&(g=a.ya.id+","+a.M.id,b=c[g]),k=dF(a,f),d=!a.Dc||!a.ca.ye,b?(d&&_.tp(new _.rp(k),a.ca.duration),b.Qa(k),c=_.qp(a.presentationTimeline),_.sp(b,c-a.ca.start)):(_.np(a.presentationTimeline,
k,a.ca.start),b=new _.rp(k),g&&a.Dc&&(c[g]=b)),d&&_.tp(b,a.ca.duration),a={createSegmentIndex:Promise.resolve.bind(Promise),findSegmentPosition:b.find.bind(b),getSegmentReference:b.get.bind(b),adjustSegmentDurations:b.xc.bind(b),getDuration:b.getDuration.bind(b)});return{createSegmentIndex:a.createSegmentIndex,findSegmentPosition:a.findSegmentPosition,getSegmentReference:a.getSegmentReference,adjustSegmentDurations:a.adjustSegmentDurations,getDuration:a.getDuration,initSegmentReference:e,Ha:f.Ha}},
bF=function(a){return a.jc},cF=function(a,b){var c=a.ca.duration,d=b.la,e=b.kc,f=b.timescale,g=b.Ee,k=a.bandwidth||null,l=a.M.id,m=a.M.Ka;return{createSegmentIndex:Promise.resolve.bind(Promise),findSegmentPosition:function(n){return 0>n||c&&n>=c?null:Math.floor(n/d)},getSegmentReference:function(n){var p=n*d,q=p+d;c&&(q=Math.min(q,c));return 0>q||c&&p>=c?null:new _.kp(n,p,q,function(){var r=SE.Bd(g,l,n+e,k,p*f);return _.Pl(m,[r])},0,null)},adjustSegmentDurations:function(){},getDuration:function(){return c}}},
dF=function(a,b){for(var c=[],d=0;d<b.timeline.length;d++){var e=d+b.kc;c.push(new _.kp(e,b.timeline[d].start,b.timeline[d].end,function(f,g,k,l,m,n){f=SE.Bd(f,g,m,k,n);return _.Pl(l,[f]).map(function(p){return p.toString()})}.bind(null,b.Ee,a.M.id,a.bandwidth||null,a.M.Ka,e,b.timeline[d].zk+b.bf),0,null))}return c},aF=function(a){var b=SE.Pa(a,bF,"initialization");if(!b)return null;var c=a.M.id,d=a.bandwidth||null,e=a.M.Ka;return new _.jp(function(){var f=SE.Bd(b,c,null,d,null);return _.Pl(e,[f])},
0,null)},gF=function(){var a=this;this.a=this.g=null;this.j=[];this.h=null;this.C=[];this.m=1;this.D={};this.A=0;this.G=new _.ro(5);this.w=new _.Xi(function(){fF(a)});this.o=new _.Hk;this.f=new _.D("clpp.dash.DashParser");this.I=this.B=null},iF=function(a){var b,c,d,e,f,g,k;return _.z(function(l){if(1==l.a)return b=_.$i(a.j,a.g.attemptParameters),b.type=1,c=a.a.networkingEngine,d=Date.now(),e=c.fetch(b),_.lo(a.o,e),_.v(l,e.promise,2);if(3!=l.a){f=l.f;if(!a.a)return l["return"](0);f.uri&&!a.j.includes(f.uri)&&
a.j.unshift(f.uri);return _.v(l,hF(a,f.data,f.uri),3)}g=Date.now();k=(g-d)/1E3;_.so(a.G,1,k);return l["return"](k)})},hF=function(a,b,c){var d,e,f,g,k;return _.z(function(l){if(1==l.a){d=SE;e=_.L.xg(b,"MPD");if(!e)throw new _.J(_.K,4,4001,c);f=a.g.xlinkFailGracefully;g=d.Pd(e,a.g.attemptParameters,f,c,a.a.networkingEngine);_.lo(a.o,g);return _.v(l,g.promise,2)}k=l.f;return l["return"](jF(a,k,c))})},jF=function(a,b,c){var d,e,f,g,k,l,m,n,p,q,r,w,y,F,C,E,G,M,T,S,va,Ea,db,Xa;return _.z(function(Ma){if(1==
Ma.a){d=_.Ol;e=_.L;f=[c];g=e.S(b,"Location").map(e.Ub).filter(d.cb);0<g.length&&(k=_.Pl(f,g),f=a.j=k);l=e.S(b,"BaseURL").map(e.Ub);m=_.Pl(f,l);n=e.W(b,"minBufferTime",e.vb,0);a.A=e.W(b,"minimumUpdatePeriod",e.vb,-1);p=e.W(b,"availabilityStartTime",e.dk);q=e.W(b,"timeShiftBufferDepth",e.vb);r=e.W(b,"suggestedPresentationDelay",e.vb);w=e.W(b,"maxSegmentDuration",e.vb);y=b.getAttribute("type")||oE;"dynamic"===y&&(a.B=y,a.I=p);if(F=y===oE&&"dynamic"===a.B)a.f.info("MPD dynamic to static transition."),
y=a.B,p=a.I;a.h?C=a.h.presentationTimeline:(E=3*n,G=null!=r?r:E,C=new _.lp(p,G,!!a.g.autoCorrectDrift));M={Dc:y!=oE,bc:F,presentationTimeline:C,ya:null,ca:null,ka:null,M:null,bandwidth:0,ve:!1};var Ya=_.L.W(b,"mediaPresentationDuration",_.L.vb);for(var pb=[],Lb=0,eb=_.L.S(b,"Period"),kb=0;kb<eb.length;kb++){var xb=eb[kb];Lb=_.L.W(xb,_.de,_.L.vb,Lb);var uc=_.L.W(xb,kE,_.L.vb),Pa=null;if(kb!=eb.length-1){var Mb=_.L.W(eb[kb+1],_.de,_.L.vb);null!=Mb&&(Pa=Mb-Lb)}else null!=Ya&&(Pa=Ya-Lb);Mb=_.Vp;Pa&&uc&&
Math.abs(Pa-uc)>Mb&&a.f.warn("There is a gap/overlap between Periods",xb);null==Pa&&(Pa=uc);xb=kF(a,M,m,{start:Lb,duration:Pa,node:xb,ye:null==Pa||kb==eb.length-1});pb.push(xb);uc=M.ya.id;a.C.includes(uc)||(a.C.push(uc),a.h&&(a.a.filterNewPeriod(xb),a.h.periods.push(xb)));if(null==Pa){kb!=eb.length-1&&a.f.warn("Skipping Period",kb+1,"and any subsequent Periods:","Period",kb+1,"does not have a valid start time.",pb[kb+1]);Lb=null;break}Lb+=Pa}null==a.h&&a.a.filterAllPeriods(pb);null!=Ya?(Lb!=Ya&&a.f.warn("@mediaPresentationDuration does not match the total duration of ",
"all Periods."),Ya={periods:pb,duration:Ya,Kf:!1}):Ya={periods:pb,duration:Lb,Kf:!0};T=Ya;S=T.duration;va=T.periods;C.f=y==oE;if(F&&!C.bc())a.a.onEvent(new _.O(_.sd));C.B=F;y!=oE&&(F||T.Kf)||C.Nb(S||Infinity);(Ea=C.isLive())&&a.g.availabilityWindowOverride&&!isNaN(a.g.availabilityWindowOverride)&&(q=a.g.availabilityWindowOverride);null==q&&(q=Infinity);C.ic=q;_.op(C,w||1);if(a.h)return Ma.H(0);a.h={presentationTimeline:C,periods:va,offlineSessionIds:[],minBufferTime:n||0};if(null==C.m||null!=C.g)return Ma.H(0);
db=e.S(b,"UTCTiming");return _.v(Ma,lF(a,m,db),4)}Xa=Ma.f;if(!a.a)return Ma["return"]();C.w=Xa;_.x(Ma)})},kF=function(a,b,c,d){b.ya=mF(a,d.node,null,c);b.ca=d;b.ya.id||(a.f.info("No Period ID given for Period with start time "+d.start+",  Assigning a default"),b.ya.id="__clpp_period_"+d.start);c=_.L.S(d.node,"EventStream");c=_.t(c);for(var e=c.next();!e.done;e=c.next())nF(a,d.start,d.duration,e.value);e=_.L.S(d.node,_.fa).map(function(n){return oF(a,b,n)}).filter(_.Ol.cb);if(b.Dc){c=[];for(var f=
_.t(e),g=f.next();!g.done;g=f.next()){g=_.t(g.value.mk);for(var k=g.next();!k.done;k=g.next())c.push(k.value)}if(c.length!=(new Set(c)).size)throw new _.J(_.K,4,4018);}c=e.filter(function(n){return!n.af});e=e.filter(function(n){return n.af});e=_.t(e);for(f=e.next();!f.done;f=e.next()){g=f.value;f=g.streams[0];g=g.af;k=_.t(c);for(var l=k.next();!l.done;l=k.next())if(l=l.value,l.id==g){l=_.t(l.streams);for(var m=l.next();!m.done;m=l.next())m.value.trickModeVideo=f}}f=pF(c,_.Be);g=pF(c,_.Wb);if(!f.length&&
!g.length)throw new _.J(_.K,4,4004);g.length||(g=[null]);f.length||(f=[null]);e=[];g=_.t(g);for(k=g.next();!k.done;k=g.next())for(k=k.value,l=_.t(f),m=l.next();!m.done;m=l.next())qF(a,b.ya.id,k,m.value,e);g=pF(c,_.le);f=[];g=_.t(g);for(k=g.next();!k.done;k=g.next())f.push.apply(f,k.value.streams);g=pF(c,_.ad);c=[];g=_.t(g);for(k=g.next();!k.done;k=g.next())c.push.apply(c,k.value.streams);return{startTime:d.start,textStreams:f,thumbStreams:c,variants:e}},pF=function(a,b){return a.filter(function(c){return c.contentType==
b})},qF=function(a,b,c,d,e){var f=null!=b?b:a.m++ +"";if(c||d)if(c&&d){b=c.drmInfos;var g=d.drmInfos;if(b.length&&g.length?0<_.Nl(b,g).length:1){b=_.Nl(c.drmInfos,d.drmInfos);g=_.t(b);for(var k=g.next();!k.done;k=g.next())k.value.periodId=f;g=_.t(c.streams);for(f=g.next();!f.done;f=g.next()){k=f.value;var l=_.t(d.streams);for(f=l.next();!f.done;f=l.next()){var m=f.value;f=(m.bandwidth||0)+(k.bandwidth||0);f={id:a.m++,language:c.language,primary:c.De||d.De,audio:k,video:m,bandwidth:f,drmInfos:b,allowedByApplication:!0,
allowedByKeySystem:!0};e.push(f)}}}}else for(b=c||d,g=_.t(b.streams),f=g.next();!f.done;f=g.next())k=f.value,f=k.bandwidth||0,f={id:a.m++,language:b.language||_.qe,primary:b.De,audio:c?k:null,video:d?k:null,bandwidth:f,drmInfos:b.drmInfos,allowedByApplication:!0,allowedByKeySystem:!0},e.push(f)},oF=function(a,b,c){b.ka=mF(a,c,b.ya,null);var d=!1,e=_.L.S(c,"Role"),f=e.map(function(G){return G.getAttribute(_.ye)}).filter(_.Ol.cb),g=null,k=_.L.S(c,"Label").map(function(G){return G.textContent}).filter(_.Ol.cb);
k&&0<k.length&&(g=k[k.length-1]);var l=void 0;(k=b.ka.contentType==_.le)&&(l=_.ie);e=_.t(e);for(var m=e.next();!m.done;m=e.next()){m=m.value;var n=m.getAttribute(nE);if(null==n||"urn:mpeg:dash:role:2011"===n||"urn:mpeg:dash:role"===n)switch(m=m.getAttribute(_.ye),m){case "main":d=!0;break;case "caption":case _.ie:l=m}}n=_.L.S(c,eE);e=null;m=!1;n=_.t(n);for(var p=n.next();!p.done;p=n.next())p=p.value,"http://dashif.org/guidelines/trickmode"==p.getAttribute(nE)?e=p.getAttribute(_.ye):m=!0;n=_.L.S(c,
"Accessibility");var q=new Map;n=_.t(n);for(p=n.next();!p.done;p=n.next()){var r=p.value;p=r.getAttribute(nE);if("urn:scte:dash:cc:cea-608:2015"==p||"urn:scte:dash:cc:cea-708:2015"==p)if(p=1,r=r.getAttribute(_.ye),null!=r){r=_.t(r.split(";"));for(var w=r.next();!w.done;w=r.next()){var y=w.value,F=w=void 0;y.includes("\x3d")?(y=y.split("\x3d"),w=y[0].startsWith("CC")?y[0]:"CC"+y[0],F=y[1].split(",")[0].split(":").pop()):(w="CC"+p,p+=2,F=y);q.set(w,_.no(F))}}else q.set("CC1",_.qe)}if(m)return null;
m=_.L.S(c,dE);var C=DE(m,a.g.ignoreDrmInfo),E=_.no(c.getAttribute("lang")||_.qe);null==g&&(g=c.getAttribute("label"));m=_.L.S(c,_.Va);c=m.map(function(G){return rF(a,b,C,l,E,g,d,f,q,G)}).filter(function(G){return!!G});if(0==c.length){if(k)return null;throw new _.J(_.K,4,4003);}if(!b.ka.contentType||b.ka.contentType==_.Jb)for(b.ka.contentType=sF(c[0].mimeType,c[0].codecs),k=_.t(c),n=k.next();!n.done;n=k.next())n.value.type=b.ka.contentType;k=_.t(c);for(n=k.next();!n.done;n=k.next())for(n=n.value,p=
_.t(C.drmInfos),r=p.next();!r.done;r=p.next())r=r.value,n.keyId&&r.keyIds.push(n.keyId);k=m.map(function(G){return G.getAttribute("id")}).filter(_.Ol.cb);return{id:b.ka.id||"__fake__"+a.m++,contentType:b.ka.contentType,language:E,De:d,streams:c,drmInfos:C.drmInfos,af:e,mk:k}},rF=function(a,b,c,d,e,f,g,k,l,m){var n=_.L.fc;b.M=mF(a,m,b.ka,null);if(!tF(a,b.M))return a.f.warn("Skipping Representation",b.M),null;b.bandwidth=_.L.W(m,"bandwidth",_.L.Nd)||0;var p=b.M.contentType,q=p==_.le||p==_.Jb,r=p==_.ad;
try{if(p=function(C,E,G){return uF(a,C,E,G)},b.M.cd)var w=WE(b,p);else if(b.M.Mb)w=$E(b,a.D);else if(b.M.jc)w=eF(b,p,a.D,!!a.h);else{var y=b.M.Ka,F=b.ca.duration||0;w={createSegmentIndex:function(){return Promise.resolve()},findSegmentPosition:function(C){return 0<=C&&C<F?1:null},getSegmentReference:function(C){return 1!=C?null:new _.kp(1,0,F,function(){return y},0,null)},adjustSegmentDurations:function(){},getDuration:function(){return F},initSegmentReference:null,Ha:0}}}catch(C){if(q&&4002==C.code)return null;
throw C;}q=_.L.S(m,dE);q=EE(q,c,a.g.ignoreDrmInfo);p=void 0;r&&(r=(m=_.L.Uc(m,eE))&&m.getAttribute(nE),SE.ej(r||"")&&(m=(m=m.getAttribute(_.ye))&&m.split("x").map(function(C){return parseInt(C,10)}))&&2==m.length&&(w.horizontalTiles=m[0],w.verticalTiles=m[1],m=w.horizontalTiles*w.verticalTiles,null!==b.ka.jc&&(p=(n=_.L.W(b.ka.jc,kE,n)||0)?n/m:void 0)));return{id:a.m++,originalId:b.M.id,createSegmentIndex:w.createSegmentIndex,findSegmentPosition:w.findSegmentPosition,getSegmentReference:w.getSegmentReference,
initSegmentReference:w.initSegmentReference,adjustSegmentDurations:w.adjustSegmentDurations,getDuration:w.getDuration,presentationTimeOffset:w.Ha,mimeType:b.M.mimeType,codecs:b.M.codecs,frameRate:b.M.frameRate,bandwidth:b.bandwidth,width:b.M.width,height:b.M.height,kind:d,encrypted:0<c.drmInfos.length,keyId:q,language:e,label:f,type:b.ka.contentType,primary:g,trickModeVideo:null,emsgSchemeIdUris:b.M.emsgSchemeIdUris,roles:k,channelsCount:b.M.Ge,closedCaptions:l,horizontalTiles:w.horizontalTiles,verticalTiles:w.verticalTiles,
durationPerTile:p}},fF=function(a){var b,c,d;_.z(function(e){switch(e.a){case 1:return a.f.info(_.ib),b=0,_.hf(e,2),_.v(e,iF(a),4);case 4:b=e.f;_.jf(e,3);break;case 2:if(c=_.kf(e),a.a&&c.f&&(a.a.onError(c),d=c,d.a===_.K))return e["return"]();case 3:if(!a.a)return e["return"]();vF(a,b);_.x(e)}})},vF=function(a,b){0>a.A||a.w.$(Math.max(3,a.A-b,_.to(a.G)))},mF=function(a,b,c,d){c=c||{contentType:"",mimeType:"",codecs:"",emsgSchemeIdUris:[],frameRate:void 0,Ge:null};d=d||c.Ka;var e=_.L.fc,f=_.L.Gi,g=
_.L.S(b,"BaseURL").map(_.L.Ub),k=b.getAttribute("contentType")||c.contentType,l=b.getAttribute(_.qd)||c.mimeType,m=b.getAttribute(_.xc)||c.codecs;f=_.L.W(b,_.Tc,f)||c.frameRate;var n=_.L.S(b,"InbandEventStream"),p=c.emsgSchemeIdUris.slice();n=_.t(n);for(var q=n.next();!q.done;q=n.next())q=q.value.getAttribute(nE),p.includes(q)||p.push(q);n=_.L.S(b,"AudioChannelConfiguration");a=wF(a,n)||c.Ge;k||(k=sF(l,m));return{Ka:_.Pl(d,g),cd:_.L.Uc(b,"SegmentBase")||c.cd,Mb:_.L.Uc(b,"SegmentList")||c.Mb,jc:_.L.Uc(b,
"SegmentTemplate")||c.jc,width:_.L.W(b,_.Pe,e)||c.width,height:_.L.W(b,_.Wc,e)||c.height,contentType:k,mimeType:l,codecs:m,frameRate:f,emsgSchemeIdUris:p,id:b.getAttribute("id"),Ge:a}},wF=function(a,b){for(var c=_.t(b),d=c.next();!d.done;d=c.next()){var e=d.value;if(d=e.getAttribute(nE))if(e=e.getAttribute(_.ye))switch(d){case "urn:mpeg:dash:outputChannelPositionList:2012":return e.trim().split(/ +/).length;case "urn:mpeg:dash:23003:3:audio_channel_configuration:2011":case "urn:dts:dash:audio_channel_configuration:2012":var f=
parseInt(e,10);if(!f){a.f.warn(cE,d,e);continue}return f;case "tag:dolby.com,2014:dash:audio_channel_configuration:2011":case "urn:dolby:dash:audio_channel_configuration:2011":f=parseInt(e,16);if(!f){a.f.warn(cE,d,e);continue}for(c=0;f;)f&1&&++c,f>>=1;return c;default:a.f.warn("Unrecognized audio channel scheme:",d,e)}}return null},tF=function(a,b){var c=b.cd?1:0;c+=b.Mb?1:0;c+=b.jc?1:0;if(0==c){if(b.contentType==_.le||b.contentType==_.Jb)return!0;a.f.warn("Representation does not contain a segment information source:",
"the Representation must contain one of SegmentBase, SegmentList,",'SegmentTemplate, or explicitly indicate that it is "text".',b);return!1}1!=c&&(a.f.warn("Representation contains multiple segment information sources:","the Representation should only contain one of SegmentBase,","SegmentList, or SegmentTemplate.",b),b.cd?(a.f.info("Using SegmentBase by default."),b.Mb=null):a.f.info("Using SegmentList by default."),b.jc=null);return!0},xF=function(a,b,c,d){var e,f,g,k,l,m;return _.z(function(n){if(1==
n.a)return e=_.Pl(b,[c]),f=_.$i(e,a.g.attemptParameters),f.type=5,f.method=d,g=a.a.networkingEngine.fetch(f),_.lo(a.o,g),_.v(n,g.promise,2);k=n.f;if("HEAD"==d){if(!k.headers||!k.headers.date)return a.f.warn("UTC timing response is missing","expected date header"),n["return"](0);l=k.headers.date}else l=_.Uf(k.data);m=Date.parse(l);return isNaN(m)?(a.f.warn("Unable to parse date from UTC timing response"),n["return"](0)):n["return"](m-Date.now())})},lF=function(a,b,c){c=c.map(function(e){return{scheme:e.getAttribute(nE),
value:e.getAttribute(_.ye)}});var d=a.g.clockSyncUri;!c.length&&d&&c.push({scheme:sE,value:d});return _.Ol.Ci(c,function(e){var f=e.scheme;e=e.value;switch(f){case sE:case "urn:mpeg:dash:utc:http-head:2012":return xF(a,b,e,"HEAD");case "urn:mpeg:dash:utc:http-xsdate:2014":case "urn:mpeg:dash:utc:http-iso:2014":case "urn:mpeg:dash:utc:http-xsdate:2012":case "urn:mpeg:dash:utc:http-iso:2012":return xF(a,b,e,"GET");case "urn:mpeg:dash:utc:direct:2014":case "urn:mpeg:dash:utc:direct:2012":return f=Date.parse(e),
Promise.resolve(isNaN(f)?0:f-Date.now());case "urn:mpeg:dash:utc:http-ntp:2014":case "urn:mpeg:dash:utc:ntp:2014":case "urn:mpeg:dash:utc:sntp:2014":return a.f.warn("NTP UTCTiming scheme is not supported"),Promise.reject();default:return a.f.warn("Unrecognized scheme in UTCTiming element",f),Promise.reject()}})["catch"](function(){a.f.warn("A UTCTiming element should always be given in live manifests! This content may not play on clients with bad clocks!");return 0})},nF=function(a,b,c,d){var e=_.L.fc,
f=d.getAttribute(nE)||"",g=d.getAttribute(_.ye)||"",k=_.L.W(d,pE,e)||1,l={};d=_.t(_.L.S(d,"Event"));for(var m=d.next();!m.done;l={pc:l.pc},m=d.next()){m=m.value;var n=_.L.W(m,"presentationTime",e)||0,p=_.L.W(m,kE,e)||0;n=n/k+b;p=n+p/k;null!=c&&(n=Math.min(n,b+c),p=Math.min(p,b+c));var q=_.Hi;f.startsWith(_.te)?q=_.Ji:"urn:mpeg:dash:event:callback:2015"===f&&(q=_.td);l.pc={schemeIdUri:f,value:g,startTime:n,endTime:p,id:m.getAttribute("id")||"",type:q,eventElement:m};l.pc.type===_.td&&(l.pc.kg=function(r){return function(){var w=
r.pc;w.eventElement&&(w=w.eventElement.getAttribute("messageData")||"")&&w.startsWith("http")&&(w=_.$i(w),w.type=_.Jk,a.a.networkingEngine.fetch(w))}}(l));a.a.onTimelineCueAdded(l.pc)}},uF=function(a,b,c,d){var e,f,g,k;return _.z(function(l){if(1==l.a)return e=_.oo(b,c,d,a.g.attemptParameters),e.type=_.Op,f=a.a.networkingEngine,g=f.fetch(e),_.lo(a.o,g),_.v(l,g.promise,2);k=l.f;return l["return"](k.data)})},sF=function(a,b){return _.Tg(_.Pg(a,b))?_.le:a.split("/")[0]},yF=function(){},xE=(new Map).set("urn:uuid:1077efec-c0b2-4d02-ace3-3c1e52e2fb4b",
_.Ed).set(uE,_.Bc).set(tE,_.Ac).set("urn:uuid:79f0049a-4098-8642-ab92-e65be0885f95",_.Ac).set("urn:uuid:f239e769-efa3-4850-9c16-a903c6932efb","com.adobe.primetime"),FE=(new Map).set(_.Bc,function(a){return(a=_.L.Cd(a.node,"urn:microsoft","laurl"))?a.getAttribute("licenseUrl")||"":""}).set(_.Ac,function(a){a=_.L.Cd(a.node,qE,"pro");if(!a)return"";a=_.Zk(a.textContent);return _.wn(a).h||""});var SE={df:"http://www.w3.org/1999/xlink",Og:["http://dashif.org/thumbnail_tile","http://dashif.org/guidelines/thumbnail_tile"],ej:function(a){return 0<=SE.Og.indexOf(a)},Bd:function(a,b,c,d,e){var f={RepresentationID:b,Number:c,Bandwidth:d,Time:e};return a.replace(/\$(RepresentationID|Number|Bandwidth|Time)?(?:%0([0-9]+)([diouxX]))?\$/g,function(g,k,l,m){if("$$"==g)return"$";var n=f[k];if(null==n)return _.Kf("URL template does not have an available substitution for identifier",'"'+k+'":',a),g;"RepresentationID"==
k&&l&&(_.Kf("URL template should not contain a width specifier for identifier",'"RepresentationID":',a),l=void 0);"Time"==k&&(n=Math.round(n));switch(m){case void 0:case "d":case "i":case "u":g=n.toString();break;case "o":g=n.toString(8);break;case "x":g=n.toString(16);break;case "X":g=n.toString(16).toUpperCase();break;default:g=n.toString()}l=window.parseInt(l,10)||1;return Array(Math.max(0,l-g.length)+1).join("0")+g})},Di:function(a,b,c,d){a=_.L.S(a,"S");for(var e=[],f=0,g=0;g<a.length;++g){var k=
a[g],l=_.L.W(k,"t",_.L.fc),m=_.L.W(k,"d",_.L.fc),n=_.L.W(k,"r",_.L.parseInt);null!=l&&(l-=c);if(!m){_.Kf('"S" element must have a duration:',lE,k);break}l=null!=l?l:f;n=n||0;if(0>n)if(g+1<a.length){n=_.L.W(a[g+1],"t",_.L.fc);if(null==n){_.Kf('An "S" element cannot have a negative repeat','if the next "S" element does not have a valid start time:',lE,k);break}else if(l>=n){_.Kf('An "S" element cannot have a negative repeatif its start ','time exceeds the next "S" element\'s start time:',lE,k);break}n=
Math.ceil((n-l)/m)-1}else{if(Infinity==d){_.Kf(jE,"if the Period has an infinite duration:",'ignoring the last "S" element.',k);break}else if(l/b>=d){_.Kf(jE,"if its start time exceeds the Period's duration:",'igoring the last "S" element.',k);break}n=Math.ceil((d*b-l)/m)-1}0<e.length&&l!=f&&(Math.abs((l-f)/b)>=_.Vp&&_.Kf("SegmentTimeline contains a large gap/overlap:","the content may have errors in it.",k),e[e.length-1].end=l/b);for(k=0;k<=n;++k)f=l+m,e.push({start:l/b,end:f/b,zk:l}),l=f}return e},
wg:function(a,b){var c=SE.Pa(a,b,pE),d=1;c&&(d=_.L.Nd(c)||1);c=SE.Pa(a,b,kE);(c=_.L.Nd(c||""))&&(c/=d);var e=SE.Pa(a,b,"startNumber"),f=0;a.bc||(f=Number(SE.Pa(a,b,mE))||0);var g=_.L.fc(e||"");if(null==e||null==g)g=1;var k=SE.we(a,b,iE);e=null;k&&(e=SE.Di(k,d,f,a.ca.duration||Infinity));k=f/d||0;var l=SE.Pa(a,b,"availabilityTimeOffset"),m=_.L.parseFloat(l||"");if(null==l||null==m)m=0;return{timescale:d,la:c,kc:g,Ha:k,bf:f,timeline:e,de:m}},Pa:function(a,b,c){return[b(a.M),b(a.ka),b(a.ya)].filter(_.Ol.cb).map(function(d){return d.getAttribute(c)}).reduce(function(d,
e){return d||e})},we:function(a,b,c){return[b(a.M),b(a.ka),b(a.ya)].filter(_.Ol.cb).map(function(d){return _.L.Uc(d,c)}).reduce(function(d,e){return d||e})},aj:function(a,b,c,d,e,f){for(var g=SE.df,k=_.L.getAttributeNS(a,g,"href"),l=_.L.getAttributeNS(a,g,"actuate")||"onRequest",m=0;m<a.attributes.length;m++){var n=a.attributes[m];n.namespaceURI==g&&(a.removeAttributeNS(n.namespaceURI,n.localName),--m)}if(5<=f)return vE(new _.J(_.K,4,4028));if("onLoad"!=l)return vE(new _.J(_.K,4,4027));var p=_.Pl([d],
[k]);d=_.$i(p,b);d.type=1;return e.fetch(d).od(function(q){q=_.L.xg(q.data,a.tagName);if(!q)return vE(new _.J(_.K,4,4001,{uri:k}));for(;a.childNodes.length;)a.removeChild(a.childNodes[0]);for(;q.childNodes.length;){var r=q.childNodes[0];q.removeChild(r);a.appendChild(r)}for(r=0;r<q.attributes.length;r++){var w=q.attributes[r].nodeName,y=q.getAttribute(w);a.setAttribute(w,y)}return SE.Pd(a,b,c,p[0],e,f+1)})},Pd:function(a,b,c,d,e,f){f=void 0===f?0:f;var g=SE.df;if(_.L.getAttributeNS(a,g,"href"))return g=
SE.aj(a,b,c,d,e,f),c&&(g=g.od(void 0,function(){return SE.Pd(a,b,c,d,e,f)})),g;for(var k=[],l=0;l<a.childNodes.length;l++){var m=a.childNodes[l];m instanceof Element&&("urn:mpeg:dash:resolve-to-zero:2013"==_.L.getAttributeNS(m,g,"href")?(a.removeChild(m),--l):m.tagName!=iE&&k.push(SE.Pd(m,b,c,d,e,f)))}return wE(k).od(function(){return a})}};var LE=[[255],[127,255],[63,255,255],[31,255,255,255],[15,255,255,255,255],[7,255,255,255,255,255],[3,255,255,255,255,255,255],[1,255,255,255,255,255,255,255]];PE.prototype.parse=function(a,b,c,d){b=new JE(new DataView(b));if(440786851!=NE(b).id)throw _.Of("Not an EBML element."),new _.J(_.K,3,3008);var e=NE(b);if(408125543!=e.id)throw _.Of("Not a Segment element."),new _.J(_.K,3,3009);b=e.a.byteOffset;e=new JE(e.a);for(var f=null;_.Th(e.a);){var g=NE(e);if(357149030==g.id){f=g;break}}if(!f)throw _.Of("Not an Info element."),new _.J(_.K,3,3010);f=new JE(f.a);g=1E6;for(e=null;_.Th(f.a);){var k=NE(f);if(2807729==k.id)g=OE(k);else if(17545==k.id)if(4==k.a.byteLength)e=
k.a.getFloat32(0);else if(8==k.a.byteLength)e=k.a.getFloat64(0);else throw new _.J(_.K,3,3003);}if(null==e)throw new _.J(_.K,3,3011);f=g/1E9;e*=f;a=NE(new JE(new DataView(a)));if(475249515!=a.id)throw _.Of("Not a Cues element."),new _.J(_.K,3,3007);return RE(a,b,f,e,c,d)};_.h=gF.prototype;_.h.configure=function(a){this.g=a};_.h.start=function(a,b){var c=this,d;return _.z(function(e){if(1==e.a)return c.j=[a],c.a=b,_.v(e,iF(c),2);d=e.f;c.a&&vF(c,d);if(!c.a)throw new _.J(_.K,7,7001);return e["return"](c.h)})};_.h.stop=function(){this.g=this.a=null;this.j=[];this.h=null;this.C=[];this.D={};null!=this.w&&(this.w.stop(),this.w=null);return this.o.destroy()};_.h.update=function(){var a=this;iF(this)["catch"](function(b){if(a.a&&b)a.a.onError(b)})};
_.h.onExpirationUpdated=function(){};_.u(yF,_.qm);_.h=yF.prototype;_.h.ab=function(){_.Wp.Qd("mpd",gF);_.Wp.$c(_.vm,gF)};_.h.Va=function(){_.Wp.Se("mpd");_.Wp.Rd(_.vm)};_.h.zc=function(){};_.h.release=function(){};_.h.id=function(){return"clpp.component.dash"};_.A("clpp.dash.DashComponent",yF);};
if(typeof(module)!="undefined"&&module.exports){var x=require("./cl.core.min.js");_ = x._;(f.call(g,this));module.exports=g;}
else if (typeof(define)!="undefined"&&define.amd) {define(["./cl.core.min"], function(c){_=c._;(f.call(g,this));return g;});}
else{_=this.clpp._;(f.call(g,this));}
})();
