/* eslint-disable react/jsx-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import {
    Edit,
    SimpleForm,
    useMutation,
    useNotify,
    useRedirect
} from 'react-admin';
import MetadataInputElements from './MetadataInputElements';
import BasicActionsToolbar from '../BasicActionsToolbar';
import '../entities.scss';

const MetadataEdit = (props) => {
    const [mutate] = useMutation();
    const notify = useNotify();
    const redirect = useRedirect();
    const {
        onSuccess,
        onFailure,
        id,
        transform,
        basePath,
        actions,
        toolbar
    } = props;
    const additionalProps = { mutationMode: 'pessimistic' };
    if (onSuccess) {
        additionalProps.onSuccess = onSuccess;
    }
    if (onFailure) {
        additionalProps.onFailure = onFailure;
    }
    if (id) {
        additionalProps.id = id;
    }
    if (transform) {
        additionalProps.transform = transform;
    }
    if (basePath) {
        additionalProps.basePath = basePath;
    }
    if (actions !== null) {
        additionalProps.actions = <BasicActionsToolbar showList {...props} />;
    }
    const toolbarProp = { toolbar } || {};

    // eslint-disable-next-line consistent-return
    const save = async (values) => {
        try {
            const response = await mutate(
                {
                    type: 'update',
                    resource: props.resource,
                    payload: {
                        id: values['@id'],
                        data: {
                            ...values,
                            metadataType: values.metadataType['@id']
                        }
                    }
                },
                { returnPromise: true }
            );
            if (onSuccess) {
                onSuccess(response);
            } else {
                notify(`${props.resource} was updated !`);
                redirect(`/${props.resource}`);
            }
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(
                error
            );

            if (!submissionErrors) {
                notify(`Error: ${error.message}`);
                return {};
            }

            return submissionErrors;
        }
    };

    return (
        <Edit
            {...props}
            {...additionalProps}
            transform={(data) => {
                return {
                    ...data,
                    metadataType: data.metadataType['@id']
                };
            }}
        >
            <SimpleForm {...toolbarProp} save={save}>
                <MetadataInputElements />
            </SimpleForm>
        </Edit>
    );
};

export default MetadataEdit;
