/* eslint-disable react/button-has-type */
import React from 'react';
import 'gantt-task-react/dist/index.css';
import { ViewMode } from 'gantt-task-react';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { useTranslate } from 'react-admin';

export const ViewSwitcher = ({
    onViewModeChange, runningReleases, viewMode
}) => {
    const translate = useTranslate();
    return (
        <div className="ViewContainer" style={{ marginBottom: '10px' }}>
            <Button onClick={() => onViewModeChange(ViewMode.Day)} variant={viewMode === ViewMode.Day ? 'contained' : 'text'}>{translate('day')}</Button>
            <Button onClick={() => onViewModeChange(ViewMode.Week)} variant={viewMode === ViewMode.Week ? 'contained' : 'text'}>{translate('week')}</Button>
            <Button onClick={() => onViewModeChange(ViewMode.Month)} variant={viewMode === ViewMode.Month ? 'contained' : 'text'}>{translate('month')}</Button>
            <span style={{ marginLeft: '50px' }}>{moment().format('DD.MM.yyyy')}</span>
            <span style={{ marginLeft: '50px' }}>{translate('runningReleases')}: <strong>{runningReleases}</strong></span>
        </div>
    );
};
