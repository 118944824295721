import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useLocale } from 'ra-core';

const useStyles = makeStyles({
    table: {
        width: '100%'
    }
});

function createData(name, category, icon) {
    return { name, category, icon };
}

export default ({ resources }) => {
    const locale = useLocale();
    const classes = useStyles();
    const rows = [];
    resources.forEach((res) => {
        rows.push(createData(res.name, res.options.level.label[locale] || res.options.level.label, res.options.icon));
    });

    return (
        <TableContainer component={Paper}>
            <Table
                className={classes.table}
                size="small"
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <strong>Resource</strong>
                        </TableCell>
                        <TableCell align="left">
                            <strong>Category</strong>
                        </TableCell>
                        <TableCell align="center">
                            <strong>Icon</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell align="left">{`<${row.name}>`}</TableCell>
                            <TableCell align="left">{row.category}</TableCell>
                            <TableCell align="center">
                                <row.icon />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
