/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { TextInput, ReferenceInput, SelectInput, useInput } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

const PreviewFullSource = (props) => {
    const {
        input: { value }
    } = useInput(props);
    const [previewContent, setPreviewContent] = useState(
        <div>No preview available</div>
    );

    const renderImage = () => {
        const testImage = document.createElement('img');
        testImage.src = value;
        testImage.onerror = () => {
            setPreviewContent(<div>No preview available</div>);
        };

        testImage.onload = () => {
            setPreviewContent(
                <a href={value} target="_blank" rel="noreferrer">
                    <img className="media-preview-picture" src={value} alt="" />
                </a>
            );
        };
    };

    useEffect(() => {
        renderImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <>
            <h3>Preview</h3>
            {previewContent}
        </>
    );
};

const CustomFormatsField = (props) => {
    const {
        input: { value }
    } = useInput(props);
    const records = value === '' ? [] : value;
    return (
        <>
            <h3>Formats</h3>
            <TableContainer component={Paper} style={{ width: '50%' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>Name</strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>Type</strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>Source</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.length > 0 ? (
                            records.map((row) => {
                                return (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.name || 'N/A'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.type?.name || 'N/A'}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Link
                                                href={row.fullSource}
                                                variant="body2"
                                            >
                                                Link
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan="3"
                                    component="th"
                                    scope="row"
                                    style={{ textAlign: 'center' }}
                                >
                                    No formats available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

const CustomSourceMetadata = (props) => {
    const {
        input: { value }
    } = useInput(props);

    const parseSourceMetadata = (content) => {
        if (content) {
            try {
                return JSON.stringify(JSON.parse(value), null, 2);
            } catch (error) {
                return content;
            }
        } else {
            return 'No data available';
        }
    };

    return (
        <>
            <h3>Source Metadata</h3>
            <pre>{parseSourceMetadata(value)}</pre>
        </>
    );
};

function MediaInputElements({
    operation = null,
    onFileInputChange,
    mediaFile
}) {
    const fileInputRef = useRef(null);
    return (
        <div className="form-flexed-vertical">
            <TextInput label="Name" source="name" />
            <ReferenceInput
                label="Type"
                source="type[@id]"
                reference="media_types"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            {operation === 'EDIT' && (
                <TextInput label="Source" source="source" disabled />
            )}
            <div style={{ width: '100%', textAlign: 'left' }}>
                <IconButton
                    onClick={() => {
                        fileInputRef.current.click();
                    }}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                >
                    <PhotoCamera />
                </IconButton>
                <span>
                    {(mediaFile && mediaFile.name) || 'No file choosen'}
                </span>
            </div>

            <input
                ref={fileInputRef}
                hidden
                type="file"
                accept="image/x-png,image/gif,image/jpeg,video/mp4, video/mov, video/qt"
                onChange={(e) => {
                    onFileInputChange(e.target.files[0]);
                }}
            />
            {operation === 'EDIT' && (
                <>
                    <PreviewFullSource source="previewFullSource" />
                    <CustomFormatsField source="formats" />
                    <CustomSourceMetadata source="sourceMetadata" />
                </>
            )}
        </div>
    );
}

export default MediaInputElements;
