/* eslint-disable react/jsx-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import {
    Edit,
    useMutation,
    useNotify,
    useRedirect,
    TabbedForm,
    FormTab,
    useEditController,
    useLocale,
    useTranslate,
    usePermissions
} from 'react-admin';
import {
    submitErrorsMutators,
    SubmitErrorsSpy
} from 'final-form-submit-errors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import SSDSInputElements from './SSDSInputElements';
import BasicActionsToolbar from '../BasicActionsToolbar';
import WorkflowTimeline from '../WorkflowTimeline';
import { ssdStatuses } from '../../constants';
import { isDetailsTabAccesibleInEditSSDs, isStatusTabAccesibleInEditSSDs } from '../../utils/user';
import '../entities.scss';

const SSDSEdit = (props) => {
    const { record } = useEditController(props);
    const [mutate] = useMutation();
    const notify = useNotify();
    const redirect = useRedirect();
    const locale = useLocale();
    const translate = useTranslate();
    const { permissions: userRoles } = usePermissions();

    const {
        onSuccess,
        onFailure,
        id,
        transform,
        basePath,
        actions,
        toolbar
    } = props;
    const additionalProps = { mutationMode: 'pessimistic' };
    if (onSuccess) {
        additionalProps.onSuccess = onSuccess;
    }
    if (onFailure) {
        additionalProps.onFailure = onFailure;
    }
    if (id) {
        additionalProps.id = id;
    }
    if (transform) {
        additionalProps.transform = transform;
    }
    if (basePath) {
        additionalProps.basePath = basePath;
    }
    if (actions !== null) {
        additionalProps.actions = <BasicActionsToolbar showList {...props} />;
    }
    const toolbarProp = { toolbar } || {};

    // eslint-disable-next-line consistent-return
    const save = async (values) => {
        const body = { ...values };
        delete body.logs;
        try {
            const response = await mutate(
                {
                    type: 'update',
                    resource: props.resource,
                    payload: {
                        id: values['@id'],
                        data: { ...body, release: values?.release?.['@id'] }
                    }
                },
                { returnPromise: true }
            );
            if (onSuccess) {
                onSuccess(response);
            } else {
                notify(`${props.resource} was updated !`);
                redirect(`/${props.resource}`);
            }
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(
                error
            );

            if (!submissionErrors) {
                notify(`Error: ${error.message}`);
                return {};
            }

            return submissionErrors;
        }
    };

    const areDetailsAndStatusTAbsAccesible = isDetailsTabAccesibleInEditSSDs(userRoles) && isStatusTabAccesibleInEditSSDs(userRoles);

    const formTabs = [
        <FormTab key="1" label="details">
            <SSDSInputElements edit/>
        </FormTab>,
        <FormTab key="2" label="Logs">
            <TableContainer component={Paper} style={{ width: areDetailsAndStatusTAbsAccesible ? '50%' : '100%' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>{translate('date')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>{translate('createdBy')}</strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>{translate('entry')}</strong>
                            </TableCell>
                            <TableCell align="right">
                                <strong>{translate('category')}</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {record &&
                        record.logs &&
                        record.logs.length > 0 ? (
                            record.logs.map((log) => (
                                <TableRow key={log.createdAt}>
                                    <TableCell>
                                        {moment(log.createdAt).format(
                                            'DD.MM.YYYY HH:mm'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {log?.createdBy || 'N/A'}
                                    </TableCell>
                                    <TableCell align="right">
                                        {log?.entry || 'N/A'}
                                    </TableCell>
                                    <TableCell align="right">
                                        {log?.category || 'N/A'}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell align="center" colSpan={3}>
                                    {translate('noLogs')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </FormTab>,
        <FormTab key="3" label="Status">
            <div style={{ width: '100%' }}>
                {record && (
                    <WorkflowTimeline
                        resource="s_s_ds"
                        record={record}
                        currentStatus={record.workflowStatus}
                        allStatuses={ssdStatuses.map((el) => ({ ...el, description: el.description[locale], status: el.status[locale] }))}
                        lastStatus={false}
                    />
                )}
            </div>
        </FormTab>
    ];

    if (!areDetailsAndStatusTAbsAccesible) {
        formTabs.splice(0, 1);
        formTabs.splice(1, 1);
    }

    return (
        <Edit {...props} {...additionalProps}>
            <TabbedForm
                save={save}
                mutators={{
                    ...submitErrorsMutators
                }}
                sanitizeEmptyValues={false}
                {...toolbarProp}
            >
                {formTabs}
                <SubmitErrorsSpy />
            </TabbedForm>
        </Edit>
    );
};

export default SSDSEdit;
