import React, { useState, createContext } from 'react';

export const ImportPollingContext = createContext({
    startPolling: false,
    setStartPolling: () => {}
});

const ImportPollingContextProvider = ({ children }) => {
    const [startPolling, setStartPolling] = useState(false);

    return (
        <ImportPollingContext.Provider value={{
            startPolling, setStartPolling
        }}
        >
            {children}
        </ImportPollingContext.Provider>
    );
};

export default ImportPollingContextProvider;
