/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import {
    List,
    TextField,
    Datagrid,
    EditButton,
    Filter,
    TextInput,
    useListContext
} from 'react-admin';
import BasicActionsToolbar from '../BasicActionsToolbar';

const MetadataListFilters = (props) => {
    return (
        <Filter {...props}>
            <TextInput label="Content" source="content" />
        </Filter>
    );
};

function MetadataList(props) {
    return (
        <List
            {...props}
            actions={
                <BasicActionsToolbar
                    listContext={useListContext}
                    showFilters
                    showCreate
                    {...props}
                />
            }
            filters={<MetadataListFilters />}
        >
            <Datagrid>
                <TextField source="metadataType.name" label="Type" />
                <TextField source="content" label="Content" />
                <EditButton />
            </Datagrid>
        </List>
    );
}

export default MetadataList;
