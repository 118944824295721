/* eslint-disable object-shorthand */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
    hydraDataProvider as baseHydraDataProvider,
    // fetchHydra as baseFetchHydra,
    useIntrospection
} from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import { defaultLocale } from './constants';

const _reactAdmin = require('react-admin');
const _apiDocParser = require('@api-platform/api-doc-parser');
const { expand } = require('jsonld');

const customFetchHydra = (url, options = {}) => {
    const requestHeaders = options.headers || new Headers();

    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set('Authorization', options.user.token);
    }

    const authOptions = { ...options, headers: requestHeaders };
    return _apiDocParser.fetchJsonLd(url, authOptions).then((data) => {
        // eslint-disable-next-line prefer-destructuring
        const status = data.response.status;

        if (status < 200 || status >= 300) {
            // eslint-disable-next-line prefer-destructuring
            const body = data.body;
            delete body.trace;
            return expand(body, {
                base: _apiDocParser.getDocumentationUrlFromHeaders(data.response.headers),
                documentLoader: (input) => _apiDocParser.fetchJsonLd(input, authOptions)
            })
                .then((json) => {
                    return Promise.reject(
                        new _reactAdmin.HttpError(
                            json[0]['http://www.w3.org/ns/hydra/core#description'][0]['@value'],
                            status,
                            json
                        )
                    );
                })
                .catch((e) => {
                    if (e.hasOwnProperty('body')) {
                        return Promise.reject(e);
                    }
                    if (body && body['hydra:description']) {
                        return Promise.reject(new _reactAdmin.HttpError(body['hydra:description'], status, {}));
                    }
                    return Promise.reject(new _reactAdmin.HttpError(data.response.statusText, status));
                });
        }

        return {
            status: status,
            headers: data.response.headers,
            json: data.body
        };
    });
};
const getHeaders = () => {
    return localStorage.getItem('token')
        ? {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Accept-Language':
                  (localStorage.getItem('locale') && localStorage.getItem('locale').toLowerCase()) || defaultLocale
          }
        : {};
};

const fetchHydra = (url, options = {}) => customFetchHydra(url, { ...options, headers: getHeaders });

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem('token')) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login" />;
};
const apiDocumentationParser = async (url) => {
    try {
        const { api } = await parseHydraDocumentation(url, {
            headers: getHeaders
        });
        return { api };
    } catch (result) {
        if (result.status === 401) {
            localStorage.removeItem('token');
            return {
                api: result.api,
                customRoutes: [<Route path="/" component={RedirectToLogin} />]
            };
        }
        throw result;
    }
};
export const dataProvider = baseHydraDataProvider(global.RA_API_ENTRYPOINT, fetchHydra, apiDocumentationParser, true);
