/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import './ssds.scss';
import moment from 'moment';
import { Gantt, ViewMode } from 'gantt-task-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewSwitcher } from './ViewSwitcher';
import { releaseColors } from '../../constants';
import 'gantt-task-react/dist/index.css';

const getLocalStorageLocaleLanguage = () => {
    const localeDictionary = {
        DE: 'de-DE',
        EN: 'en-US'
    };
    if (localStorage) {
        const cmsAdminLocale = localStorage.getItem('locale');
        if (cmsAdminLocale) {
            return localeDictionary[cmsAdminLocale] || 'de-DE';
        }
    }
    return 'de-DE';
};

const TooltipContent = ({ task }) => {
    const translate = useTranslate();
    return (
        <div className="ssd-gant-chart-tooltip">
            <span className="ssd-gant-chart-tooltip__title">{task.name}</span><br />
            <span className="ssd-gant-chart-tooltip__title">{moment(task.start).format('DD.MM.YYYY')} - {moment(task.end).format('DD.MM.YYYY')}</span><br />
            {task.progress > 0 && <><span>{translate('progress')}: {task.progress}%</span><br /></>}
            {task.releaseDuration > 0 && <><span>{translate('duration')}: {task.releaseDuration} {translate('days')}</span><br /></>}
        </div>
    );
};

function SSDSGanttChart() {
    const chartRef = useRef();
    const [view, setView] = useState(ViewMode.Month);
    const [isChecked, setIsChecked] = useState(true);
    const dataProvider = useDataProvider();
    const [releases, setReleases] = useState([]);
    const [releaseRequestLoading, setReleaseRequestLoading] = useState(false);

    useEffect(() => {
        setReleaseRequestLoading(true);
        dataProvider
            .getList('releases', {
                pagination: { page: 1, perPage: 900 },
                sort: { field: 'validTo', order: 'DESC' },
                filter: { workflowStatus: ['Approved', 'Ordered'] }
            })
            .then(({ data }) => {
                setReleaseRequestLoading(false);
                const translatedReleases =
                    data.sort((a, b) => {
                        return moment(
                            moment(a.validTo).format('DD.MM.YYYY'),
                            'DD.MM.YYYY'
                        ).diff(
                            moment(
                                moment(b.validTo).format('DD.MM.YYYY'),
                                'DD.MM.YYYY'
                            ), 'days'
                        );
                    }).slice(-4).map((release) => {
                        let progress = 0;
                        let releaseDuration = 0;
                        if (moment(moment().format()).isAfter(
                            moment(
                                moment(release.validTo).format('DD.MM.YYYY'),
                                'DD.MM.YYYY'
                            )
                        )) {
                            progress = 100;
                            releaseDuration = moment(moment(release.validTo).format('DD.MM.YYYY'), 'DD.MM.YYYY').diff(moment(moment(release.validFrom).format('DD.MM.YYYY'), 'DD.MM.YYYY'), 'days');
                        } else {
                            releaseDuration = moment(moment(release.validTo).format('DD.MM.YYYY'), 'DD.MM.YYYY').diff(moment(moment(release.validFrom).format('DD.MM.YYYY'), 'DD.MM.YYYY'), 'days');
                            const daysPassed = moment().diff(moment(moment(release.validFrom).format('DD.MM.YYYY'), 'DD.MM.YYYY'), 'days');
                            progress = Math.round((daysPassed / releaseDuration) * 100);
                            progress = progress > 0 ? progress : 0;
                        }
                        return {
                            start: moment(release.validFrom).toDate(),
                            end: moment(release.validTo).toDate(),
                            name: release.name,
                            id: release.id,
                            type: 'task',
                            progress,
                            releaseDuration,
                            isDisabled: true,
                            styles: {
                                progressColor: release.colorCode,
                                progressSelectedColor: release.colorCode,
                                backgroundSelectedColor: releaseColors.find((color) => color.code === release.colorCode)?.fadedCode || '#fff',
                                backgroundColor: releaseColors.find((color) => color.code === release.colorCode)?.fadedCode || '#fff'
                            }
                        };
                    });
                setReleases(translatedReleases);
            })
            .catch((error) => {
                setReleaseRequestLoading(false);
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }, []);


    useEffect(() => {
        if (releases.find((release) => release.styles.progressColor === '#ffff00')) {
            const yellowFilledTags = chartRef.current.querySelectorAll('[fill="#ffff00"]');
            if (yellowFilledTags.length > 0) {
                setTimeout(() => {
                    try {
                        chartRef.current.querySelectorAll('[fill="#ffff00"]')[0].parentElement.parentElement.parentElement.querySelector('text').style.fill = '#000000';
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.log(error);
                    }
                }, 300);

            }
        }
    }, [releases, chartRef.current]);


    let columnWidth = 25;
    if (view === ViewMode.Month) {
        columnWidth = 130;
    } else if (view === ViewMode.Week) {
        columnWidth = 80;
    }

    return (
        releases.length > 0 ? (
            <div style={{ position: 'absolute', left: 0, right: 0 }} ref={chartRef}>
                <ViewSwitcher
                    onViewModeChange={(viewMode) => setView(viewMode)}
                    onViewListChange={setIsChecked}
                    isChecked={isChecked}
                    viewMode={view}
                    runningReleases={releases.reduce((acc, val) => {
                        if (moment().isBetween(moment(val.start), moment(val.end))) {
                            // eslint-disable-next-line no-param-reassign
                            acc += 1;
                        }
                        return acc;
                    }, 0)}
                />
                {releases.length && (
                    <Gantt
                        tasks={releases}
                        viewMode={view}
                        onDateChange={() => { }}
                        onTaskDelete={() => { }}
                        onProgressChange={() => { }}
                        onDoubleClick={() => { }}
                        columnWidth={columnWidth}
                        todayColor="rgba(255, 204, 153, .4)"
                        listCellWidth=""
                        locale={getLocalStorageLocaleLanguage()}
                        TooltipContent={TooltipContent}
                    />
                )}
            </div>
        ) : (
            <div style={{ textAlign: 'center' }}>
                {releaseRequestLoading ? <CircularProgress /> : 'No eligible release entries'}
            </div>
        )
    );
}

export default SSDSGanttChart;
