import Create from './UsersCreate';
import Show from './UsersShow';
import Edit from './UsersEdit';
import List from './UsersList';

export default {
    create: Create,
    edit: Edit,
    list: List,
    show: Show,
    resource: 'users',
    name: 'users'
};
