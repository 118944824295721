import React from 'react';
import {
    List,
    Filter,
    TextInput,
    EditButton,
    BooleanInput,
    usePermissions,
    Datagrid,
    TextField,
    BulkDeleteButton
} from 'react-admin';
import {
    isEditLocksAccessible, isDeleteLocksAccessible
} from '../../utils/user';
import LocksListActions from './LocksListActions';

const PostFilter = (props) => {
    return (
        <Filter {...props}>
            <TextInput label="Name" source="name" />
            <TextInput label="Comment" source="comment" />
            <BooleanInput label="Status" source="status" />
        </Filter>
    );
};

const PostBulkActionButtons = (props, { userRoles }) => (
    <>
        {isDeleteLocksAccessible(userRoles) ? <BulkDeleteButton {...props} /> : null}
    </>
);

const LocksList = (props) => {
    const { permissions: userRoles } = usePermissions();
    return (
        <List
            className="centered-table"
            {...props}
            actions={(
                <LocksListActions />
            )}
            filters={<PostFilter />}
            bulkActionButtons={<PostBulkActionButtons userRoles={userRoles} />}
        >
            <Datagrid>
                <TextField source="status" label="Status" />
                <TextField source="name" label="Name" />
                <TextField source="comment" label="Comment" />
                <TextField source="code" label="Code" />

                {isEditLocksAccessible(userRoles) && <EditButton />}
            </Datagrid>
        </List>
    );
};


export default LocksList;
