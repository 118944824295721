import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { getData } from 'country-list';
import { iso6392 as isoLanguages } from 'iso-639-2';
import AvailsInputElements from './AvailsInputElements';

const AvailsEdit = ({ match: { params: { id } } }) => {
    const [tagsList, setTagsList] = useState([]);
    const [contentProvidersList, setContentProvidersList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [contriesOptions, setContriesOptions] = useState([]);
    const [languagesOptions, setLanguagesOptions] = useState([]);

    const getTags = () => {
        const url = new URL(`${window.RA_API_ENTRYPOINT}/tags`);
        url.search = new URLSearchParams({
            itemsPerPage: 999
        }).toString();
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setTagsList(response['hydra:member']);
            });
    };

    const getContentProviders = () => {
        const url = new URL(`${window.RA_API_ENTRYPOINT}/content_providers`);
        url.search = new URLSearchParams({
            itemsPerPage: 999
        }).toString();
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setContentProvidersList(response['hydra:member']);
            });
    };

    const getCategories = () => {
        const url = new URL(`${window.RA_API_ENTRYPOINT}/categories`);
        url.search = new URLSearchParams({
            itemsPerPage: 999
        }).toString();
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setCategoriesList(response['hydra:member']);
            });
    };

    const getCountriesOptions = async () => {
        const countries = await getData();

        setContriesOptions(countries);
    };

    const getLanguagesOptions = async () => {
        const decoratedIsoLanguages = isoLanguages.map((isoLang) => ({ ...isoLang, code: isoLang.iso6392B }));

        setLanguagesOptions(decoratedIsoLanguages);
    };

    useEffect(() => {
        getTags();
        getContentProviders();
        getCategories();
        getCountriesOptions();
        getLanguagesOptions();
    }, []);

    return (
        <Paper style={{ height: '100%', position: 'relative' }}>
            <AvailsInputElements
                availId={id}
                tagsList={tagsList}
                contentProvidersList={contentProvidersList}
                categoriesList={categoriesList}
                contriesOptions={contriesOptions}
                languagesOptions={languagesOptions}
            />
        </Paper>
    );
};

export default AvailsEdit;
