import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title, getResources } from 'react-admin';
import { useSelector } from 'react-redux';
import 'fontsource-roboto';
import DashboardResourceTable from './DashboardResourceTable';

export default () => {
    const resources = useSelector(getResources);
    return (
        <Card>
            <Title title="DB VOD Administration area" />
            <CardContent>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <span
                        style={{
                            fontSize: '1.4em',
                            marginLeft: '10px',
                            display: 'block'
                        }}
                    >
                        Welcome to Portal Admin
                    </span>
                </div>
                <DashboardResourceTable resources={resources} />
            </CardContent>
        </Card>
    );
};
