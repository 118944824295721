import React, { cloneElement } from 'react';
import {
    sanitizeListRestProps,
    TopToolbar,
    ListButton,
    CreateButton,
    EditButton,
    ExportButton
} from 'react-admin';

function BasicActionsToolbar(props) {
    const {
        showList = false,
        showCreate = false,
        showEdit = false,
        showFilters = false,
        basePath,
        className,
        exporter,
        filters,
        maxResults,
        listContext = null,
        ...rest
    } = props;
    const {
        currentSort = null,
        resource = null,
        displayedFilters = null,
        filterValues = null,
        showFilter = null,
        total = null
    } = listContext ? listContext() : {};
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {showFilters &&
                filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button'
                })}
            {showList && <ListButton basePath={basePath} />}
            {showCreate && <CreateButton basePath={basePath} />}
            {showEdit && <EditButton basePath={basePath} />}
            {listContext && (
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={maxResults}
                />
            )}
        </TopToolbar>
    );
}

export default BasicActionsToolbar;
