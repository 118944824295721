import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, IconButton, Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@material-ui/icons/Close';
import { teaserlaneTypes } from '../../utils/editorial-process';

const defaultTeasersTabs = [
    {
        id: uuidv4(),
        label: 'Teaser 1',
        asset: { id: '', name: '' }
    },
    {
        id: uuidv4(),
        label: 'Teaser 2',
        asset: { id: '', name: '' }
    },
    {
        id: uuidv4(),
        label: 'Teaser 3',
        asset: { id: '', name: '' }
    }
];

const AddTeaserlaneSelect = ({ addTeaserLane, onClose }) => {
    const [selectedTeaserlaneType, setSelectedTeaserlaneType] = useState(teaserlaneTypes.DEFAULT);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <IconButton onClick={onClose} style={{ alignSelf: 'flex-end' }}>
                <CloseIcon />
            </IconButton>
            <FormControl style={{ maxWidth: '250px', width: '100%', display: 'flex' }}>
                <InputLabel id="demo-simple-select-label">Teaserlane</InputLabel>
                <Select
                    defaultValue={selectedTeaserlaneType}
                    onChange={(e) => setSelectedTeaserlaneType(e.target.value)}
                >
                    {Object.values(teaserlaneTypes).map((teaserlaneType) => <MenuItem value={teaserlaneType}>{teaserlaneType}</MenuItem>)}
                </Select>
                <div style={{ display: 'flex', alignSelf: 'flex-start', marginTop: '50px' }}>
                    <Button style={{ border: '1px solid #999', marginRight: '20px' }} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        style={{ border: '1px solid #999' }}
                        onClick={() => {
                        addTeaserLane({ id: uuidv4(), type: selectedTeaserlaneType, tabs: defaultTeasersTabs });
                        onClose();
                    }}
                    >
                        Create
                    </Button>

                </div>
            </FormControl>
        </div>
    );
};

export default AddTeaserlaneSelect;
