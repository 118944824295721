import React, { useState, useEffect } from 'react';
import {
    TextField,
    FormControlLabel,
    Switch,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
    Button,
    Checkbox
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import './avails-input-elements.scss';

import { videoStatuses } from '../../constants';
import CountryMultiSelect from './CountryMultiSelect';

const AvailsInputElements = ({ availId, tagsList, contriesOptions, languagesOptions, contentProvidersList, categoriesList }) => {
    const [isDataSet, setIsDataSet] = useState(false);
    const [initialState, setInitialState] = useState({});
    const [currentState, setCurrentState] = useState({});
    const [hasStateChanged, setHasStateChanged] = useState(false);
    const notify = useNotify();
    // fixed data
    const [duration, setLength] = useState('');
    const [displayDuration, setLengthFE] = useState('');
    const [revenue, setRevenue] = useState('');
    const [workflowStatus, setWorkflowStatus] = useState('');
    const [imageSource, setImageSource] = useState('');
    const [videoSource, setVideoSource] = useState('');

    // editable data
    const [isAvailable, setIsAvailable] = useState(true);
    const [title, setTitle] = useState('');
    const [originalTitle, setOriginalTitle] = useState('');
    const [description, setDescription] = useState('');
    const [shortdescription, setShortDescription] = useState('');
    const [licenseStart, setLicenceStart] = useState('');
    const [licenseEnd, setLicenceEnd] = useState('');
    const [ellipsis, setEllipsis] = useState('');
    const [land, setCountry] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [subtitles, setSubtitles] = useState([]);
    const [genres, setGenres] = useState([]);
    const [category, setCategory] = useState([]);
    const [tags, setTags] = useState([]);
    const [parentalRatingNumber, setFSK] = useState([]);
    const [year, setYear] = useState('');
    const [provider, setProvider] = useState('');
    const [screenplay, setScreenplay] = useState([]);
    const [direction, setDirection] = useState([]);
    const [producer, setProducer] = useState([]);
    const [cast, setCast] = useState([]);

    const genresList = ['Action',
        'Animation',
        'Arthouse',
        'Comedy',
        'Dokumentation',
        'Drama',
        'Infotainment',
        'Kids & Family',
        'Krimi & Thriller',
        'Romance',
        'Sci-Fi & Fantasy'
    ];

    const readOnlyKeys = ['duration', 'displayDuration', 'revenue', 'workflowStatus', 'imageSource', 'videoSource', 'licenseStart', 'licenseEnd'];

    const FSKList = ['0', '6', '12', '16', '18'];

    const generateAvailTagsList = (availTagsIdsList) => {
        const availTags = tagsList.filter((tag) => {
            const tagId = tag['@id'].slice(tag['@id'].lastIndexOf('/') + 1);

            return availTagsIdsList.includes(tagId);
        }).map(({ name }) => name);

        setTags(availTags);
    };

    const handleSetCountry = async (options, countriesList, setList) => {
        const defaultCountriesCodes = options.filter((country) => countriesList.includes(country.code));

        setList(defaultCountriesCodes);
    };

    const availDataMethods = {
        title: { action: setTitle },
        originalTitle: { action: setOriginalTitle },
        description: {
            alternateKey: 'synopsis',
            action: setDescription
        },
        shortdescription: { alternateKey: 'shortDescription', action: setShortDescription },
        ellipsis: { alternateKey: 'titleEllipsis', action: setEllipsis },
        licenseStart: { action: setLicenceStart },
        licenseEnd: { action: setLicenceEnd },
        land: {
            action: (value) => handleSetCountry(contriesOptions, value, setCountry)
        },
        languages: {
            action: (value) => handleSetCountry(languagesOptions, value, setLanguages)
        },
        subtitles: { action: (value) => handleSetCountry(languagesOptions, value, setSubtitles) },
        genres: {
            alternateKey: 'genre',
            action: setGenres
        },
        category: { alternateKey: 'categoryId', action: (value) => setCategory([value]) },
        tags: { action: (list) => generateAvailTagsList(list) },
        parentalRatingNumber: { action: setFSK },
        year: { action: setYear },
        provider: {
            alternateKey: 'contentProviderId',
            action: (value) => setProvider([value])
        },
        screenplay: { action: setScreenplay },
        direction: { action: setDirection },
        producer: { action: setProducer },
        cast: { action: setCast },
        duration: { action: setLength },
        displayDuration: { action: setLengthFE },
        revenue: { action: setRevenue },
        workflowStatus: { action: setWorkflowStatus },
        imageSource: { action: setImageSource },
        videoSource: { action: setVideoSource }
    };

    const getAvail = async () => {
        await fetch(`${global.RA_API_ENTRYPOINT}/avails/${availId}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (response) {
                    const stateProperties = Object.keys(availDataMethods);
                    const map = new Map();
                    stateProperties.forEach((stateProp) => {
                        // if there is no value on the response, we set it to [] in order to have all keys
                        // set to initialState and currentState objects, so that when we cancel changes we reset all of them
                        const responseValue = response[stateProp] || response[availDataMethods[stateProp].alternateKey] || [];

                        availDataMethods[stateProp].action(responseValue);

                        // no need to add readonly properties to initial and current state
                        if (readOnlyKeys.includes(stateProp)) {
                            return;
                        }

                        map.set(stateProp, responseValue);
                    });

                    // currentState and initialState should be the same on mount and when the values get reset
                    setInitialState(Object.fromEntries(map));
                    setCurrentState(Object.fromEntries(map));
                    setIsDataSet(true);
                }
            });
    };


    useEffect(() => {
        if (contriesOptions.length && tagsList.length) {
            getAvail();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contriesOptions, tagsList]);

    useEffect(() => {
        const initialStateStringify = JSON.stringify(initialState);
        const currentStateStringify = JSON.stringify(currentState);

        setHasStateChanged(initialStateStringify !== currentStateStringify);
    }, [initialState, currentState]);

    const handleAnyStateChange = (stateProp, value, setFunction) => {
        let newValue = value;
        const statePropsToTransform = ['land', 'languages', 'subtitles'];

        if (statePropsToTransform.includes(stateProp)) {
            newValue = value.map(({ code }) => code);
        }

        setFunction(value);
        setCurrentState({ ...currentState, [stateProp]: newValue });
    };

    const handleSave = () => {
        fetch(`${global.RA_API_ENTRYPOINT}/videos/${availId}/complete`, {
            method: 'PUT',
            body: JSON.stringify(currentState),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((response) => response.json()).then(({ messages }) => {
            notify(messages);
            setInitialState(currentState);
        });
    };

    const handleClear = () => {
        setIsDataSet(false);
        getAvail();
    };

    const setWorkflowStatusData = (workflowStatusValue) => {
        const workflowStatusDetails = videoStatuses.find((status) => status.id === workflowStatusValue);
        const workflowStatusText = workflowStatusDetails?.status || workflowStatusValue;
        const workflowStatusColor = workflowStatusDetails?.backgroundColor || '#fff';

        return { workflowStatusText, workflowStatusColor };
    };

    return (
        isDataSet ? (
            <div className="container">
                <h3 style={{ gridArea: 'pageTitle' }}>EDIT MOVIE</h3>
                <TextField defaultValue={title} label="Title" style={{ gridArea: 'title1' }} onChange={(e) => handleAnyStateChange('title', e.target.value, setTitle)}/>
                <TextField defaultValue={originalTitle} label="Original Title" style={{ gridArea: 'title2' }} onChange={(e) => handleAnyStateChange('originalTitle', e.target.value, setOriginalTitle)}/>
                <div style={{ gridArea: 'revenue' }}>
                    <div className="title">Revenue/&#36;</div>
                    <div>{revenue}</div>
                </div>
                <div style={{ gridArea: 'workflow' }}>
                    <div className="title">Workflow Status</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ width: '10px', height: '10px', border: '1px solid #eee', marginRight: '5px', borderRadius: '10px', backgroundColor: setWorkflowStatusData(workflowStatus).workflowStatusColor }}/>
                        <span style={{ textTransform: 'capitalize' }}>{setWorkflowStatusData(workflowStatus).workflowStatusText}</span>
                    </div>
                </div>
                <FormControlLabel
                    style={{ gridArea: 'available' }}
                    control={<Switch size="small" checked={isAvailable} onChange={() => handleAnyStateChange('isAvailable', !isAvailable, setIsAvailable)} />}
                    label="Available"
                />
                <TextField style={{ gridArea: 'description1' }} defaultValue={description} focused={!!description} label="Description" multiline variant="outlined" onChange={(e) => handleAnyStateChange('description', e.target.value, setDescription)} />
                <TextField style={{ gridArea: 'description2' }} defaultValue={shortdescription} focused={!!shortdescription} label="Short Description" multiline variant="outlined" onChange={(e) => handleAnyStateChange('shortdescription', e.target.value, setShortDescription)} />
                <TextField style={{ gridArea: 'description3' }} defaultValue={ellipsis} label="Ellipsis" onChange={(e) => handleAnyStateChange('ellipsis', e.target.value, setEllipsis)}/>
                <div style={{ gridArea: 'licences' }}>
                    <div className="licences">Licences</div>
                    <div style={{ fontWeight: 'bold' }}>{`${licenseStart} - ${licenseEnd}`}</div>
                </div>
                <div style={{ gridArea: 'country' }}>
                    <CountryMultiSelect
                        selectLabel="Country"
                        defaultCountries={land}
                        contriesOptions={contriesOptions}
                        onChange={(value) => handleAnyStateChange('land', value, setCountry)}
                    />
                </div>
                <div style={{ gridArea: 'languages' }}>
                    <CountryMultiSelect
                        selectLabel="Languages"
                        defaultCountries={languages}
                        contriesOptions={languagesOptions}
                        onChange={(value) => handleAnyStateChange('languages', value, setLanguages)}

                    />
                </div>
                <div style={{ gridArea: 'subtitles' }}>
                    <CountryMultiSelect
                        selectLabel="Subtitles"
                        defaultCountries={subtitles}
                        contriesOptions={languagesOptions}
                        onChange={(value) => handleAnyStateChange('subtitles', value, setSubtitles)}
                    />
                </div>
                <TextField disabled defaultValue={duration} label="Length/min" style={{ gridArea: 'length1', marginRight: '20px' }}/>
                <TextField disabled defaultValue={displayDuration} label="Length FE/min" style={{ gridArea: 'length2' }}/>
                <FormControl style={{ gridArea: 'genre' }}>
                    <InputLabel id="demo-simple-select-label">Genre</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={genres}
                        multiple
                        onChange={(e) => handleAnyStateChange('genres', e.target.value, setGenres)}
                        renderValue={(selected) => `(${selected.length}) ${selected.join(', ')}`}
                    >
                        {genresList.map((genre) => (
                            <MenuItem value={genre}>
                                <Checkbox checked={genres.includes(genre)} />
                                <span>{genre}</span>
                            </MenuItem>
))}
                    </Select>
                </FormControl>
                <FormControl style={{ gridArea: 'category' }}>
                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        multiple
                        onChange={(e) => handleAnyStateChange('category', e.target.value, setCategory)}
                        renderValue={(selected) => {
                            const selectedCategorieItems = categoriesList.filter((categ) => {
                                const categoryId = categ['@id'].slice(categ['@id'].lastIndexOf('/') + 1);

                                return selected.includes(categoryId);
                            });
                            return `(${selectedCategorieItems.length}) ${selectedCategorieItems.map(({ name }) => name).join(', ')}`;
}}
                    >
                        {categoriesList.map((categoryItem) => {
                            const categoryId = categoryItem['@id'].slice(categoryItem['@id'].lastIndexOf('/') + 1);

                            return (
                                <MenuItem value={categoryId}>
                                    <Checkbox checked={category.includes(categoryId)} />
                                    <span>{categoryItem.name}</span>
                                </MenuItem>
);
                        })}
                    </Select>
                </FormControl>
                <FormControl style={{ gridArea: 'tags' }}>
                    <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tags}
                        multiple
                        onChange={(e) => handleAnyStateChange('tags', e.target.value, setTags)}
                        renderValue={(selected) => `(${selected.length}) ${selected.join(', ')}`}
                    >
                        {tagsList.map((tagItem) => (
                            <MenuItem value={tagItem.name}>
                                <Checkbox checked={tags.includes(tagItem.name)} />
                                <span>{tagItem.name}</span>
                            </MenuItem>
))}
                    </Select>
                </FormControl>
                <FormControl style={{ gridArea: 'fsk', marginRight: '20px' }}>
                    <InputLabel id="demo-simple-select-label">FSK</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={parentalRatingNumber}
                        onChange={(e) => handleAnyStateChange('parentalRatingNumber', e.target.value, setFSK)}
                    >
                        {FSKList.map((fskItem) => <MenuItem value={fskItem}>{fskItem}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField type="number" label="Year" defaultValue={year} style={{ gridArea: 'year' }} onChange={(e) => setYear(e.target.value)}/>
                <FormControl style={{ gridArea: 'provider' }}>
                    <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={provider[0]}
                        onChange={(e) => handleAnyStateChange('provider', [e.target.value], setProvider)}
                    >
                        {contentProvidersList.map((contentProviderItem) => {
                            const providerId = contentProviderItem['@id'].slice(contentProviderItem['@id'].lastIndexOf('/') + 1);
                            return (
                                <MenuItem value={providerId}>
                                    <span>{contentProviderItem.name}</span>
                                </MenuItem>
                            );
                      })}
                    </Select>
                </FormControl >
                <TextField label="Screenplay" value={screenplay.join(', ')} style={{ gridArea: 'screenplay' }} focused={screenplay.length} onChange={(e) => handleAnyStateChange('screenplay', e.target.value.split(', '), setScreenplay)} />
                <TextField label="Direction" value={direction.join(', ')} style={{ gridArea: 'direction' }} focused={direction.length} onChange={(e) => handleAnyStateChange('direction', e.target.value.split(', '), setDirection)}/>
                <TextField label="Producer" value={producer} style={{ gridArea: 'producer' }} focused={producer.length} onChange={(e) => handleAnyStateChange('producer', e.target.value.split(', '), setProducer)}/>
                <TextField
                    style={{ gridArea: 'cast' }}
                    value={cast.join(', ')}
                    focused={cast.length}
                    label="Cast"
                    multiline
                    variant="outlined"
                    onChange={(e) => handleAnyStateChange('cast', e.target.value.split(', '), setCast)}
                />
                <TextField disabled label="Static Picture Source Link" value={imageSource} style={{ gridArea: 'pictureSrc' }} />
                <TextField disabled label="VideoSource Link" value={videoSource} style={{ gridArea: 'videoSrc' }} />
                <div style={{ gridArea: 'buttons', display: 'flex', justifyContent: 'flex-end', paddingTop: '60px' }}>
                    <Button
                        disabled={!hasStateChanged}
                        onClick={handleClear}
                        color="primary"
                        variant="outlined"
                        style={{ marginRight: '20px', width: '220px' }}
                    >
                        Cancel Changes
                    </Button>
                    <Button
                        disabled={!hasStateChanged}
                        onClick={handleSave}
                        color="primary"
                        variant="contained"
                        style={{ width: '220px' }}
                    >
                        Save
                    </Button>
                </div>
            </div>
) : <CircularProgress style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, margin: 'auto' }} />
    );
};

export default AvailsInputElements;
