/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import AvailsDialog from 'components/AvailsDialog';
import { extractBaseUrl } from '../../utils/helpers';

const EXCEL_ImportLogsView = ({ importWorkerId, open, onClose }) => {
    const [logs, setLogs] = useState(null);
    const notify = useNotify();

    useEffect(() => {
        if (importWorkerId) {
            fetch(`${extractBaseUrl(global.RA_API_ENTRYPOINT)}/api/import_workers/${importWorkerId}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.logs) {
                        setLogs(data.logs);
                    }
                })
                .catch(() => notify(`Unable to retrieve logs for ${importWorkerId}.`));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importWorkerId]);
    return (
        logs && <AvailsDialog data={logs} open={open} onClose={onClose} topic="Metadata" />
    );
};


export default EXCEL_ImportLogsView;
