import { getCountries } from '../../utils/countries';

const getSeriesById = async (id, onSuccess, onError, apiData2UIData) => {
    const url = new URL(`${window.RA_API_ENTRYPOINT}/series/${id}`);
    const method = 'GET';
    const headers = {
        'Content-Type': 'application/ld+json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    const httpResponse = await fetch(url, { method, headers });
    if (!httpResponse.ok) {
        onError(httpResponse);
        return;
    }
    const httpResponseData = await httpResponse.json();
    onSuccess(apiData2UIData(httpResponseData));
};

const editSeriesById = async (id, newData, onSuccess, onError) => {
    const url = new URL(`${window.RA_API_ENTRYPOINT}/series/${id}/complete`);
    const method = 'PUT';
    const headers = {
        'Content-Type': 'application/ld+json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    const body = JSON.stringify(newData);
    const httpResponse = await fetch(url, { method, headers, body });
    if (!httpResponse.ok) {
        onError(httpResponse);
        return;
    }
    const httpResponseDataJSON = await httpResponse.json();
    onSuccess(httpResponseDataJSON);
};

// this is awesome
const editSeriesByIdThroughAnotherEndpoint = async (id, newData, onSuccess, onError) => {
    const url = new URL(`${window.RA_API_ENTRYPOINT}/series/${id}`);
    const method = 'PUT';
    const headers = {
        'Content-Type': 'application/ld+json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    const body = JSON.stringify(newData);
    const httpResponse = await fetch(url, { method, headers, body });
    if (!httpResponse.ok) {
        onError(httpResponse);
        return;
    }
    const httpResponseDataJSON = await httpResponse.json();
    onSuccess(httpResponseDataJSON);
};

// move in dedicated file and take out from markers crud
const createRequestHeaders = () => {
    return {
        'Content-Type': 'application/ld+json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
};

const createGetTagsRequestConfig = () => {
    const url = new URL(`${window.RA_API_ENTRYPOINT}/tags`);
    const method = 'GET';
    const headers = createRequestHeaders();

    return { url, method, headers };
};

const getTags = async (onSuccess, onError) => {
    const { url, method, headers } = createGetTagsRequestConfig();
    const httpResponse = await fetch(url, { method, headers });
    if (!httpResponse.ok) {
        onError(httpResponse);
        return;
    }
    const httpResponseData = await httpResponse.json();
    onSuccess(httpResponseData);
};

export const seriesServices = {
    getSeriesById,
    editSeriesById,
    editSeriesByIdThroughAnotherEndpoint,
    getTags,
    getCountries
};
