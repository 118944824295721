/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { AppBar, useRefresh, useSetLocale } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FileUploadStatus from 'components/FileUploadStatus';
import Contact from 'components/Contact';
import { defaultLocale } from './constants';
import de from './assets/de.png';
import en from './assets/gb.png';
import { DBLogo } from './assets/svgs';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    spacer: {
        flex: 1
    },
    img: {
        marginRight: '5px',
        width: '20px',
        height: '15px',
        cursor: 'pointer'
    },
    grayscale: {
        filter: 'grayscale(100%)'
    }
});

const MyAppBar = (props) => {
    const classes = useStyles();
    const refresh = useRefresh();
    const setAdminLocale = useSetLocale();
    // eslint-disable-next-line no-unused-vars
    const [locale, setLocale] = useState(localStorage.getItem('locale'));

    const getCurrentLocale = () => {
        return localStorage.getItem('locale') || defaultLocale.toUpperCase();
    };

    const changeLocale = (p) => {
        setLocale(p);
        setAdminLocale(p.toLowerCase());
        localStorage.setItem('locale', p);
        refresh();
    };

    return (
        <AppBar {...props} style={{ backgroundColor: '#fff', color: 'gray' }}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <DBLogo />
            <span className={classes.spacer} />
            <FileUploadStatus />
            <img
                className={`${classes.img} ${getCurrentLocale() !== 'EN' && classes.grayscale
                    }`}
                src={en}
                alt=""
                onClick={() => changeLocale('EN')}
            />
            <img
                className={`${classes.img} ${getCurrentLocale() !== 'DE' && classes.grayscale
                    }`}
                src={de}
                alt=""
                onClick={() => changeLocale('DE')}
            />
            <Contact />
        </AppBar>
    );
};

export default MyAppBar;
