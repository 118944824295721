(function(){var g={}; var _ = _ || {};
var f=function(window){var Ys=function(a,b){b=void 0===b?function(){return Promise.resolve()}:b;_.pj.call(this);this.l=a;this.j=b;this.f=0;this.a=[];this.g=!1;this.h=this.ig.bind(this)},Zs=function(a){a.trigger(new _.O(_.Od))},$s=function(a,b){return 0<=b&&b<a.a.length};_.u(Ys,_.pj);_.h=Ys.prototype;_.h.getPosition=function(){return this.f};_.h.Zi=function(){return this.a};_.h.oe=function(){return 0<this.a.length&&$s(this,this.f)?this.a[this.f]:null};
_.h.$i=function(){var a=this.f+1;return 0<this.a.length&&$s(this,a)?this.a[a]:null};_.h.dj=function(){return this.g};_.h.start=function(){this.g||(this.g=!0,this.l.on(_.Qc,this.h),this.Fd(this.f,!0))};_.h.stop=function(){this.g=!1;this.l.off(_.Qc,this.h)};_.h.ig=function(){this.next()};_.h.next=function(){var a=this,b;return _.z(function(c){b=Math.min(a.a.length-1,a.f+1);return _.v(c,a.Fd(b),0)})};_.h.hk=function(){var a=this,b;return _.z(function(c){b=Math.max(0,a.f-1);return _.v(c,a.Fd(b),0)})};
_.h.Fd=function(a,b){b=void 0===b?!1:b;var c=this,d;return _.z(function(e){if(1==e.a){if(!(0<c.a.length&&$s(c,a))||!b&&c.f===a)return e["return"]();c.f=a;return(d=c.oe())?_.v(e,c.j(d),3):e.H(0)}if(4!=e.a)return _.v(e,c.l.load(d.configuration),4);c.trigger(new _.O(_.Nd,{item:d}));_.x(e)})};_.h.append=function(a){this.a.push(a);Zs(this)};_.h.bj=function(a,b){if(0<=b&&b<=this.a.length){var c=this.oe();this.a.splice(b,0,a);c&&(this.f=this.a.indexOf(c));Zs(this)}};
_.h.remove=function(a){if(0<this.a.length&&$s(this,a)){if(this.f===a)throw Error("Can't remove the current item");this.a.splice(a,1);Zs(this)}};_.h.clear=function(){this.stop();this.f=0;this.a=[];this.g=!1;Zs(this)};_.A("clpp.Playlist",Ys);Ys.prototype.clear=Ys.prototype.clear;Ys.prototype.remove=Ys.prototype.remove;Ys.prototype.insert=Ys.prototype.bj;Ys.prototype.append=Ys.prototype.append;Ys.prototype.goTo=Ys.prototype.Fd;Ys.prototype.previous=Ys.prototype.hk;Ys.prototype.next=Ys.prototype.next;
Ys.prototype.onEnded=Ys.prototype.ig;Ys.prototype.stop=Ys.prototype.stop;Ys.prototype.start=Ys.prototype.start;Ys.prototype.isStarted=Ys.prototype.dj;Ys.prototype.getUpcoming=Ys.prototype.$i;Ys.prototype.getCurrent=Ys.prototype.oe;Ys.prototype.getQueue=Ys.prototype.Zi;Ys.prototype.getPosition=Ys.prototype.getPosition;};
if(typeof(module)!="undefined"&&module.exports){var x=require("./cl.core.min.js");_ = x._;(f.call(g,this));module.exports=g;}
else if (typeof(define)!="undefined"&&define.amd) {define(["./cl.core.min"], function(c){_=c._;(f.call(g,this));return g;});}
else{_=this.clpp._;(f.call(g,this));}
})();
