import { Show, SimpleShowLayout, TextField, ArrayField, SingleFieldList } from 'react-admin';

export default (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="forename" />
            <TextField source="surname" />
            <TextField source="email" />
            <ArrayField source="role">
                <SingleFieldList linkType={false}>
                    <TextField source="name" />
                </SingleFieldList>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);
