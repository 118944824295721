import { regex } from 'react-admin';
// _
// eslint-disable-next-line no-useless-escape
export default regex(/^(?=.*[a-zäöüß])(?=.*[A-ZÖÄÜß])(?=.*[0-9])(?=.*[!@#\$%\^&\*()_\-+=\{\}\[\]\\\|:;"'<>,./?¡“¶¢≠¿°])(?=.{12,})/,
          (
              <div>
                  <div>Password must contain the following:</div>
                  <ul>
                      <li>A <b>lowercase</b> letter</li>
                      <li>A <b>capital (uppercase)</b> letter</li>
                      <li>A <b>number</b></li>
                      <li>A <b>special character</b></li>
                      <li>Minimum <b>12 characters</b></li>
                  </ul>
              </div>
));
