/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import MUITableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { createPageTypes } from '../../utils/editorial-process';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'none'
        }
    }
});

function TableRow(props) {
    const { row, isItemSelected, onClick, headCells, setModalType } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <MUITableRow
                className={classes.root}
                key={row.name}
                hover
                style={{ backgroundColor: 'silver' }}
            >
                {headCells.map((cell) => {
                    return (
                        <TableCell
                            key={cell.id}
                            style={{ ...cell.tdStyle, fontWeight: 'bold' }}
                            component="td"
                            scope="row"
                        >
                            {cell.transform
                                ? cell.transform(row[cell.id])
                                : row[cell.id]}
                        </TableCell>
                    );
                })}

                {row.type === 'release' && (
                    <>
                        <TableCell component="td" scope="row" padding="none" style={{ width: '15%' }}>
                            <Button
                                variant="text"
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                startIcon={<CloudUploadIcon />}
                            >
                                Import
                            </Button>
                        </TableCell>
                        <TableCell component="td" scope="row" padding="none" style={{ width: '15%' }}>
                            <Button
                                variant="text"
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                startIcon={<OpenInNewIcon />}
                            >
                                Export
                            </Button>
                        </TableCell>
                        <TableCell
                            size="small"
                            style={{ width: '1%', textAlign: 'right' }}
                            colSpan={headCells.length}
                        >
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </IconButton>
                        </TableCell>
                    </>
                )}
            </MUITableRow>
            {open && row.pages.map((page) => {
                    return (
                        <MUITableRow
                            className={classes.root}
                            key={page.name}
                            hover
                            onClick={(event) => onClick(event, page.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                        >
                            {headCells.map((cell) => {
                                return (
                                    <TableCell
                                        key={cell.id}
                                        style={cell.style}
                                        component="td"
                                        scope="row"
                                    >
                                        {cell.transform
                                            ? cell.transform(page[cell.id])
                                            : page[cell.id]}
                                    </TableCell>
                                );
                            })}
                            <TableCell component="td" scope="row" padding="none" />
                            <TableCell component="td" scope="row" padding="none" />
                            <TableCell component="td" scope="row" padding="none">
                                <IconButton>
                                    <VisibilityIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell component="td" scope="row" padding="none">
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell component="td" scope="row" padding="none">
                                <IconButton onClick={() => setModalType(createPageTypes.COPY, page)}>
                                    <FileCopyIcon />
                                </IconButton>
                            </TableCell>
                        </MUITableRow>
                    );
                })}
        </React.Fragment>
    );
}

export default TableRow;
