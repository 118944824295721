import { Introspecter } from '@api-platform/admin';
import Create from './VideoCreate';
import Edit from './VideoEdit';
import List from './VideoList';
import Inputs from './VideoInputElements';

export default {
    create: (props) => <Introspecter component={Create} {...props} />,
    inputs: Inputs,
    edit: (props) => <Introspecter component={Edit} {...props} />,
    list: List,
    resource: 'videos',
    name: 'videos'
};
