import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';

const sortingOptions = {
    ASC: 'A-Z',
    DESC: 'Z-A',
    MOST_WATCHED: 'Meistgesehen',
    YEAR: 'Erscheinungsjahr',
    NEWEST: 'Neueste',
    DURATION: 'Spieldauer',
    LANGUAGE: 'Sprache'
};

const filterOptions = {
    COMEDY: 'Comedy',
    HORROR: 'Horror',
    ROMANTIC: 'Romantic'
};

const tagsOptions = {
    FAMILY: 'Family',
    KIDS: 'Kids'
};

const channelPartenersList = {
    ARD: 'ARD',
    PARAMOUNT: 'Paramount',
    TNT: 'TNT'
};
const SubcategoryPageFields = ({ tags, sortingOption, channelPartener, filters, setFilters, setChannelPartener, setSorting, setTags }) => {
    return (
        <section className="editPage-section subcategory-fields">
            <div style={{ gridArea: 'tags', marginRight: '20px' }}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Tag</InputLabel>
                    <Select
                        multiple
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                        renderValue={(selected) => `(${selected.length}) ${selected.join(', ')}`}
                    >
                        {Object.values(tagsOptions).map((tagOption) => <MenuItem value={tagOption}>{tagOption }</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div style={{ gridArea: 'filters', marginRight: '20px' }}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                    <Select
                        multiple
                        value={filters}
                        onChange={(e) => setFilters(e.target.value)}
                        renderValue={(selected) => `(${selected.length}) ${selected.join(', ')}`}
                    >
                        {Object.values(filterOptions).map((filterOption) => <MenuItem value={filterOption}>{filterOption}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div style={{ gridArea: 'sorting' }}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label" required>Sorting</InputLabel>
                    <Select
                        defaultValue={sortingOption}
                        onChange={(e) => setSorting(e.target.value)}
                    >
                        {Object.values(sortingOptions).map((sortingOptionValue) => (
                            <MenuItem value={sortingOptionValue}>
                                {sortingOptionValue}
                            </MenuItem>
                                ))}
                    </Select>
                </FormControl>
            </div>
            <div style={{ gridArea: 'partener' }}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label" required>Channel Partener</InputLabel>
                    <Select
                        defaultValue={channelPartener}
                        onChange={(e) => setChannelPartener(e.target.value)}
                    >
                        {
                                Object.values(channelPartenersList).map((channelPartenerOption) => (
                                    <MenuItem value={channelPartenerOption}>
                                        {channelPartenerOption}
                                    </MenuItem>
                                    ))
                                }
                    </Select>
                </FormControl>
            </div>
        </section>
    );
};

export default SubcategoryPageFields;
