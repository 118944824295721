import React from 'react';
import {
    TextField,
    Tabs as MaterialTabs,
    Tab,
    Box,
    Typography,
    Button,
    IconButton,
    Tooltip
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
};

const Tabs = ({ currentTab, tabs, tabType, maxTabs, isSelectAssetAvailable = true, setCurrentTab, handleSetNewTeaserTab, openSearchModal }) => {
    const handleChangeCurrentTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <div className="tabs" style={{ gridArea: 'teasertabs' }}>
            <div style={{ display: 'flex' }}>
                <MaterialTabs value={currentTab} onChange={handleChangeCurrentTab} aria-label="simple tabs example">
                    {tabs.map((teaserTab) => (
                        <Tab label={teaserTab.label} />
                ))}
                </MaterialTabs>
                <Button style={{ gridArea: 'addTeaser' }} startIcon={<AddIcon />} disabled={tabs.length === maxTabs} onClick={handleSetNewTeaserTab}>Add {tabType}</Button>
            </div>
            <TabPanel value={currentTab} index={currentTab}>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField value={tabs[currentTab].asset.name || ''} label={tabs[currentTab].label} disabled/>
                        <Tooltip title="Copy asset name" aria-label="Copy asset name">
                            <IconButton
                                disabled={!tabs[currentTab].asset.name}
                                onClick={() => navigator.clipboard.writeText(tabs[currentTab].asset.name)}
                                size="small"
                            >
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Tooltip title={isSelectAssetAvailable ? 'Select asset' : 'Choose a Channel Partner first'} aria-label="Copy asset name">
                        <span>
                            <Button style={{ gridArea: 'addTeaser', margin: '0 20px' }} disabled={!isSelectAssetAvailable} variant="outlined" onClick={() => openSearchModal(tabs[currentTab].id)}>Select</Button>
                        </span>
                    </Tooltip>
                    {tabType === 'slide' ? <Button style={{ gridArea: 'addTeaser', marginRight: '20px' }} variant="outlined" startIcon={<NavigateNextIcon />} onClick={() => null}>Go to asset</Button> : null}
                    <Button style={{ gridArea: 'addTeaser' }} variant="outlined" startIcon={<DeleteOutlineIcon />} onClick={() => null}>Delete {`${tabType}`}</Button>
                </div>
            </TabPanel>
        </div>
    );
};


export default Tabs;
