import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { usePermissions, useQuery, useInput, required } from 'react-admin';
import {
    isMasterNutzerUser,
    isSuperUser,
    isWerksMeisterUser,
    ROLE_MANAGER,
    ROLE_MASTER_NUTZER,
    ROLE_TECHNIKER,
    ROLE_WERKSMEISTER
} from '../../utils/user';

const useStyles = makeStyles(() => ({
    select: {
        width: '256px',
        minHeight: '49px'
    },
    chips: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    chip: {
      margin: 2
    }
  }));

const RoleInput = () => {
    const { input, meta: { touched, error } } = useInput({ source: 'role', validate: required() });
    const classes = useStyles();
    const { permissions: userRoles } = usePermissions();
    const { data: allRoles, loading, error: queryError } = useQuery({
        type: 'getList',
        resource: 'roles',
        payload: {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name' }
        }
    });

    if (loading) return <div>Loading</div>;
    if (queryError) return <div>{queryError}</div>;
    if (!allRoles) return null;

    let filteredRoles = allRoles;
    if (!isSuperUser(userRoles) && [isMasterNutzerUser, isWerksMeisterUser].some((e) => e(userRoles))) {
        filteredRoles = allRoles.filter((e) => [ROLE_MASTER_NUTZER, ROLE_WERKSMEISTER, ROLE_TECHNIKER, ROLE_MANAGER].some((x) => x === e.name));
    }

    const changed = (e) => {
        const inputChanged = allRoles.find((el) => el['@id'] === e.target.value);
        input.onChange([inputChanged]);
    };
    const hasError = !!(touched && error);
    return (
        <FormControl required error={hasError}>
            <InputLabel id="role-input-select">Role</InputLabel>
            <Select
                error={hasError}
                labelId="role-input-select"
                className={classes.select}
                {...input}
                value={input.value && input.value[0] ? input.value[0]['@id'] : ''}
                onChange={changed}
            >
                {filteredRoles.map((role) => <MenuItem key={role['@id']} value={role['@id']}>{role.name}</MenuItem>)}
            </Select>
            {hasError && <FormHelperText>Required</FormHelperText>}
        </FormControl>
    );
};
export default RoleInput;
