import {
    People,
    VerticalSplit,
    VideoLibrary,
    CloudUpload,
    // Settings, Removed according jira : https://jira.endava.com/browse/DBNDV2DEV-2265
    LocalOffer,
    RecentActors,
    Timeline,
    SwitchVideo,
    MovieFilter,
    Movie,
    Theaters,
    PostAdd,
    Toc,
    VpnLock,
    Storage,
    NewReleases,
    BusinessCenter,
    MoveToInbox,
    SdStorage,
    Train,
    ArtTrack,
    ArrowForwardIos
} from '@material-ui/icons';

export const levels = {
    ADMINISTRATION: {
        level: 'administration',
        label: 'Administration',
        icon: People
    },
    PORTAL: {
        level: 'portal',
        label: 'Portal',
        icon: VerticalSplit
    },
    PORTAL_OLD: {
        level: 'portal old',
        label: 'Portal old',
        icon: VerticalSplit
    },
    CONTENT: {
        level: 'content',
        label: 'Content',
        icon: VideoLibrary
    },
    DELIVERY: {
        level: 'delivery',
        label: {
            en: 'Delivery',
            de: 'Lieferung'
        },
        icon: CloudUpload
    },
    TYPES: {
        level: 'types',
        label: 'Types',
        icon: ArtTrack
    }
};

export const defaultLocale = 'de';

export const entities = {
    PARAMETERS: {
        name: 'parameters',
        label: 'Parameters',
        level: levels.ADMINISTRATION,
        icon: RecentActors
    },
    PORTAL_SETTINGS: {
        name: 'portal_settings',
        label: 'Portal Settings',
        level: levels.PORTAL,
        icon: RecentActors
    },
    LOCATIONS: {
        name: 'locations',
        label: 'Locations',
        level: levels.ADMINISTRATION,
        icon: RecentActors
    },
    WORKFLOW: {
        name: 'workflow',
        label: 'Workflow',
        level: levels.ADMINISTRATION,
        icon: Timeline
    },
    PROFILES: {
        name: 'profiles',
        label: 'Profiles',
        level: levels.ADMINISTRATION,
        icon: RecentActors
    },
    PROFILE_TYPES: {
        name: 'profile_types',
        label: 'Profile Types',
        level: levels.TYPES,
        icon: ArrowForwardIos
    },
    TAGS: {
        name: 'tags',
        label: 'Tags',
        level: levels.ADMINISTRATION,
        icon: LocalOffer
    },
    //
    // Removed according jira : https://jira.endava.com/browse/DBNDV2DEV-2265
    //
    // ROLES: {
    //     name: 'roles',
    //     label: 'Roles',
    //     level: levels.ADMINISTRATION,
    //     icon: Settings
    // },
    // MODULES: {
    //     name: 'modules',
    //     label: 'Modules',
    //     level: levels.ADMINISTRATION,
    //     icon: Settings
    // },
    USERS: {
        name: 'users',
        label: 'Users',
        level: levels.ADMINISTRATION,
        icon: People,
        custom: true
    },
    AVAILS: {
        name: 'avails',
        label: 'Avails',
        level: levels.PORTAL,
        icon: VideoLibrary,
        custom: true
    },
    EPISODES: {
        name: 'episodes',
        label: 'Episodes',
        level: levels.PORTAL_OLD,
        icon: VideoLibrary
    },
    SEASONS: {
        name: 'seasons',
        label: 'Seasons',
        level: levels.PORTAL,
        icon: SwitchVideo,
        custom: true
    },
    SERIES: {
        name: 'series',
        label: 'Series',
        level: levels.PORTAL,
        icon: VideoLibrary,
        custom: true
    },
    TEASERS: {
        name: 'teasers',
        label: 'Teasers',
        level: levels.PORTAL_OLD,
        icon: MovieFilter
    },
    MOVIES: {
        name: 'movies',
        label: 'Movies',
        level: levels.PORTAL,
        icon: MovieFilter,
        custom: true
    },
    MOVIES_OLD: {
        name: 'movies',
        label: 'Movies',
        level: levels.PORTAL_OLD,
        icon: MovieFilter
    },
    TEASER_TYPES: {
        name: 'teaser_types',
        label: 'Teaser Types',
        level: levels.TYPES,
        icon: ArrowForwardIos
    },
    CONTENTS: {
        name: 'contents',
        label: 'Contents',
        level: levels.PORTAL_OLD,
        icon: Toc
    },
    PAGES: {
        name: 'pages',
        label: 'Pages',
        level: levels.PORTAL,
        icon: PostAdd,
        custom: true
    },
    MEDIA: {
        name: 'media',
        label: 'Medias',
        level: levels.PORTAL_OLD,
        icon: Theaters,
        custom: true
    },
    MEDIA_TYPES: {
        name: 'media_types',
        label: 'Media Types',
        level: levels.TYPES,
        icon: ArrowForwardIos
    },
    CONTENT_TYPES: {
        name: 'content_types',
        label: 'Content Types',
        level: levels.TYPES,
        icon: ArrowForwardIos
    },
    FORMATS: {
        name: 'formats',
        label: 'Formats',
        level: levels.PORTAL_OLD,
        icon: Theaters
    },
    FORMAT_TYPES: {
        name: 'format_types',
        label: 'Format Types',
        level: levels.TYPES,
        icon: ArrowForwardIos
    },
    METADATA: {
        name: 'metadata',
        label: 'Metadata',
        level: levels.PORTAL_OLD,
        icon: Theaters,
        custom: true
    },
    METADATA_TYPES: {
        name: 'metadata_types',
        label: 'Metadata Types',
        level: levels.TYPES,
        icon: ArrowForwardIos
    },
    CATEGORIES: {
        name: 'categories',
        label: 'Categories',
        level: levels.TYPES,
        icon: ArrowForwardIos
    },
    VIDEOS: {
        name: 'videos',
        label: 'Videos',
        level: levels.PORTAL_OLD,
        icon: Movie,
        custom: true
    },
    COLLECTIONS: {
        name: 'collections',
        label: 'Collections',
        level: levels.PORTAL_OLD,
        icon: VideoLibrary
    },
    RELEASES: {
        name: 'releases',
        label: 'Releases',
        level: levels.PORTAL,
        icon: NewReleases,
        custom: true
    },
    ITEMS: {
        name: 'items',
        label: 'Items',
        level: levels.PORTAL_OLD,
        icon: NewReleases
    },
    LICENCES: {
        name: 'licences',
        label: 'Licences',
        level: levels.CONTENT,
        icon: VpnLock
    },
    CONTENT_PROVIDERS: {
        name: 'content_providers',
        label: 'Content Providers',
        level: levels.CONTENT,
        icon: Storage
    },
    LOCKS: {
        name: 'locks',
        label: 'Locks',
        level: levels.DELIVERY,
        icon: MoveToInbox,
        custom: true
    },
    DELIVERY_CASES: {
        name: 'delivery_cases',
        label: 'Cases',
        level: levels.DELIVERY,
        icon: BusinessCenter,
        custom: true
    },
    S_S_DS: {
        name: 's_s_ds',
        label: 'SSDs',
        level: levels.DELIVERY,
        icon: SdStorage,
        custom: true
    },
    TRAINUNITS: {
        name: 'trainunits',
        label: 'Train units',
        level: levels.DELIVERY,
        icon: Train,
        custom: true
    },
    TRAIN_TYPES: {
        name: 'train_types',
        label: 'Train types',
        level: levels.TYPES,
        icon: ArrowForwardIos
    }
};

export const videoStatuses = [
    {
        id: 'ReadyForPicking',
        status: 'Ready for Picking',
        backgroundColor: '#BA68C8',
        description: 'Content is imported and ready to be picked'
    },
    {
        id: 'PickedForRelease',
        status: 'Picked for Release',
        backgroundColor: '#64B5F6',
        description: 'Content is picked for release'
    },
    {
        id: 'ReadyForOrder',
        status: 'Ready for Order',
        backgroundColor: '#ff6600',
        description: 'Content is picked and ready for ordering'
    },
    {
        id: 'Ordered',
        status: 'Ordered',
        backgroundColor: '#39A275',
        description: 'Content is ordered'
    }
];

export const releaseStatuses = [
    {
        id: 'Planning',
        status: 'Planning',
        backgroundColor: '#FFAB91',
        description: 'Release is in planning process'
    },
    {
        id: 'ReadyForApproval',
        status: 'Ready for Approval',
        backgroundColor: '#A5D6A7',
        description: 'Release is planned and ready to be approved'
    },
    {
        id: 'Approved',
        status: 'Approved',
        backgroundColor: '#75C0E0',
        description: 'Release is approved and ready to be ordered'
    },
    {
        id: 'Ordered',
        status: 'Ordered',
        backgroundColor: '#39A275',
        description: 'Release is ordered'
    }
];

export const mediaStatuses = [
    {
        id: 'Ingest',
        status: 'Ingest',
        backgroundColor: '#ff9900',
        description: 'Media is in Ingest process'
    },
    {
        id: 'QualityCheck',
        status: 'Quality Check',
        backgroundColor: '#0080ff',
        description: 'Media is in Quality Check stage'
    },
    {
        id: 'Error',
        status: 'Error',
        backgroundColor: '#ff3333',
        description: 'Media processing encountered errors'
    },
    {
        id: 'ReadyForTranscoding',
        status: 'Ready For Transcoding',
        backgroundColor: '#00b33c',
        description: 'Media is ready for transcoding'
    }
];

export const releaseColors = [
    {
        code: '#ff0000',
        fadedCode: '#ff8080',
        label: 'Red'
    },
    {
        code: '#ffff00',
        fadedCode: '#ffffb3',
        label: 'Yellow'
    },
    {
        code: '#0000ff',
        fadedCode: '#3399ff',
        label: 'Blue'
    },
    {
        code: '#00b33c',
        fadedCode: '#00cc99',
        label: 'Green'
    }
];

export const availsMoviesWorkflowStatuses = [
    'ReadyForPicking',
    'PickedForRelease',
    'ReadyForOrder',
    'Ordered'
];

export const availsSeasonsWorkflowStatuses = [
    'Draft',
    'Published'
];

export const availsSeriesWorkflowStatuses = [
    'Draft',
    'Published'
];

export const formValidationMessages = {
    required: 'This field is mandatory'
};

export const ssdStatuses = [
    {
        id: 'OnEndavaLocation',
        status: {
            en: 'On Endava Location',
            de: 'Bei Endava'
        },
        backgroundColor: '#0E6251',
        description: {
            en: 'The SSD is in Endava Location',
            de: 'Die SSD befindet sich im Endava Büro'
        }
    },
    {
        id: 'DeliveringToDB',
        status: {
            en: 'Delivering To DB',
            de: 'Lieferung zu Deutsche Bahn'
        },
        backgroundColor: '#117864',
        description: {
            en: 'The SSD is in tranzit to DB',
            de: 'Die SSD ist auf dem Weg zur DB'
        }
    },
    {
        id: 'Delivered',
        status: {
            en: 'Delivered',
            de: 'Angekommen'
        },
        backgroundColor: '#148F77',
        description: {
            en: 'Delivered',
            de: 'Angekommen'
        }
    },
    {
        id: 'Checkin',
        status: {
            en: 'CheckIn',
            de: 'Eingebaut'
        },
        backgroundColor: '#17A589',
        description: {
            en: 'CheckIn',
            de: 'Eingebaut'
        }
    },
    {
        id: 'Pending',
        status: {
            en: 'Pending',
            de: 'Steht aus'
        },
        backgroundColor: '#1ABC9C',
        description: {
            en: 'Pending',
            de: 'Steht aus'
        }
    },
    {
        id: 'Productive',
        status: {
            en: 'Productive',
            de: 'Produktiv'
        },
        backgroundColor: '#48C9B0',
        description: {
            en: 'Productive',
            de: 'Produktiv'
        }
    },
    {
        id: 'OnDBLocation',
        status: {
            en: 'On DB Location',
            de: 'Im Werk'
        },
        backgroundColor: '#76D7C4',
        description: {
            en: 'The SSD is in DB Location',
            de: 'SSD ist ausgebaut im Werk'
        }
    },
    {
        id: 'Returning',
        status: {
            en: 'Returning',
            de: 'Rückweg'
        },
        backgroundColor: '#A3E4D7',
        description: {
            en: 'Returning',
            de: 'SSD befindet sich uaf dem Rückweg'
        }
    }
];

export const caseStatuses = [
    {
        id: 'OnEndavaLocation',
        status: {
            en: 'On Endava Location',
            de: 'Bei Endava'
        },
        backgroundColor: '#0E6251',
        description: {
            en: 'The Case is in Endava Location',
            de: 'Case befindet sich im Endava Büro'
        }
    },
    {
        id: 'DeliveringToDB',
        status: {
            en: 'Delivering To DB',
            de: 'Lieferung zu Deutsche Bahn'
        },
        backgroundColor: '#117864',
        description: {
            en: 'The Case is in tranzit to DB',
            de: 'Case ist auf dem Weg zur DB'
        }
    },
    {
        id: 'Delivered',
        status: {
            en: 'Case Delivered',
            de: 'Case Angekommen'
        },
        backgroundColor: '#148F77',
        description: {
            en: 'Case Delivered',
            de: 'Case Angekommen'
        }
    },
    {
        id: 'CheckIn',
        status: {
            en: 'Case checkIn',
            de: 'Case eingecheckt'
        },
        backgroundColor: '#17A589',
        description: {
            en: 'Case checkIn',
            de: 'Case eingecheckt'
        }
    },
    {
        id: 'OnDBLocation',
        status: {
            en: 'On DB Location',
            de: 'Case ausgecheckt'
        },
        backgroundColor: '#1ABC9C',
        description: {
            en: 'The Case is in DB Location',
            de: 'Case ist bereit abgeholt zu werden'
        }
    },
    {
        id: 'Returning',
        status: {
            en: 'Returning',
            de: 'Rückweg'
        },
        backgroundColor: '#48C9B0',
        description: {
            en: 'Returning',
            de: 'Case befindet sich auf dem Rückweg'
        }
    }
];

export const genreList = [
    'Action',
    'Animation',
    'Arthouse',
    'Comedy',
    'Dokumentation',
    'Drama',
    'Infotainment',
    'Kids & Family',
    'Krimi & Thriller',
    'Romance',
    'Sci-Fi & Fantasy'
];
