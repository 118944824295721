import React from 'react';
import {
    TextInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput
} from 'react-admin';

function SSDSInputElements({ edit }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextInput label="serialNumber" source="serialNumber" />
            <TextInput label="comment" source="comment" />
            <TextInput label="VM-Version" source="vmImage" />
            <TextInput label="ssds.linkToSSH" source="linkToSsh" />
            {edit && (
                <ReferenceInput
                    label="Case"
                    source="deliveryCase"
                    reference="delivery_cases"
                >
                    <SelectInput disabled optionText="serialNumber" />
                </ReferenceInput>
            )}
            <ReferenceInput
                label="ssds.releaseNumber"
                source="release[@id]"
                reference="releases"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="trainNumber"
                source="train"
                reference="trainunits"
                perPage={999}
            >
                <AutocompleteInput optionText={({ tzn } = {}) => `${tzn}`} fullWidth />
            </ReferenceInput>
        </div>
    );
}

export default SSDSInputElements;
