import React from 'react';
import ReactDOM from 'react-dom';
import HydraApp from './HydraApp';
import 'fontsource-roboto';
import './index.scss';

// Log BUILD_TAG_VERSION
if (window.BUILD_TAG_VERSION_STRING) {
    // eslint-disable-next-line no-console
    console.log(`Build version: ${window.BUILD_TAG_VERSION_STRING}`);
}
ReactDOM.render(<HydraApp />, document.getElementById('root'));
