import { Introspecter } from '@api-platform/admin';
import Create from './MediaCreate';
import Edit from './MediaEdit';
import List from './MediaList';
import Inputs from './MediaInputElements';

export default {
    create: (props) => <Introspecter component={Create} {...props} />,
    inputs: Inputs,
    edit: (props) => <Introspecter component={Edit} {...props} />,
    list: List,
    resource: 'media',
    name: 'media'
};
