export const ROLE_SUPERUSER = 'ROLE_SUPERUSER';
export const ROLE_MASTER_NUTZER = 'ROLE_MASTER_NUTZER';
export const ROLE_WERKSMEISTER = 'ROLE_WERKSMEISTER';
export const ROLE_TECHNIKER = 'ROLE_TECHNIKER';
export const ROLE_MANAGER = 'ROLE_MANAGER';

export const USER_ROLES = {
    ROLE_SUPERUSER,
    ROLE_MASTER_NUTZER,
    ROLE_WERKSMEISTER,
    ROLE_TECHNIKER,
    ROLE_MANAGER
};

export const isSuperUser = (userRoles = []) => userRoles.some((role) => role === USER_ROLES.ROLE_SUPERUSER);

export const isMasterNutzerUser = (userRoles = []) => userRoles.some((role) => role === USER_ROLES.ROLE_MASTER_NUTZER);

export const isWerksMeisterUser = (userRoles = []) => userRoles.some((role) => role === USER_ROLES.ROLE_WERKSMEISTER);

export const isTechnikerUser = (userRoles = []) => userRoles.some((role) => role === USER_ROLES.ROLE_TECHNIKER);

export const isManagerUser = (userRoles = []) => userRoles.some((role) => role === USER_ROLES.ROLE_MANAGER);

export const isMenuFirstLevelAdministration = (menuFirstLevelName) => menuFirstLevelName === 'administration';

export const isMenuFirstLevelDelivery = (menuFirstLevelName) => menuFirstLevelName === 'delivery';

export const isMenuSecondLevelUsers = (menuSecondLevelName) => menuSecondLevelName === 'users';

export const isMenuSecondLevelCases = (menuSecondLevelName) => menuSecondLevelName === 'delivery_cases';

export const isMenuSecondLevelSSDS = (menuSecondLevelName) => menuSecondLevelName === 's_s_ds';

export const isEditUsersAccessible = (userRoles = []) => [
    isSuperUser,
    isMasterNutzerUser,
    isWerksMeisterUser].some((verifyRoles) => verifyRoles(userRoles));

export const isCreateUsersAccessible = (userRoles = []) => [
    isSuperUser,
    isMasterNutzerUser,
    isWerksMeisterUser].some((verifyRoles) => verifyRoles(userRoles));

export const isEditCasesAccessible = (userRoles = []) => [
    isSuperUser,
    isMasterNutzerUser,
    isWerksMeisterUser].some((verifyRoles) => verifyRoles(userRoles));

export const isCreateCasesAccessible = (userRoles = []) => [
    isSuperUser].some((verifyRoles) => verifyRoles(userRoles));

export const isDeleteCasesAccessible = (userRoles = []) => [
    isSuperUser].some((verifyRoles) => verifyRoles(userRoles));

export const isSaveCasesAccessible = (userRoles = []) => [
    isSuperUser].some((verifyRoles) => verifyRoles(userRoles));

export const isCreateLocksAccessible = (userRoles = []) => [
    isSuperUser].some((verifyRoles) => verifyRoles(userRoles));

export const isEditLocksAccessible = (userRoles = []) => [
    isSuperUser
].some((verifyRoles) => verifyRoles(userRoles));

export const isDeleteLocksAccessible = (userRoles = []) => [
    isSuperUser].some((verifyRoles) => verifyRoles(userRoles));

export const isNextTransitionButtonVisible = (userRoles = []) => [
    isSuperUser].some((verifyRoles) => verifyRoles(userRoles));

export const isEditSSDsAccessible = (userRoles = []) => [
    isSuperUser,
    isWerksMeisterUser
    ].some((verifyRoles) => verifyRoles(userRoles));

export const isCreateSSDsAccessible = (userRoles = []) => [
    isSuperUser
    ].some((verifyRoles) => verifyRoles(userRoles));

export const isDeleteSSDsAccessible = (userRoles = []) => [
    isSuperUser
    ].some((verifyRoles) => verifyRoles(userRoles));


export const isDetailsTabAccesibleInEditSSDs = (userRoles = []) => [
    isSuperUser
].some((verifyRoles) => verifyRoles(userRoles));

export const isStatusTabAccesibleInEditSSDs = (userRoles = []) => [
    isSuperUser
].some((verifyRoles) => verifyRoles(userRoles));

export const areCasesCheckInCheckOutButtonsEnabled = (userRoles = []) => [
    isSuperUser,
    isMasterNutzerUser,
    isWerksMeisterUser].some((verifyRoles) => verifyRoles(userRoles));

export const areSSDsCheckInCheckOutButtonsEnabled = (userRoles = []) => [
    isSuperUser,
    isMasterNutzerUser,
    isWerksMeisterUser,
    isTechnikerUser].some((verifyRoles) => verifyRoles(userRoles));

export const isDetailsTabAccessibleInEditCases = (userRoles = []) => [
    isSuperUser
].some((verifyRoles) => verifyRoles(userRoles));

export const isLogsTabAccessibleInEditCases = (userRoles = []) => [
    isSuperUser
].some((verifyRoles) => verifyRoles(userRoles));

export const userHasAccessToMenuFirstLevel = (userRoles, menuFirstLevelName) => {
    // the following checks should go from users with full accessibility to users with low accessibility
    // so keep this in mind when editing this function

    if (isSuperUser(userRoles)) {
        // has access to every item in first level menu
        return true;
    }

    if (isMasterNutzerUser(userRoles)) {
        // has access only to Administration and Delivery levels
        return isMenuFirstLevelAdministration(menuFirstLevelName) || isMenuFirstLevelDelivery(menuFirstLevelName);
    }

    if (isWerksMeisterUser(userRoles)) {
        // has access only to Administration and Delivery levels (same as MasterNutzerUser in first level)
        return isMenuFirstLevelAdministration(menuFirstLevelName) || isMenuFirstLevelDelivery(menuFirstLevelName);
    }

    if (isManagerUser(userRoles)) {
        // has access only to Delivery levels
        return isMenuFirstLevelDelivery(menuFirstLevelName);
    }

    if (isTechnikerUser(userRoles)) {
        // has access only to Delivery levels (same as manager in first level)
        return isMenuFirstLevelDelivery(menuFirstLevelName);
    }

    return false;
};

export const userHasAccessToMenuSecondLevel = (userRoles, menuFirstLevelName, secondLevelName) => {
    // the following checks should go from users with full accessibility to users with low accessibility
    // so keep this in mind when editing this function

    if (isSuperUser(userRoles)) {
        // has access to every item in first level menu
        return true;
    }

    if (isMasterNutzerUser(userRoles)) {
        // has access only to Administration and Delivery levels
        if (isMenuFirstLevelAdministration(menuFirstLevelName)) {
            // has access only to users in the administration section
            return isMenuSecondLevelUsers(secondLevelName);
        }

        if (isMenuFirstLevelDelivery(menuFirstLevelName)) {
            // has access only to cases and ssds in delivery section
            return isMenuSecondLevelCases(secondLevelName) || isMenuSecondLevelSSDS(secondLevelName);
        }
    }

    if (isWerksMeisterUser(userRoles)) {
        // has access only to Administration and Delivery levels
        if (isMenuFirstLevelAdministration(menuFirstLevelName)) {
            // has access only to users in the administration section
            return isMenuSecondLevelUsers(secondLevelName);
        }

        if (isMenuFirstLevelDelivery(menuFirstLevelName)) {
            // has access only to cases and ssds in delivery section
            return isMenuSecondLevelCases(secondLevelName) || isMenuSecondLevelSSDS(secondLevelName);
        }
    }

    if (isManagerUser(userRoles)) {
        // has access only to Delivery levels
        if (isMenuFirstLevelDelivery(menuFirstLevelName)) {
            // has access only to cases and ssds in delivery section
            return isMenuSecondLevelCases(secondLevelName) || isMenuSecondLevelSSDS(secondLevelName);
        }
    }

    if (isTechnikerUser(userRoles)) {
        // has access only to Delivery levels (same as manager in first level)
        if (isMenuFirstLevelDelivery(menuFirstLevelName)) {
            // has access only and ssds in delivery section
            return isMenuSecondLevelSSDS(secondLevelName);
        }
    }

    return false;
};
