/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React from 'react';
import classNames from 'classnames';
import topTeaserSliderPng from '../../assets/top-teaser-slider.png';
import teaserLanePng from '../../assets/teaserlane.png';
import teaserLaneSpecialPng from '../../assets/teaserlane-special.png';
import teaserLaneShowcasePng from '../../assets/teaserlane-showcase.png';
import navigationPng from '../../assets/navigation.png';
import footerPng from '../../assets/footer.png';
import filtersPng from '../../assets/filters.png';
import sortingPng from '../../assets/sorting.png';

const contentBgImgsDictionary = {
    topTeaserSlider: topTeaserSliderPng,
    filters: filtersPng,
    sorting: sortingPng,
    teaserLane: teaserLanePng,
    navigation: navigationPng,
    footer: footerPng,
    teaserLaneSpecial: teaserLaneSpecialPng,
    teaserLaneShowcase: teaserLaneShowcasePng
};

function RDB_PageContentList({ data, selectedContent, onSelectedRowChange }) {
    return (
        <div className="page-content">
            {data.length > 0 ? data.map((content) => {
                const selected = selectedContent === content.id;
                return (
                    <div st className={classNames('page-content__content', { 'page-content__content--selected': selected })} role="button" onClick={() => onSelectedRowChange(content.id)}>
                        <img src={contentBgImgsDictionary[content.type]} alt="x" />
                    </div>
                );
            }) : <div style={{ textAlign: 'center', padding: '15px 10px' }}>There are no records to display</div>}
        </div >
    );
}

export default RDB_PageContentList;
