/* eslint-disable max-len */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DataTable from 'react-data-table-component';
import { Paper } from '@material-ui/core';
import { extractBaseUrl } from '../../utils/helpers';
import RDB_PageContentList from './RDB_PageContentList';

const contentLabelDictionary = {
    topTeaserSlider: 'Top Teaser Slider',
    filters: 'Filters section',
    sorting: 'Sorting section',
    teaserLane: 'Teaserlane',
    navigation: 'Navigation',
    footer: 'Footer',
    teaserLaneSpecial: 'Teaserlane Special',
    teaserLaneShowcase: 'Teaserlane Showcase'
};
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    }
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ExpandedComponent = (props) => {
    return <div style={{ paddingLeft: '20px' }}><pre>{JSON.stringify(props.logs, null, 2)}</pre></div>;
};

const logTypes = {
    ERRORS: 'errors',
    NOTICES: 'notices',
    INFOS: 'infos'
};

const JSON_ImportLogsView = ({ importWorkerId, open, onClose }) => {
    const classes = useStyles();
    const notify = useNotify();
    const [logs, setLogs] = useState(null);
    const [activeLogsIdx, setActiveLogsIdx] = useState(null);
    const [activeLogs, setActiveLogs] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [selectedContent, setSelectedContent] = useState(null);

    const columns = [
        {
            name: 'Key',
            selector: (row) => row.pageKey,
            sortable: true
        },
        {
            name: 'Page Name',
            selector: (row) => row.name,
            sortable: true
        },
        {
            name: 'Page Logs',
            cell: (row) => (row.logs.length > 0 ? 'Yes' : 'No'),
            sortable: true
        }
    ];

    useEffect(() => {
        setSelectedContent(null);
    }, [selectedPage]);

    useEffect(() => {
        setSelectedPage(null);
        setSelectedContent(null);
    }, [activeLogsIdx]);

    useEffect(() => {
        if (importWorkerId) {
            fetch(`${extractBaseUrl(global.RA_API_ENTRYPOINT)}/api/import_workers/${importWorkerId}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.logs) {
                        setLogs(data.logs);
                    }
                })
                .catch(() => notify(`Unable to retrieve logs for ${importWorkerId}.`));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importWorkerId]);

    const getPageTableData = () => {
        if (activeLogs !== null) {
            if (Array.isArray(activeLogs)) {
                return [];
            }
            if (Object.keys(activeLogs).length > 0) {
                return Object.keys(activeLogs).map((pageKey) => ({ ...activeLogs[pageKey], pageKey }));
            }
        } else {
            return [];
        }
        return [];
    };

    const conditionalRowStyles = [
        {
            when: (row) => (selectedPage && row.pageKey === selectedPage.pageKey) || false,
            style: {
                backgroundColor: '#F9E79F',
                color: '#000',
                '&:hover': {
                    cursor: 'pointer'
                }
            }
        }
    ];

    const getSelectedPageContent = () => {
        if (selectedPage) {
            return Object.keys(selectedPage.contents).map((contentKey) => ({ ...selectedPage.contents[contentKey], id: contentKey })).sort((a, b) => a.position - b.position);
        }
        return [];
    };

    const getLogsCount = (logType) => {
        if (!logs) {
            return 0;
        }
        if (Array.isArray(logs[logType])) {
            return 0;
        }
        const counter = Object.keys(logs[logType]).reduce((acc, pageKey) => {
            Object.keys(logs[logType][pageKey].contents).forEach((contentKey) => {
                // eslint-disable-next-line no-param-reassign
                acc += logs[logType][pageKey].contents[contentKey].logs.length;
            });
            return acc;
        }, 0);
        return counter;

    };

    return (
        <div>
            <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Page Import Logs (JSON)
                        </Typography>
                        <Button autoFocus variant={activeLogsIdx === 0 ? 'outlined' : 'secondary'} color="inherit" onClick={() => { setActiveLogsIdx(0); setActiveLogs(logs.errors); }}>
                            Errors ({getLogsCount(logTypes.ERRORS)})
                        </Button>
                        <Button autoFocus variant={activeLogsIdx === 1 ? 'outlined' : 'secondary'} color="inherit" onClick={() => { setActiveLogsIdx(1); setActiveLogs(logs.notices); }}>
                            Notices ({getLogsCount(logTypes.NOTICES)})
                        </Button>
                        <Button autoFocus variant={activeLogsIdx === 2 ? 'outlined' : 'secondary'} color="inherit" onClick={() => { setActiveLogsIdx(2); setActiveLogs(logs.infos); }}>
                            Infos ({getLogsCount(logTypes.INFOS)})
                        </Button>
                        <Button
                            style={{ marginLeft: '10px' }}
                            variant="outlined"
                            color="inherit"
                            onClick={() => {
                                const blob = new Blob([JSON.stringify(logs, null, 4)], { type: 'application/json' });
                                const href = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.href = href;
                                link.setAttribute('download', `JSON_export_${Date.now()}.json`);
                                document.body.appendChild(link);
                                link.click();
                            }}
                        >
                            DOWNLOAD LOGS
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="JSON_ImportLogsView">
                    <Paper className="JSON_ImportLogsView__page-list">
                        <div style={{ padding: '10px 0px', fontWeight: 'bold' }}>Pages</div>
                        <DataTable
                            dense
                            columns={columns}
                            data={getPageTableData()}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            expandableRowDisabled={(row) => row.logs.length === 0}
                            conditionalRowStyles={conditionalRowStyles}
                            onRowClicked={(row) => setSelectedPage(row)}
                        />
                    </Paper>
                    <Paper className="JSON_ImportLogsView__content-list">
                        <div style={{ padding: '10px 0px', fontWeight: 'bold' }}>Contents</div>
                        <RDB_PageContentList
                            data={getSelectedPageContent()}
                            onSelectedRowChange={(contentId) => setSelectedContent({ ...selectedPage.contents[contentId], id: contentId })}
                            selectedContent={selectedContent?.id}
                        />
                    </Paper>
                    <Paper className="JSON_ImportLogsView__messages">
                        <div style={{ padding: '10px 0px', fontWeight: 'bold' }}>Messages</div>
                        <h4>{selectedContent && `${contentLabelDictionary[selectedContent.type]} (${selectedContent.name}) - position ${selectedContent.position}`}</h4>
                        <ul>
                            {
                                (selectedContent && selectedContent.logs && selectedContent.logs.map((log) => <li>{log}</li>)) ||
                                <div style={{ textAlign: 'center', padding: '15px 10px' }}>There are no records to display</div>
                            }
                        </ul>
                    </Paper>
                </div>
            </Dialog>
        </div >
    );
};
export default JSON_ImportLogsView;
