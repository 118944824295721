import { Show, SimpleShowLayout, TextField, TopToolbar, ListButton, EditButton, usePermissions } from 'react-admin';
import {
    isWerksMeisterUser,
    isEditLocksAccessible
} from '../../utils/user';

const LocaksShowToolbar = ({ basePath, data, userRoles }) => {
    const customBasePath = isWerksMeisterUser(userRoles) ? '/delivery_cases' : basePath;

    return (
        <TopToolbar>
            <ListButton basePath={customBasePath} record={data} />
            {isEditLocksAccessible(userRoles) ? <EditButton basePath={basePath} record={data} /> : null}
        </TopToolbar>
    );
};

export default (props) => {
    const { permissions: userRoles } = usePermissions();
    return (
        <Show
            {...props}
            actions={<LocaksShowToolbar userRoles={userRoles} />}
        >
            <SimpleShowLayout>
                <TextField source="status" />
                <TextField source="name" />
                <TextField source="comment" />
                <TextField source="code" />
            </SimpleShowLayout>
        </Show>
);
};
