import React from 'react';
import { TextInput, ReferenceInput, SelectInput } from 'react-admin';

const MetadataInputElements = () => {
    return (
        <>
            <ReferenceInput
                label="Metadata type"
                source="metadataType[@id]"
                reference="metadata_types"
                fullWidth
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="content" fullWidth />
        </>
    );
};

export default MetadataInputElements;
