(function(){var g={}; var _ = _ || {};
var f=function(window){var yq="No thumbnail duration or URL specified",zq="No thumbnail found for position ",Aq="Position is out of range: ",Bq="Stream configuration is invalid",Cq="Thumbnail URL is mandatory",Dq="Wrong gridSize format",Eq="clpp.thumbnails",Fq=function(a,b,c,d,e,f,g,k,l,m,n,p){d=void 0===d?0:d;e=void 0===e?0:e;f=void 0===f?0:f;g=void 0===g?0:g;k=void 0===k?1:k;l=void 0===l?1:l;m=void 0===m?0:m;n=void 0===n?0:n;p=void 0===p?new Image:p;this.time=b;this.a=p;this.src=a;this.duration=c;this.x=d;this.y=e;this.width=
f;this.height=g;this.g=k;this.m=l;this.h=m;this.j=n;this.f=null},Gq=function(){this.f=new _.D(Eq);this.a=this.l=null},Hq=function(a){if(!a)return null;a=new _.Uk(a);return a.path.match(/.*\.vtt$/i)?"WEBVTT":a.path.match(/.*\.bif$/i)?"BIF":a.path.match(/.*\.(png|jpg|jpeg)$/i)?"SINGLE":null},Iq=function(){},Jq=function(a,b){this.time=a;this.duration=0;this.offset=b;this.length=0;this.a=new _.lk},Kq=function(){this.g=new _.D(Eq);this.f=new ArrayBuffer(0);this.a=[];this.h=new _.lk},Lq=function(a){var b=
a.f.byteLength;a.a.forEach(function(c){c.offset+c.length<b&&c.a.resolve()})},Nq=function(a,b){return Mq(a,b).then(function(c){return new Fq(c,b.time,b.duration)})},Mq=function(a,b){return b.load().then(function(){var c=_.I(a.f);c=new Uint8Array(Array.prototype.slice.call(c,b.offset,b.offset+b.length));return"data:image/jpeg;base64,"+_.ag(c)})},Oq=function(){this.log=new _.D(Eq);this.m=null;this.f=[];this.D=!1},Qq=function(a,b){b.reduce(function(c,d){var e=d,f=!1;typeof d===_.wd&&(e=d.step,f=d.preloadWhileBuffering);
return c.then(function(){return Pq(a,0,e,!!f)})},Promise.resolve())},Pq=function(a,b,c,d){if(a.D||b>=a.f.length)return Promise.resolve();var e=b+c;return Rq(a,d).then(function(){return a.f[b].load()}).then(function(){return Pq(a,e,c,d)})},Rq=function(a,b){return new Promise(function(c){if(b||a.m.getState()!==_.$o)c();else a.m.one(_.fc,function(){c()})})},Sq=function(){Oq.call(this);this.a=null},Tq=function(a){a=a.split(/\.|:/).map(parseFloat);var b=0,c,d={3:.001,2:1,1:60,0:3600};for(c=a.length-1;0<=
c;c--)b+=a[c]*d[c];return b},Uq=function(a){Oq.call(this);this.C=a;this.a=null;this.j=this.g=1;this.w=this.A=0;this.h=1;this.o=-1},Vq=function(a,b){if(!b||_.B.N(b.horizontalTiles)||_.B.N(b.verticalTiles)||_.B.N(b.width)||_.B.N(b.height)||0>=b.horizontalTiles||0>=b.verticalTiles||0>=b.width||0>=b.height)throw new _.J(_.K,7,7100,"Selected stream contains one or more invalid configuration values");a.a=b;a.g=a.a.horizontalTiles||1;a.j=a.a.verticalTiles||1;a.h=a.g*a.j;a.o=a.a.durationPerTile||0;var c=
a.a.width,d=a.a.height;void 0!==c&&(a.A=c/a.g);void 0!==d&&(a.w=d/a.j)},Wq=function(){Oq.call(this);this.a=null;this.j=this.g=1;this.h=0};Fq.prototype.raw=function(){return this.a.cloneNode(!1)};
Fq.prototype.element=function(a,b){var c=this.width,d=this.height,e=this.width/this.height;void 0!==a&&void 0===b&&(b=0);null!==a&&void 0!==a&&(c=a);null!==b&&void 0!==b&&(d=b);c||(c=Math.ceil(d*e));d||(d=Math.ceil(c/e));e=document.createElement(_.Gc);e.style.width=c+_.Qd;e.style.height=d+_.Qd;e.style.backgroundColor="#000";e.style.backgroundRepeat="no-repeat";e.style.backgroundImage="url("+this.src+")";e.style.backgroundSize=Math.ceil(c/this.width*(this.a.naturalWidth||this.a.width))+"px "+Math.ceil(d/
this.height*(this.a.naturalHeight||this.a.height))+_.Qd;var f=this.y/this.height;e.style.backgroundPositionX="-"+this.x/this.width*c+_.Qd;e.style.backgroundPositionY="-"+f*d+_.Qd;return e};
Fq.prototype.load=function(){var a=this;this.f||(this.f=new Promise(function(b,c){function d(){a.width||(a.width=(a.a.naturalWidth||a.a.width)/a.g);a.height||(a.height=(a.a.naturalHeight||a.a.height)/a.m);a.x||(a.x=a.width*a.h);a.y||(a.y=a.height*a.j)}if(a.a.src&&a.a.complete)d(),b(a);else{var e=null,f=function(){d();a.a.removeEventListener("load",f);a.a.removeEventListener(_.Sc,e);b(a)};e=function(){a.a.removeEventListener("load",f);a.a.removeEventListener(_.Sc,e);c()};a.a.addEventListener("load",
f);a.a.addEventListener(_.Sc,e);a.a.src=a.src}}));return this.f};_.A("clpp.thumbnails.Thumbnail",Fq);Fq.prototype.load=Fq.prototype.load;Fq.prototype.element=Fq.prototype.element;Fq.prototype.raw=Fq.prototype.raw;_.h=Gq.prototype;_.h.onPlayerCreated=function(a){this.l=a};_.h.onPlayerWillDestroy=function(){this.l=null};_.h.onPlayerWillRelease=function(){this.a=null};
_.h.onContentWillLoad=function(a){var b=this,c=a.getConfiguration();if(c.thumbnails){var d=c.thumbnails;d&&(void 0===d.enabled||d.enabled)&&((c=d.mode)||(c=Hq(d.url||null)),c&&("SINGLE"===c||"GRID"===c?this.a=new Wq:"WEBVTT"===c?this.a=new Sq:"BIF"===c&&(this.a=new Kq)),this.a&&(this.f.info("Loading thumbs configuraiton",d),this.l.one(_.kd,function(){b.f.info("Initialize thumbnails provider");b.a.init(a,d)})))}else this.a=null};
_.h.onContentLoaded=function(a,b){for(var c=[],d=1;d<arguments.length;++d)c[d-1]=arguments[d];this.a||(d=(d=a.getConfiguration())&&d.thumbnails,d&&void 0!==d.enabled&&!d.enabled)||(c=c&&c.find(function(e){return Array.isArray(e)&&0<e.length}),this.f.info(_.yd,c),c&&(this.a=new Uq(c),this.a.init(a,d)))};_.h.get=function(a){return this.a?this.a.get(a):Promise.reject()};_.h.id=function(){return"thumbnails"};_.A("clpp.thumbnails.ThumbnailsPlugin",Gq);Gq.prototype.get=Gq.prototype.get;Gq.Id="thumbnails";
Iq.prototype.create=function(){return new Gq};_.Vo(new Iq);Jq.prototype.load=function(){return this.a};
Kq.prototype.init=function(a,b){var c=this;if(!b||!b.url)throw new _.J(_.K,7,7100,Cq);var d=_.$i(b.url);d.type=_.Jk;a.getNetworkEngine().fetch(d).promise.then(function(e){e=e.data;var f=_.I(c.f);e=_.I(e);for(var g=f.byteLength+e.byteLength,k=new ArrayBuffer(g),l=new DataView(k),m=0,n=0,p=f.byteLength;n<p;n++)l.setUint8(m,f[n]),m+=1;f=0;for(n=e.byteLength;f<n;f++)l.setUint8(m,e[f]),m+=1;c.f=k;c.g.info("Appended "+e.byteLength+" bytes. Total data now "+g+" bytes.")}).then(function(){var e=c.f,f=_.I(e),
g=String.fromCharCode.apply(null,Xq),k=Array.prototype.slice.call(f,0,8);f=64;var l=0;var m=!1;if(g===String.fromCharCode.apply(null,k))for(e=new DataView(e,16,20),e=e.getUint32(0,!0),0===e&&(e=1E3);!m;){l+=1;k=e;g=new DataView(c.f,f);m=g.getUint32(0,!0);k=m*k/1E3;g=g.getUint32(4,!0);g=new Jq(k,g);if(k=c.a.length)k=c.a[k-1],k.length=g.offset-k.offset,k.duration=g.time-k.time;c.a.push(g);f+=8;(m=4294967295===m)&&c.h.resolve();if(1E5<l)throw Error("There was a problem during reading BIF entries");}else throw Error("The file is not a valid BIF format");
}).then(function(){Lq(c)})["catch"](function(e){c.g.error("Error while loading thumbnail index",e)})};Kq.prototype.get=function(a){var b=this;return 0>a?Promise.reject(Aq+a):this.h.then(function(){for(var c=0;c<b.a.length;c++){var d=b.a[c];if(d.time>=a&&a<d.time+d.duration)return Nq(b,d).then(function(e){return e.load().then(function(){return e})})}return Promise.reject(zq+a)})};var Xq=[137,66,73,70,13,10,26,10];Oq.prototype.init=function(a,b){var c=this;this.m=a;a.one(_.Td,function(){c.D=!0});a.isLive()||this.B().then(function(){b&&b.preload&&Qq(c,b.preload)})};_.u(Sq,Oq);
Sq.prototype.init=function(a,b){var c=this;if(!b||!b.url)throw new _.J(_.K,7,7100,Cq);var d=b.url,e=_.$i(d);e.type=_.Jk;this.a=a.getNetworkEngine().fetch(e).promise.then(function(f){if(f=f.data){f=_.Uf(f).split(/\n/);for(var g=0;g<f.length;g++){var k=f[g],l=k.split(" --\x3e ");if(2===l.length){k=f[++g];var m=k.indexOf("\x3d");var n=0>m?[]:k.substr(m+1).split(/,/).map(parseFloat);m=n[0];var p=n[1],q=n[2];n=n[3];var r=k.indexOf("#");k=0>r?k:k.substr(0,r);k.match(/^http|^\/\//)||(k=d.substr(0,d.lastIndexOf("/"))+
"/"+k);r=Tq(l[0]);l=Tq(l[1])-r;c.f.push(new Fq(k,r,l,m,p,q,n,0,0))}}}})["catch"](function(f){c.log.error("[VTT] Error while loading thumbnail",f)});Oq.prototype.init.call(this,a,b)};Sq.prototype.get=function(a){var b=this;return 0>a?Promise.reject(Aq+a):this.a.then(function(){for(var c={},d=0;d<b.f.length;c={Pb:c.Pb},d++)if(c.Pb=b.f[d],c.Pb.time>=a&&a<c.Pb.time+c.Pb.duration)return c.Pb.load().then(function(e){return function(){return e.Pb}}(c));return Promise.reject(zq+a)})};Sq.prototype.B=function(){return this.a};_.u(Uq,Oq);Uq.prototype.init=function(a,b){this.log.debug("init start");if(0===this.C.length)throw new _.J(_.K,7,7100,"Player does not return any thumbnails");this.C.sort(function(c,d){return void 0!==c.bandwidth&&void 0!==d.bandwidth?c.bandwidth-d.bandwidth:0});try{Vq(this,this.C[0])}catch(c){throw c;}Oq.prototype.init.call(this,a,b)};
Uq.prototype.get=function(a){if(0>a)return this.log.error("Position must be greater than 0 but is "+a),Promise.reject();if(!this.a)return this.log.error(Bq),Promise.reject();var b=this.a.findSegmentPosition(a);if(null===b||void 0===b)return this.log.error("Could not find segment index for specified position",a),Promise.reject();var c=this.a.getSegmentReference(b);if(c){var d=this.o?this.o*this.h:c.endTime-c.startTime,e=d/this.h;a=Math.floor((a-c.startTime)/d*this.h);var f;if(0<this.f.length)e=this.a.findSegmentPosition(0),
null!==e&&(f=this.f[(b-e)*this.h+a]);else if((d=c.Oa())&&0<d.length){var g=0,k=0;c=c.startTime;1<this.h&&(g=a%this.g,k=Math.floor(a/this.g),c+=a*e);f=new Fq(d[0],c,e,this.A*g,this.w*k,this.A,this.w,this.g,this.j,g,k)}if(f)return f.load().then(function(){return f})}this.log.error("segment reference for specified segment index does not exist",b);return Promise.reject()};
Uq.prototype.B=function(){if(0<this.f.length)return Promise.resolve();if(!this.a)return this.log.error(Bq),Promise.reject();for(var a=this.a.findSegmentPosition(0),b;null!==a&&!_.B.N(b=this.a.getSegmentReference(a++));)for(var c=b.Oa()[0],d=new Image,e=b.startTime,f=this.o?this.o*this.h:b.endTime-b.startTime,g=f/this.h,k=0;k<this.j;++k)for(var l=0;l<this.g;++l)this.f.push(new Fq(c,e+k*f+l*g,g,this.A*l,this.w*k,this.A,this.w,this.g,this.j,l,k,d));return Promise.resolve()};_.u(Wq,Oq);
Wq.prototype.init=function(a,b){if(!b||!b.url)throw new _.J(_.K,7,7100,Cq);if("GRID"===b.mode&&!b.gridSize)throw new _.J(_.K,7,7100,"Grid size is mandatory for GRID mode");if(!b.duration)throw new _.J(_.K,7,7100,"Duration is mandatory");this.a=b;var c=this.a.duration,d=this.a.url;if(!c||!d)throw this.log.error(yq,c,d),new _.J(_.K,7,7100,yq);this.h=c;if(this.a.gridSize)try{var e=this.a.gridSize.split(/x/).map(function(f){return parseInt(f,10)}).filter(function(f){return 0<f});if(2===e.length)this.g=e[0],
this.j=e[1];else throw new _.J(_.K,7,7100,Dq);}catch(f){throw this.log.error("Error during parsing gridSize",f),new _.J(_.K,7,7100,Dq);}Oq.prototype.init.call(this,a,b)};Wq.prototype.get=function(a){var b=this.m.getDuration();if(void 0===this.a.duration)return Promise.reject("Duration configuration not defined");var c=Math.floor(a/this.a.duration);if(0>a||a>b)return Promise.reject(Aq+a);var d=this.f[c];return d?d.load().then(function(){return d}):Promise.reject(zq+a+" at index "+c)};
Wq.prototype.B=function(){if(0<this.f.length)return Promise.resolve();var a=this.m.getDuration(),b=this.g*this.j;a=Math.ceil(Math.ceil(a/this.h)/b);for(var c=this.a.templateKey||"$index$",d=0;d<a;d++)for(var e=this.a.url.replace(c,String(d+1)),f=new Image,g=0;g<b;g++)this.f.push(new Fq(e,this.h*(d*b+g),this.h,0,0,0,0,this.g,this.j,g%this.g,Math.floor(g/this.g),f));return Promise.resolve()};};
if(typeof(module)!="undefined"&&module.exports){var x=require("./cl.core.min.js");_ = x._;(f.call(g,this));module.exports=g;}
else if (typeof(define)!="undefined"&&define.amd) {define(["./cl.core.min"], function(c){_=c._;(f.call(g,this));return g;});}
else{_=this.clpp._;(f.call(g,this));}
})();
