import React from 'react';
import {
    TextInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput
} from 'react-admin';

function SSDSInputElements() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextInput label="serialNumber" source="serialNumber" />
            <TextInput label="comment" source="comment" />
            <ReferenceInput
                label="location"
                source="location"
                reference="locations"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                fullWidth
                perPage={999}
                label="lock1"
                source="lock1"
                reference="locks"
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                fullWidth
                perPage={999}
                label="lock2"
                source="lock2"
                reference="locks"
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        </div>
    );
}

export default SSDSInputElements;
