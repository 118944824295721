/* eslint-disable react/jsx-wrap-multilines */
import { Create, TextInput, SelectInput, SimpleForm, useMutation, DateInput, useNotify, useRedirect } from 'react-admin';
import { submitErrorsMutators, SubmitErrorsSpy } from 'final-form-submit-errors';
import BasicActionsToolbar from '../BasicActionsToolbar';
import { releaseColors } from '../../constants';

const ReleasesCreate = (props) => {
    const [mutate] = useMutation();
    const notify = useNotify();
    const redirect = useRedirect();
    // eslint-disable-next-line consistent-return
    const save = async (values) => {
        try {
            await mutate(
                {
                    type: 'create',
                    resource: props.resource,
                    payload: { data: { ...values, contentSubversion: 0 } }
                },
                { returnPromise: true }
            );
            notify('Release was created !');
            redirect(`/${props.resource}`);
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(error);

            if (!submissionErrors) {
                notify('text', { type: 'warning' });
                return {};
            }

            return submissionErrors;
        }
    };

    return (
        <Create
            transform={(data) => {
                return {
                    ...data,
                    video: []
                };
            }}
            {...props}
            actions={<BasicActionsToolbar showList {...props} />}
        >
            <SimpleForm
                save={save}
                mutators={{
                    ...submitErrorsMutators
                }}
                sanitizeEmptyValues={false}
            >
                <div className="form-flexed-vertical">
                    <TextInput label="Name" source="name" />
                    <SelectInput
                        label="Color"
                        source="colorCode"
                        choices={releaseColors.map((color) => ({
                            id: color.code,
                            name: color.label
                        }))}
                    />
                    <DateInput label="Start SSD exchange date" source="ssdExchangeFrom" />
                    <DateInput label="Valid From" source="validFrom" range="from" />
                    <DateInput label="Valid To" source="validTo" range="to" />
                    <TextInput label="Comment" source="comment" />
                </div>
                <SubmitErrorsSpy />
            </SimpleForm>
        </Create>
    );
};

export default ReleasesCreate;
