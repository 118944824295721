import React from 'react';
import { HydraAdmin, ResourceGuesser } from '@api-platform/admin';
import { Layout } from 'react-admin';
import ImportPollingContextProvider from 'context/ImportPollingContext';
import Menu from './Menu';
import AppBar from './AppBar';
import authProvider from './HydraAuthProvider';
import i18nProvider from './HydraI18nProvider';
import { dataProvider } from './HydraDataProvider';
import Dashboard from './Dashboard';
import Login from './HydraLoginPage';
import { entities } from './constants';

import videos from './entities/Videos';
import metadata from './entities/Metadata';
import media from './entities/Media';
import releases from './entities/Releases';
import ssds from './entities/SSDS';
import cases from './entities/Cases';
import locks from './entities/Locks';
import avails from './entities/Avails';
import pages from './entities/Pages';
import availsSeasons from './entities/Avails/indexAvailsSeasons';
import availsMovies from './entities/Avails/indexAvailsMovies';
import trainunits from './entities/Trainunits';
import series from './entities/Series';
import {
    isCreateCasesAccessible,
    isCreateSSDsAccessible,
    isCreateUsersAccessible,
    isEditCasesAccessible,
    isEditSSDsAccessible,
    isEditUsersAccessible
} from './utils/user';
import users from './entities/Users';
import customRoutes from './customRoutes';

const MyLayout = (props) => <Layout {...props} menu={Menu} appBar={AppBar} />;

export default () => {
    return (
        <ImportPollingContextProvider>
            <HydraAdmin
                customRoutes={customRoutes}
                entrypoint={global.RA_API_ENTRYPOINT}
                dataProvider={dataProvider}
                i18nProvider={i18nProvider}
                authProvider={authProvider}
                loginPage={Login}
                layout={MyLayout}
                dashboard={Dashboard}
            >
                {(userRoles) => [
                    ...Object.keys(entities)
                        .filter((entity) => !entities[entity].custom)
                        .map((entity) => (
                            <ResourceGuesser
                                key={entities[entity].name}
                                name={entities[entity].name}
                                options={{ ...entities[entity] }}
                            />
                        )),
                    <ResourceGuesser {...metadata} options={{ ...entities.METADATA }} />,
                    <ResourceGuesser {...media} options={{ ...entities.MEDIA }} />,
                    <ResourceGuesser {...videos} options={{ ...entities.VIDEOS }} />,
                    <ResourceGuesser {...releases} options={{ ...entities.RELEASES }} />,
                    <ResourceGuesser {...trainunits} options={{ ...entities.TRAINUNITS }} />,
                    // Edit page has to be restricted from here in order to prevent the user to enter an exact url
                    // Removing the edit button has to be done within the ssds list component
                    <ResourceGuesser
                        create={isCreateSSDsAccessible(userRoles) ? ssds.create : null}
                        inputs={ssds.inputs}
                        edit={isEditSSDsAccessible(userRoles) ? ssds.edit : null}
                        list={ssds.list}
                        resource={ssds.resource}
                        name={ssds.name}
                        options={{ ...entities.S_S_DS }}
                    />,
                    // Edit page has to be restricted from here in order to prevent the user to enter an exact url
                    // Removing the edit button has to be done within the cases list component
                    <ResourceGuesser
                        create={isCreateCasesAccessible(userRoles) ? cases.create : null}
                        inputs={cases.inputs}
                        edit={isEditCasesAccessible(userRoles) ? cases.edit : null}
                        list={cases.list}
                        resource={cases.resource}
                        name={cases.name}
                        options={{ ...entities.DELIVERY_CASES }}
                    />,
                    // Edit page has to be restricted from here in order to prevent the user to enter an exact url
                    // Removing the edit button has to be done within the cases list component
                    <ResourceGuesser
                        list={locks.list}
                        show={locks.show}
                        edit={locks.edit}
                        create={locks.create}
                        resource={locks.resource}
                        name={locks.name}
                        options={{ ...entities.LOCKS }}
                    />,
                    // Edit page has to be restricted from here in order to prevent the user to enter an exact url
                    // Removing the edit button has to be done within the user list component
                    <ResourceGuesser
                        create={isCreateUsersAccessible(userRoles) ? users.create : null}
                        edit={isEditUsersAccessible(userRoles) ? users.edit : null}
                        list={users.list}
                        show={users.show}
                        resource={users.resource}
                        name={users.name}
                        options={{ ...entities.USERS }}
                    />,
                    <ResourceGuesser
                        list={avails.list}
                        edit={avails.edit}
                        resource="avails"
                        name="avails"
                        options={{ ...entities.AVAILS }}
                    />,
                    <ResourceGuesser {...series} options={{ ...entities.SERIES }} />,
                    <ResourceGuesser
                        list={availsSeasons.list}
                        resource="avails"
                        name="seasons"
                        options={{ ...entities.SEASONS }}
                    />,
                    <ResourceGuesser
                        list={availsMovies.list}
                        edit={availsMovies.edit}
                        resource="avails"
                        name="movies"
                        options={{ ...entities.MOVIES }}
                    />,
                    <ResourceGuesser
                        list={pages.list}
                        resource="pages"
                        name="pages"
                        options={{ ...entities.PAGES }}
                    />
                ]}
            </HydraAdmin>
            {window.RA_API_ENTRYPOINT &&
                (window.RA_API_ENTRYPOINT.includes('integration') ||
                    window.RA_API_ENTRYPOINT.includes('testing') ||
                    window.RA_API_ENTRYPOINT.includes('stage')) && (
                    <div style={{ textAlign: 'center' }}>Build version: {window.BUILD_TAG_VERSION_STRING}</div>
                )}
        </ImportPollingContextProvider>
    );
};
