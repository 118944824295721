/* eslint-disable react/jsx-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import {
    Create,
    SimpleForm,
    useMutation,
    useNotify,
    useRedirect
} from 'react-admin';
import {
    submitErrorsMutators,
    SubmitErrorsSpy
} from 'final-form-submit-errors';
import SSDSInputElements from './SSDSInputElements';
import BasicActionsToolbar from '../BasicActionsToolbar';
import '../entities.scss';

const SSDSCreate = (props) => {
    const [mutate] = useMutation();
    const notify = useNotify();
    const redirect = useRedirect();
    const { onSuccess, onFailure, id, transform, basePath, actions } = props;
    const additionalProps = { mutationMode: 'pessimistic' };
    if (onSuccess) {
        additionalProps.onSuccess = onSuccess;
    }
    if (onFailure) {
        additionalProps.onFailure = onFailure;
    }
    if (id) {
        additionalProps.id = id;
    }
    if (transform) {
        additionalProps.transform = transform;
    }
    if (basePath) {
        additionalProps.basePath = basePath;
    }
    if (actions !== null) {
        additionalProps.actions = <BasicActionsToolbar showList {...props} />;
    }

    // eslint-disable-next-line consistent-return
    const save = async (values) => {
        try {
            const response = await mutate(
                {
                    type: 'create',
                    resource: props.resource,
                    payload: {
                        data: { ...values, release: values?.release?.['@id'] }
                    }
                },
                { returnPromise: true }
            );
            if (onSuccess) {
                onSuccess(response);
            } else {
                notify(`${props.resource} was created !`);
                redirect(`/${props.resource}`);
            }
        } catch (error) {
            const submissionErrors = props.schemaAnalyzer.getSubmissionErrors(
                error
            );

            if (!submissionErrors) {
                notify(`Error: ${error.message}`);
                return {};
            }

            return submissionErrors;
        }
    };

    return (
        <Create {...props} {...additionalProps}>
            <SimpleForm
                save={save}
                mutators={{
                    ...submitErrorsMutators
                }}
            >
                <SSDSInputElements />
                <SubmitErrorsSpy />
            </SimpleForm>
        </Create>
    );
};

export default SSDSCreate;
