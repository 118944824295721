import { Introspecter } from '@api-platform/admin';
import Create from './SSDSCreate';
import Edit from './SSDSEdit';
import List from './SSDSList';
import Inputs from './SSDSInputElements';

export default {
    create: (props) => <Introspecter component={Create} {...props} />,
    inputs: Inputs,
    edit: (props) => <Introspecter component={Edit} {...props} />,
    list: List,
    resource: 's_s_ds',
    name: 's_s_ds'
};
