export const DBLogo = ({ className }) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="40"
            height="28"
            viewBox="0 0 40 28"
        >
            <defs>
                <path
                    id="eb43zl686a"
                    d="M36 0c2.2 0 4 1.768 4 3.929v19.603c0 2.2-1.8 3.968-4 3.968H4c-2.2 0-4-1.768-4-3.929V3.93C0 1.768 1.8 0 4 0zm0 2.79H3.96c-.6.039-1.08.55-1.04 1.139V23.57c0 .59.48 1.1 1.08 1.1h32c.64-.039 1.12-.55 1.08-1.139V3.89c0-.589-.48-1.1-1.08-1.1zm-8.32 17.05h-1.8v-4.636h1.92c2.28 0 2.84 1.296 2.84 2.317 0 2.318-2.24 2.318-2.96 2.318zm-1.8-12.297h1.4c1.96 0 2.76.668 2.76 2.16 0 1.18-.88 2.161-2.4 2.161h-1.72V7.543h-.04zm5.32 5.775c1.88-.55 3.16-2.24 3.2-4.204 0-.393-.08-4.4-5.24-4.4h-7.64v17.993H28c1.72 0 7.08 0 7.08-5.068 0-1.218-.56-3.614-3.88-4.321zm-16.04.707c0-3.614-.4-6.443-4.36-6.443h-.88V19.84h1.56c2.32 0 3.68-1.846 3.68-5.814zm-2.92 8.721H5.52V4.754h6.72c4.76 0 7.36 2.907 7.36 8.917 0 5.186-1.8 9.036-7.36 9.075z"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-140 -58) translate(140 58)">
                        <mask id="oguhb366ob" fill="#fff">
                            <use xlinkHref="#eb43zl686a" />
                        </mask>
                        <g fillRule="nonzero" mask="url(#oguhb366ob)">
                            <path d="M0 0H40V28H0z" />
                            <g fill="#EC0016">
                                <path d="M0 0H40V28H0z" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
