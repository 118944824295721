import polyglotI18nProvider from 'ra-i18n-polyglot';
import english from 'ra-language-english';
import german from 'ra-language-german';
import { defaultLocale } from './constants';

const englishMessages = {
    ...english,
    day: 'DAY',
    week: 'WEEK',
    month: 'MONTH',
    duration: 'Duration',
    progress: 'Progress',
    runningReleases: 'Running releases',
    reset: 'Reset',
    number: 'Number',
    comment: 'Comment',
    serialNumber: 'Serial Number',
    lock1: 'Lock 1',
    lock2: 'Lock 2',
    lockDeletionValidation: 'This Lock can not be deleted since it is being used on a case',
    location: 'Location',
    details: 'Details',
    action: 'Action',
    source: 'Source',
    noLogs: 'No logs available',
    date: 'Date',
    createdBy: 'Created by',
    entry: 'Entry',
    category: 'Category',
    current: 'current',
    nextPossible: 'next possible',
    passed: 'passed',
    train: 'Train',
    runs: 'Runs',
    days: 'days',
    trainNumber: 'Train number',
    link: 'Link',
    errors: {
        somethingWrong: 'Something went wrong!'
    },
    releases: {
        past: 'Past',
        current: 'Current',
        next: 'Next'
    },
    ssds: {
        checkin: 'Checkin',
        checkout: 'Checkout',
        return: 'Return',
        addSSD: 'ADD SSD',
        workflow: 'Workflow',
        colorCode: 'Release color',
        releaseNumber: 'Release - Number',
        linkToSSH: 'Link to SSH',
        checkedInSuccessfully: 'SSD checked in successfully!',
        checkedOutSuccessfully: 'SSD checked out successfully!',
        returnedSuccesfully: 'SSD returned successfully'
    },
    cases: {
        checkin: 'Checkin',
        checkout: 'Checkout',
        associateSSD: 'Associate SSD with Case'
    },
    markers: {
        copyFromEpisode: 'Copy from another episode',
        chapter: 'Chapter',
        intro: 'Intro',
        credits: 'Credits',
        jumpTo: 'Jump to',
        edit: 'Edit',
        delete: 'Delete',
        create: 'Create',
        save: 'Save'
    },
    support: {
        infoText: 'You can leave us a message and we will get back to you as soon as possible.',
        subject: 'Subject',
        message: 'Message',
        send: 'Send'

    },
    validateEntities: 'Validate Entities',
    addNewEntry: 'Add new entry',
    next: 'Next',
    caseSSDConfirmation: 'Case SSD Confirmation',
    available: 'Available',
    missing: 'Missing',
    inTheSystem: 'S.N. (in the system)',
    inRealCase: 'Status (in real case)',
    ifYouHaveMissingSSDs: 'If you have missing SSD entries, please contact Endava suport/administration team.',
    checkedSSDsConfirmList: 'Checked SSD Confirm List',
    ssdAlreadyAdded: 'This SSD has already added in this form'
};
german.ra.action.add_filter = 'Filtern';
german.ra.action.list = 'Zurück';
english.ra.action.reset = 'Reset';
german.ra.action.reset = 'Zurücksetzen';

const germanMessages = {
    ...german,
    day: 'TAG',
    week: 'WOCHE',
    month: 'MONAT',
    runningReleases: 'Laufende Releases',
    duration: 'Dauer',
    progress: 'Fortschritt',
    reset: 'Reset',
    number: 'Nummer',
    comment: 'Kommentar',
    serialNumber: 'Seriennummer',
    lock1: 'Schloss 1',
    lock2: 'Schloss 2',
    lockDeletionValidation: 'Dieses Schloss kann nicht gelöscht werden, da es bei einem Case verwendet wird',
    location: 'Standort',
    details: 'Einzelheiten',
    action: 'Aktion',
    source: 'Quelle',
    noLogs: 'Es sind keine Logs verfügbar',
    date: 'Datum',
    createdBy: 'Erstellt von',
    entry: 'Eintrag',
    category: 'Kategorie',
    current: 'aktuell',
    nextPossible: 'nächste Station',
    passed: 'bestätigt',
    train: 'Zug',
    runs: 'Läuft',
    days: 'Tage',
    trainNumber: 'Zug-Nummer',
    link: 'Verknüpfung',
    errors: {
        somethingWrong: 'Etwas ist schief gelaufen!'
    },
    releases: {
        past: 'Letztes',
        current: 'Aktuelles',
        next: 'Nächstes'
    },
    ssds: {
        checkin: 'Einbauen',
        checkout: 'für Versand markieren',
        return: 'Zurück legen',
        addSSD: 'SSD hinzufügen',
        workflow: 'Letzte Meldung',
        colorCode: 'Release-Farbe',
        releaseNumber: 'Release - Nummer',
        linkToSSH: 'Link zum SSH',
        checkedInSuccessfully: 'SSD erfolgreich eingebaut!',
        checkedOutSuccessfully: 'SSD erfolgreich ausgecheckt!',
        returnedSuccesfully: 'SSD erfolgreich zurückgegeben!'
    },
    cases: {
        checkin: 'Öffnen',
        checkout: 'Verschicken',
        associateSSD: 'Verknüpfen Sie SSD mit Case'
    },
    markers: {
        copyFromEpisode: 'Aus einer anderen Folge kopieren',
        chapter: 'Kapitel',
        intro: 'Einführung',
        credits: 'Kredite',
        jumpTo: 'Springen zu',
        edit: 'Bearbeiten',
        delete: 'Löschen',
        create: 'Erstellen'
    },
    support: {
        infoText: 'Sie können uns eine Nachricht hinterlassen und wir werden uns so bald wie möglich bei Ihnen melden.',
        subject: 'Betreff',
        message: 'Nachricht',
        send: 'Senden'

    },
    validateEntities: 'Jetzt prüfen',
    addNewEntry: 'Neue zeile hinzufügen',
    next: 'Weiter',
    caseSSDConfirmation: 'SSD Case Bestätigung',
    available: 'Verfügbar',
    missing: 'Fehlt',
    inTheSystem: 'Verfügbar im System',
    inRealCase: 'Eingabe Werksleitung',
    ifYouHaveMissingSSDs: 'Für Missstände im Case wende dich bitte an den Endava Support oder gehe auf Bearbeiten in der Cases übersicht.',
    checkedSSDsConfirmList: 'Checkliste SSDs im Case',
    ssdAlreadyAdded: 'Diese SSD wurde bereits in dieser Form hinzugefügt'
};

export default polyglotI18nProvider(
    (locale) => (locale === 'de' ? germanMessages : englishMessages),
    localStorage.getItem('locale') ? localStorage.getItem('locale').toLowerCase() : defaultLocale
);
