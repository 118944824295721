/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import MUITableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import TableHead from './TableHead';
import TablePaginationActions from '../Avails/TablePaginationActions';
import TableToolbar from './TableToolbar';
import TableRow from './TableRow';
import CreatePageModal from './CreatePageModal';

const availsUseStyles = makeStyles({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: '10px'
    },
    table: {
        minWidth: 750
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
});

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Release - Site Template',
        style: { width: '20%', textTransform: 'capitalize', textAlign: 'left' },
        tdStyle: { textAlign: 'left', textTransform: 'capitalize' },
        transform: (value) => value
    },
    {
        id: 'availability',
        numeric: false,
        disablePadding: false,
        label: 'Availability',
        style: { width: '20%', textTransform: 'capitalize', textAlign: 'left' },
        tdStyle: { textAlign: 'left', textTransform: 'capitalize' },
        transform: ({ validTo, validFrom }) => {
            return `${moment(validFrom).format('DD-MM-YYYY')} - ${moment(validTo).format('DD-MM-YYYY')}`;
        }

    },
    {
        id: 'workflowStatus',
        numeric: false,
        disablePadding: false,
        label: 'Workflow/Status',
        style: { width: '20%', textTransform: 'capitalize', textAlign: 'left' },
        tdStyle: { textAlign: 'left', textTransform: 'capitalize' },
        transform: (value) => value
    }
];

const setAvailability = (items) => {
    if (!items || !items.length) {
        return null;
    }
    return items.map((item) => (item.validTo && item.validFrom ? {
        ...item,
        availability: { validTo: item.validTo, validFrom: item.validFrom },
        pages: setAvailability(item.pages)
    } : item));
};

export default function PagesView() {
    const classes = availsUseStyles();
    const [loading] = useState(true);
    const [data, setData] = useState([]);
    const [dataLength] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [releasesList, setReleasesList] = useState([]);
    const [modalType, setModalType] = useState(null);
    const [pageData, setPageData] = useState({});

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);
    useEffect(() => {
        const res = [
            {
                name: 'Release 1',
                type: 'release',
                validFrom: '2021-04-10T00:00:00+00:00',
                validTo: '2021-09-01T00:00:00+00:00',
                workflowStatus: 'approved',
                pages: [
                    {
                        name: 'Main Page',
                        validFrom: '2021-05-01T00:00:00+00:00',
                        validTo: '2021-07-31T00:00:00+00:00',
                        workflowStatus: 'approved',
                        slug: 'main-page',
                        type: 'page'
                    },
                    {
                        name: 'Series',
                        validFrom: '2021-05-01T00:00:00+00:00',
                        validTo: '2021-07-31T00:00:00+00:00',
                        workflowStatus: 'approved',
                        slug: 'series',
                        type: 'page'
                    }
                ]
            },
            {
                name: 'Release 2',
                type: 'release',
                validFrom: '2021-04-10T00:00:00+00:00',
                validTo: '2021-09-01T00:00:00+00:00',
                workflowStatus: 'approved',
                pages: [
                    {
                        name: 'Main Page',
                        validFrom: '2021-05-01T00:00:00+00:00',
                        validTo: '2021-07-31T00:00:00+00:00',
                        workflowStatus: 'approved',
                        slug: 'main-page',
                        type: 'page'
                    },
                    {
                        name: 'Movies',
                        validFrom: '2021-08-01T00:00:00+00:00',
                        validTo: '2021-10-31T00:00:00+00:00',
                        workflowStatus: 'approved',
                        slug: 'movies',
                        type: 'page'
                    }
                ]
            }
        ];
        setData(setAvailability(res));
    }, []);

    useEffect(() => {
        if (data && data.length) {
            const releases = data.map((item) => item.name);

            setReleasesList(releases);
        }
    }, [data]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableToolbar
                    numSelected={selected.length}
                    loading={loading}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    setModalType={(type) => setModalType(type)}
                />
                <TableContainer>
                    <Table size="small" className={classes.table} aria-label="custom pagination table">
                        <TableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {data.map((row) => {
                                const isItemSelected = isSelected(row.name);
                                return (
                                    <TableRow
                                        key={row['@id']}
                                        headCells={headCells}
                                        row={row}
                                        isItemSelected={isItemSelected}
                                        emptyRows={emptyRows}
                                        onClick={handleClick}
                                        setModalType={(type, { name }) => {
                                            setModalType(type);
                                            setPageData({ name });
                                        }}
                                    />
                                );
                            })}

                            {emptyRows > 0 && (
                                <MUITableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={headCells.length} />
                                </MUITableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <MUITableRow>
                                <TablePagination
                                    style={{ width: '100%' }}
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: dataLength }]}
                                    colSpan={headCells.length + 2}
                                    count={dataLength}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page'
                                        },
                                        native: true
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </MUITableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <CreatePageModal
                    modalType={modalType}
                    pageData={pageData}
                    onClose={() => {
                        setModalType(null);
                    }}
                    releases={releasesList}
                />
            </Paper>
        </div>
    );
}
