import { Introspecter } from '@api-platform/admin';
import Create from './CasesCreate';
import Edit from './CasesEdit';
import List from './CasesList';
import Inputs from './CasesInputElements';

export default {
    create: (props) => <Introspecter component={Create} {...props} />,
    inputs: Inputs,
    edit: (props) => <Introspecter component={Edit} {...props} />,
    list: List,
    resource: 'delivery_cases',
    name: 'delivery_cases'
};
