import List from './TrainunitsList';
import TrainunitsCreate from './TrainunitsCreate';
import TrainunitsEdit from './TrainunitsEdit';

export default {
    list: List,
    create: TrainunitsCreate,
    edit: TrainunitsEdit,
    resource: 'trainunits',
    name: 'trainunits'
};
