import React, { useState } from 'react';
import {
    TextField,
    Button,
    Tooltip,
    IconButton
} from '@material-ui/core';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tabs from './Tabs';

const TopTeaserSlider = ({ pageData: { type, contents }, openSearchModal }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const decoratedSlides = [...contents[0].videos, ...contents[0].series].map((item, index) => ({
        ...item,
        label: `Slide ${index + 1}`,
        asset: { id: '', name: '' }
    }));
    const [slides, setSlides] = useState(decoratedSlides);

    return (
        <section style={{ width: '100%', margin: '80px 0' }}>
            <div className="editPage-section topTeaserSlider" style={{ display: 'grid', width: '100%', maxWidth: '90%', margin: 'auto' }}>
                <div style={{ gridArea: 'title', padding: '20px 0', fontWeight: 'bold' }}>{type === 'start' ? 'Top Teaser Slider' : 'Hero Top Teaser'}</div>
                {type === 'start' ? (
                    <Tabs
                        currentTab={currentTab}
                        tabType="slide"
                        maxTabs={5}
                        tabs={slides}
                        setCurrentTab={setCurrentTab}
                        setTabs={setSlides}
                        openSearchModal={openSearchModal}
                    />
    ) : (
        <div style={{ gridArea: 'heroTopTeaserSelect', display: 'flex', alignItems: 'flex-end' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField value={decoratedSlides[currentTab].asset.name || ''} label="Selected Asset Name" disabled/>
                <Tooltip title="Copy asset name" aria-label="Copy asset name">
                    <IconButton
                        disabled={!decoratedSlides[currentTab].asset.name}
                        onClick={() => navigator.clipboard.writeText(decoratedSlides[currentTab].asset.name)}
                        size="small"
                    >
                        <FileCopyIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <Button style={{ gridArea: 'addTeaser', margin: '0 20px' }} variant="outlined" onClick={openSearchModal}>Select</Button>
            <Button style={{ gridArea: 'addTeaser', marginLeft: '20px' }} variant="outlined" startIcon={<NavigateNextIcon />} onClick={() => null}>Go to asset</Button>
        </div>
    )}
            </div>
        </section>
    );
};

export default TopTeaserSlider;
