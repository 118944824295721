import React from 'react';
import {
    Button,
    TextField
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const GeneralPageFields = ({
 staticData, pageTitle, pageSlug, startDate, endDate, setEndDate, setStartDate, setPageTitle, setPageSlug
}) => {
    const { releaseName, workflowStatus, pageType, contentType } = staticData;

    return (
        <section className="editPage-section general-fields">
            <div style={{ gridArea: 'pageTitle', marginBottom: '60px' }}>
                <TextField required value={pageTitle} label="Page title" onChange={(e) => setPageTitle(e.target.value)} />
            </div>
            <div style={{ gridArea: 'pageInfo', display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
                <div>
                    <div>Release</div>
                    <strong>{releaseName}</strong>
                </div>
                <div>
                    <div>Workflow/Status</div>
                    <strong>{workflowStatus}</strong>
                </div>
                <div>
                    <div>Page type</div>
                    <strong>{pageType}</strong>
                </div>
                {pageType !== 'start' ? (
                    <div>
                        <div>Content type</div>
                        <strong>{contentType}</strong>
                    </div>
                ) : null}

            </div>
            <div style={{ gridArea: 'pageActions', display: 'flex', justifyContent: 'flex-end', alignSelf: 'flex-start' }}>
                <Button startIcon={<VisibilityIcon />} onClick={() => null}>
                    Preview
                </Button>
                <Button startIcon={<DeleteOutlineIcon />} onClick={() => null}>
                    Delete
                </Button>
            </div>
            <div style={{ gridArea: 'datePickers', display: 'flex' }}>
                <div style={{ marginRight: '20px' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk
                            required
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label="Start Availability"
                            value={startDate}
                            onChange={setStartDate}
                            KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                    }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div style={{ marginRight: '20px' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk
                            required
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label="End Availability"
                            value={endDate}
                            onChange={setEndDate}
                            KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>
            <div style={{ gridArea: 'slug' }}>
                <TextField required value={pageSlug} label="Slug" onChange={(e) => setPageSlug(e.target.value)} />
            </div>
            <div style={{ gridArea: 'actions', display: 'flex' }}>
                <Button style={{ width: '100%', border: '1px solid #999', marginRight: '20px' }} onClick={() => null}>
                    Cancel
                </Button>
                <Button style={{ width: '100%', border: '1px solid #999' }} onClick={() => null}>
                    Save
                </Button>
            </div>
        </section>
    );
};

export default GeneralPageFields;
