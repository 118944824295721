import {
    List, Datagrid, TextField, EditButton, ShowButton, ArrayField, SingleFieldList,
    Filter, TextInput, usePermissions
} from 'react-admin';
import {
    isEditUsersAccessible,
    isMasterNutzerUser,
    isSuperUser,
    isWerksMeisterUser, ROLE_MANAGER,
    ROLE_MASTER_NUTZER,
    ROLE_TECHNIKER,
    ROLE_WERKSMEISTER
} from '../../utils/user';

const UsersListFilters = (props) => (
    <Filter {...props}>
        <TextInput label="Forename" source="forename" />
        <TextInput label="Surname" source="surname" />
        <TextInput label="Email" source="email" />
    </Filter>
    );

const HoudiniEdit = (props) => {
    const { permissions: userRoles } = usePermissions();
    const { record: { role } } = props;
    const thisRecordRole = role[0] && role[0].name;
    let userCanEditThisRole = false;

    if (isSuperUser(userRoles)) {
        userCanEditThisRole = true;
    } else if (!isSuperUser(userRoles) && [isMasterNutzerUser, isWerksMeisterUser].some((e) => e(userRoles))) {
        if ([ROLE_MASTER_NUTZER, ROLE_WERKSMEISTER, ROLE_TECHNIKER, ROLE_MANAGER].some((e) => e === thisRecordRole)) {
            userCanEditThisRole = true;
        }
    }

    return (
        <>
            {isEditUsersAccessible(userRoles) && userCanEditThisRole && <EditButton {...props} />}
        </>
    );
};


export default (props) => {
    return (
        <List {...props} className="centered-table" filters={<UsersListFilters />}>
            <Datagrid>
                <TextField source="forename" />
                <TextField source="surname" />
                <TextField source="email" />
                <ArrayField source="role">
                    <SingleFieldList style={{ justifyContent: 'center' }} linkType={false}>
                        <TextField source="name" />
                    </SingleFieldList>
                </ArrayField>
                <ShowButton />
                <HoudiniEdit />
            </Datagrid>
        </List>
    );
};
