/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useRef, useContext } from 'react';
import { useNotify } from 'react-admin';
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, IconButton, Typography } from '@material-ui/core';
import { Update, AccessTime, Close, Check, Refresh, DeleteForever, Visibility } from '@material-ui/icons';
import { ImportPollingContext } from 'context/ImportPollingContext';
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import JSON_ImportLogsView from 'entities/Releases/JSON_ImportLogsView';
import EXCEL_ImportLogsView from 'entities/Releases/EXCEL_ImportLogsView';
import { extractBaseUrl } from '../utils/helpers';
import './FileUploadStatus.scss';

function FileUploadStatus() {
    const { startPolling, setStartPolling } = useContext(ImportPollingContext);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [requestLoading, setRequestLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [rowToBeDeletedData, setRowToBeDeletedData] = React.useState({});
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false);
    const [showImportLogs, setShowImportLogs] = React.useState(false);
    const [importLogs, setImportLogs] = React.useState(null);
    const pollingInterval = useRef(null);
    const notify = useNotify();

    const setStatusIcon = (statusId) => {
        if (!statusId) {
            return <Close />;
        }
        if (statusId < 3) {
            return <AccessTime />;
        }

        return <Check />;
    };

    const columns = [
        {
            id: 'filename',
            name: 'Filename',
            selector: (row) => row.file,
            cell: (row) => row.file.split('/').pop(),
            sortable: true
        },
        {
            id: 'started',
            name: 'Started',
            selector: (row) => row.startImportAt,
            sortable: true,
            maxWidth: '170px'
        },
        {
            id: 'finished',
            name: 'Finished',
            selector: (row) => row.endImportAt,
            sortable: true,
            maxWidth: '170px'
        },
        {
            id: 'message',
            name: 'Message',
            selector: (row) => row.statusMessage,
            format: (row) => <div title={row.statusMessage}>{row.statusMessage}</div>,
            sortable: true,
            maxWidth: '170px'
        },
        {
            id: 'status',
            name: 'Status',
            sortable: true,
            cell: (row) => setStatusIcon(row.statusId),
            maxWidth: '10px'
        },
        {
            id: 'logs',
            name: 'Logs',
            cell: (row) => {
                return (
                    <IconButton
                        aria-label="preview"
                        size="small"
                        style={{ color: 'green' }}
                        onClick={() => { setShowImportLogs(true); setImportLogs(row.id); }}
                    >
                        <Visibility fontSize="small" />
                    </IconButton >
                );
            },
            maxWidth: '10px'
        },
        {
            id: 'delete',
            name: 'Delete',
            cell: (row) => {
                return (
                    <IconButton
                        aria-label="preview"
                        size="small"
                        style={{ color: 'red' }}
                        onClick={() => {
                            setRowToBeDeletedData({ id: row.id, fileName: row.file });
                            setIsConfirmationDialogOpen(true);
                        }}
                    >
                        <DeleteForever fontSize="small" />
                    </IconButton>
                );
            },
            maxWidth: '10px'
        }
    ];

    const getAllImports = () => {
        setRequestLoading(true);
        fetch(`${extractBaseUrl(global.RA_API_ENTRYPOINT)}/api/import_workers`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => response.json()).then((data) => {
                setRequestLoading(false);
                setRows(data);
                if (data.length && data.every(({ statusId }) => statusId >= 3)) {
                    clearInterval(pollingInterval.current);
                    pollingInterval.current = null;
                    setStartPolling(false);
                }
            })
            .catch(() => {
                setRequestLoading(false);
                clearInterval(pollingInterval.current);
                pollingInterval.current = null;
                setStartPolling(false);
                notify('Unable to get import workers. Please refresh the application');
            });
    };

    const deleteImportWorker = () => {
        fetch(`${extractBaseUrl(global.RA_API_ENTRYPOINT)}/api/import_workers/${rowToBeDeletedData.id}`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                notify(`${data.message} ${rowToBeDeletedData.fileName} was deleted.`);
                setIsConfirmationDialogOpen(false);
                getAllImports();
            })
            .catch(() => notify(`Unable to delete${rowToBeDeletedData.fileName}.`));
    };

    useEffect(() => {
        getAllImports();

        if (!pollingInterval.current || startPolling) {
            pollingInterval.current = setInterval(getAllImports, 10000);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startPolling]);

    const statusLabelStyle = {};
    let filesInProgress = 0;
    if (rows.length > 0) {
        const importsInProgress = rows.filter((imp) => imp.statusId === 1 || imp.statusId === 2).length;
        if (importsInProgress) {
            statusLabelStyle.color = 'red';
            filesInProgress = importsInProgress;
        }
    }

    const showLogsPopup = () => {
        const importTypes = { EXCEL: 0, JSON: 1 };
        if (importLogs) {
            const importType = rows.find((log) => log.id === importLogs)?.typeId;
            if (importType === importTypes.EXCEL) {
                return <EXCEL_ImportLogsView importWorkerId={importLogs} open={showImportLogs} onClose={() => { setShowImportLogs(false); }} />;
            }
            if (importType === importTypes.JSON) {
                return <JSON_ImportLogsView importWorkerId={importLogs} open={showImportLogs} onClose={() => { setShowImportLogs(false); }} />;
            }
        }
        return null;
    };

    return (
        <>
            <div className="file-upload-status-bar" role="button" onClick={() => setDialogOpen(true)}><Update style={{ ...statusLabelStyle, verticalAlign: 'text-bottom' }} /> Import queue ({filesInProgress})</div>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={dialogOpen}
                onClose={() => setDialogOpen(!dialogOpen)}
            >
                <DialogTitle disableTypography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">File upload status</Typography>
                    <IconButton onClick={getAllImports}>
                        <Refresh />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DataTable
                        dense
                        columns={columns}
                        data={rows}
                        defaultSortFieldId="started"
                        defaultSortAsc={false}
                        pagination
                        progressPending={requestLoading}
                        progressComponent={<div className="loader-container"><CircularProgress /></div>}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(!dialogOpen)}>Close</Button>
                </DialogActions>
            </Dialog>
            {showImportLogs && importLogs && showLogsPopup()}
            {isConfirmationDialogOpen ?
                (
                    <Dialog
                        onClose={() => setIsConfirmationDialogOpen(false)}
                        open
                    >
                        <DialogTitle>Delete import worker</DialogTitle>
                        <DialogContent>Are you sure you want to delete this import worker?</DialogContent>
                        <DialogActions>
                            <Button onClick={() => deleteImportWorker()}>Yes</Button>
                            <Button onClick={() => setIsConfirmationDialogOpen(false)}>No</Button>
                        </DialogActions>
                    </Dialog>
                )
                : null}
        </>

    );
}

FileUploadStatus.propTypes = {

};

export default FileUploadStatus;
