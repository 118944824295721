import { Introspecter } from '@api-platform/admin';
import Create from './MetadataCreate';
import Edit from './MetadataEdit';
import List from './MetadataList';
import Inputs from './MetadataInputElements';

export default {
    create: (props) => <Introspecter component={Create} {...props} />,
    inputs: Inputs,
    edit: (props) => <Introspecter component={Edit} {...props} />,
    list: List,
    resource: 'metadata',
    name: 'metadata'
};
