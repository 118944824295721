export const createPageTypes = {
    NEW: 'new',
    COPY: 'copy'
};

export const teaserlaneTypes = {
    DEFAULT: 'Teaserlane',
    SHOWCASE: 'Teaserlane Showcase',
    SPECIAL: 'Teaserlane Special'
};

export const pageTypes = {
    START: {
        label: 'Startpage'
    },
    SUBCATEGORY: {
        label: 'Subcategory',
        contentTypes: {
            MOVIES: 'Movies',
            SERIES: 'Series',
            ALL: 'All'
        }
    }
};
