import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import Teaserlane from './Teaserlane';
import AddTeaserlaneSelect from './AddTeaserlaneSelect';
import TopTeaserSlider from './TopTeaserSlider';
import './edit-page.scss';
import SubcategoryPageFields from './SubcategoryPageFields';
import GeneralPageFields from './GeneralPageFields';
import SearchAssetsModal from './SearchAssetsModal';


const EditPageView = () => {
    const [pageData, setPageData] = useState();
    const [teaserlanes, setTeaserlanes] = useState([]);
    const [isAddTeaserlaneSelectOpen, setIsAddTeaserlaneSelectOpen] = useState(false);
    const [tags, setTags] = useState([]);
    const [sortingOption, setSorting] = useState('');
    const [channelPartener, setChannelPartener] = useState('');
    const [filters, setFilters] = useState([]);
    const [pageTitle, setPageTitle] = useState([]);
    const [pageSlug, setPageSlug] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [staticData, setStaticData] = useState({});
    const [currentTabSearchModalIds, setCurrentTabSearchModalIds] = useState(null);
    const [availsList, setAvailsList] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);

    const handleSetNewTeaserTab = (currentTeaserLaneId) => {
        const updatedTeaserlanes = teaserlanes.map((teaserlane) => (teaserlane.id === currentTeaserLaneId
            ? {
                ...teaserlane,
                tabs: [
                    ...teaserlane.tabs,
                    {
                        id: uuidv4(),
                        label: `Teaser ${teaserlane.tabs.length + 1}`,
                        asset: { id: '', name: '' }
                    }
                ]
            }
            : teaserlane));

        setTeaserlanes(updatedTeaserlanes);
    };

    const handleSetTeaserAsset = (asset) => {
        const { currentTeaserlaneId, tabId } = currentTabSearchModalIds;
        const updatedTeaserlanes = teaserlanes.map((teaserlane) => (
            teaserlane.id === currentTeaserlaneId
                ? {
                    ...teaserlane,
                    tabs: teaserlane.tabs.map((tab) => (
                        tab.id === tabId
                        ? {
                            ...tab,
                            asset
                        }
                        : tab
                    ))
                }
                : teaserlane
        ));

        setTeaserlanes(updatedTeaserlanes);
    };

    const handleMoveTeaserlane = (teaserlanePosition, teaserlaneNewPosition) => {
        const array = [...teaserlanes];
        const item = array.splice(teaserlanePosition, 1);
        array.splice(teaserlaneNewPosition, 0, item[0]);

        setTeaserlanes(array);

        const elemInDOM = document.querySelectorAll('.teaserlane')[teaserlaneNewPosition];
        elemInDOM.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    const handleDeleteTeaserlane = (teaserlanePosition) => {
        setTeaserlanes(teaserlanes.filter((_teaserlane, index) => index !== teaserlanePosition));
    };
    const getAvails = () => {
        const url = new URL(`${window.RA_API_ENTRYPOINT}/avails`);
        // setIsLoading(true);
        const requestParams = {
            itemsPerPage: 999
        };
        url.search = new URLSearchParams(requestParams).toString();
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setAvailsList(response['hydra:member']);
                // setIsLoading(false);
            });
    };
    useEffect(() => {
        // getPage request
        const res = {
            id: '49eca728-0cfd-11ec-92d7-0242ac170004',
            name: 'Page start',
            type: 'start',
            slug: 'start',

            // name: 'Movies',
            // type: 'subcategory',
            // contentType: 'movies',
            // slug: 'movies',
            validFrom: '2021-09-01T00:00:00+00:00',
            validTo: '2021-09-30T00:00:00+00:00',
            workflowStatus: 'published',

            releases: [{
                id: '42052fb9-0cfd-11ec-92d7-0242ac170004',
                name: 'Release 1',
                validFrom: '2021-09-01T00:00:00+00:00',
                validTo: '2021-09-30T00:00:00+00:00',
                workflowStatus: 'published'
              }],


            contents: [
              {
                id: '488475bc-0cfd-11ec-92d7-0242ac170004',
                name: '',
                type: 'topTeaserSlider',
                backgroundColor: '#ffffff',
                backgroundColorDark: '#000000',
                buttonLink: '',
                enabled: 1,
                orientation: 'landscape',
                shortText: '',
                teaserAlign: 'left',
                position: '0',

                logoImage: [], // from partners in the furure
                logoImageDark: [], // from partners in the future

                backgroundImage: [],
                backgroundImageDark: [],

                videos: [
                  {
                    id: 'videoID',
                    name: 'Nume film',
                    type: 'video' // serie / video
                  }

                ], // items

                series: [
                  {
                    id: 'serieID',
                    name: 'Nume serial',
                    type: 'serie' // serie / video
                  }

                ] // items
              }
            ]
          };
          setPageData(res);
          getAvails();
    }, []);

    useEffect(() => {
        if (pageData) {
            let pageContentType = null;
            const { slug, name, validTo, validFrom, workflowStatus, releases, type: pageType } = pageData;

            if (pageType !== 'start') {
                const { contentType } = pageData;
                pageContentType = contentType;
            }

            setPageTitle(name);
            setPageSlug(slug);
            setStartDate(validFrom);
            setEndDate(validTo);

            setStaticData({ releaseName: releases[0].name, workflowStatus, pageType, contentType: pageContentType });
        }
    }, [pageData]);

    return (
        pageData ? (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '40px 20px', backgroundColor: '#fff' }}>
                    <GeneralPageFields
                        staticData={staticData}
                        pageTitle={pageTitle}
                        pageSlug={pageSlug}
                        setPageTitle={setPageTitle}
                        setPageSlug={setPageSlug}
                        startDate={startDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setStartDate={setStartDate}
                    />
                    {/* Specific Page Fields  (pageType) */}
                    {pageData.type === 'start'
                ? null
                : (
                    <SubcategoryPageFields
                        tags={tags}
                        sortingOption={sortingOption}
                        channelPartener={channelPartener}
                        filters={filters}
                        setFilters={setFilters}
                        setChannelPartener={setChannelPartener}
                        setSorting={setSorting}
                        setTags={setTags}
                    />
)}
                    {/* Top Teaser Slider || Top Teaser (pageType) */}

                    <TopTeaserSlider
                        pageData={pageData}
                        openSearchModal={(currentTeaserlaneId, tabId) => setCurrentTabSearchModalIds({ currentTeaserlaneId, tabId })}
                    />
                    {teaserlanes.map((teaserlane, index) => (
                        <Teaserlane
                            teaserlane={teaserlane}
                            numOfLanes={teaserlanes.length}
                            teaserlanePosition={index}
                            handleSetNewTeaserTab={(currentTeaserlaneId) => handleSetNewTeaserTab(currentTeaserlaneId)}
                            handleMoveTeaserlane={handleMoveTeaserlane}
                            handleDeleteTeaserlane={handleDeleteTeaserlane}
                            openSearchModal={(currentTeaserlaneId, tabId) => setCurrentTabSearchModalIds({ currentTeaserlaneId, tabId })}
                        />
))}
                    {/* Add teaserlane */}
                    {pageData.type === 'start' ? (
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%', paddingTop: '40px' }}>
                            <Button
                                variant="text"
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                style={{ border: '1px solid #999' }}
                                disabled={isAddTeaserlaneSelectOpen}
                                startIcon={<Add />}
                                onClick={() => setIsAddTeaserlaneSelectOpen(true)}
                            >
                                Add teaserlane
                            </Button>
                            {isAddTeaserlaneSelectOpen
                    ? (
                        <AddTeaserlaneSelect
                            addTeaserLane={(newLane) => setTeaserlanes([...teaserlanes, newLane])}
                            onClose={() => setIsAddTeaserlaneSelectOpen(false)}
                        />
    )
                    : null}

                        </div>
) : null}
                </div>
                <SearchAssetsModal
                    isSearchModalOpen={!!currentTabSearchModalIds}
                    availsList={availsList}
                    handleSetTeaserAsset={(asset) => handleSetTeaserAsset(asset)}
                    onClose={() => setCurrentTabSearchModalIds(null)}
                />
            </>
        ) : null

    );
};

export default EditPageView;
