// in src/customRoutes.js
import React from 'react';
import { Route } from 'react-router-dom';
import ReleaseDashboard from './entities/Releases/ReleaseDashboard';
import AvailsView from './entities/Avails/AvailsView';
import AvailsViewSeasons from './entities/Avails/AvailsViewSeasons';
import AvailsViewMovies from './entities/Avails/AvailsViewMovies';
import EditPageView from './entities/Pages/EditPageView';
import AvailsEditMovie from './entities/Avails/AvailsEdit';
import SeasonsEdit from './entities/Seasons/EditSeasons';

export default [
    <Route exact path="/avails" component={AvailsView} />,
    <Route exact path="/seasons" component={AvailsViewSeasons} />,
    <Route match path="/seasons/:id/edit" component={SeasonsEdit} />,
    <Route exact path="/movies" component={AvailsViewMovies} />,
    <Route exact path="/movies/:id/edit" component={AvailsEditMovie} />,
    <Route exact path="/edit-page" component={EditPageView} />,
    <Route exact path="/release-dashboard" component={ReleaseDashboard} />
];
